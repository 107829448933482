/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: 0,
    borderTop: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  sub_button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12,
    marginTop: -10,
    marginBottom: -10
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const {
    pages,
    marketingCampaignPages,
    PaymentPages,
    // TrackingPages,
    // CommunicationPages,
    // dbCorruptionPages,
    // masterPages,
    userPages,
    cardPages,
    SalesDashboardPages,
    className,
    promoCodePages,
    jobPostsPages,
    PitchPages,
    // IntroPages,
    // ExperiencePostsPages,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}>
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
       {SalesDashboardPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
        {jobPostsPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
        {PaymentPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
       {/* {CommunicationPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
      {TrackingPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))} */}
      {marketingCampaignPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
      {promoCodePages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
      {/* {ExperiencePostsPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))} */}
      {PitchPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
      {/* {IntroPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))} */}
      {userPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
      {/* 
      {cardPages.map((item)=>(
        <ListItem
          className={classes.item}
          disableGutters>
          <ExpansionPanel style={{boxShadow:'none',marginLeft:-23,marginTo:-20,marginbottom:-20}}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            
            </ExpansionPanelSummary>
            {item.pages.map((p)=>(
              <ListItem
                className={classes.item}
                disableGutters>
                <ExpansionPanelDetails style={{marginLeft:20}}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}
                  >
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem> 
      ))} */}

      {/* {dbCorruptionPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))} */}

      {/* {masterPages.map((item) => (
        <ListItem className={classes.item} disableGutters>
          <ExpansionPanel
            style={{
              boxShadow: 'none',
              marginLeft: -23,
              marginTo: -20,
              marginbottom: -20
            }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                //to={item.href}
              >
                <div className={classes.icon}>{item.icon}</div>
                {item.title}
              </Button>
            </ExpansionPanelSummary>
            {item.pages.map((p) => (
              <ListItem className={classes.item} disableGutters>
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    activeClassName={classes.active}
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={p.href}>
                    <div className={classes.icon}>{p.icon}</div>
                    {p.title}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))} */}
    </List>
  );
};

SidebarNav.propTypes = {
  cardPages: PropTypes.array.isRequired,
  className: PropTypes.string,
  // dbCorruptionPages: PropTypes.array.isRequired,
  // masterPages: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  userPages: PropTypes.array.isRequired
};

export default SidebarNav;
