import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({

}));

const InfoCard = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default InfoCard;