import React, { useState, useEffect, useRef, useCallback } from 'react';
import sanitizeHtml from 'sanitize-html';
import { sendHttpCall } from 'shareService/httpService';
import styles from './Chats.module.scss';

const monthsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export default function Chats(props) {
  const [user1Info, setUser1Info] = useState({});
  const [user2Info, setUser2Info] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(0);
  const [page, setPage] = useState(0);
  const [lastMessageFetched, setLastMessageFetched] = useState(false);
  const userIds = props.match.params.userIds;
  const userId1 = userIds.split('-')[0];
  const userId2 = userIds.split('-')[1];
  const getData = () => {
    if (lastMessageFetched) return;
    const chatType = props.match.params.chatType;
    const url = `chats?userId1=${userId1}&userId2=${userId2}&type=${chatType}&limit=20&page=${page}`;
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      setData((d) => [...d, ...response.messages]);
      setUser1Info(response.user1Info);
      setUser2Info(response.user2Info);
      if (response.messages.length < 20) setLastMessageFetched(true);
      setLoading(false);
    });
  };
  const observer = useRef();
  const firstMsg = useCallback(
    (node) => {
      if (loading || !data.length) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setPage((p) => p + 1);
          }
        },
        { threshold: 0.1 }
      );
      if (node) observer.current.observe(node);
    },
    [loading]
  );
  useEffect(() => {
    getData();
  }, [page]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Chat Between {user1Info?.name} and {user2Info?.name}
      </div>
      <div className={styles.chatBoxContainer}>
        <div className={styles.chatBox}>
          {data.map((item, index) => {
            let showDate = false;
            const messageSent = item.fromUserId === parseInt(userId1, 10);
            if (index === data.length - 1) showDate = true;
            else if (
              new Date(item.crtd).toDateString() !==
              new Date(data[index + 1].crtd).toDateString()
            )
              showDate = true;
            return (
              <Message
                item={item}
                showDate={showDate}
                messageSent={messageSent}
                data={data}
                user1Info={user1Info}
                user2Info={user2Info}
              />
            );
          })}
          {!lastMessageFetched && (
            <div className={styles.loaderContainer} ref={firstMsg}>
              <div
                style={
                  loading && data.length
                    ? { display: 'block' }
                    : { display: 'none' }
                }>
                <div>Loading... </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const Message = ({ item, showDate, messageSent, user1Info, user2Info }) => {
  const relativeTime = useCustomMessageTimeHook(item.crtd);
  return (
    <>
      <div
        className={`${styles.messageContainer} ${
          messageSent
            ? styles.messageContainerSent
            : styles.messageContainerReceived
        }`}>
        <div className={styles.userPfp}>
          <img
            src={messageSent ? user1Info.image_id : user2Info.image_id}
            alt=""
          />
        </div>
        <div className={styles.messageBubble}>
          <div
            className={styles.messageText}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(item.message)
            }}
          />
          <div className={styles.messageTime}>{relativeTime.time}</div>
        </div>
      </div>
      {showDate ? <DateItem crtd={item.crtd} /> : ''}
    </>
  );
};
const formatChatDate = (date) =>
  `${date.getDate()} ${monthsFull[date.getMonth()]} ${date.getFullYear()}`;

const DateItem = ({ crtd }) => (
  <div className={styles.chatDateContainer}>
    {/* <div className={styles.chatDateLine} /> */}
    <div className={styles.chatDateValue}>{formatChatDate(new Date(crtd))}</div>
  </div>
);
const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  interval = seconds / 2592000;
  interval = seconds / 86400;
  interval = seconds / 3600;
  interval = seconds / 60;
  if (interval > 16) {
    return { thresholdCompleted: true, time: getTimeIn12Hours(date) };
  }
  if (interval > 1) {
    return {
      thresholdCompleted: false,
      time: `${Math.floor(interval)} min ago`
    };
  }
  return { thresholdCompleted: false, time: 'Just now' };
};

const useCustomMessageTimeHook = (date) => {
  const [relativeFormattedTime, setRelativeFormattedTime] = useState(
    timeSince(new Date(date))
  );
  let interval;
  useEffect(() => {
    if (!relativeFormattedTime.thresholdCompleted)
      interval = setInterval(() => {
        setRelativeFormattedTime(timeSince(new Date(date)));
      }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (relativeFormattedTime.thresholdCompleted) clearInterval(interval);
  }, [relativeFormattedTime.thresholdCompleted]);

  return relativeFormattedTime;
};
