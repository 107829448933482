import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sendHttpCall } from 'shareService/httpService';
import moment from 'moment';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
// import { UsersToolbar } from '../components';
import './style.css';
import ReactSelect from 'react-select';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AddPromoCode = (props) => {
  const [loading2, setLoading2] = useState(false);
  const [error, setError] = useState('');
  const [promoCodeName, setPromoCodeName] = useState('');
  const [validityType, setValidityType] = useState('');
  const [discount, setDiscount] = useState('');
  const [frequency, setFrequency] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const [data2, setData2] = useState('');
  const classes = useStyles();

  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };
  const addPromoCode = () => {
    if (data2) return;
    const data = {
      promo_code_name: promoCodeName,
      discount_percentage: parseInt(discount),
      validity_type: validityType,
      validity_frequency: validityType === 'FREQUENCY' ? frequency : null,
      validity_start:
        validityType === 'TIME_BASED'
          ? moment(new Date(startDate)).format('YYYY-MM-DD')
          : null,
      validity_end:
        validityType === 'TIME_BASED'
          ? moment(new Date(endDate)).format('YYYY-MM-DD')
          : null
    };
    console.log(data);
    if (data.promo_code_name === '')
      return setError("Can't have empty Promo Code Name");
    if (isNaN(data.discount_percentage))
      return setError('Invalid discount value');
    if (data.validity_type === '') return setError('Invalid validity type');
    if (!data.validity_frequency && !data.validity_start)
      return setError('Invalid validity parameters.');
    setError('');
    setLoading2(true);
    let url = '/promo_code/add_promo_code';
    sendHttpCall('POST', url, data, props)
      .then((response) => {
        if (response.state === 200) {
          setData2(response.data);
          //   setTotalCount(response.totalCount);
          setLoading2(false);
          setError('');
        } else {
          setLoading2(false);
          setError(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <div className="marketingCampaign__title">Promo Codes Manager</div>
        <div className="addCampaign__text">Add new Promo Code</div>

        <div className="addNewCampaign_container">
          <div className="addNewCampaign_label">Enter Promo Code Name</div>
          <input
            type="text"
            className="addNewCampaign_input"
            value={promoCodeName}
            onChange={(e) => setPromoCodeName(e.target.value)}
          />
          <div className="addNewCampaign_label">Enter Discount Percentage</div>
          <input
            type="text"
            value={discount}
            className="addNewCampaign_input"
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setDiscount(e.target.value);
              }
            }}
            // onChange={(e) => {
            //   setNewCampaignInput(e.target.value);
            // }}
          />
          <div className="addNewCampaign_label">Choose Validity Type</div>
          <ReactSelect
            options={[
              {
                label: 'Time Based',
                value: 'TIME_BASED'
              },
              {
                label: 'Frequency Based',
                value: 'FREQUENCY'
              }
            ]}
            onChange={(e) => {
              setValidityType(e.value);
            }}
          />
          <div className="addNewCampaign_container">
            {validityType === 'FREQUENCY' && (
              <>
                <div className="addNewCampaign_label">
                  Enter Promo Code Frequency
                </div>
                <input
                  type="text"
                  value={frequency}
                  className="addNewCampaign_input"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setFrequency(e.target.value);
                    }
                  }}
                />
              </>
            )}
            {validityType === 'TIME_BASED' && (
              <>
                <div className="addNewCampaign_label">Enter Start Date</div>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-start"
                  // label="Date picker dialog"
                  format="dd/MM/yyyy"
                  value={startDate}
                  minDate={new Date()}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
                <div className="addNewCampaign_label">Enter End Date</div>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-end"
                  // label="Date picker dialog"
                  minDate={startDate}
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </>
            )}
          </div>
          {validityType !== '' && (
            <button className="addNewCampaign_btn" onClick={addPromoCode}>
              {loading2
                ? 'Adding Promo Code'
                : data2
                ? 'Promo Code Added'
                : 'Add Promo Code'}
            </button>
          )}
          {data2 && (
            <div className="addNewCampaign_label">
              Link for Promo Code :<br />
              <a href={data2} target="_blank">
                {data2}
              </a>
            </div>
          )}

          {error && <div className="addNewCampaign_error">{error}</div>}
        </div>
        {/* </ReactModal> */}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default AddPromoCode;
