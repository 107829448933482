import React, { useEffect, useRef } from 'react';

export default function AudioPlayer({ src, onPlaying, onPause }) {
  const audioPlayer = useRef(null);

  const loadSongDetails = () => {
    console.log(audioPlayer.current.duration);
  };
  useEffect(() => {
    if (src) {
      console.log('playing audio');
      audioPlayer.current.src = src;
      audioPlayer.current.currentTime = 0;
      audioPlayer.current.play();
    } else {
      console.log('pausing audio');
      audioPlayer.current.pause();
      audioPlayer.current.src = '';
    }
  }, [src]);
  return (
    <audio
      ref={audioPlayer}
      onPause={onPause}
      onPlay={onPlaying}
      onDurationChange={(e) => console.log(e.target.duration)}
      onLoadedMetadata={loadSongDetails}
    />
  );
}
