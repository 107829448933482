import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  IconButton,
  Typography,
  InputBase,
  Snackbar
} from '@material-ui/core';
import { PhotoCamera, Delete } from '@material-ui/icons';
import MuiAlert from '@mui/material/Alert';
import ReactQuill from 'react-quill';
import Loader from 'components/Loader';
import ChipInput from 'material-ui-chip-input';
import 'react-quill/dist/quill.snow.css';
import { sendFileUploadHttpCall, sendHttpCall } from 'shareService/httpService';
import { getFormatedDate } from '../helper';
import styles from './styles.module.scss';
import context from 'react-bootstrap/esm/AccordionContext';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BlogEdit(props) {
  const [blogData, setBlogData] = useState({
    title: '',
    menuBar: '',
    categories: [],
    tags: [],
    altTags: [],
    url: '',
    metaDescription: '',
    content: '',
    bannerImage: '',
    featuredImage: '',
    blogDate: '',
    blogId: props.match.params.blog_id || ''
  });

  const [imageFile, setImageFile] = useState(null);
  const [imageFile2, setImageFile2] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const placeholderImage = 'https://via.placeholder.com/496x278';
  const placeholderImage2 = 'https://via.placeholder.com/1440x518';

  useEffect(() => {
    if (blogData.blogId) {
      sendHttpCall(
        'GET',
        `blogs/getById?id=${blogData.blogId}`,
        null,
        props
      ).then((response) => {
        setEdit(true);
        if (response.blogData) {
          setBlogData({
            ...blogData,
            title: response.blogData.title,
            menuBar:
              response.blogData.menu_bar !== 'null'
                ? response.blogData.menu_bar
                : '',
            tags: response.blogData.tags,
            altTags: response.blogData.alt_tags,
            categories: response.blogData.category,
            url: response.blogData.url !== 'null' ? response.blogData.url : '',
            metaDescription:
              response.blogData.meta_description !== 'null'
                ? response.blogData.meta_description
                : '',
            content: response.blogData.text,
            bannerImage: response.blogData.bannerImage || '',
            featuredImage: response.blogData.featuredImage || '',
            blogDate: response.blogData.created_at
          });
          setCharCount(response.blogData.title.length);
          setDescriptionCharCount(response.blogData.meta_description.length)
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [blogData.blogId]);

  useEffect(() => {
    // Update image files when blogData changes
    if (blogData.bannerImage) {
      fetch(blogData.bannerImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'BannerImage', { type: blob.type });
          setImageFile(file);
        });
    }

    if (blogData.featuredImage) {
      fetch(blogData.featuredImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'FeatureImage', { type: blob.type });
          setImageFile2(file);
        });
    }
  }, [blogData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name)
    console.log(value)
    switch (name) {
      case 'title':
        setCharCount(value.length);
        break;
      case 'menuBar':
        break;
      case 'url':
        break;
      case 'metaDescription':
        setDescriptionCharCount(value.length);
        break;
      case 'tags':
        break;

    }
    setBlogData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateFields = () => {
    let validationErrors = {};
    if (!blogData.title) validationErrors.title = 'Title is required';
    if (!blogData.content) validationErrors.content = 'Content is required';
    if (blogData.title.length > 60) validationErrors.title = 'Title cannot be more than 60 characters'
    if (blogData.metaDescription.length > 160) validationErrors.meta_description = 'Meta Description cannot be more than 160 characters'
    if (blogData.categories.length === 0)
      validationErrors.categories = 'At least one category is required';
    if (blogData.tags.length === 0)
      validationErrors.tags = 'At least one tag is required';
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleImageUpload = (event, imageType) => {
    const file = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file) {
      if (file.size > maxFileSize) {
        setSnackbar({
          open: true,
          message: `File size exceeds 5MB. Current size: ${(
            file.size /
            1024 /
            1024
          ).toFixed(2)}MB`,
          severity: 'warning'
        });
        return;
      }

      if (imageType === 'banner') {
        if (imageFile2 && imageFile2.name === file.name) {
          setSnackbar({
            open: true,
            message: 'Banner and Featured images cannot be the same.',
            severity: 'warning'
          });
          return;
        }
        setImageFile(file);
      } else {
        if (imageFile && imageFile.name === file.name) {
          setSnackbar({
            open: true,
            message: 'Banner and Featured images cannot be the same.',
            severity: 'warning'
          });
          return;
        }
        setImageFile2(file);
      }
    }
  };

  const handleSaveButtonClick = () => {
    if (!validateFields()) {
      setSnackbar({
        open: true,
        message: 'Please fill out all mandatory fields.',
        severity: 'warning'
      });
      return;
    }

    if (blogData.title.length > 100) {
      setSnackbar({
        open: true,
        message: 'Title exceeds maximum length of 100 characters.',
        severity: 'warning'
      });
      return;
    }

    const data = new FormData();
    if (imageFile) data.append('files[]', imageFile, 'BannerImage');
    if (imageFile2) data.append('files[]', imageFile2, 'FeatureImage');

    Object.keys(blogData).forEach((key) => {
      if (blogData[key]) {
        data.append(
          key,
          Array.isArray(blogData[key])
            ? JSON.stringify(blogData[key])
            : blogData[key]
        );
      }
    });

    const url = edit ? 'blogs/edit' : 'blogs/add';
    const method = edit ? 'PUT' : 'POST';

    sendFileUploadHttpCall(method, url, data, props)
      .then((response) => {
        if (response.blogData) {
          setSnackbar({
            open: true,
            message: edit
              ? 'Successfully edited the Blog'
              : 'Successfully added the Blog',
            severity: 'success'
          });
          props.history.push('/blog-details');
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Something went wrong. Please try again.',
          severity: 'error'
        });
      });
  };

  const handleDeleteButtonClick = () => {
    if (edit) {
      sendHttpCall('POST', 'blogs/delete', { id: blogData.blogId }, props)
        .then((response) => {
          if (response.blogData) {
            setSnackbar({
              open: true,
              message: 'Successfully Deleted the Blog',
              severity: 'success'
            });
            props.history.push('/blog-details');
          }
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message: 'Something went wrong. Please try again.',
            severity: 'error'
          });
        });
    }
  };

  const handleChipDelete = (chipToDelete, type) => {
    setBlogData((prevData) => ({
      ...prevData,
      [type]: prevData[type].filter((chip) => chip !== chipToDelete)
    }));
  };

  const handleChipAdd = (chip, type) => {
    if (chip.length > 30) {
      setSnackbar({
        open: true,
        message: 'Tags cannot exceed 30 characters.',
        severity: 'warning'
      });
      return;
    }
    if (blogData[type].includes(chip)) {
      setSnackbar({
        open: true,
        message: 'Duplicate tags are not allowed.',
        severity: 'warning'
      });
      return;
    }
    setBlogData((prevData) => ({
      ...prevData,
      [type]: [...new Set([...prevData[type], chip])]
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  if (loading) {
    return <Typography variant="h2"><Loader /></Typography>;
  }

  return (
    <div className={styles.container}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div className={styles.buttonGroupTop}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveButtonClick}>
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => props.history.push('/blog-details')}>
          Cancel
        </Button>
        {edit && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteButtonClick}>
            Delete
          </Button>
        )}
      </div>
      <Typography variant="h4" className={styles.title}>
        Create/Edit Blog
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Card className={styles.card}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={styles.avatar}>
                  G
                </Avatar>
              }
              title="GoodSpace"
              subheader={edit ? getFormatedDate(blogData.blogDate) : ''}
            />
            <Grid container spacing={3} className={styles.form}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="title"
                  value={blogData.title}
                  onChange={handleInputChange}
                  placeholder="Enter title"
                  inputProps={{ maxLength: 60 }}
                  className={`${styles.inputField}`}
                  error={!!errors.title}
                  helperText={errors.title}
                />
                <Typography variant='caption'
                  color='textSecondary'>
                  {charCount}/60
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="menuBar"
                  value={blogData.menuBar}
                  onChange={handleInputChange}
                  placeholder="Enter menu bar"
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={6}>
                <ChipInput
                  fullWidth
                  name="categories"
                  value={blogData.categories}
                  onAdd={(chip) => handleChipAdd(chip, 'categories')}
                  onDelete={(chip) => handleChipDelete(chip, 'categories')}
                  placeholder="Add categories"
                  className={`${styles.inputField}`}
                  error={!!errors.categories}
                  helperText={errors.categories}
                />
              </Grid>
              <Grid item xs={6}>
                <ChipInput
                  fullWidth
                  name="tags"
                  value={blogData.tags}
                  onAdd={(chip) => handleChipAdd(chip, 'tags')}
                  onDelete={(chip) => handleChipDelete(chip, 'tags')}
                  placeholder="Add tags"
                  className={`${styles.inputField}`}
                  error={!!errors.tags}
                  helperText={errors.tags}
                />
              </Grid>
              <Grid item xs={6}>
                <ChipInput
                  fullWidth
                  name="altTags"
                  value={blogData.altTags}
                  onAdd={(chip) => handleChipAdd(chip, 'altTags')}
                  onDelete={(chip) => handleChipDelete(chip, 'altTags')}
                  placeholder="Add alt tags"
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="url"
                  value={blogData.url}
                  onChange={handleInputChange}
                  placeholder="Enter URL"
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="metaDescription"
                  multiline
                  rows={3}
                  value={blogData.metaDescription}
                  onChange={handleInputChange}
                  error={!!errors.meta_description}
                  helperText={errors.meta_description}
                  inputProps={{ maxLength: 160 }}
                  placeholder="Enter meta description"
                  className={styles.inputField}
                />
                <Typography variant='caption'
                  color='textSecondary'>
                  {descriptionCharCount}/160
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tagsInline"
                  placeholder="Add comma-separated values. These will appear as tags."
                  value={blogData.tags.join(', ')}
                  onChange={(e) => {
                    handleInputChange({
                      target: {
                        name: 'tags',
                        value: e.target.value
                          .split(',')
                          .map((item) => item.trim())
                      }
                    });
                  }}
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={12}>
                <ReactQuill
                  theme="snow"
                  value={blogData.content}
                  onChange={(content) => setBlogData({ ...blogData, content })}
                  className={`${styles.editor} ${styles.inputField}`}
                />
                {errors.content && (
                  <Typography className={styles.errorText}>
                    {errors.content}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={styles.card}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={styles.imageUpload}>
                <Typography variant="body1">
                  Banner Image (496 x 278px)
                </Typography>
                <div className={`${styles.imagePreviewContainer}`}>
                  <img
                    src={
                      imageFile
                        ? URL.createObjectURL(imageFile)
                        : blogData.bannerImage || placeholderImage
                    }
                    alt="Banner"
                    className={styles.imagePreview}
                  />
                  {imageFile && (
                    <IconButton
                      className={`${styles.deleteIcon} ${styles.outsideDeleteIcon}`}
                      onClick={() => setImageFile(null)}>
                      <Delete />
                    </IconButton>
                  )}
                </div>
                <div>
                  {imageFile && (
                    <Typography variant="caption" className={styles.imageSize}>
                      Size: {(imageFile.size / 1024 / 1024).toFixed(2)}MB
                    </Typography>
                  )}
                </div>
                {errors.bannerImage && (
                  <Typography className={styles.errorText}>
                    {errors.bannerImage}
                  </Typography>
                )}
                <InputBase
                  type="file"
                  onChange={(e) => handleImageUpload(e, 'banner')}
                  startAdornment={
                    <IconButton color="primary" component="span">
                      <PhotoCamera />
                    </IconButton>
                  }
                  className={styles.uploadButton}
                />
              </Grid>

              <Grid item xs={12} className={styles.imageUpload}>
                <Typography variant="body1">
                  Featured Image (1440 x 518px)
                </Typography>
                <div className={`${styles.imagePreviewContainer} `}>
                  <img
                    src={
                      imageFile2
                        ? URL.createObjectURL(imageFile2)
                        : blogData.featuredImage || placeholderImage2
                    }
                    alt="Featured"
                    className={styles.imagePreview}
                  />

                  {imageFile2 && (
                    <IconButton
                      className={`${styles.deleteIcon} ${styles.outsideDeleteIcon}`}
                      onClick={() => setImageFile2(null)}>
                      <Delete />
                    </IconButton>
                  )}
                </div>
                <div>
                  {imageFile2 && (
                    <Typography variant="caption" className={styles.imageSize}>
                      Size: {(imageFile2.size / 1024 / 1024).toFixed(2)}MB
                    </Typography>
                  )}
                </div>
                {errors.featuredImage && (
                  <Typography className={styles.errorText}>
                    {errors.featuredImage}
                  </Typography>
                )}
                <InputBase
                  type="file"
                  onChange={(e) => handleImageUpload(e, 'featured')}
                  startAdornment={
                    <IconButton color="primary" component="span">
                      <PhotoCamera />
                    </IconButton>
                  }
                  className={styles.uploadButton}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
