import React, { useEffect, useRef, useState } from 'react';
import ReactSlider from '@mui/material/Slider';
import styles from './AudioPlayer.module.scss';

export default function AudioPlayer({ src }) {
  const interval = useRef(null);
  const audioPlayer = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [timeStamp, setTimeStamp] = useState(false);
  const [duration, setDuration] = useState(null);
  const playAudio = () => {
    audioPlayer.current.play();
    // setPlaying(true);
  };
  const pauseAudio = () => {
    audioPlayer.current.pause();
    // setPlaying(false);
  };
  const toggleAudio = () => (playing ? pauseAudio() : playAudio());

  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const formatTime = (time) => {
    const minutes = addZero(Math.floor(time / 60));
    const seconds = addZero(Math.floor(time % 60));
    return `${minutes}:${seconds}`;
  };
  const seekAudio = (val) => {
    const temp = (val / 100) * duration;
    setTimeStamp(temp);
    audioPlayer.current.currentTime = temp;
  };
  const loadSongDetails = () => {
    console.log(audioPlayer.current.duration);
    setDuration(audioPlayer.current.duration);
    setTimeStamp(0);
    interval.current = setInterval(() => {
      setTimeStamp(audioPlayer.current.currentTime);
    }, 1000);
  };
  useEffect(() => {
    audioPlayer.current.src = src;
    audioPlayer.current.currentTime = 0;
    if (playing) audioPlayer.current.play();
    return () => {
      // cleanup;
      clearInterval(interval.current);
    };
  }, [src]);
  return (
    <div className={styles.container}>
      <div className={styles.playPauseButton} onClick={toggleAudio}>
        {playing ? <PlayIcon /> : <PauseIcon />}
      </div>
      <div className={styles.seekBar}>
        <ReactSlider
          valueLabelDisplay="off"
          sx={{
            // color: "success.main",
            '&.MuiSlider-root': {
              color: '#389fff',
              height: '2px'
            },
            '& .MuiSlider-thumb': {
              //   backgroundColor: "transparent",
              width: '15px',
              height: '15px'
            }
            //   "& .MuiSlider-rail": {
            //     opacity: "1",
            //   },
          }}
          min={0}
          max={100}
          value={(timeStamp / duration) * 100}
          onChange={(e) => {
            seekAudio(e.target.value);
          }}
        />
      </div>
      <div className={styles.fileInfo}>{formatTime(duration)} mp3</div>
      <audio
        ref={audioPlayer}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onDurationChange={(e) => console.log(e.target.duration)}
        onLoadedMetadata={loadSongDetails}
      />
    </div>
  );
}

const PlayIcon = () => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.163 3.819C5 4.139 5 4.559 5 5.4v13.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.581.164h.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .656-.656c.163-.32.163-.74.163-1.581V5.4c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C8.861 3 8.441 3 7.6 3h-.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656zm9 0C14 4.139 14 4.559 14 5.4v13.2c0 .84 0 1.26.164 1.581a1.5 1.5 0 0 0 .655.655c.32.164.74.164 1.581.164h.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .655-.656c.164-.32.164-.74.164-1.581V5.4c0-.84 0-1.26-.163-1.581a1.5 1.5 0 0 0-.656-.656C17.861 3 17.441 3 16.6 3h-.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.655.656z"
      fill="#389fff"
    />
  </svg>
);
const PauseIcon = () => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.286 3.407A1.5 1.5 0 0 0 6 4.684v14.632a1.5 1.5 0 0 0 2.286 1.277l11.888-7.316a1.5 1.5 0 0 0 0-2.555L8.286 3.407z"
      fill="#389fff"
    />
  </svg>
);
