import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sendHttpCall } from 'shareService/httpService';
import ReactModal from 'react-modal';
import './styles.scss';
import moment from 'moment';
import styles from './styles.module.scss';

import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));
export default function (props) {
  const classes = useStyles();
  // const navigate = useNavigate();
  const start = new Date();
  const [editModalData, setEditModalData] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [lastPage, setLastPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  //   const [startDate, setStartDate] = useState(
  //     props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
  //   );
  //   const [endDate, setEndDate] = useState(
  //     props.match.params.end_date
  //       ? props.match.params.end_date
  //       : moment(new Date(start)).format('YYYY-MM-DD')
  //   );

  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const getData = () => {
    setLoading(true);
    let url = `/social-experiences/get_under_review?limit=${10}&page=${page}`;
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      console.log();
      setData(response.data);
      let count = response.totalCount;
      setTotalCount(count);
      setLastPage(Math.ceil(count / 10));
      setLoading(false);
    });
  };
  const changeJobStatus = (socialExpId, approved) => {
    let url = `/social-experiences/update_exp_post_status`;
    sendHttpCall(
      'POST',
      url,
      {
        socialExpId,
        approved
      },
      props
    ).then((response) => {
      console.log(response);
      //   setSelectedJob({});
      showToast(
        approved
          ? 'Successfully approved experience post.'
          : 'Successfully rejected experience post.'
      );
      setTimeout(getData, 2000);
    });
  };
  const approveJob = (offerId) => {
    changeJobStatus(offerId, true);
  };
  const rejectJob = (offerId) => {
    changeJobStatus(offerId, false);
  };

  useEffect(() => {
    getData();
  }, [page]);
  return (
    <div>
      <div className="jobs_under_review__container">
        <div className="jobs_under_review__header">
          Experience Posts Under Review
        </div>
        <div className={styles.cardsContainer}>
          {loading && (
            <div className="jobs_under_review__posts-loading">Loading...</div>
          )}
          {!loading &&
            data.map((item) => (
              <div
                className={styles.superContainer}
                style={{
                  margin: '1.5rem',
                  marginLeft: 0,
                  marginTop: 0
                }}>
                {' '}
                <div className={styles.expCard}>
                  <div className={styles.expCardImg}>
                    <img src={item.imgLink} alt="" />
                  </div>
                  <div className={styles.expDetails}>
                    <div>
                      <div className={styles.expUserRow}>
                        <div className={styles.expUserRowLeft}>
                          <div className={styles.expUserImg}>
                            <img src={item.posterPfp} alt="" />
                          </div>
                          <div className={styles.expUserName}>
                            {item.posterName}
                          </div>
                        </div>
                      </div>
                      <div className={styles.expTitle}>{item.title}</div>
                      <div className={styles.expDesc}>{item.description}</div>
                    </div>
                    {/* <div
                    className={styles.expBtn}
                    style={showViewInterestsButton ? { cursor: "pointer" } : {}}
                    onClick={
                      showViewInterestsButton
                        ? () => onViewInterestExpClick(data.socialExpId)
                        : ""
                    }
                  >
                    {data.interestedPeopleCount +
                      (data.interestedPeopleCount !== 1 ? " Interests " : " Interest ")}
                    Shown
                  </div> */}
                  </div>
                </div>
                <div className={styles.buttonsContainer}>
                  <div
                    className={styles.acceptBtn}
                    onClick={() => approveJob(item.socialExpId)}>
                    Approve
                  </div>
                  <div
                    className={styles.rejectBtn}
                    onClick={() => setSelectedJob(item)}>
                    Reject
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="jobs_under_review__pagination">
          <Pagination
            size="large"
            color="primary"
            count={lastPage}
            page={page}
            onChange={handleChange}
          />
          <div className="jobs_under_review__pagination-count">
            Total Count : {totalCount}
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={selectedJob.socialExpId}
        className="jobs_under_review__modal-modal"
        overlayClassName="jobs_under_review__modal-overlay">
        <div className="jobs_under_review__modal">
          Are you sure you want to reject experience post for{' '}
          {selectedJob.title} by {selectedJob.posterName}?
          <div className="jobs_under_review__modal-btns">
            <button
              className="jobs_under_review__modal-btn-reject"
              onClick={() => {
                rejectJob(selectedJob.socialExpId);
                setSelectedJob({});
              }}>
              Reject
            </button>
            <button
              className="jobs_under_review__modal-btn-cancel"
              onClick={() => {
                setSelectedJob({});
              }}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>{' '}
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}
