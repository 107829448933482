import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactModal from 'react-modal';
import ReactSelect from 'react-select';
import { makeStyles } from '@material-ui/styles';
import styles from './styles.module.scss';
import {
  Paper,
  // Card,
  // CardContent,
  Avatar,
  Typography,
  Divider,
  LinearProgress,
  Grid,
  // TextField,
  Button
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
// import EmailIcon from '@material-ui/icons/Email';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import PinDropIcon from '@material-ui/icons/PinDrop';
// import WcIcon from '@material-ui/icons/Wc';
// import Crop32Icon from '@material-ui/icons/Crop32';
// import Rating from '@material-ui/lab/Rating';
// import EditIcon from '@material-ui/icons/Edit';
// import { apiUrl } from 'config';
// import FormDialog from 'components/CustomDialog/CustomDialog';
import { sendHttpCall } from 'shareService/httpService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  score: {
    // margin: theme.spacing(3),
    border: '2px solid #3f51b5',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 10
  }
}));

const UserAccountDetails = (props) => {
  const {
    className,
    userid,
    user,
    modes,
    selectedMode,
    setSelectedMode,
    ...rest
  } = props;
  console.log(user);
  const classes = useStyles();
  const [purposeVal, setPurposeVal] = useState(null);
  const [parameters, setParameters] = useState([]);
  // const [user, setUser] = useState({});
  const [open, setopen] = useState(false);
  const [confirmBlock, setConfirmBlock] = useState(false);
  const [confirmOfferBlock, setConfirmOfferBlock] = useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [purpose, setPurpose] = useState(' ');
  const [showPurposeModal, setShowPurposeModal] = useState(false);
  const [userStatus, setUserStatus] = useState(user.status);
  // const users = props.user;

  // async function fetchUserDetail() {
  //   sendHttpCall('GET', `users/user_detail/${props.userid}`, null, props).then((response) => {
  //     setUser(response);
  //   })
  // }

  const rcStyle = {
    color: '#00b8e6',
    fontWeight: 'bold'
  };

  async function fetchUserParamerViewedScore() {
    sendHttpCall(
      'GET',
      `users/user_parameter/${props.userid}`,
      null,
      props
    ).then((response) => {
      setParameters(response);
    });
  }

  const handleBlock = async () => {
    console.log(user);
    let status1 = user.status == 5 ? 3 : 5;
    sendHttpCall(
      'POST',
      'users/block',
      { userId: user.user_id, status: status1 },
      props
    )
      .then((response) => {
        setUserStatus(status1);
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handleOfferBlock = async () => {
    let disable = user.sendOfferDisabled ? false : true;
    sendHttpCall(
      'POST',
      'users/toggle_send_offers',
      { userId: user.user_id, disable },
      props
    )
      .then((response) => {
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handleDeactivate = async () => {
    let status1 = user.status == 6 ? 3 : 6;
    sendHttpCall(
      'POST',
      'users/deactivate',
      { userId: user.user_id, status: status1 },
      props
    )
      .then((response) => {
        setUserStatus(status1);
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handleOpenDialog = () => {
    setopen(true);
  };

  const getDialogData = (data) => {
    if (data == 'x') {
      setopen(false);
    }
  };

  const fixedLenthString = (data) => {
    if (data.length > 0) {
      var d = data.substring(0, data.length / 3);
      return d;
    }
  };

  useEffect(() => {
    // fetchUserDetail();
    fetchUserParamerViewedScore();
  }, []);
  const getNameFromId = (d) => {
    const options = [
      { label: 'LinkedIn', value: 2 },
      { label: 'Github', value: 3 },
      { label: 'Twitter', value: 5 },
      { label: 'Instagram', value: 4 },
      { label: 'Facebook', value: 1 },
      { label: 'Behance', value: 6 },
      { label: 'Other', value: 7 }
    ];
    return options.find((item) => item.value === d)?.label;
  };
  const changePurpose = () => {
    sendHttpCall(
      'POST',
      'users/changePurpose',
      { userId: user.user_id, purposeId: purposeVal.value },
      props
    )
      .then((response) => {
        window.location.reload(true);
      })
      .catch((e) => {
        console.log(e);
        alert('Something went wrong');
      });
  };

  console.log(user.links);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant="h4">
          User links
        </Typography>
        <Divider gutterBottom />
        <div className={styles.container}>
          <div className={styles.links}>
            {user?.links?.map((item) => (
              <div className={styles.link}>
                <div className={styles.linkIcon}>
                  {getNameFromId(item.link_type_id)}
                </div>
                <a href={item.user_link} target="_blank">
                  {item.user_link}
                </a>
              </div>
            ))}
          </div>
        </div>
      </Paper>
    </div>
  );
};

UserAccountDetails.propTypes = {
  className: PropTypes.string
};

export default UserAccountDetails;
