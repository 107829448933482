// eslint-disable-next-line react/jsx-filename-extension

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import ReactModal from 'react-modal';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({}));

export default function PotentialCandidatess8EmailFullText(props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [uniqueJobCount, setUniqueJobCounts] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('potentialCandidates8EmailFullTextPage'), 10) || 0
  );
  const [pageSize, setPageSize] = useState(
    parseInt(sessionStorage.getItem('potentialCandidates8EmailFullTextSize'), 10) || 10
  );
  const getData = () => {
    // const encodedOfferId = encodeURIComponent(props.match.params.offerId);
    let url = `/jobs/potential_candidates_ai_cutoff_user3_full_text?page=${page}&limit=${pageSize}&offerId=${props.match.params.offerId}`;
    setIsLoading(true);
    sendHttpCall('GET', url, null, props).then((response) => {
      sessionStorage.removeItem('potentialCandidates8EmailFullTextSize');
      sessionStorage.removeItem('potentialCandidates8EmailFullTextPage');
      setData(response.data);
      setTotalCount(response.totalCount);
      setIsLoading(false);
    });
  };
  
  useEffect(() => {
    getData();
  }, [page]);
  useEffect(() => {
    if (!data.length) return;
    if (page === 0) getData();
    else setPage(0);
  }, [pageSize]);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          isLoading={isLoading}
          columns={[
            {
              title: 'S No.',
              render: (rowData) => page * pageSize + rowData.tableData.id + 1
            },
            {
              title: 'name',
              field: 'name',
              render: (rowData) => (
                <div
                  onClick={() => {
                    sessionStorage.setItem('potentialCandidates8EmailFullTextPage', page);
                    sessionStorage.setItem('potentialCandidates8EmailFullTextSize', pageSize);
                    props.history.push(`/account/${rowData.user_id}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.name}
                </div>
              )
            },
            { title: 'Job Title', field: 'userDesignation',render: (rowData) => rowData.userDesignation.join(',\t') },
            { title: 'Location', field: 'location_city' },
            { title: 'Work Ex', field: 'total_work_experience_years',render: (rowData) => rowData.total_work_experience_years+" Years"}
          ]}
          data={data}
          totalCount={totalCount}
          title="Potential Candidates status8"
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 50]
          }}
        />
      </CardContent>{' '}
    </Card>
  );
}
