import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from "material-table";
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
}));

const UserTable = props => {

  const { title, data} = props;
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <Card
    >
      <CardContent className={classes.content}>
        <MaterialTable
          actions={[
            {
              icon: 'send',
              tooltip: 'Open User',
              onClick: (event, rowData) => {
                props.history.push(`/account/${rowData.user_id}`);
              }
            }
          ]}
          columns={[
            { title: 'id', field: 'user_id' },
            { title: 'Name', field: 'name' },
            { title: 'Phone', field: 'mobile_number' },
            { title: 'Location', field: 'location_city' },
            { title: 'Registration Date', field: 'created_at', type: 'date' },
            { title: 'G Coins', field: 'count' },
          ]}
          data= {data}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 5
          }}
          title={title}
        />
      </CardContent>
    </Card>
  );
};

UserTable.propTypes = {
  className: PropTypes.string,
};


export default withRouter(UserTable);