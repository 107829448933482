// JobBoostMasterContext.js
import React, { createContext, useContext, useState } from 'react';
import { sendHttpCall } from 'shareService/httpService';
const JobBoostMasterContext = createContext();

export function useJobBoostMaster() {
  return useContext(JobBoostMasterContext);
}

export const JobBoostMasterProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [boostData, setBoostData] = useState(null);

  const openBoostDrawer = async (offerId) => {
    setIsOpen(true);
    console.log(offerId);
    let url = `job/boostHistory/${offerId}`;
    sendHttpCall('GET', url, null)
      .then((response) => {
        setBoostData(response);
        console.log(response);
      })
      .catch((error) => {
        console.log('Error fetching lead data', error);
      });
  };

  const closeBoostDrawer = () => {
    setIsOpen(false);
    setBoostData(null);
  };

  return (
    <JobBoostMasterContext.Provider
      value={{ isOpen, boostData, openBoostDrawer, closeBoostDrawer }}>
      {children}
    </JobBoostMasterContext.Provider>
  );
};
