import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from "material-table";
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
}));

const LinkClicks = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <MaterialTable
          actions={[
            {
              icon: 'send',
              tooltip: 'Open User',
              onClick: (event, rowData) => {
                props.history.push(`/dashboard/uan/${rowData.name}/${rowData.user_id}/${rowData.start_date}/${rowData.end_date}`);
              }
            }
          ]}
          columns={[
            { title: "Sl.No", field: "slno" },
            { title: "User Id", field: "user_id" },
            { title: 'Name', field: 'name' },
            { title: "Link Click Count", field: "linkclickCount" },
            { title: 'Survey Received', field: 'surveyCount' },
          ]}
          data={query => {
            return new Promise((resolve, reject) => {
              let url = 'dashboard/get_link_clicks';
              sendHttpCall('POST', url, { limit: query.pageSize, offset: query.page,start_date: props.match.params.start_date, end_date: props.match.params.end_date }, props)
                .then(response => {
                  for (let i = 0; i < response.data.length; i++) {
                    response.data[i].slno = i + 1;
                  };

                  resolve({
                    data: response.data,
                    page: response.page,
                    totalCount: response.count,
                  })
                })
                .catch((e)=>{
                })
            })
          }
          }
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30,50]
          }}
          title="All Users"
        />
      </CardContent>
    </Card>
  );
};

LinkClicks.propTypes = {
  className: PropTypes.string,
};

export default withRouter(LinkClicks);