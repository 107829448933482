import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Divider,
  Grid,
  Button,
  Typography,
  Paper,
} from '@material-ui/core';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import Crop32Icon from '@material-ui/icons/Crop32';
// import { apiUrl } from 'config';
import { sendHttpCall } from 'shareService/httpService';
// import CustomAutocomplete from '../../../../components/CustomAutoComplete/CustomAutoComplete';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  text: {
    fontWeight: 'bold',
    float: 'right',
    margin: 10,
    color: '#3f51b5',
    fontSize: 17,
    cursor: 'pointer'
  },
  interest: {
    margin: theme.spacing(1),
  }
}));

const UserPersonalDetails = props => {
  const { className, userid, user, ...rest } = props;
  const classes = useStyles((theme) => ({
    cardSpace: {
      marginLeft: theme.spacing(2)
    }
  }));

  // const user = props.user
  // const [open, setOpen] = React.useState(false);
  // const [interests, setInterests] = React.useState([]);
  const [userInterests, setUserInterests] = useState([]);
  // const [newInterests, setNewInterests] = useState([]);

  async function fetchUserInterests() {
    sendHttpCall('GET', `users/interest/${props.userid}`, null, props).then((response) => {
      setUserInterests(response);
    })
  }

  // const getInterests = () => {
  //   sendHttpCall('GET', 'interests', null, props).then((response) => {
  //     setInterests(response.data)
  //   })
  // }

  // const handleAddInterest = () => {
  //   var promise = new Promise((resolve, reject) => {
  //     newInterests.forEach(element => {
  //       sendHttpCall('POST', 'user_interest',
  //         { user_id: props.userid, interest_id: element[0].interest_id, score: 0, status: 'ACTIVE' }, props).then((data) => {
  //         });
  //     });
  //     resolve();
  //   });

  //   promise.then(() => {
  //     fetchMyAPI();
  //     setOpen(false);
  //   })
  // }

  // const getData = (data) => {
  //   setNewInterests(data);
  // }

  useEffect(() => {
    fetchUserInterests();
    // getInterests();
  }, [])

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container >
            <Grid xs={6} item>
              <Typography
                gutterBottom
                variant="h4"
              >
                Personal Details
              </Typography>
              <Divider gutterBottom/>
              <Grid
                container
                spacing={0}
              >
                <Grid
                  item
                  xs={2}
                >
                  <Crop32Icon color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <Typography variant="h6" color="textSecondary">
                    Height : {user.height}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <LineWeightIcon color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <Typography variant="h6" color="textSecondary">
                    Weight :{user.weight}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <FitnessCenterIcon color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <Typography variant="h6" color="textSecondary">
                    Fitness : {user.fitness}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <LocalBarIcon color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <Typography variant="h6" color="textSecondary">
                    Drinking : {user.drinking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <SmokingRoomsIcon color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <Typography variant="h6" color="textSecondary">
                    Smoking : {user.smoking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <Crop32Icon color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <Typography variant="h6" color="textSecondary">
                    Political View : {user.political_view}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6} item>
              <Typography
                gutterBottom
                variant="h4"
              >
                Interests
              </Typography>
              <Divider gutterBottom/>
              {userInterests.map((item, key) => (
                <Button variant="outlined" color="primary" className={classes.interest}> {item.interest}</Button>
              ))}
            </Grid>
          </Grid>

        </Paper>
      </div>
    </>
  );
};

UserPersonalDetails.propTypes = {
  className: PropTypes.string
};

export default UserPersonalDetails;
