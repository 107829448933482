import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { selectoptions } from '../../../../config';
import TotalUsers from '../TotalUsers';
import moment from 'moment';
import 'react-dropdown/style.css';
import { sendHttpCall, Toast } from 'shareService/httpService';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { margin: 8 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right'
  },
  space: {
    margin: 2
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem('social-services-period')
      ? localStorage.getItem('social-services-period')
      : 'Yesterday'
  );
  let end = new Date();
  let start = new Date();
  switch (selectedPeriod) {
    case 'All time':
      start = new Date(2019, 0, 1);
      localStorage.removeItem('social-services-period');
      break;
    case 'Last Year':
      start.setDate(start.getDate() - 365);
      localStorage.removeItem('social-services-period');
      break;
    case 'Last 30 days':
      start.setDate(start.getDate() - 30);
      localStorage.removeItem('social-services-period');
      break;
    case 'Last 7 days':
      start.setDate(start.getDate() - 7);
      localStorage.removeItem('social-services-period');
      break;
    case 'Yesterday':
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      localStorage.removeItem('social-services-period');
      break;
    default:
      localStorage.removeItem('social-services-period');
  }

  // const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(true);
  const [compData, setCompData] = useState({});
  const [surveyCount, setSurveyCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [linkClickCount, setLinkClickCount] = useState(0);
  const [surveyDownloadCount, setSurveyDownloadCount] = useState(0);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState('2019-02-01');
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [okdate, setOkdate] = useState(false);
  var reqBody = {
    start_date: moment(new Date(start)).format('YYYY-MM-DD'),
    end_date: moment(new Date(end)).format('YYYY-MM-DD'),
    city: selectedCity,
    gender: selectedGender
  };
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    // Perform your search or fetch action here using the newDateRange
    reqBody.start_date = moment(new Date(newDateRange.startDate)).format(
      'YYYY-MM-DD'
    );
    reqBody.end_date = moment(new Date(newDateRange.endDate)).format('YYYY-MM-DD');
    fetchAllData();
    console.log('Selected Date Range:', newDateRange.startDate);
  };


  //_______________________________________________
  const fetchCompleteData = async () => {
    setLoading(true);
    let yesterday = false;
    let date = new Date();

    date = date.setDate(date.getDate() - 1);
    date = moment(date).format('YYYY-MM-DD');
    let additionalQuery =
      '?start_date=' + reqBody.start_date + '&end_date=' + reqBody.end_date;
    let response = await sendHttpCall(
      'GET',
      'dashboard/dashboard_get_volunteer_counts' + additionalQuery,
      null,
      props
    );

    if (response) {
      setCompData(response);
      setLoading(false);
    }
  };
  //_______________________________________________
  async function fetchAllData() {
    fetchCompleteData();
  }

  useEffect(() => {
    fetchAllData();
    setSelectedStartDate(reqBody.start_date);
    setSelectedEndDate(reqBody.end_date);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item container justifyContent="flex-start">
           <DateRangePicker onDateChange={handleDateChange}/>
          </Grid>
        </Grid>

        <div className="dashboard-label">Social Services Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.want_to_donate_blood}
              loading={loading}
              onClick={() => {
                localStorage.setItem('social-services-period', selectedPeriod);
                localStorage.setItem(
                  'social-services-selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'social-services-endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'social-services-startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                localStorage.removeItem('social-services-filter');
                localStorage.removeItem('social-services-filterVal');
                props.history.push(`/social-services/donate-blood`);
              }}
              Text="Want To Donate Blood"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.want_to_donate_money}
              loading={loading}
              onClick={() => {
                localStorage.setItem('social-services-period', selectedPeriod);
                localStorage.setItem(
                  'social-services-selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'social-services-endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'social-services-startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                localStorage.removeItem('social-services-filter');
                localStorage.removeItem('social-services-filterVal');
                props.history.push(`/social-services/donate-money`);
              }}
              Text="Want To Donate To Charity"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.want_to_volunteer}
              loading={loading}
              onClick={() => {
                localStorage.setItem('social-services-period', selectedPeriod);
                localStorage.setItem(
                  'social-services-selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'social-services-endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'social-services-startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                localStorage.removeItem('social-services-filter');
                localStorage.removeItem('social-services-filterVal');
                props.history.push(`/social-services/volunteer`);
              }}
              Text="Want To Work For Charity"
            />
          </Grid>
        </Grid>
        {/* <div className="dashboard-label">Graphs</div>
        <Grid container spacing={4}>
          <Grid
            item
            lg={6}
            md={6}
            xl={6}
            xs={12}
            style={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              boxShadow:
                '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
              margin: '2%',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '5%',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: 'rgb(39, 99, 255)'
                }}>
                {' '}
                Goodness Score Distribution{' '}
              </div>
              <div style={{ width: '50%' }}>
                <PieChart
                  data={scoreGraphData}
                  lineWidth={40}
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  viewBoxSize={[100, 100]}
                  paddingAngle={0}
                  radius={50}
                  center={[50, 50]}
                />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {scoreGraphData.map((item) => (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '20px',
                      flexDirection: 'row',
                      marginBottom: '5%'
                    }}>
                    <div
                      style={{
                        width: '10%',
                        padding: '1%',
                        marginRight: '5%',
                        backgroundColor: `${item.color}`
                      }}>
                      &nbsp;
                    </div>
                    <div style={{ fontSize: '14px' }}>
                      score {item.title} : {item.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>

          <Grid
            item
            lg={5}
            md={5}
            xl={5}
            xs={12}
            style={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              boxShadow:
                '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
              margin: '2%',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '10%',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: 'rgb(39, 99, 255)'
                }}>
                {' '}
                Profile Completed{' '}
              </div>
              <div style={{ width: '50%' }}>
                <PieChart
                  data={usersProfile}
                  lineWidth={90}
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  viewBoxSize={[100, 100]}
                  paddingAngle={0}
                  // labelPosition={90}
                  radius={50}
                  center={[50, 50]}
                />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {usersProfile.map((item) => (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '20px',
                      flexDirection: 'row',
                      marginBottom: '5%'
                    }}>
                    <div
                      style={{
                        width: '10%',
                        padding: '1%',
                        marginRight: '5%',
                        backgroundColor: `${item.color}`
                      }}>
                      &nbsp;
                    </div>
                    <div style={{ fontSize: '14px' }}>
                      score {item.title} : {item.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xl={6}
            xs={12}
            style={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              boxShadow:
                '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
              margin: '2%',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '5%',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: 'rgb(39, 99, 255)'
                }}>
                User Count By Purpose{' '}
              </div>
              <div style={{ width: '50%' }}>
                <PieChart
                  data={totalUserPurpose}
                  lineWidth={40}
                  animate
                  animationDuration={500}
                  animationEasing="ease-out"
                  viewBoxSize={[100, 100]}
                  paddingAngle={0}
                  radius={50}
                  center={[50, 50]}
                />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                {totalUserPurpose.map((item) => (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '20px',
                      flexDirection: 'row',
                      marginBottom: '5%'
                    }}>
                    <div
                      style={{
                        width: '10%',
                        padding: '1%',
                        marginRight: '5%',
                        backgroundColor: `${item.color}`
                      }}>
                      &nbsp;
                    </div>
                    <div style={{ fontSize: '14px' }}>
                      {item.title} : {item.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid> */}
      </div>
    </>
  );
};

export default Dashboard;
