import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { sendHttpCall } from 'shareService/httpService';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Card_template } from '../../../config';
import {
    BackgroundImageOnlyCardList,
  } from './components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
}));

const AuxCardsList = props => {

  const classes = useStyles();
  const [cards, setCards] = useState([]);
  const [info5Cards, setInfo5Cards] = useState([]);
  const [infocards, setInfoCards] = useState([]);
  const [backgroundImageOnlyCards, setBackgroundImageOnlyCards] = useState([]);

  const onChange=()=>{
    getCardList();
  }

  const getCardList = () => {
    sendHttpCall('GET', 'cards/aux_card_list', null, props)
      .then((cards) => {
        setCards(cards);
        let _infoCards = cards.filter(card => card.template == Card_template.INFO_1 || card.template == Card_template.INFO)
        setInfoCards(_infoCards);
        let _info5Cards = cards.filter(card => card.template == Card_template.INFO_5);
        setInfo5Cards(_info5Cards);
        let _imageOnlyCards = cards.filter(card => card.template == Card_template.BACKGROUND_IMAGE_ONLY)
        setBackgroundImageOnlyCards(_imageOnlyCards);
      })
      .catch((error) => {

      })
  }

    useEffect(() => {
        getCardList();
    }, []);

    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography className={classes.heading}>Background Image Only Cards</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <BackgroundImageOnlyCardList cards={backgroundImageOnlyCards} onChange={onChange} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        {/* <BackgroundImageOnlyCardList cards={backgroundImageOnlyCards} /> */}
        </div>
    )
};

AuxCardsList.propTypes = {
  className: PropTypes.string,
};
  
export default withRouter(AuxCardsList);