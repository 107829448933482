import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PurposeList from './PurposeList';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { status, apiUrl, headers, purpose_type, message } from '../../../config'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { sendHttpCall, Toast } from 'shareService/httpService';

import {
    Grid,
    Button,
    IconButton,
    TextField,
    Typography,

} from '@material-ui/core';

// For form validation
const schema = {
    religion_id: {},
    degree: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    status: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

const useStyles = makeStyles(theme => ({

    formControl: {
        margin: theme.spacing(1),
        minWidth: "95%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentList: {
        marginTop: '2%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    policy: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    },
    policyCheckbox: {
        marginLeft: '-14px'
    },
    signUpButton: {
        margin: theme.spacing(2, 0)
    }
}));



const Purposes = props => {
    const { history } = props;
    const classes = useStyles();
    const [purpose_data, setPurposeData] = useState([]);

    async function getPurpose() {
        sendHttpCall('GET', 'purposes', null,props).then((data) => {
            if(data){
                
                setPurposeData(data.data);
            }

        });
    }
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const handleChange = event => {
        event.persist();
        

        setFormState(formState => ({
            ...formState,
            isValid: true,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleBack = () => {
        history.goBack();
    };
    const postPurpose = event => {
        event.preventDefault();
        createPurpose();
    };

    async function createPurpose() {
        if (formState.values.purpose_id != undefined) {
            // this is for Update
            const purpose_id = formState.values.purpose_id;
            sendHttpCall('PUT', `purposes/${purpose_id}`, formState.values).then((data) => { 
                Toast('Purpose'+message.UPADTE);

            });
        } else {
            // this is for Insert
            sendHttpCall('POST', 'purposes', formState.values).then((data) => { 
                Toast('Purpose'+message.ADD);

            });
        }
        setFormState({
            values: {
                religion: '',
                type: '',
                status: ''
            }
        });
    }

    async function getDataforDelete(childData) {
        DeleteRecord(childData.purpose_id);
    }

    async function DeleteRecord(purpose_id) {
        sendHttpCall('PUT', `purposes/change-status/${purpose_id}`).then((res) => {
            Toast('Purpose'+message.DELETE);

            getPurpose();
        });
    }

    const getDataFromChild = (childData) => {
        
        setFormState(formState => ({
            ...formState,
            isValid: true,
            values: {
                purpose_id: childData.purpose_id,
                purpose: childData.purpose,
                type: childData.type,
                status: childData.status
            },
        }));
        
    }



    useEffect(() => {
        getPurpose();
        const errors = validate(formState.values, schema);
        
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.content}
                    item
                    lg={6}
                    xs={6}
                >
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className={classes.contentBody}>
                            <form
                                className={classes.form}
                                onSubmit={postPurpose}
                            >
                                <Typography
                                    className={classes.title}
                                    variant="h2"
                                >
                                    Purpose
                                </Typography>
                                <TextField

                                    className={classes.textField}
                                    // error={hasError('degree')}
                                    fullWidth
                                    // helperText={
                                    //   hasError('degree') ? formState.errors.degree[0] : null
                                    // }
                                    label="Purpose"
                                    name="purpose"
                                    onChange={handleChange}
                                    autoComplete='off'
                                    type="text"
                                    value={formState.values.purpose || ''}
                                    variant="outlined"
                                />

                                <TextField
                                    // error={hasError('status')}
                                    fullWidth
                                    // helperText={
                                    //   hasError('status') ? formState.errors.status[0] : null
                                    // }
                                    label="Select Type"
                                    margin="dense"
                                    name="type"
                                    onChange={handleChange}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={formState.values.type || ''}
                                    variant="outlined"
                                >

                                    <option key={""} value={""} >
                                        {""}
                                    </option>
                                    {purpose_type.map(opt => (
                                        <option key={opt.id} value={opt.name} >
                                            {opt.name}
                                        </option>
                                    )
                                    )}
                                </TextField>

                                <TextField
                                    // error={hasError('status')}
                                    fullWidth
                                    // helperText={
                                    //   hasError('status') ? formState.errors.status[0] : null
                                    // }
                                    label="Select State"
                                    margin="dense"
                                    name="status"
                                    onChange={handleChange}
                                    required
                                    select
                                    // eslint-disable-next-line react/jsx-sort-props
                                    SelectProps={{ native: true }}
                                    value={formState.values.status || ''}
                                    variant="outlined"
                                >
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {""}
                                    </option>
                                    {status.map(option => (
                                        <option
                                            key={option.id}
                                            value={option.name}
                                        >
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>

                                <Button
                                    className={classes.signUpButton}
                                    color="primary"
                                    //   disabled={!formState.isValid}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    SAVE
                        </Button>
                            </form>
                        </div>
                    </div>
                </Grid>
                <Grid
                    className={classes.contentList}
                    item
                    lg={6}
                    xs={6}
                >
                    <PurposeList purpose_data={purpose_data} editRow={getDataFromChild} deleteRow={getDataforDelete}></PurposeList>
                </Grid>
            </Grid>
        </div>
    );
}

Purposes.propTypes = {
    history: PropTypes.object
};

export default withRouter(Purposes);
