import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { TextField } from '@material-ui/core';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({}));

const JobPostings = (props) => {
  const { className, ...rest } = props;
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    value: 'All'
  });
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const classes = useStyles();
  console.log(props);
  useEffect(() => {}, []);

  const convertToYearly = (monthlyVal) => {
    switch (monthlyVal) {
      case '₹ 10k - 25k':
      case '₹10k - 25k':
        return '₹ 1.2L - 3L';
      case '₹ 25k - 50k':
      case '₹25k - 50k':
        return '₹ 3L - 6L';
      case '₹ 50k - 75k':
        return '₹ 6L - 9L';
      case '₹ 75k - 100k':
        return '₹ 9L - 12L';
      case '₹ 100k - 150k':
        return '₹ 12L - 18L';
      case '₹ 150k - 200k':
        return '₹ 18L - 24L';
      case '₹ 200k - 300k':
        return '₹ 24L - 36L';
      case '₹ 300k - 500k':
        return '₹ 36L - 60L';
      case '>  ₹ 500k':
        return '> ₹ 60L';
      default:
        return monthlyVal;
    }
  };
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const getTimeIn12Hours = (date) => {
    const hour = date.getHours();
    return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
      date.getMinutes()
    )} ${hour > 12 ? 'PM' : 'AM'}`;
  };
  const formatDate = (date, variation = 1) =>
    `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
      date.getMonth() + 1
    )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

  const formatTime = (time) => {
    const date = new Date(time);

    return getTimeIn12Hours(date) + ', ' + formatDate(date);
  };

  const getData = () => {
    setLoading(true);
    let url = 'dashboard/get_job_data';
    sendHttpCall(
      'POST',
      url,
      {
        limit: pageSize,
        offset: page,
        type: selectedFilter.label || props.match.params.type,
        start_date: props.match.params.start_date,
        end_date: props.match.params.end_date
      },
      props
    ).then((response) => {
      setLoading(false);
      setData(response.data);
      setTotalCount(response.count);
    });
  };
  useEffect(() => {
    getData();
  }, [selectedFilter, page]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div
        className="sm-width"
        style={{ width: '5rem', margin: '1rem', marginBottom: '0' }}>
        <TextField
          fullWidth
          // label="Select Period"
          margin="dense"
          name="status"
          onChange={(e) => {
            setSelectedFilter(JSON.parse(e.target.value));
            setPage(0);
          }}
          required
          select
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          // value={ageFilter ? JSON.stringify(ageFilter) : 'All Ages'}
          variant="outlined"
          // placeholder="select period"
        >
          {/* <option value={""} > All time </option> */}
          <option value={JSON.stringify({ label: 'All', value: 'All' })}>
            ALL
          </option>
          <option value={JSON.stringify({ label: 'ACTIVE', value: 'ACTIVE' })}>
            ACTIVE
          </option>
          <option
            value={JSON.stringify({ label: 'INACTIVE', value: 'INACTIVE' })}>
            INACTIVE
          </option>
          <option
            value={JSON.stringify({
              label: 'UNDER_REVIEW',
              value: 'UNDER_REVIEW'
            })}>
            UNDER_REVIEW
          </option>
        </TextField>
      </div>
      <CardContent className={classes.content}>
        <MaterialTable
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          // actions={[
          //   {
          //     icon: 'send',
          //     tooltip: 'Open User',
          //     onClick: (event, rowData) => {
          //       props.history.push(`/account/${rowData.user_id}`);
          //     }
          //   }
          // ]}
          columns={[
            {
              title: 'S No.',
              render: (rowData) => page * pageSize + rowData.tableData.id + 1
            },
            {
              title: 'Recruiter Name',
              field: 'name',
              render: (rowData) => (
                <div
                  onClick={() => {
                    props.history.push(`/account/${rowData.userId}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.name}
                </div>
              )
            },
            {
              title: 'Job Title',
              field: 'title',
              render: (rowData) => (
                <div
                  onClick={() => {
                    setJobData(rowData);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.title}
                </div>
              )
            },
            { title: 'Applied Candidates', field: 'totalApplications' },
            {
              title: 'Shortlisted Candidates',
              field: 'shortlistedApplications'
            },
            { title: 'Rejected Candidates', field: 'rejectedApplications' },
            { title: 'Pending Candidates', field: 'pendingApplications' },
            { title: 'Status', field: 'status' },
            {
              title: 'Source',
              field: 'invite_source',
              render: (rowData) => (
                <div>{rowData.invite_source?.replace('campaign_', '')}</div>
              )
            },
            { title: 'Date Created', field: 'created_at' }
          ]}
          data={data}
          isLoading={loading}
          totalCount={totalCount}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          title="All Users"
        />
      </CardContent>
      <ReactModal
        isOpen={jobData}
        onRequestClose={() => setJobData(null)}
        className="jobs_under_review__modal-modal"
        overlayClassName="jobs_under_review__modal-overlay">
        {jobData && (
          <div
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
            className="jobs_under_review__posts-post-container">
            <div className="jobs_under_review__posts-post-postedAt">
              Posted At: {formatTime(new Date(jobData.created_at))}
            </div>
            <div className="jobs_under_review__posts-post-posted_by">
              <img
                src={jobData.userInfo[0].image_id}
                className="jobs_under_review__posts-post-posted_by-image"
              />
              <div>
                <Link
                  className="active_jobs__posts-post-posted_by-name"
                  to={`/account/${jobData.userInfo[0].user_id}`}>
                  {jobData.userInfo[0].name}
                </Link>
                <div>{jobData.userInfo[0].organisation}</div>
              </div>
            </div>
            <div className="jobs_under_review__posts-post-content">
              <div className="jobs_under_review__posts-post-details">
                <div className="jobs_under_review__posts-post-title">
                  {jobData.title}
                </div>
                <div className="jobs_under_review__row">
                  <div className="jobs_under_review__posts-post-jobType">
                    {jobData.jobType.length > 0
                      ? jobData.jobType[0].job_type
                      : ''}
                  </div>
                  -
                  <div className="jobs_under_review__posts-post-location">
                    {jobData.is_remote ? 'Remote' : jobData.location_city}
                  </div>
                </div>
                <div className="jobs_under_review__posts-post-data">
                  <div className="jobs_under_review__posts-post-label">
                    Description
                  </div>
                  <div className="jobs_under_review__posts-post-desc">
                    {jobData.description}
                  </div>
                  <div className="jobs_under_review__posts-post-skills-section">
                    <div className="jobs_under_review__posts-post-label">
                      Skills
                    </div>
                    <div className="jobs_under_review__posts-post-skills">
                      {jobData.skills.map((skill) => (
                        <div className="jobs_under_review__posts-post-skill">
                          {skill.skill}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="jobs_under_review__posts-post-compensation-section">
                    <div className="jobs_under_review__posts-post-label">
                      {jobData.monthly_compensation
                        ? 'Yearly Compensation'
                        : 'Hourly Compensation'}
                    </div>
                    <div className="jobs_under_review__posts-post-value">
                      {jobData.monthly_compensation
                        ? convertToYearly(jobData.monthly_compensation)
                        : jobData.hourly_compensation}
                    </div>
                  </div>
                  <div className="jobs_under_review__posts-post-exp-section">
                    <div className="jobs_under_review__posts-post-label">
                      Work Experience Required
                    </div>
                    <div className="jobs_under_review__posts-post-value">
                      {`${jobData.lowerworkex} - ${jobData.upperworkex} years`}
                    </div>
                  </div>
                  <div className="jobs_under_review__posts-post-industry-section">
                    <div className="jobs_under_review__posts-post-label">
                      Industry
                    </div>
                    <div className="jobs_under_review__posts-post-value">
                      {jobData.industry_type}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ReactModal>
    </Card>
  );
};

JobPostings.propTypes = {
  className: PropTypes.string
};

export default withRouter(JobPostings);
