import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { selectoptions, ageOptions, genders } from '../../config'
import palette from 'theme/palette';
import {
  TotalUsers,
  LatestSales,
  TZPoints,
  UserTable,
  GenerationUserTable
} from '../Dashboard/components';
import moment from 'moment';
import 'react-dropdown/style.css';
import { sendHttpCall, Toast } from 'shareService/httpService';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { margin: 8 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right',

  },
  space: {
    margin: 2
  }
}));

const UserEngagement = (props) => {
  const classes = useStyles();
  const [userGenerationData, setUserGenerationData] = useState([]);
  const [userGenerationList, setUserGenerationList] = useState([]);

  function fetchAllData() {
    fetchGenerationdata();
  }

  function setUserGenerationBars(response) {
    let labels = [];
    let datas = []
    var promise = new Promise((res, rej) => {
      response.forEach(element => {
        labels.push(element.label);
        datas.push(element.count);
      });
      res();
    })
    promise.then(() => {
      let data = {
        labels: labels,
        datasets: [
          {
            backgroundColor: palette.primary.main,
            data: datas
          },
        ]
      }
      setUserGenerationData(data);
    });
  }

  async function fetchGenerationdata() {
    sendHttpCall('POST', 'dashboard/get_generation_data', props).then((response) => {
      if (response) {
        if (response.length > 0) {
          setUserGenerationBars(response);
          setUserGenerationList(response);
        } else {
          setUserGenerationBars([]);
        }
      }
    });
  }

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestSales data={userGenerationData}  title="Generation Wise count"/>
          </Grid>
          {userGenerationList.map((item, key) => (
            <Grid
              item
              lg={12}
              md={12}
              xl={9}
              xs={12}
            >
              <GenerationUserTable data={item} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default UserEngagement;