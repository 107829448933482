import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPaginate from 'react-paginate';

import { red } from '@material-ui/core/colors';

import { Grid, Link, Button } from '@material-ui/core';
import { sendHttpCall } from 'shareService/httpService';
import BlogCard from './component/blogCard';
import './blog.css';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    maxWidth: 445
  },
  contentBody: {
    padding: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const handlePageChange = (page) => {
    setCurrentPage(page.selected + 1);
    console.log(page);
  };

  // const getPaginatedData = () => {
  //   const startIndex = currentPage * 8 - 8;
  //   const endIndex = startIndex + 8;
  //   return data.slice(startIndex, endIndex);
  // };
  useEffect(() => {
    let url = `blogs/getAll?limit=8&page=${currentPage - 1}`;
    sendHttpCall('GET', url, null, props).then((response) => {
      setPages(Math.ceil(response.count / 8));
      setData(response.blogs);
      console.log(response);
    });
  }, [currentPage]);

  return (
    <div className={classes.contentBody}>
      <div className="marketingCampaign__title blogs_Container">
        <span>Posted Blogs</span>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => {
            props.history.push(`/edit-blogs`);
          }}>
          Create New
        </Button>
      </div>
      <div class="blog-list-wrapper">
        <Grid container spacing={3}>
          {data.length === 0 && (
            <div
              style={{
                margin: '5rem 0',
                textAlign: 'center',
                width: '100%',
                fontFamily: 'Roboto',
                fontSize: '1.1rem',
                fontWeight: '500'
              }}>
              No Data Found
            </div>
          )}
          {
            // console.log(getPaginatedData)
            data.map((d, idx) => (
              <BlogCard key={idx} data={d} props={props} />
            ))
          }
        </Grid>
      </div>
      <ReactPaginate
        previousLabel="previous"
        nextLabel="next"
        breakLabel=".."
        breakClassName="break-me"
        pageCount={pages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
}
