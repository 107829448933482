import MaterialTable, { MTablePagination } from 'material-table';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ReactModal from 'react-modal';
import React from 'react';
import mutualImg from './mIcon.png';
import ReactSelect from 'react-select';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import styles from './Status0Search.module.scss';

const locationOptions = [
  { label: 'Select', value: '' },
  { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
  { label: 'Assam', value: 'Assam' },
  { label: 'Bihar', value: 'Bihar' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Gujarat', value: 'Gujarat' },
  { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
  { label: 'Haryana', value: 'Haryana' },
  { label: 'Jammu and Kashmir', value: 'Jammu and Kashmir' },
  { label: 'Kerala', value: 'Kerala' },
  { label: 'Karnataka', value: 'Karnataka' },
  { label: 'Kolkata', value: 'Kolkata' },
  { label: 'Maharashtra', value: 'Maharashtra' },
  { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
  { label: 'Mumbai', value: 'Mumbai' },
  { label: 'North East', value: 'North East' },
  { label: 'Odisha', value: 'Odisha' },
  { label: 'Punjab', value: 'Punjab' },
  { label: 'Rajasthan', value: 'Rajasthan' },
  { label: 'Tamil Nadu', value: 'Tamil Nadu' },
  { label: 'UP (East)', value: 'UP (East)' },
  { label: 'UP (West)', value: 'UP (West)' },
  { label: 'West Bengal', value: 'West Bengal' }
];
const MutualIcon = ({ style }) => (
  <img
    style={style ? { ...style } : null}
    className={styles.mutualIcon}
    src={mutualImg}
  />
);
export default function Status0Search(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [query, setQuery] = useState(
    sessionStorage.getItem('searchContactQuery') || ''
  );
  const [mobileNumberForMutual, setMobileNumberForMutual] = useState(
    sessionStorage.getItem('mobileNumberForMutual') || null
  );
  const [mobileNumberForMutualValue, setMobileNumberForMutualValue] =
    useState('');
  const [locationFilter, setLocationFilter] = useState(
    sessionStorage.getItem('searchContactLocation')
      ? JSON.parse(sessionStorage.getItem('searchContactLocation') || '{}')
      : ''
  );
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('searchContactPage'), 10) || 0
  );
  const [savedByData, setSavedByData] = useState(null);
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('searchContactLimit'), 10) || 50
  );
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [queryVal, setQueryVal] = useState(
    sessionStorage.getItem('searchContactQueryVal') || ''
  );
  const getUserData = async (query, city) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);

      let url = `users/search_in_contact_db?query=${query}&limit=${limit}&page=${page}&region=${city || ''
        }&mobileNumberForMutual=${mobileNumberForMutual || ''}`;
      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
        setTotalCount(response.count);
      });
    });
  };
  const getSavedByData = (userId) => {
    let url = `users/get_contact_saved_by?userId=${userId}&mobileNumberForMutual=${mobileNumberForMutual || ''
      } `;
    sendHttpCall('GET', url, null, props).then((response) => {
      setSavedByData(response);
    });
  };
  useEffect(() => {
    setSavedByData(null);
    if (selectedUserId) getSavedByData(selectedUserId);
  }, [selectedUserId]);
  const exportDataToCSV = async () => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);

      let url = `users/search_in_contact_db_export?query=${query}&region=${locationFilter?.value || ''
        }`;
      sendHttpCallBlob('GET', url, null, props)
        .then(async (blob) => {
          setIsLoading(false);
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = `u-${query || ''}-${locationFilter?.value || ''}.csv`
            .toLowerCase()
            .replaceAll(' ', '-')
            .replaceAll('--', '-');
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };
  const openUserPageNewTab = (userId, social) => {
    window.open(`/#/account/${userId}`, '_blank');
  };
  const openUserPage = (userId, social) => {
    sessionStorage.setItem('searchContactPage', page);
    sessionStorage.setItem('searchContactLimit', limit);
    if (locationFilter)
      sessionStorage.setItem(
        'searchContactLocation',
        JSON.stringify(locationFilter)
      );
    if (queryVal) sessionStorage.setItem('searchContactQueryVal', queryVal);
    if (query) sessionStorage.setItem('searchContactQuery', query);
    if (mobileNumberForMutual)
      sessionStorage.setItem('mobileNumberForMutual', mobileNumberForMutual);
    props.history.push(`/account/${userId}`);
  };
  useEffect(() => {
    console.log('running');
    console.log(query || locationFilter?.value);
    if (query || locationFilter?.value)
      getUserData(query, locationFilter?.value);
  }, []);
  useEffect(() => {
    if (query || locationFilter?.value)
      getUserData(query, locationFilter?.value);
    if (!query && !locationFilter?.value) {
      setData([]);
      setTotalCount(0);
    }
    sessionStorage.removeItem('searchContactPage');
    sessionStorage.removeItem('searchContactLimit');
    sessionStorage.removeItem('searchContactLocation');
    sessionStorage.removeItem('searchContactQuery');
    sessionStorage.removeItem('searchContactQueryVal');
    sessionStorage.removeItem('mobileNumberForMutual');
  }, [query, locationFilter?.value, limit, page, mobileNumberForMutual]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>User Contact Database Search</div>
      <div style={{ display: 'flex' }}>
        <div className={styles.userContactSearch}>
          <input
            type="text"
            placeholder="Enter search string"
            value={queryVal}
            onChange={(e) => setQueryVal(e.target.value)}
          />
          <button
            onClick={() => {
              setPage(0);
              setQuery(queryVal);
            }}>
            Search
          </button>
        </div>
        <div
          style={{
            width: '18rem',
            maxWidth: '40%',
            marginLeft: '2rem',
            position: 'relative',
            zIndex: '999'
          }}>
          <div>
            <Typography variant="body2" color="textSecondary" component="p">
              SELECT LOCATION FILTER
            </Typography>
          </div>
          <ReactSelect
            options={locationOptions}
            value={locationFilter}
            onChange={setLocationFilter}
          />
        </div>
        <div className={styles.userContactSearchMobile}>
          <input
            type="text"
            placeholder="Mobile Number For Mutuals"
            value={
              mobileNumberForMutual
                ? mobileNumberForMutual
                : mobileNumberForMutualValue
            }
            readOnly={!!mobileNumberForMutual}
            onChange={(e) => setMobileNumberForMutualValue(e.target.value)}
          />
          <button
            style={{
              backgroundColor: mobileNumberForMutual ? '#06e097' : '#4789fc'
            }}
            onClick={() => {
              setMobileNumberForMutual((a) =>
                a ? null : mobileNumberForMutualValue
              );
              setMobileNumberForMutualValue('');
            }}>
            {mobileNumberForMutual ? 'Change' : 'USE'}
          </button>
        </div>
      </div>

      <MaterialTable
        isLoading={isLoading}
        totalCount={totalCount}
        columns={[
          {
            title: 'S No.',
            render: (rowData) => page * 20 + rowData.tableData.id + 1,
            cellStyle: {
              width: 20,
              maxWidth: 20
            },
            headerStyle: {
              width: 20,
              maxWidth: 20
            }
          },

          {
            title: 'Name',
            field: 'contact_name',
            cellStyle: {
              width: 20,
              maxWidth: 20
            },
            headerStyle: {
              width: 20,
              maxWidth: 20
            },
            render: (rowData) => {
              const moreThanOneName = rowData.names.split(',').length > 1;
              return (
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    openUserPage(rowData.user_id);
                  }}
                  title={moreThanOneName ? rowData.names : null}>
                  {rowData.mutualContact && (
                    <MutualIcon
                      style={{ marginLeft: '-25px', width: '20px' }}
                    />
                  )}{' '}
                  <span>
                    {rowData.contact_name}
                    {moreThanOneName ? '...' : ''}
                  </span>
                </div>
              );
            }
          },
          {
            title: 'Mobile Number',
            field: 'mobile_number',
            render: (rowData) => {
              return (
                <a
                  target="_blank"
                  href={`https://wa.me/${rowData.mobile_number?.replace(
                    '+',
                    ''
                  )}`}
                  style={{ color: '#263238' }}>
                  {rowData.mobile_number}
                </a>
              );
            }
          },
          {
            title: 'Email Id',
            field: 'email_id'
          },
          {
            title: 'Saved By ',
            field: 'no_of_times_saved',
            render: (rowData) => (
              <div
                style={
                  rowData.no_of_times_saved > 1 ? { cursor: 'pointer' } : null
                }
                onClick={() =>
                  rowData.no_of_times_saved > 1
                    ? setSelectedUserId(rowData.user_id)
                    : null
                }>
                {rowData.no_of_times_saved}{' '}
              </div>
            )
          },
          {
            title: 'Region',
            field: 'region'
          },
          {
            title: 'User Status',
            field: 'status'
          },
          {
            title: 'Contact Of',
            field: 'saved_by',
            render: (rowData) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openUserPage(rowData.saved_by_user_id);
                }}>
                {rowData.saved_by}
              </div>
            )
          }
        ]}
        data={data}
        page={page}
        title={!query ? '' : `Search Results for ${query}`}
        onChangePage={(e) => {
          console.log('Changing page - ' + e);
          setPage(e);
        }}
        onChangeRowsPerPage={(e) => setLimit(e)}
        components={
          totalCount <= 10000
            ? {
              Pagination: (props2) => (
                <div className="table__pagination-container">
                  {localStorage.getItem('user_type') === 'ADMIN' && (
                  <div className="exportToCsvBtn" onClick={exportDataToCSV}>
                    <span> Export To CSV</span> <CloudDownload />
                  </div>)}
                  <div className="table__pagination-default-container">
                    <MTablePagination {...props2} />
                  </div>
                </div>
              )
            }
            : null
        }
        options={{
          search: false,
          actionsColumnIndex: -1,
          pageSize: limit,
          exportButton: localStorage.getItem('user_type') === 'SALES' ? false : true,
          pageSizeOptions:
            props.match.params.type === 'active_member'
              ? [20]
              : [25, 50, 100, 150, 1000],
          sorting: false
        }}
      />
      <ReactModal
        isOpen={!!selectedUserId}
        onRequestClose={() => setSelectedUserId(null)}
        className="jobs_under_review__modal-modal"
        overlayClassName="jobs_under_review__modal-overlay">
        <div className="contact_list_modal">
          <div className="contact_list_title">Contact Saved By</div>
          <div className="contact_list_items">
            <div className="contact_list_item contact_list_item-bold">
              <div className="contact_list_item_no">SNo.</div>
              <div className="contact_list_item_name"> Saved By</div>
              <div className={'contact_list_item_status'}>Saved As</div>
            </div>
            {!savedByData && (
              <div className="contact_list_loading">Loading...</div>
            )}
            {savedByData?.map((a, index) => (
              <div className="contact_list_item">
                <div className="contact_list_item_no">{index + 1}.</div>
                <div
                  className="contact_list_item_name"
                  onClick={() => openUserPageNewTab(a.user_id)}>
                  {a.mutualContact && <MutualIcon />}
                  {a.name}
                </div>
                <div className={'contact_list_item_status'}>
                  {a.contact_name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
