import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, MenuItem, Typography } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import { selectoptions } from '../../config';
import './index.css';
import { TotalUsers } from './components';
import moment from 'moment';
import 'react-dropdown/style.css';
import { sendHttpCall, Toast } from 'shareService/httpService';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { margin: 8 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right'
  },
  space: {
    margin: 2
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  quarterSelectContainer: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },

}));

const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

const Dashboard = (props) => {
  const classes = useStyles();
  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem('period') ? localStorage.getItem('period') : 'Today'
  );
  
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [fromQuarter, setFromQuarter] = useState('Q1');
  const [toQuarter, setToQuarter] = useState('Q1');
  let end = new Date();
  let start = new Date();
  switch (selectedPeriod) {
    case 'All time':
      start = new Date(2019, 0, 1);
      localStorage.removeItem('period');
      break;
    case 'Last Year':
      start.setDate(start.getDate() - 365);
      localStorage.removeItem('period');
      break;
    case 'Last 30 days':
      start.setDate(start.getDate() - 30);
      localStorage.removeItem('period');
      break;
    case 'Last 7 days':
      start.setDate(start.getDate() - 7);
      localStorage.removeItem('period');
      break;
    case 'Yesterday':
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      localStorage.removeItem('period');
      break;
    default:
      localStorage.removeItem('period');
  }

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  // const [cities, setCities] = useState([]);
  const getValidMonths = (selectedYear) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
  
    if (currentYear === selectedYear) {
      return months.slice(0, currentMonth + 1);
    } else if (selectedYear < currentYear) {
      return months;
    } else {
      return [];
    }
  };

  const [loading, setLoading] = useState(true);
  const [compData, setCompData] = useState({});
  const [surveyCount, setSurveyCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [linkClickCount, setLinkClickCount] = useState(0);
  const [surveyDownloadCount, setSurveyDownloadCount] = useState(0);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [validMonths, setValidMonths] = useState(getValidMonths(new Date().getFullYear()));
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [okdate, setOkdate] = useState(false);
  var reqBody = {
    start_date: moment(new Date(start)).format('YYYY-MM-DD'),
    end_date: moment(new Date(end)).format('YYYY-MM-DD'),
    city: selectedCity,
    gender: selectedGender
  };

  const handleYearChange = (event) => {
    const year = Number(event.target.value);
    setSelectedYear(year);
    setValidMonths(getValidMonths(year));
  };

  const handleFromQuarterChange = (event) => {
    setFromQuarter(event.target.value);
  };

  const handleToQuarterChange = (event) => {
    setToQuarter(event.target.value);
  };

  const getStartDateFromMonth = (year, month) => {
    const monthIndex = months.indexOf(month);
    const startDate = new Date(year, monthIndex, 1);
    return moment(startDate).format('YYYY-MM-DD');
  };
  
  const getEndDateFromMonth = (year, month) => {
    const monthIndex = months.indexOf(month);
    const endDate = new Date(year, monthIndex + 1, 0);
    return moment(endDate).format('YYYY-MM-DD');
  };
  
  
  const fetchDataForMonth = () => {
    const start_date = getStartDateFromMonth(selectedYear, selectedMonth);
    const end_date = getEndDateFromMonth(selectedYear, selectedMonth);
  
    // Set the reqBody with the new start and end dates
    reqBody.start_date = start_date;
    reqBody.end_date = end_date;
  
    console.log(`Fetching data from ${start_date} to ${end_date}`);
    fetchAllData();
  };

  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    // Perform your search or fetch action here using the newDateRange
    reqBody.start_date = moment(new Date(newDateRange.startDate)).format(
      'YYYY-MM-DD'
    );
    reqBody.end_date = moment(new Date(newDateRange.endDate)).format('YYYY-MM-DD');
    fetchAllData();
    console.log('Selected Date Range:', newDateRange.startDate);
  };

  const getEndDateFromQuarter = (year, quarter) => {
    const quarterEndMonths = { Q1: '03-31', Q2: '06-30', Q3: '09-30', Q4: '12-31' };
    return `${year}-${quarterEndMonths[quarter]}`;
  };

  const fetchDataForQuarters = () => {
    const start_date = getStartDateFromQuarter(selectedYear, fromQuarter);
    const end_date = getEndDateFromQuarter(selectedYear, toQuarter);

    // Set the reqBody with the new start and end dates
    reqBody.start_date = start_date;
    reqBody.end_date = end_date;

    // Log the start and end dates
    console.log(`Fetching data from ${start_date} to ${end_date}`);
    fetchAllData()
  };
  //_______________________________________________
  const fetchCompleteData = async () => {
    setLoading(true);
    let yesterday = false;
    let date = new Date();

    date = date.setDate(date.getDate() - 1);
    date = moment(date).format('YYYY-MM-DD');
    let additionalQuery =
      '?start_date=' + reqBody.start_date + '&end_date=' + reqBody.end_date;
    let response = await sendHttpCall(
      'GET',
      'dashboard/kpi' + additionalQuery,
      null,
      props
    );

    if (response) {
      setCompData(response);
      setLoading(false);
    }
  };
  //_______________________________________________
  async function fetchAllData() {
    fetchCompleteData();
  }

  useEffect(() => {
    fetchAllData();
    setSelectedStartDate(reqBody.start_date);
    setSelectedEndDate(reqBody.end_date);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item container justifyContent="flex-start">
          <DateRangePicker onDateChange={handleDateChange} />
            
            {/* {isCustomDate === true ? (
              <Grid container justify="space-around">
                <Grid xs={8}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="SELECT START DATE"
                          format="MM/dd/yyyy"
                          value={selectedStartDate}
                          onChange={handleChangeStartDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </Grid>
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          format="MM/dd/yyyy"
                          id="date-picker-dialog"
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          label="SELECT END DATE"
                          margin="normal"
                          onChange={handleChageEndDate}
                          value={selectedEndDate}
                        />
                      </Grid>
                      <Grid xs={2}>
                        {okdate === false ? null : (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.srch_btn}
                            onClick={filterByDateRange}>
                            SEARCH
                          </Button>
                        )}
                      </Grid>
                      <Grid xs={2}></Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            ) : null} */}
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
      <Grid item>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="year-select-label"> Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select"
            value={selectedYear}
            onChange={handleYearChange}
            label="Year"
          >
            {/* Generate year options dynamically */}
            {[...Array(5)].map((_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl variant="outlined" className={classes.selectControl}>
          <InputLabel id="from-quarter-select-label">From</InputLabel>
          <Select
            labelId="from-quarter-select-label"
            id="from-quarter-select"
            value={fromQuarter}
            onChange={handleFromQuarterChange}
            label="From"
          >
            {quarters.map((quarter) => (
              <MenuItem key={quarter} value={quarter}>
                {quarter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl variant="outlined" className={classes.selectControl}>
          <InputLabel id="to-quarter-select-label">To</InputLabel>
          <Select
            labelId="to-quarter-select-label"
            id="to-quarter-select"
            value={toQuarter}
            onChange={handleToQuarterChange}
            label="To"
          >
            {quarters.map((quarter) => (
              <MenuItem key={quarter} value={quarter}>
                {quarter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => fetchDataForQuarters()}
        >
          →
        </Button>
      </Grid>

      
    </Grid>
    
    <Grid container direction="row" alignItems="center" spacing={1}>
  <Grid item>
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="month-select-label">Month</InputLabel>
      <Select
        labelId="month-select-label"
        id="month-select"
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
        label="Month"
      >
        {validMonths.map((month, index) => (
          <MenuItem key={index} value={month}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
  <Grid item>
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={fetchDataForMonth}
    >
      →
    </Button>
  </Grid>
</Grid>

       
        
        <div className="dashboard-label">Job Seekers</div>
        <Grid container spacing={3}>
          {/* <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}> */}
          {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
          {/* <TotalUsers
              totalUser={compData.totalUsers}
              loading={loading}
              Text="New Users"
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/active_member/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
            /> */}
          {/* </Grid>{' '} */}
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.jobSeekers}
              loading={loading}
              Text="New Job Seekers"
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/active_member/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
            />
          </Grid>{' '}
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={'₹' + (compData.totalJobSeekerRevenue || 0)}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Total Job Seeker Revenue"
            />
          </Grid>
        </Grid>
        {/* <div className="dashboard-label">Premium</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.totalTransactions}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Number of Transactions"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={'₹' + (compData.totalRevenue || 0)}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Total Revenue"
            />
          </Grid>
        </Grid> */}
        <div className="dashboard-label">Recruiters</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.hirers}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Hirers"
            />
          </Grid>{' '}
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={'₹' + (compData.totalHirerRevenue || 0)}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Total Hirer Revenue (Gcoin purchase)"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Jobs</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.totalPosted}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Posted Jobs"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.closedJobCount}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Closed Jobs"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.activeJobCount}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Active Jobs"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Job Applications</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.totalApplications}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Total Applications"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.totalUnlockedFromJob}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Unlocked Job Applications"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.gcoinsUsedForUnlockViaJob}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Gcoins Used from Unlocked Job Applications"
            />
          </Grid>
        </Grid>{' '}
        <div className="dashboard-label">Data Unlocked Via Search</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.totalUnlockedFromSearch}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Unlocked Via Search"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.gcoinsUsedForUnlockViaSearch}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Gcoins Used from Unlocked Via Search"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">AutoRecruiter</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.totalInterviews}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Total Interviews"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.finishedInterviews}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Completed Interviews"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Social Mode</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.socialUsers}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Onboarded Social Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.premiumSocialUsers}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Premium Social Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={'₹' + (compData.totalRevenueFromSocial || 0)}
              loading={loading}
              //   onClick={() => {
              //     localStorage.setItem('period', selectedPeriod);
              //     props.history.push(
              //       `/data/status2/${selectedPeriod}/${moment(
              //         selectedStartDate
              //       ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
              //         'YYYY-MM-DD'
              //       )}`
              //     );
              //   }}
              Text="Revenue From Social"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
