//import React from 'react';
import React, { useRef, useEffect } from "react";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader
} from '@material-ui/core';
// import { options } from './chart';
//import {data} from './chart';

var Chart = require("chart.js")



const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 250,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));



const DoughNut = (props) => {
   const { data,option, color,...rest } = props;
  const chartRef=useRef(null);
  const classes = useStyles();
  var values = [];
  var labels = [];

  for(var i =0; i<data.length; i++){
    labels.push(`score:${data[i].label}`);
    values.push(data[i].value);
  }
  


  useEffect(() => {
    if (chartRef.current) {
      const myChart = new Chart(chartRef.current, {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: "# of Votes",
              data:values,
              backgroundColor: color,
              borderWidth: 0
            }
          ]
        },
        options: option,
        color: color
      });
    }
  });

  return (
    <Card style={{padding:"25px"}}>
     
     <CardHeader
        title={props.title}
      />
      <canvas ref={chartRef}/>

    </Card>
  )
};

DoughNut.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default DoughNut;
