import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import ReactModal from 'react-modal';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({}));

const JobsApplications = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [jobDataArr, setJobDataArr] = useState([]);
  const [jobData, setJobData] = useState(null);
  const [shortlisterFeedback, setShortlisterFeedback] = useState(null);
  const [shortlister, setShortlister] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [uniqueJobCount, setUniqueJobCounts] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('jobApplicationPage'), 10) || 0
  );
  const openJobModal = (offerId) => {
    setJobData(jobDataArr.find((a) => a.offerId === offerId));
  };
  const [pageSize, setPageSize] = useState(
    parseInt(sessionStorage.getItem('jobApplicationPageSize'), 10) || 10
  );

  useEffect(() => {
    setType(props.match.params.type);
    getData();
    window.scrollTo(0, 0);
  }, [page, props.match.params.type]);

  useEffect(() => {
    if (!data.length) return;
    if (page === 0) getData();
    else setPage(0);

    window.scrollTo(0, 0);
  }, [pageSize]);

  const getData = () => {
    let url = 'dashboard/get_application_data';
    setIsLoading(true);
    sendHttpCall(
      'POST',
      url,
      {
        limit: pageSize,
        offset: page,
        type: props.match.params.type,
        start_date: props.match.params.start,
        end_date: props.match.params.end
      },
      props
    ).then((response) => {
      sessionStorage.removeItem('jobApplicationPageSize');
      sessionStorage.removeItem('jobApplicationPage');
      setData(response.data);
      setTotalCount(response.count);
      setIsLoading(false);
      if (response.jobCounts) setUniqueJobCounts(response.jobCounts);
      else setUniqueJobCounts(null);
    });
  };

  const columns = [
    {
      title: 'S No.',
      render: (rowData) => page * pageSize + rowData.tableData.id + 1
    },
    {
      title: 'Job Seeker Name',
      field: 'name',
      render: (rowData) => (
        <div
          onClick={() => {
            sessionStorage.setItem('jobApplicationPageSize', pageSize);
            sessionStorage.setItem('jobApplicationPage', page);
            props.history.push(`/account/${rowData.user_id}`);
          }}
          style={{ cursor: 'pointer', display: 'flex' }}>
          {rowData.apply_source === 'WHATSAPP' ? (
            <div
              style={{
                backgroundColor: '#25D366',
                color: 'white',
                marginRight: '1rem',
                minWidth: '1.85rem',
                fontWeight: '500',
                marginLeft: '-1.85rem',
                fontSize: '.8rem',
                width: '1.85rem',
                height: '1.85rem',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              W
            </div>
          ) : null}
          {rowData.name}
        </div>
      )
    },
    { title: 'Contact', field: 'jobseeker_mobile' },
    {
      title: 'Shortlister Feedback',
      field: 'shortlisterFeedBack',
      cellStyle: {
        width: '200%', 
        maxWidth: '200%'
      },
      render: (rowData) => (
        <div>
          {rowData.shortlisterFeedBack}
        </div>
      )
    },
    {
      title: 'Job Title',
      field: 'title',
      render: (rowData) => (
        <div
          onClick={() => openJobModal(rowData.user_offer_id)}
          style={{ cursor: 'pointer' }}>
          {rowData.title}
        </div>
      )
    },
    { title: 'Date', field: 'created_at' }
  ];

  if (type !== 'rejected') {
    columns.push({
      title: 'InterviewFeedBack',
      field: 'interviewCleared',
      render: (rowData) => (
        <div
          onClick={() => {
            sessionStorage.setItem('jobApplicationPageSize', pageSize);
            sessionStorage.setItem('jobApplicationPage', page);
            props.history.push(
              `/job-application/candidate/feedBack/${rowData.user_offer_id}/${rowData.user_id}`
            );
          }}
          style={
            rowData.interviewCleared === 'N/A'
              ? { cursor: 'not-allowed' }
              : { cursor: 'pointer' }
          }>
          {rowData.interviewCleared}
        </div>
      )
    });
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          isLoading={isLoading}
          columns={columns}
          data={data}
          totalCount={totalCount}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 50, 250, 500, 1000],
            exportButton: localStorage.getItem('user_type') === 'SALES' ? false : true // Enable export options
          }}
          title={`All ${type} Users`}
        />
      </CardContent>
      {/* Modals and other UI elements */}
    </Card>
  );
};

JobsApplications.propTypes = {
  className: PropTypes.string
};

export default withRouter(JobsApplications);
