
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Link,
  Box,
  Grid,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';

import { sendHttpCall, Toast } from 'shareService/httpService';
import CustomTabs from 'components/CustomTab/CustomTab';
import { message } from '../../../config';
import CustomCard from 'components/CustomeCard/CustomCard';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function UserImageRequest(props) {
  const { className, ...rest } = props;




  const classes = useStyles();
  //const [arrayDatas, setarrayDatas] = useState([]);
  const [Requests, setRequests] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleSelectAll = event => {

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = Requests.map(Request => Request.user_image_id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getAllRequest = () => {
    sendHttpCall('GET', 'user_image_request', null, props.prop).then((response) => {
      if (response) {
        setRequests(response);
        setSelectedStatus(response.status);
      }

    });
  }

  const handleChange = (event, data) => {
  
    setSelectedStatus(event.target.value)
    let body = {
      status: event.target.value
    }
    sendHttpCall('POST', `user_image_request/update_status/${data.user_image_id}`, body, props).then((response) => {
     
      Toast('User Image Status' + message.UPADTE);

      getAllRequest();
    });
  }

  useEffect(() => {
    var userDataString = localStorage.getItem('login_data');
    var userDataParse = JSON.parse(userDataString);

    if (userDataParse === null) {
      localStorage.clear();
      props.prop.history.push('sign-in');
    }
    getAllRequest();
  }, [])


  return (
    <div className={classes.content}>
      <Grid container>
      
     
      {Requests.map((item, i) => (
          <Grid xs={4}>
        <CustomCard request={item} component={
          <div style={{width:'100%'}}>
          <TextField
          disableUnderline={true}
          select
          value={item.status} 
          onChange={(event)=>{
            handleChange(event,item)
          }}>
              <MenuItem  value="UNDER_REVIEW">UNDER_REVIEW</MenuItem>
              <MenuItem  value="ACTIVE">ACTIVE</MenuItem>
              <MenuItem  value="REJECTED">REJECTED</MenuItem>
          </TextField>
            </div>
        }></CustomCard></Grid>
      ))}
 </Grid>
    </div>

  );
};

UserImageRequest.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  //parentCallBack:propTypes.func,
};


export default UserImageRequest;