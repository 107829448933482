import React, { useState, useEffect } from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ResumeViewer from './ResumeViewer';
import ReactModal from 'react-modal';
import { sendHttpCall } from 'shareService/httpService';
import styles from './ResumeViewer.module.scss';
import AudioPlayer from './AudioPlayer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  score: {
    // margin: theme.spacing(3),
    border: '2px solid #3f51b5',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 10
  }
}));

export default function UserCompensationDetails(props) {
  const classes = useStyles();
  const { className, userid, user, data, ...rest } = props;
  const resume_ = user.resumes.find((item) => (item.status = 'ACTIVE'));
  const resume = resume_
    ? {
        fileType: resume_.file_type,
        name: resume_.resume_name,
        link: resume_.resume_link,
        lastUpdatedAt: resume_.created_at
      }
    : null;
  if (!resume) return null;

  const deleteResume = () => {
    console.log('data');
    sendHttpCall('POST', `users/delete_resume`, { userId: userid }, props)
      .then((response) => {
        window.location.reload(true);
      })
      .catch((err) => {
        window.location.reload(true);
      });
  };
  const [showDelete, setShowDelete] = useState(false);
  console.log(data);
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography gutterBottom variant="h4">
            User Resume{' '}
            {data?.isResumeParsed && (
              <span style={{ color: 'green', fontSize: '0.8rem' }}>
                (Valid Resume)
              </span>
            )}
          </Typography>

          <Divider gutterBottom />
          <div className="com_container">
            <ResumeViewer
              link={resume.link}
              data={resume}
              fileType={resume.fileType}
            />
          </div>
          {data?.timeToSaveData && (
  <Typography gutterBottom variant="h4">
    Time taken to save data: &nbsp;
    <span style={{ color: 'purple' }}>
       {(data.timeToSaveData / 1000).toFixed(2)} seconds
    </span>
  </Typography>
)}

          <div
            style={{ display: 'flex', marginTop: '1rem', marginLeft: '1rem' }}>
            <div
              className="org__buttons-btn org__buttons-btn-delete"
              onClick={() => setShowDelete(true)}>
              Delete
            </div>
          </div>
        </Paper>
        {data?.resumeAudio?.resume_audio_link && (
          <Paper className={classes.paper} style={{ marginTop: '2rem' }}>
            <Typography gutterBottom variant="h4">
              User Resume Audio
            </Typography>
            <Divider gutterBottom />
            <div className="com_container">
              <AudioPlayer src={data.resumeAudio.resume_audio_link} />
            </div>
          </Paper>
        )}
        {data?.generatedCoverLetterLink && (
          <Paper className={classes.paper} style={{ marginTop: '2rem' }}>
            <Typography gutterBottom variant="h4">
              Generated Cover Letter
            </Typography>
            <Divider gutterBottom />
            <div className="com_container">
              <ResumeViewer
                link={data.generatedCoverLetterLink}
                data={{
                  fileType: 'PDF',
                  name: 'Cover Letter',
                  link: data.generatedCoverLetterLink,
                  lastUpdatedAt: new Date()
                }}
                fileType={'PDF'}
              />
            </div>
          </Paper>
        )}
        {data?.generatedResumeLink && (
          <Paper className={classes.paper} style={{ marginTop: '2rem' }}>
            <Typography gutterBottom variant="h4">
              Generated Resume
            </Typography>
            <Divider gutterBottom />
            <div className="com_container">
              <ResumeViewer
                link={data.generatedResumeLink}
                data={{
                  fileType: 'PDF',
                  name: 'Cover Letter',
                  link: data.generatedResumeLink,
                  lastUpdatedAt: new Date()
                }}
                fileType={'PDF'}
              />
            </div>
          </Paper>
        )}
      </div>
      <ReactModal
        isOpen={showDelete}
        className="active_jobs__modal-modal"
        overlayClassName="active_jobs__modal-overlay">
        <div className="active_jobs__modal">
          Are you sure you want to delete this resume?
          <div className="active_jobs__modal-btns">
            <button
              className="active_jobs__modal-btn-reject"
              onClick={() => {
                setShowDelete(false);
                deleteResume();
              }}>
              Delete
            </button>
            <button
              className="active_jobs__modal-btn-cancel"
              onClick={() => {
                setShowDelete(false);
              }}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
const getCompensationFromIdNew = (id) => {
  switch (id) {
    case 1:
      return '1.2-3 LPA';
    case 2:
      return '3-6 LPA';
    case 3:
      return '6-9 LPA';
    case 4:
      return '9-12 LPA';
    case 5:
      return '12-18 LPA';
    case 6:
      return '18-24 LPA';
    case 7:
      return '24-36 LPA';
    case 8:
      return '36-60 LPA';
    case 9:
      return '>60 LPA';
    case 10:
      return '₹ 100 - 200';
    case 11:
      return '₹ 200 - 350';
    case 12:
      return '₹ 350 - 500';
    case 13:
      return '₹ 500 - 700';
    case 14:
      return '₹ 700 - 1000';
    case 15:
      return '₹ 1k - 1.5k';
    case 16:
      return '₹ 1.5k - 2k';
    case 17:
      return '₹ 2k - 3k';
    case 18:
      return '₹ 3k - 5k';
    case 19:
      return '> ₹ 5k';
    default:
      return '';
  }
};
