import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import './style.scss';

const useStyles = makeStyles((theme) => ({}));

const ReportedJobs = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [offerData, setOfferData] = useState(null);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('reportedJobsTablePage'), 10) || 0
  );
  const [pageSize, setPageSize] = useState(
    parseInt(sessionStorage.getItem('reportedJobsTablePageSize'), 10) || 10
  );
  console.log(props.history.location.pathname);

  const getData = () => {
    setIsLoading(true);
    let url = `jobs/redirect_apply_clicks?offerId=${
      props.match.params.offerId
    }&limit=${pageSize}&page=${page}${
      props.history.location.pathname.includes('unique') ? '&unique=true' : ''
    }`;
    sendHttpCall('GET', url, null, props).then((response) => {
      sessionStorage.removeItem('reportedJobsTablePageSize');
      sessionStorage.removeItem('reportedJobsTablePage');
      setData(response.data);
      setTotalCount(response.count);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, [page]);
  useEffect(() => {
    if (!data.length) return;
    if (page === 0) getData();
    else setPage(0);
  }, [pageSize]);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          // actions={[
          //   {
          //     icon: 'send',
          //     tooltip: 'Open User',
          //     onClick: (event, rowData) => {
          //       props.history.push(`/account/${rowData.user_id}`);
          //     }
          //   }
          // ]}timestamp
          columns={[
            {
              title: 'S No.',
              render: (rowData) => page * pageSize + rowData.tableData.id + 1
            },
            {
              title: 'Name',
              field: 'name',
              render: (rowData) => (
                <div
                  onClick={() => {
                    sessionStorage.setItem(
                      'reportedJobsTablePageSize',
                      pageSize
                    );
                    sessionStorage.setItem('reportedJobsTablePage', page);
                    props.history.push(`/account/${rowData.user_id}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.name}
                </div>
              )
            },
            { title: 'Contact', field: 'mobile_number' },

            { title: 'Device Type', field: 'device_type' },
            { title: 'Time Stamp', field: 'created_at' }
          ]}
          data={data}
          isLoading={isLoading}
          totalCount={totalCount}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 50, 100]
          }}
          title={'Apply Now Clicks for Job ' + props.match.params.offerId}
        />
      </CardContent>
      <ReactModal
        onRequestClose={() => setOfferData(null)}
        isOpen={offerData}
        className="jobs_under_review__modal-modal"
        overlayClassName="jobs_under_review__modal-overlay">
        {offerData && (
          <>
            <div className="offer_card">
              <div className="offer_card-title">Offer Details</div>
              <div className="offer_card-grp">
                <div className="offer_card-grp-label">Job Title</div>
                <div className="offer_card-grp-value">
                  {offerData.job_title}
                </div>
              </div>
              <div className="offer_card-grp">
                <div className="offer_card-grp-label">Skills</div>
                <div className="offer_card-grp-value">
                  {offerData.skills.split(',').map((a) => (
                    <div className="offer_card-grp-value-skill">{a}</div>
                  ))}
                </div>
              </div>
              <div className="offer_card-grp">
                <div className="offer_card-grp-label">Compensation</div>
                <div className="offer_card-grp-value">
                  {offerData.compensation &&
                    offerData.compensation.replaceAll('?', '₹')}
                </div>
              </div>
              <div className="offer_card-grp">
                <div className="offer_card-grp-label">Type of Work</div>
                <div className="offer_card-grp-value">{offerData.job_type}</div>
              </div>

              <div className="offer_card-grp">
                <div className="offer_card-grp-label">Location</div>
                <div className="offer_card-grp-value">
                  {offerData.location.toLowerCase() === 'work from home'
                    ? 'Remote'
                    : offerData.location}
                </div>
              </div>
            </div>
          </>
        )}
      </ReactModal>
    </Card>
  );
};

ReportedJobs.propTypes = {
  className: PropTypes.string
};

export default withRouter(ReportedJobs);
