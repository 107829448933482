import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownload from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import MaterialTable, { MTablePagination } from 'material-table';
import ReactSelect from 'react-select';
import { selectYesterday } from '../../../config';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

let timeoutId;
const UserSearch = (props) => {
  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate()))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  };
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    localStorage.getItem('startDate')
      ? localStorage.getItem('startDate')
      : moment(new Date()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    localStorage.getItem('endDate')
      ? localStorage.getItem('endDate')
      : moment(new Date()).format('YYYY-MM-DD')
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('selectedPeriodUserSearch')
      ? localStorage.getItem('selectedPeriodUserSearch')
      : 'Today'
  );
  const [goToPageValue, setGoToPageValue] = useState('');

  const [users, setUsers] = useState([]);
  const [filterByName, setFilterByName] = useState(
    localStorage.getItem('searchData') ? true : false
  );
  const [sortingData, setSortingData] = useState(
    localStorage.getItem('sortingData')
      ? localStorage.getItem('sortingData')
      : 'registrationDate'
  );
  const [loading, setLoading] = useState(false);
  const [searchData, setSearch] = useState(
    localStorage.getItem('searchData') ? localStorage.getItem('searchData') : ''
  );
  const [filterDefault, setFilterDefault] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const [page, setPage] = useState(
    localStorage.getItem('pageNo') ? localStorage.getItem('pageNo') : 0
  );
  const [data, setData] = useState([]);
  const [ageFilter, setAgeFilter] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    if (seletedPeriod !== 'All time' && sortingData) {
      setSortingData('');
    }
  }, [seletedPeriod]);
  useEffect(() => {
    console.log(selectedStartDate);
    if (
      ('' + selectedStartDate).includes(' ') ||
      ('' + selectedEndDate).includes(' ')
    ) {
      return;
    }
    getData();
  }, [page, selectedStartDate, selectedEndDate, ageFilter]);
  const goToPage = (val) => {
    setPage(val - 1);
  };

  const onPageChange = (i) => {
    // console.log("page changed")
    // localStorage.setItem('pageNo',i);
    setPage(i);
  };
  const getData = () => {
    // console.log("unsorted")
    let pageNum = page;
    setLoading(true);
    let url =
      'gcoins/transactions_grouped?' +
      'limit=' +
      20 +
      '&page=' +
      pageNum +
      `${
        props.match.params.type === 'debited' ? '&debit=true' : ''
      }&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}`;
    sendHttpCall('GET', url, null, props).then((response) => {
      setData(response.data);
      setTotalCount(response.count);
      localStorage.removeItem('pageNo');
      localStorage.removeItem('startDate', selectedStartDate);
      localStorage.removeItem('endDate', selectedEndDate);
      localStorage.removeItem('selectedPeriodUserSearch', seletedPeriod);
      setLoading(false);
    });
  };

  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        // reqBody.start_date = moment(new Date(start)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date()).format('YYYY-MM-DD');
        //   fetchAllData();
        //startInitial
        setSelectedStartDate(
          moment(new Date(startInitial)).format('YYYY-MM-DD')
        );
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));

        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //   fetchAllData();
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //  fetchAllData();
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        // fetchAllData();
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
      //
    }
    setPage(0);
  };
  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    // var d = new Date(event);
    if (event < selectedEndDate) {
      setSelectedStartDate(event);
      setOkdate(true);
    }
  };

  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      // toast('You have selected date greater than End date');
      setOkdate(false);
    } else {
      setSelectedEndDate(event);
      setOkdate(true);
    }
  };

  const filterByDateRange = () => {
    setSelectedStartDate(
      moment(new Date(selectedStartDate)).format('YYYY-MM-DD')
    );
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };
  return (
    <div className={classes.root}>
      <div className="flex">
        {' '}
        <div className="flex">
          <div>
            <div className="sm-width">
              <Typography variant="body2" color="textSecondary" component="p">
                SELECT PERIOD
              </Typography>
            </div>
            <div className="sm-width">
              <TextField
                fullWidth
                // label="Select Period"
                margin="dense"
                name="status"
                onChange={handleTimeChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={seletedPeriod}
                variant="outlined"
                // placeholder="select period"
              >
                {/* <option value={""} > All time </option> */}
                {selectYesterday.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          {isCustomDate === true ? (
            <Grid container justify="space-around">
              <Grid xs={8}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <Grid xs={4}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="SELECT START DATE"
                        format="MM/dd/yyyy"
                        value={selectedStartDate}
                        onChange={handleChangeStartDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        id="date-picker-dialog"
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        label="SELECT END DATE"
                        margin="normal"
                        onChange={handleChageEndDate}
                        value={selectedEndDate}
                      />
                    </Grid>
                    <Grid xs={2}>
                      {okdate === false ? null : (
                        <Button
                          color="primary"
                          variant="outlined"
                          className={classes.srch_btn}
                          onClick={filterByDateRange}>
                          SEARCH
                        </Button>
                      )}
                    </Grid>
                    <Grid xs={2}></Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          ) : null}
          {/* <Grid item alignContent="center">
          <Button
            style={{ marginTop: '23px', marginLeft: '10px' }}
            variant="contained"
            color="primary"
            className={classes.srch_btn}
            onClick={resetFilter}>
            RESET
          </Button>
        </Grid> */}
        </div>
      </div>
      <div className={classes.content}>
        <MaterialTable
          columns={[
            {
              title: 'S No.',
              cellStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
              },
              headerStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
              },
              render: (rowData) => page * 20 + rowData.tableData.id + 1
            },
            {
              title: 'GCoinsCode',
              field: 'name',
              cellStyle: {
                position: 'sticky',
                left: 40,
                backgroundColor: '#FFF'
              },

              headerStyle: {
                position: 'sticky',
                left: 40,
                backgroundColor: '#FFF'
              },
              render: (rowData) => {
                return (
                  <div
                    style={{
                      cursor: 'pointer',
                      // fontWeight: 500,
                      textTransform: 'capitalize',
                      // color: '#297bca'
                      // fontSize: '.85rem',
                      fontFamily: 'Roboto',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClick={() => {
                      localStorage.setItem('startDate', selectedStartDate);
                      localStorage.setItem('endDate', selectedEndDate);
                      localStorage.setItem(
                        'selectedPeriodUserSearch',
                        seletedPeriod
                      );
                      // console.log(page)
                      localStorage.setItem('pageNo', page);
                      props.history.push(
                        `/gcoins/transactions/${rowData.tz_points_code}`
                      );
                    }}>
                    {rowData.tz_points_code}
                  </div>
                );
              }
            },

            {
              title: 'GCoins Value',
              field: 'tz_points_value'
            },

            {
              title: 'Count',
              field: 'count'
            }

            // { title: 'Offer', field: 'created_at', type: 'date' }
          ]}
          data={data}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20],
            // pageSizeOptions: [5, 10, 20, 30],
            sorting: false
          }}
          title="Active Social Users"
          page={page}
          isLoading={loading}
          onChangePage={setPage}
          totalCount={totalCount}
          style={{ zIndex: 0 }}
        />
        {/* <UsersTable users={users}/> */}
      </div>
    </div>
  );
};

export default UserSearch;

const GoToPageComponent = ({ goToPage }) => {
  const [val, setVal] = useState('');
  const goToPage_ = () => {
    if (!isNaN(parseInt(val)) && parseInt(val) !== 0) goToPage(parseInt(val));
  };
  return (
    <>
      {' '}
      <div className="table__pagination-goToPage-desc">Go To Page</div>
      <div className="table__pagination-goToPage">
        <input
          type="text"
          value={val}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              goToPage_();
            }
          }}
          onChange={(e) => setVal(e.target.value)}
        />
        <button className="table__pagination-goToPage-btn" onClick={goToPage_}>
          Go
        </button>
      </div>
    </>
  );
};
