const getFormatedDate = (blogDate) =>{
    var date = new Date(blogDate);
    var month = date.getUTCMonth();
    var day= date.getUTCDate();
    var year = date.getUTCFullYear();
    var monthstring = "";
    switch(month) {
      case 0: monthstring = "January"
      break;
      case 1: monthstring = "Feburary"
      break;
      case 2: monthstring = "March"
      break;
      case 3: monthstring = "April"
      break;
      case 4: monthstring = "May"
      break;
      case 5: monthstring = "June"
      break;
      case 6: monthstring = "July"
      break;
      case 7: monthstring = "August"
      break;
      case 8: monthstring = "September"
      break;
      case 9: monthstring = "October"
      break;
      case 10: monthstring = "November"
      break;
      default: monthstring = "December"
    }
      return monthstring + " " + day + ", " + year;

  }

  module.exports = {getFormatedDate}