/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import ResumeUploader from './ResumeUploader';
import { Viewer } from '@react-pdf-viewer/core';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCloudDownload
} from 'react-icons/io';
import styles from './ResumeViewer.module.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function ResumeViewer({ link = '', fileType = 'PDF', data }) {
  const [open, setOpen] = useState(false);
  const onDownloadClick = () => {
    window.open(link, '_blank').focus();
  };

  if (fileType !== 'PDF')
    return (
      <div className={styles.resumeContainer}>
        <ResumeUploader readOnly restoredData={data} type="MINIMAL" />
      </div>
    );
  return (
    <div className={styles.superContainerTopLevel}>
      <div className={styles.downloadButton} onClick={onDownloadClick}>
        <span className={styles.downloadButtonIcon}>
          <IoIosCloudDownload />
        </span>
        Download
      </div>
      <div className={styles.superContainer}>
        <div
          className={`${styles.container} ${open ? styles.containerOpen : ''}`}>
          <div className={styles.resumePreviewContainer}>
            <Viewer fileUrl={link} />
          </div>
        </div>
        <div
          className={styles.containerBottom}
          onClick={() => setOpen((a) => !a)}>
          {!open ? 'Show More' : 'Show Less'}
          <span className={styles.containerBottomIcon}>
            {!open ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </span>
        </div>
      </div>
    </div>
  );
}
