import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from "material-table";
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
}));

const SkillUsersList = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <MaterialTable
          actions={[
            {
              icon: 'send',
              tooltip: 'Open User',
              onClick: (event, rowData) => {
                props.history.push(`/account/${rowData.user_id}`);
              }
            }
          ]}
          columns={[
            { title: 'id', field: 'user_id' },
            { title: 'Name', field: 'name' },
            { title: 'Phone', field: 'mobile_number' },
            { title: 'city', field: 'location_city' },
            { title: 'Score', field: 'score' },
            { title: 'Joined On', field: 'created_at', type: 'date' },
          ]}
          data= {query => {
              //| user_id  | name                | mobile_number | score | location_city 
            return new Promise((resolve, reject) => {
              let url = 'dashboard/socialmedia/list?' + 'media=' + props.match.params.media + '&limit=' + query.pageSize + '&page=' + (query.page)+ `&start_date=${props.match.params.start}&end_date=${props.match.params.end}`;
              sendHttpCall('GET',url,null,props)
                .then(response => {
                  resolve({
                    data: response.data,
                    page: response.page,
                    totalCount: response.totalCount,
                  })
                })
            })
          }
          }
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10,20,30]
          }}
          title="All Users"
        />
      </CardContent>
    </Card>
  );
};

SkillUsersList.propTypes = {
  className: PropTypes.string,
};

export default withRouter(SkillUsersList);