
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Link,
  Box,
  Grid,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';

import { sendHttpCall, Toast } from 'shareService/httpService';
import CustomTabs from 'components/CustomTab/CustomTab';
import { message } from '../../../config';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function UserDesignationRequest  (props){
    const { className, ...rest } = props;


  

    const classes = useStyles();
    //const [arrayDatas, setarrayDatas] = useState([]);
    const [Requests,setRequests]= useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('');
  
    const handleSelectAll = event => {
    
      let selectedUsers;
  
      if (event.target.checked) {
        selectedUsers = Requests.map(Request => Request.designation_id);
      } else {
        selectedUsers = [];
      }
  
      setSelectedUsers(selectedUsers);
    };
  
    const handleSelectOne = (event, id) => {
      const selectedIndex = selectedUsers.indexOf(id);
      let newSelectedUsers = [];
  
      if (selectedIndex === -1) {
        newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
      } else if (selectedIndex === 0) {
        newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
      } else if (selectedIndex === selectedUsers.length - 1) {
        newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedUsers = newSelectedUsers.concat(
          selectedUsers.slice(0, selectedIndex),
          selectedUsers.slice(selectedIndex + 1)
        );
      }
  
      setSelectedUsers(newSelectedUsers);
    };
  
    const handlePageChange = (event, page) => {
      setPage(page);
    };
  
    const handleRowsPerPageChange = event => {
      setRowsPerPage(event.target.value);
    };

   const getAllRequest = ()=>{
    sendHttpCall('GET','all-designations',null,props.prop).then((response)=>{
      if(response){
        setRequests(response.data);
        setSelectedStatus(response.data.status);
      }

    });
  }
  
  const handleChange = (event,data)=>{
    
    setSelectedStatus(event.target.value)
    let body={
      status:event.target.value
    }
    sendHttpCall('PUT',`designations/edit-status/${data.designation_id}`,body,props).then((response)=>{
      
      Toast('Designation Status'+message.UPADTE);

      getAllRequest();
    });
  }

  useEffect(()=>{
    var userDataString = localStorage.getItem('login_data');
    
    var userDataParse = JSON.parse(userDataString);

   if(userDataParse === null){
     localStorage.clear();
    props.prop.history.push('sign-in');
   }
    getAllRequest();
  },[])
 
  
    return (
      <div className = {classes.content}>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.length === Requests.length}
                        color="primary"
                        indeterminate={
                          selectedUsers.length > 0 &&
                          selectedUsers.length < Requests.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Status</TableCell>
                 
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((Request, index) => (
                    <TableRow
                     
                      className={classes.tableRow}
                      hover
                      key={Request.designation_id}
                      selected={selectedUsers.indexOf(Request.designation_id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUsers.indexOf(Request.designation_id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, Request.designation_id)}
                          value="true"
                        />
                      </TableCell>

                   
                  <TableCell>{Request.designation}</TableCell>
                  <TableCell>
                    <div style={{width:100}}>
                  <Select
                  disableUnderline={true}
                  value={Request.status} 
                  onChange={(event)=>{
                    handleChange(event,Request)
                  }}>
                      <MenuItem  value="UNDER_REVIEW">UNDER_REVIEW</MenuItem>
                      <MenuItem  value="ACTIVE">ACTIVE</MenuItem>
                      <MenuItem  value="INACTIVE">INACTIVE</MenuItem>
                  </Select>
                    </div>
                    </TableCell>
                   
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={Requests.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      </div>
   
    );
  };
  
  UserDesignationRequest.propTypes = {
   className: PropTypes.string,
    users: PropTypes.array.isRequired,
    //parentCallBack:propTypes.func,
  };


export default UserDesignationRequest;