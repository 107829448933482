import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sendHttpCall } from 'shareService/httpService';
// import { UsersToolbar } from '../components';
import MaterialTable from 'material-table';
import './style.css';
import ReactSelect from 'react-select';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const PromoCodes = (props) => {
  //   const [users, setUsers] = useState([]);
  //   const [filterByName, setFilterByName] = useState('');
  //   const [sortingData, setSortingData] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [options, setOptions] = useState([]);
  const [enableDisableCampaign, setEnableDisableCampaign] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: ''
  });
  //   const [totalCount, setTotalCount] = useState(0);
  //   const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const classes = useStyles();

  // const getCampaignTypes = () => {
  //   setLoading1(true);
  //   let url = '/marketing_campaign/get_types';
  //   sendHttpCall('GET', url, null, props).then((response) => {
  //     if (response.state === 200) {
  //       console.log(response.data);
  //       let options_ = response.data.map((a) => ({
  //         value: a.marketing_campaign_type,
  //         label: a.marketing_campaign_type
  //       }));
  //       setOptions(options_);
  //       setSelectedOption(options_[0]);
  //       setLoading1(false);
  //     }
  //   });
  // };
  const handleTypeChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoading(true);
    let url = '/promo_code/get_all';
    sendHttpCall('GET', url, null, props).then((response) => {
      setData(response.data);
      //   setTotalCount(response.totalCount);
      setLoading(false);
    });
  };
  const handleToggleStatus = (value) => {
    let url = '/promo_code/change_promo_code_status';
    let status =
      data.find((a) => a.promo_code_name === value).status === 'ACTIVE';
    sendHttpCall(
      'POST',
      url,
      {
        promo_code_name: value,
        status: status ? 'INACTIVE' : 'ACTIVE'
      },
      props
    )
      .then((response) => {
        setEnableDisableCampaign('');
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };
  return (
    <div className={classes.root}>
      <div className="marketingCampaign__title">Promo Codes Manager</div>
      {/* <div className="marketingCampaign__row">
        <div className="marketingCampaign__text">Choose Campaign Type</div>
        <div className="marketingCampaign__select">
          <ReactSelect
            options={options}
            isLoading={loading1}
            value={selectedOption}
            onChange={handleTypeChange}
          />
        </div>
      </div> */}
      <div className={classes.content}>
        <MaterialTable
          columns={[
            {
              title: 'S No.',
              render: (rowData) => rowData.tableData.id + 1
            },
            {
              title: 'Promo Code Name',
              field: 'promo_code_name'
            },
            {
              title: 'Discount Percentage',
              field: 'discount_percentage'
            },
            {
              title: 'Promo Code Link',
              field: 'promo_code_link',
              render: (rowData) => (
                <a
                  href={rowData.promo_code_link}
                  className="campaign_link"
                  target="_blank">
                  {rowData.promo_code_link}
                </a>
              )
            },
            {
              title: 'Frequency',
              field: 'validity_frequency'
            },

            {
              title: 'Validity Start',
              field: 'validity_start',
              render: (rowData) =>
                rowData.validity_type === 'TIME_BASED'
                  ? getLocaleTime(rowData.validity_start)
                  : ''
            },
            {
              title: 'Validity End',
              field: 'validity_end',
              render: (rowData) =>
                rowData.validity_type === 'TIME_BASED'
                  ? getLocaleTime(rowData.validity_end)
                  : ''
            },
            {
              title: 'Created At',
              field: 'created_at',
              render: (rowData) => getLocaleTime(rowData.created_at)
            },
            {
              title: 'Updated At',
              field: 'updated_at',
              render: (rowData) => getLocaleTime(rowData.updated_at)
            },
            {
              title: 'Status',
              field: 'status'
            },
            {
              title: 'Action',
              render: (rowData) => (
                <button
                  className="disable_enable"
                  onClick={() => {
                    setEnableDisableCampaign(rowData.promo_code_name);
                  }}>
                  {rowData.status === 'ACTIVE' ? 'Disable' : 'Enable'}
                </button>
              )
            }
          ]}
          data={data}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20],
            // pageSizeOptions: [5, 10, 20, 30],
            sorting: false
          }}
          title="Promo Codes"
          isLoading={loading}
          style={{ zIndex: 0 }}
        />
        {/* <UsersTable users={users}/> */}
      </div>
      <ReactModal
        isOpen={enableDisableCampaign}
        overlayClassName="overlay"
        onRequestClose={() => {
          setEnableDisableCampaign('');
        }}
        className="modal">
        <div className="campaign_modal">
          <div className="campaign_modal_text">
            Are you sure you want to{' '}
            {enableDisableCampaign &&
            data.find((a) => a.promo_code_name === enableDisableCampaign)
              .status === 'ACTIVE'
              ? 'disable'
              : 'enable'}{' '}
            {enableDisableCampaign}?
          </div>
          <div className="campaign_modal_buttons">
            <button
              className="campaign_modal_buttons-cancel"
              onClick={() => {
                setEnableDisableCampaign('');
              }}>
              Cancel
            </button>
            <button
              className="campaign_modal_buttons-action"
              onClick={() => handleToggleStatus(enableDisableCampaign)}>
              Continue
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default PromoCodes;
