import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, Select, Button, Grid, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import styles from './style.module.scss'; // Assuming styles are in styles.module.scss

const DateRangePicker = ({ onDateChange }) => {
    const [selectedPeriod, setSelectedPeriod] = useState('Today');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isCustomDate, setIsCustomDate] = useState(false);

    useEffect(() => {
        handlePeriodChange(selectedPeriod);
    }, [selectedPeriod]);

    const handlePeriodChange = (period) => {
        let start = new Date();
        let end = new Date();
        switch (period) {
            case 'Today':
                start=end=new Date();
                break;
            case 'Yesterday':
                start.setDate(start.getDate() - 1);
                end.setDate(end.getDate() - 1);
                break;
            case 'Last 7 days':
                start.setDate(start.getDate() - 7);
                break;
            case 'Last 30 days':
                start.setDate(start.getDate() - 30);
                break;
            case 'Last Year':
                start.setDate(start.getDate() - 365);
                break;
            case 'Custom Date':
                setIsCustomDate(true);
                return;
            default:
                break;
        }
        setIsCustomDate(false);
        setStartDate(start);
        setEndDate(end);
        onDateChange({ startDate: moment(start).format('YYYY-MM-DD'), endDate: moment(end).format('YYYY-MM-DD') });
    };

    const handleCustomDateChange = () => {
        onDateChange({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') });
    };

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
            <FormControl fullWidth>
                <Typography variant="body2" color="textSecondary" component="p">
                    SELECT PERIOD
                </Typography>
                <Select
                    value={selectedPeriod}
                    onChange={(e) => setSelectedPeriod(e.target.value)}
                    variant="outlined"
                    className={styles.select}
                >
                    <MenuItem value="Today">Today</MenuItem>
                    <MenuItem value="Yesterday">Yesterday</MenuItem>
                    <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                    <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                    {/* <MenuItem value="Last Year">Last Year</MenuItem> */}
                    <MenuItem value="Custom Date">Custom Date</MenuItem>
                </Select>
            </FormControl>
            </div>

            {/* Date pickers and button are rendered only when "Custom Date" is selected */}
            {isCustomDate && (
                <Grid container spacing={1}
                    className={styles.customDateContainer}
                    alignItems="center"
                    justifyContent="flex-start">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={4}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Start Date"
                                format="MM/dd/yyyy"
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                className={styles.datePicker}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="End Date"
                                format="MM/dd/yyyy"
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                className={styles.datePicker}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCustomDateChange}
                                className={styles.searchButton}
                            >
                                Search
                            </Button>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
            )}
        </div>


    );
};

export default DateRangePicker;