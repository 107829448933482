import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from "material-table";
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
}));

const ZeroCountryCode = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <MaterialTable
          columns={[
            { title: 'Contact ID', field: 'contact_user_id' },
            { title: 'Contact Name', field: 'contact_name' },
            { title: 'Country Code', field: 'country_code' },
            { title: 'Mobile', field: 'mobile_number' },
            { title: 'User ID', field: 'user_id' },
            // { title: 'User Name', field: 'name' },
            // { title: 'Registration Date', field: 'created_at', type: 'date' },
          ]}
          data= {query => {
            return new Promise((resolve, reject) => {
              let url = 'users/invalid_country_code_list?' + 'limit=' + query.pageSize + '&page=' + (query.page);
              sendHttpCall('GET',url,null,props)
                .then(response => {
                  resolve({
                    data: response.data,
                    page: response.page,
                    totalCount: response.totalCount,
                  })
                })
            })
          }
          }
          options={{
            search: false,
            pageSize: 10,
            pageSizeOptions: [10,20,30]
          }}
          title="Users with country code +0"
        />
      </CardContent>
    </Card>
  );
};

ZeroCountryCode.propTypes = {
  className: PropTypes.string,
};

export default withRouter(ZeroCountryCode);