/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList as List } from 'react-window';
import { createFilter, components } from 'react-select';
import PropTypes from 'prop-types';
import Axios from 'axios';

const notLoggedInTokenProd =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYyMjYwNDY4MH0.ZQxGVUbEZFpSyRYNEeTQysh4fNomDM_RS5XUSqx7YyY'; // prod

const fetchSpecificConfigApi = async (url) => {
  let data = await Axios.get('https://api.ourgoodspace.com/api/d2/' + url, {
    headers: {
      authorization: notLoggedInTokenProd
    }
  });
  return data;
};
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const height = 35;
  const initialOffset = options.indexOf(value) * height;
  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

MenuList.propTypes = {
  options: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
  maxHeight: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired
};
const DropdownIndicator = (props) => {
  // eslint-disable-next-line react/prop-types
  const { theme } = props;
  return (
    <components.DropdownIndicator {...props}>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path
          d="M20 20L15.514 15.506L20 20ZM18 9.5C18 11.7543 17.1045 13.9163 15.5104 15.5104C13.9163 17.1045 11.7543 18 9.5 18C7.24566 18 5.08365 17.1045 3.48959 15.5104C1.89553 13.9163 1 11.7543 1 9.5C1 7.24566 1.89553 5.08365 3.48959 3.48959C5.08365 1.89553 7.24566 1 9.5 1C11.7543 1 13.9163 1.89553 15.5104 3.48959C17.1045 5.08365 18 7.24566 18 9.5V9.5Z"
          // eslint-disable-next-line react/prop-types
          stroke={theme.colors.iconColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};
function CustomSelectWindowed({
  options,
  placeholder,
  theme,
  selectedOption,
  onChange
}) {
  const customStyles = {
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: theme === 'filter' ? '#b1c7ff' : '#989BA4'
    }),
    control: (base, state) => ({
      ...base,
      background: theme === 'filter' ? '#ffffff' : '#eeeeee',
      padding: '0.125rem 0.35rem',
      border: 'none',
      fontFamily: 'Roboto',
      borderRadius: theme === 'filter' ? '11px' : '9px',
      fontSize: '15px',
      color: theme === 'filter' ? '#3c72ff' : '#4E525C',
      boxShadow: theme === 'filter' ? '0px 2px 10px rgba(0, 0, 0, 0.15)' : null
    })
  };

  return (
    <div className="customSelect-container">
      <CreatableSelect
        styles={customStyles}
        components={{ MenuList, DropdownIndicator }}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={selectedOption && selectedOption}
        classNamePrefix="customSelect"
        filterOption={createFilter({ ignoreAccents: false })}
        theme={(theme_) => ({
          ...theme_,
          borderRadius: 0,
          colors: {
            ...theme_.colors,
            primary25: '#deebff',
            primary: '#6ea7ff',
            iconColor: theme === 'filter' ? '#b1c7ff' : '#C4C4C4'
          }
        })}
      />
    </div>
  );
}

export default function CustomSelectWrapper({
  type,
  searchType,
  placeholder,
  theme,
  options,
  selectedOption,
  onChange
}) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const getUrl = (typeArg, searchTypeArg = 'config', value) => {
    switch (searchTypeArg) {
      case 'config':
        return `config/searchConfig?config=${typeArg}&search=${'%'}`;
      case 'contact':
        return `/contact/search?query=${'%'}`;
      case 'organisation':
        return `/config/organisation?search=${value}`;
      case 'school':
        return `/config/organisation?search=${value}&edu=1`;
      case 'degree':
        return `config/searchConfig?config=degree&search=${value}`;
      case 'field':
        return `config/searchConfig?config=degree_field&search=${value}`;
      default:
        return '';
    }
  };

  const getData = (val = null) => {
    setLoading(true);
    const url = getUrl(type, searchType, val);
    if (options && options.length) {
      setList(options);
    } else
      fetchSpecificConfigApi(url).then((res) => {
        setList(res.data.data.map((a) => ({ value: a.id, label: a.value })));
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <CustomSelectWindowed
      options={list}
      height={35}
      selectedOption={selectedOption}
      theme={theme}
      onChange={onChange}
      placeholder={placeholder}
      filterOption={createFilter({ ignoreAccents: false })}
    />
  );
}

CustomSelectWindowed.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired
};
CustomSelectWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  searchType: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  theme: PropTypes.string
};
CustomSelectWrapper.defaultProps = {
  theme: 'default'
};
