import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import ReactModal from 'react-modal';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent, Button } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CloudDownload from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles((theme) => ({}));

export default function PotentialCandidatesRerank(props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('potentialCandidatePage'), 10) || 0
  );
  const [pageSize, setPageSize] = useState(
    parseInt(sessionStorage.getItem('potentialCandidateSize'), 10) || 10
  );

  const getData = () => {
    const location = props.history.location.pathname;
    let url = `/jobs/potential_candidates_s3_v2_rerank${
      location.includes('cutoff') ? '_cutoff' : ''
    }?page=${page}&limit=${pageSize}&offerId=${props.match.params.offerId}`;
    setIsLoading(true);
    sendHttpCall('GET', url, null, props).then((response) => {
      sessionStorage.removeItem('potentialCandidateSize');
      sessionStorage.removeItem('potentialCandidatePage');
      setData(response.data);
      setTotalCount(response.count);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [page, pageSize]);

  const exportAllDataToCSV = async () => {
    let allData = [];
    let page = 0;
    const limit = 100; 
    let hasMoreData = true;

    while (hasMoreData) {
      const url = `/jobs/potential_candidates_s3_v2_rerank${props.history.location.pathname.includes('cutoff') ? '_cutoff' : ''}?page=${page}&limit=${limit}&offerId=${props.match.params.offerId}`;
      try {
        const response = await sendHttpCall('GET', url, null, props);
        if (response.data && response.data.length > 0) {
          allData = allData.concat(response.data);
          page++;
          hasMoreData = response.data.length === limit; 
        } else {
          hasMoreData = false;
        }
      } catch (error) {
        console.error('Failed to fetch all data for export:', error);
        hasMoreData = false;
      }
    }

    if (allData.length === 0) {
      console.log("No data available to export.");
      return;
    }
   
    const csvHeaders = ['Name', 'User Id', 'Skills', 'Designations'];
    
    const csvRows = allData.map(item => [
        `"${item.name}"`,
        `"${item.user_id}"`,
        `"${item.skills}"`,
        `"${item.designations}"`
    ]);

    const csvContent = [csvHeaders.join(','), ...csvRows.map(e => e.join(','))].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'potential_candidates.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          isLoading={isLoading}
          columns={[
            { title: 'S No.', render: (rowData) => page * pageSize + rowData.tableData.id + 1 },
            {
              title: 'Name',
              field: 'name',
              render: (rowData) => (
                <div
                  onClick={() => {
                    sessionStorage.setItem('potentialCandidatePage', page);
                    sessionStorage.setItem('potentialCandidateSize', pageSize);
                    console.log(rowData.user_id);
                    props.history.push(`/account/${rowData.user_id}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.name}
                </div>
              )
            },
            { title: 'Skills', field: 'skills' },
            { title: 'Designations', field: 'designations' },
            { title: 'Location', field: 'location_city' }
          ]}
          data={data}
          page={page}
          totalCount={totalCount}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Potential Candidates S3
              <Button
                variant="contained"
                onClick={exportAllDataToCSV}
                style={{ 
                  padding: '10px', 
                  margin: '10px',
                  backgroundColor: '#3ca7ff', 
                  color: '#fff'
                }}
              >
                <span style={{ marginRight: '5px' }}>Export To CSV</span> <CloudDownload />
              </Button>
            </div>
          }
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 50]
          }}
        />
      </CardContent>
    </Card>
  );
}
