import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from "material-table";
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
}));

const UserCountByCity = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <MaterialTable
          columns={[
          { title: 'City', field: 'city' },
          { title: 'User Count', field: 'total_user' },
          ]}
          data= {query => {
          return new Promise((resolve, reject) => {
              let url = 'users/count_user_by_city?' + 'limit=' + query.pageSize + '&page=' + (query.page);
              sendHttpCall('GET',url,null,props)
              .then(response => {
                  resolve({
                  data: response.data,
                  page: response.page,
                  totalCount: response.totalCount,
                  })
              })
          })
          }
          }
          options={{
          search: false,
          pageSize: 10,
          pageSizeOptions: [10,20,30]
          }}
          title="Number of User per City"
        />
      </CardContent>
    </Card>
  );
};

UserCountByCity.propTypes = {
  className: PropTypes.string,
};

export default withRouter(UserCountByCity);