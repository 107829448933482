import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from "material-table";
import { sendHttpCall } from 'shareService/httpService';
import { Grid, Card, CardContent,Typography, TextField, Button  } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import moment from 'moment';
import { toast } from 'react-toastify';
import { selectYesterday } from '../../../config';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
}));

const ActiveUsersList = props => {
  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate() - 1))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate() - 1))).format(
      'YYYY-MM-DD'
    )
  };
  const tableRef = React.createRef();
  const { className, ...rest } = props;
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = React.useState( moment(new Date(start.setDate(start.getDate()))).format('YYYY-MM-DD'));
  const [selectedEndDate, setSelectedEndDate] = React.useState( moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD'));
  const [okdate, setOkdate] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize,setPageSize]= useState(30);
  const [seletedPeriod, setSeletedPeriod] = useState('');
  const [isCustomDate, setIsCustomDate] = useState(false);
 

  useEffect(() => {
  }, []);
  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        // reqBody.start_date = moment(new Date(start)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date()).format('YYYY-MM-DD');
        //   fetchAllData();
        //startInitial
        setSelectedStartDate(moment(new Date(startInitial)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        //   fetchAllData();
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        //  fetchAllData();
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        // fetchAllData();
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
           tableRef.current.onQueryChange();
        // fetchAllData();
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        // fetchAllData();
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
      //  tableRef.current.onQueryChange();
    }
  };
  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    // var d = new Date(event);
    if(event<selectedEndDate){
      setSelectedStartDate(event);
      setOkdate(true);
    }
  } 

  const handleChageEndDate = (event) => {
    if(event<selectedStartDate){
      toast('You have selected date greater than End date'); 
      setOkdate(false);
    } else{
      setSelectedEndDate(event);
      setOkdate(true);
    }
  }

  const filterByDateRange = () => {
    setSelectedStartDate( moment(new Date(selectedStartDate)).format('YYYY-MM-DD'));
    setSelectedEndDate( moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
    tableRef.current.onQueryChange();
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      <Grid container>
        <Grid xs={2} item>
          <Typography variant="body2" color="textSecondary" component="p">
            SELECT PERIOD
          </Typography>
          <TextField
            fullWidth
            // label="Select Period"
            margin="dense"
            name="status"
            onChange={handleTimeChange}
            required
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={seletedPeriod}
            variant="outlined"
            // placeholder="select period"
          >
            {/* <option value={""} > All time </option> */}
            {selectYesterday.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        </Grid>
        {/* <Grid item alignContent="center">
          <Button
            style={{ marginTop: '23px', marginLeft: '10px' }}
            variant="contained"
            color="primary"
            className={classes.srch_btn}
            onClick={resetFilter}>
            RESET
          </Button>
        </Grid> */}
      </Grid>
      {isCustomDate === true ? 
              <Grid container justify="space-around">
                <Grid xs={8}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="SELECT START DATE"
                          format="MM/dd/yyyy"
                          value={selectedStartDate}
                          onChange={handleChangeStartDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          format="MM/dd/yyyy"
                          id="date-picker-dialog"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          label="SELECT END DATE"
                          margin="normal"
                          onChange={handleChageEndDate}
                          value={selectedEndDate}
                        />
                      </Grid>
                      <Grid xs={2}>
                        {okdate===false?null:<Button color="primary" variant="outlined"  className={classes.srch_btn} onClick={filterByDateRange}>SEARCH</Button>}
                      </Grid>
                      <Grid xs={2}>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid> : null}
        <MaterialTable
          tableRef={tableRef}
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          actions={[
            {
              icon: 'send',
              tooltip: 'Open User',
              onClick: (event, rowData) => {
                props.history.push(`/account/${rowData.user_id}`);
              }
            }
          ]}
          columns={[
            {
              title: 'S No.',
              render: (rowData) => page*pageSize + rowData.tableData.id + 1
            },
            { title: 'id', field: 'user_id' },
            { title: 'Name', field: 'name' },
            { title: 'Phone', field: 'mobile_number' },
            { title: 'Email', field: 'email_id' },
            { title: 'Status', field: 'status' },
            { title: 'Registration Date', field: 'created_at', type: 'date' },
          ]}
          data= {query => {
            return new Promise((resolve, reject) => {
              let url = 'users/active_users_list?' + 'limit=' + query.pageSize + '&page=' + (query.page) + `&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}`;;
              sendHttpCall('GET',url,null,props)
                .then(response => {
                  resolve({
                    data: response.data,
                    page: response.page,
                    totalCount: response.totalCount,
                  })
                })
            })
          }
          }
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: `${pageSize}`,
            pageSizeOptions: [10,20,30,50]
          }}
          title="All Users"
        />
      </CardContent>
    </Card>
  );
};

ActiveUsersList.propTypes = {
  className: PropTypes.string,
};

export default withRouter(ActiveUsersList);