/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';

import CloudDownload from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';
import AudioPlayer from './AudioPlayer';
import MaterialTable, { MTablePagination } from 'material-table';
import moment from 'moment';
import styles from './InterviewDetails.module.scss';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import ReactModal from 'react-modal';
import JobCard from 'components/JobCard/JobCard';

export default function EveryTable(props) {
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);
  const [finishedInterviewsCount, setFinishedInterviewsCount] = useState(0);
  const [bloodDonorRequestId, setBloodDonorRequestId] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const getLocaleTime = (time) => {
    if (time) {
      if (props.match.params.type === 'active_member')
        return moment(new Date(time + ' UTC')).format('DD/MM/YYYY h:mm:ss A');
      return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    }
    return '';
  };
  const startDate = useRef(
    props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
  );
  const endDate = useRef(
    props.match.params.end_date
      ? props.match.params.end_date
      : moment(new Date()).format('YYYY-MM-DD')
  );

  const openUserPage = (userId, social) => {
    sessionStorage.setItem('everyTablePage', page);
    sessionStorage.setItem('everyTableLimit', limit);
    props.history.push(`/account/${userId}${social ? '?type=SOCIAL' : ''}`);
  };
  const columns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.userDetails.user_id);
          }}>
          {rowData.userDetails.name}
        </div>
      )
    },
    {
      title: 'Job Title',
      field: 'job_title',
      render: (rowData) => rowData.jobDetails?.title
    },

    {
      title: 'Message Count',
      field: 'messageCount',
      render: (rowData) => rowData.messageCount - 1
    },

    {
      title: 'Status',
      render: (rowData) => rowData.interviewStatus
    },
    {
      title: 'Stop Reason',
      render: (rowData) =>
        rowData.adminRemarks?.replace(
          'INTERVIEW ENDED BY USER',
          'Ended by user'
        )
    },
    {
      title: 'Started At',
      field: 'startedAt',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.startedAt)
    },
    {
      title: 'Finished At',
      field: 'completedAt',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.completedAt)
    },
    {
      title: 'Grade',
      field: 'grade'
    }
  ];
  const getTableLabel = () => {
    let { type } = props.match.params;
    const countryCode = window.location.href.split('?countryCode=')[1];

    if (type === 'social_connect_requests') {
      return 'Social Connect Requests Table';
    }
    if (type === 'social_connect_requests_accepted') {
      return 'Social Connect Requests Accepted Table';
    }
    if (type === 'social_connect_requests_rejected') {
      return 'Social Connect Requests Rejected Table';
    }
    if (type === 'active_member') {
      return 'New Users Table';
    }
    if (type === 'active_member-country') {
      return `New Users Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'active_hirers') {
      return 'Active Hirers Table';
    }
    if (type === 'active_hirers-country') {
      return `Active Hirers Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'verified_hirers') {
      return 'Verified Hirers Table';
    }
    if (type === 'verified_hirers-country') {
      return `Verified Hirers Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'reportedJobs') {
      return 'Reported Jobs';
    }
    if (type === 'feedback') {
      return 'Feedbacks Given';
    }
    if (type === 'status1') {
      return 'Status 1 Users';
    }
    if (type === 'status1-country') {
      return `Status 1 Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'status2') {
      return 'Status 2 Users';
    }
    if (type === 'status2-country') {
      return `Status 2 Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'feedback_conversions') {
      return 'Users Converted From Feedback Requests';
    }
    if (type === 'by_country') {
      return `Users From ${countries[props.match.params.period]}`;
    }
    if (type === 'device_type') {
      return `Users From ${props.match.params.period}`;
    }
    if (type === 'by_campaign') {
      return `Users From ${props.match.params.period} Campaign`;
    }
    if (type === 'survey_sent') {
      return 'Survey Messages Sent';
    }
    if (type === 'survey_link_clicks') {
      return 'Survey Link Clicks';
    }
    if (type === 'survey_received') {
      return 'Survey Received';
    }
    if (type === 'closedJobs') {
      return 'Closed Jobs';
    }
    if (type === 'deactivated') {
      return 'Deactivated Users';
    }
    if (type === 'deactivated-country') {
      return `Deactivated Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'inactive_hirers') {
      return 'Inactive Hirers';
    }
    if (type === 'active_hirers-2') {
      return 'Active Hirers';
    }
    if (type === 'blocked') {
      return 'Blocked Users';
    }
    if (type === 'blocked-country') {
      return `Blocked Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'transactions') {
      return 'Transactions';
    }
    if (type === 'failed-transactions') {
      return 'Failed Transactions';
    }
    if (type === 'created-orders') {
      return 'Created Orders';
    }

    if (type === 'survey_conversions') {
      return 'Users Converted From Survey Messages';
    }
    if (type === 'referrals_campus_ambassadors')
      return 'Referrals (Campus Ambassadors)';
    if (type === 'referrals_others') return 'Referrals (Others)';
    if (type === 'status2Hirer') return 'Hirer Status 2';
    if (type === 'status2Hirer-country')
      return `Hirer Status 2 ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    if (type === 'status2Member') return 'Job Seeker Status 2';
    if (type === 'status2Member-country')
      return `Job Seeker Status 2 ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    if (type === 'status4') return 'Status 4 Only Members';
    if (type === 'find-blood-conversions') return 'Find Blood Conversions';
    if (type === 'find-blood-only-users') return 'Find Blood Only Users';
    if (type === 'blood-donor-requests') return 'Blood Donor Requests';
    if (type === 'blood-donor-requests-accepted')
      return 'Blood Donor Requests Accepted';
    if (type === 'blood-donor-requests-rejected')
      return 'Blood Donor Requests Rejected';
    if (type === 'blood-donor-requests-admin')
      return 'Blood Donor Requests Admin';
    if (type === 'resume-downloaded') return 'Resume Generated';
    if (type === 'resume-audio-uploaded') return 'Resume Audio Uploaded';
    if (type === 'status4-country')
      return `Status 4 Only Members ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
  };
  const getEveryOtherTableData = async () => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      let url = `interview/${props.match.params.interviewId}`;
      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
      });
    });
  };
  const getData = async () => {
    getEveryOtherTableData();
  };
  useEffect(() => {
    getData();
    sessionStorage.removeItem('everyTablePage');
    sessionStorage.removeItem('everyTableLimit');
  }, [page]);
  useEffect(() => {
    getData();
  }, []);
  const toDoubleDecimalPlaces = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>Interview Details</div>
        <div className={styles.subtitle}>
          <Link to={`/account/${data?.userDetails?.user_id}`}>
            {data?.userDetails?.name}
          </Link>{' '}
          interviewing for{' '}
          <span onClick={() => setShowJobDetails(true)}>
            {data?.jobDetails?.title}
          </span>
        </div>
        <div className={styles.CandidateStatus}>
          Candidate status{' '}: 
          <span onClick={() => setShowJobDetails(true)}>
            {data?.isRecomended}
          </span>
        </div>
        <div className={styles.row}>
          <div className={styles.chatboxContainer}>
            <div className={styles.chatBoxMessages}>
              {data.messages?.map((item) =>
                item.sentBy === 'HR' ? (
                  <MessageReceived
                    text={item.message}
                    date={new Date(item.createdAt)}
                    playAudio={setAudioSrc}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : item.sentBy === 'USER' ? (
                  <MessageSent
                    text={item.message}
                    playAudio={setAudioSrc}
                    date={new Date(item.createdAt)}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : null
              )}
            </div>
          </div>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryTitle}>Summary</div>
            <div className={styles.summaryText}>{data.interviewSummary}</div>
          </div>
        </div>
      </div>{' '}
      {data.screeningQuestions && data.screeningQuestions.length > 0 && (
  <div className={styles.container3}>
    <div className={styles.title2}>Screening Questions</div>
    <div className={styles.interviewScores2Container}>
      {data.screeningQuestions.map((item, index) => (
        <div key={index} className={styles.interviewScores2}>
          <div className={styles.interviewScoreSno}>{index + 1}.</div>
          <div>
            <div className={styles.interviewScoreLabel}>
              Question: {item.question}
            </div>
            <div className={styles.interviewScoreValue}>
              Answer Provided: {item.isAnsProvided ? "Yes" : "No"}
            </div>
            <div className={styles.interviewScoreValue}>
              Analysis: {item.analysis}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}

      {data.interviewScores && (
        <div className={styles.container3}>
          <div className={styles.title2}>Interview Scores</div>
          <div className={styles.interviewScores2Container}>
            <div className={styles.interviewScoreCategory}>
              <div className={styles.interviewScoreCategoryTitle}>
                Technical
              </div>
              {data.interviewScores.technical.map((item, index) => (
                <div className={styles.interviewScores2}>
                  <div className={styles.interviewScoreSno}>{index + 1}.</div>
                  <div>
                    <div className={styles.interviewScoreLabel}>
                      {item.skill} - <span>{item.score}</span>
                    </div>
                    <div className={styles.interviewScoreValue}>
                      {item.reason}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.interviewScoreCategory}>
              <div className={styles.interviewScoreCategoryTitle}>
                Behavioral
              </div>
              {data.interviewScores.behavioral.map((item, index) => (
                <div className={styles.interviewScores2}>
                  <div className={styles.interviewScoreSno}>{index + 1}.</div>
                  <div>
                    {' '}
                    <div className={styles.interviewScoreLabel}>
                      {item.skill} - <span>{item.score}</span>
                    </div>
                    <div className={styles.interviewScoreValue}>
                      {item.reason}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.interviewScoreCategoryMain}>
              <div className={styles.interviewScoreCategoryTitle}>
                Overall - <span>{data.interviewScores.overall.score}</span>
              </div>
              <div className={styles.interviewScores2}>
                <div
                  className={styles.interviewScoreValue}
                  style={{
                    color: '#000',
                    marginTop: '-0.15rem'
                  }}>
                  {data.interviewScores.overall.reason}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}        
      {data.pronunciationAssessment?.pronunciationScore && (
        <div className={styles.container}>
          <div className={styles.title2}>Pronunciation Assessment</div>

          <div className={styles.interviewScores}>
            <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.accuracyScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Accuracy</div>
            </div>
            <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.fluencyScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Fluency</div>
            </div>
            <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.completenessScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Completeness</div>
            </div>
            <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.pronunciationScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Pronunciation</div>
            </div>
          </div>
        </div>
      )}{' '}
      {data.userImages?.length > 0 && (
        <div className={styles.container}>
          <div className={styles.title2}>User Images</div>
          <div className={styles.userImages}>
            {data.userImages?.slice(0, 10).map((item) => (
              <img src={item} alt="" className={styles.userImg} />
            ))}
          </div>
        </div>
      )}
      {data.userVideos?.length > 0 && (
        <div className={styles.container}>
          <div className={styles.title2}>User Videos</div>
          <div className={styles.userImages}>
            {data.userVideos?.slice(0, 10).map((item) => (
              <video src={item} alt="" controls className={styles.userImg} />
            ))}
          </div>
        </div>
      )}
      <AudioPlayer src={audioSrc} onPause={() => setAudioSrc(null)} />
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
      {data?.jobDetails && (
        <ReactModal
          onRequestClose={() => setShowJobDetails(false)}
          isOpen={showJobDetails}
          className={styles.jobModalContainer}
          overlayClassName="active_jobs__modal-overlay2">
          <div className={styles.jobModal}>
            <JobCard data={data?.jobDetails} hideButtons />
          </div>
        </ReactModal>
      )}
    </div>
  );
}
const MessageSent = ({
  text,
  date,
  audioLink,
  playAudio,
  playingAudioLink
}) => (
  <div className={styles.messageSent}>
    <div className={styles.messageSentText}>{text}</div>
    <div className={styles.messageSentExtra}>
      {audioLink && (
        <div
          className={styles.messageAudioButton}
          onClick={() => playAudio(audioLink)}>
          <AudioIcon playing={playingAudioLink === audioLink} />
        </div>
      )}
      <div className={styles.messageSentTime}>
        {moment(date).format('hh:mm A')}
      </div>
    </div>
  </div>
);

const MessageReceived = ({
  text,
  date,
  audioLink,
  playAudio,
  playingAudioLink
}) => (
  <div className={styles.messageReceived}>
    <div className={styles.messageReceivedText}>{text}</div>
    <div className={styles.messageReceivedExtra}>
      {audioLink && (
        <div
          className={styles.messageAudioButton}
          onClick={() => playAudio(audioLink)}>
          <AudioIcon playing={playingAudioLink === audioLink} />
        </div>
      )}
      <div className={styles.messageReceivedTime}>
        {moment(date).format('hh:mm A')}
      </div>
    </div>
  </div>
);
const AudioIcon = ({ playing }) => {
  if (playing)
    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.1657 2.14424C12.8728 2.50021 13 3.27314 13 3.7446V20.2561C13 20.7286 12.8717 21.4998 12.1656 21.8554C11.416 22.2331 10.7175 21.8081 10.3623 21.4891L4.95001 16.6248H3.00001C1.89544 16.6248 1.00001 15.7293 1.00001 14.6248L1 9.43717C1 8.3326 1.89543 7.43717 3 7.43717H4.94661L10.3623 2.51158C10.7163 2.19354 11.4151 1.76635 12.1657 2.14424ZM11 4.63507L6.00618 9.17696C5.82209 9.34439 5.58219 9.43717 5.33334 9.43717H3L3.00001 14.6248H5.33334C5.58015 14.6248 5.81823 14.716 6.00179 14.881L11 19.3731V4.63507Z"
            fill="#000000"></path>{' '}
          <path
            d="M16.0368 4.73124C16.1852 4.19927 16.7368 3.88837 17.2688 4.03681C20.6116 4.9696 23 8.22106 23 12C23 15.779 20.6116 19.0304 17.2688 19.9632C16.7368 20.1117 16.1852 19.8007 16.0368 19.2688C15.8884 18.7368 16.1993 18.1852 16.7312 18.0368C19.1391 17.3649 21 14.9567 21 12C21 9.04332 19.1391 6.63512 16.7312 5.96321C16.1993 5.81477 15.8884 5.2632 16.0368 4.73124Z"
            fill="#000000"></path>{' '}
          <path
            d="M16.2865 8.04192C15.7573 7.88372 15.2001 8.18443 15.0419 8.71357C14.8837 9.24271 15.1844 9.79992 15.7136 9.95812C16.3702 10.1544 17 10.9209 17 12C17 13.0791 16.3702 13.8456 15.7136 14.0419C15.1844 14.2001 14.8837 14.7573 15.0419 15.2865C15.2001 15.8156 15.7573 16.1163 16.2865 15.9581C17.9301 15.4667 19 13.8076 19 12C19 10.1924 17.9301 8.53333 16.2865 8.04192Z"
            fill="#000000"></path>{' '}
        </g>
      </svg>
    );
  else
    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 4.63513L6.00618 9.17702C5.82209 9.34445 5.58219 9.43723 5.33334 9.43723H3L3.00001 14.6248H5.33334C5.58015 14.6248 5.81823 14.7161 6.00179 14.8811L11 19.3732V4.63513ZM12.1657 2.1443C12.8728 2.50027 13 3.2732 13 3.74466V20.2561C13 20.7286 12.8717 21.4999 12.1656 21.8555C11.416 22.2331 10.7175 21.8082 10.3623 21.4891L4.95001 16.6248H3.00001C1.89544 16.6248 1.00001 15.7294 1.00001 14.6248L1 9.43723C0.999998 8.33266 1.89543 7.43723 3 7.43723H4.94661L10.3623 2.51165C10.7163 2.1936 11.4151 1.76641 12.1657 2.1443Z"
            fill="#000000"></path>{' '}
        </g>
      </svg>
    );
};
