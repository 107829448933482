import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sendHttpCall } from 'shareService/httpService';
import ReactModal from 'react-modal';
import './style.scss';
import { Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import JobCard from 'components/JobCard/JobCard';
import Loader from 'components/Loader'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));
const planNames = {
  HIRER_ADD_ON_1: 'Bronze',
  HIRER_ADD_ON_2: 'Silver',
  HIRER_ADD_ON_3: 'Gold',
  HIRER_PREMIUM: 'Enterprise',
  HIRER_ADD_ON_4: 'Basic',
  HIRER_ADD_ON_5: 'Premium',
  HIRER_ADD_ON_6: 'Enterprise',
  HIRER_ADD_ON_7: 'Starter',
  HIRER_ADD_ON_8: 'Basic',
  HIRER_ADD_ON_9: 'Premium',
  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_JOB_PLAN_1: 'Jobs Basic',

  HIRER_JOB_PLAN_2: 'Jobs Standard',

  HIRER_JOB_PLAN_3: 'Jobs Gold',

  HIRER_OFFER_PLAN_1: 'Offers Basic',

  HIRER_OFFER_PLAN_2: 'Offers Standard',

  HIRER_OFFER_PLAN_3: 'Offers Gold',

  HIRER_CV_PLAN_1: 'CV Basic',

  HIRER_CV_PLAN_2: 'CV Standard',

  HIRER_CV_PLAN_3: 'CV Gold'
};
const convertToYearly = (monthlyVal) => {
  switch (monthlyVal) {
    case '₹ 10k - 25k':
    case '₹10k - 25k':
      return '₹ 1.2L - 3L';
    case '₹ 25k - 50k':
    case '₹25k - 50k':
      return '₹ 3L - 6L';
    case '₹ 50k - 75k':
      return '₹ 6L - 9L';
    case '₹ 75k - 100k':
      return '₹ 9L - 12L';
    case '₹ 100k - 150k':
      return '₹ 12L - 18L';
    case '₹ 150k - 200k':
      return '₹ 18L - 24L';
    case '₹ 200k - 300k':
      return '₹ 24L - 36L';
    case '₹ 300k - 500k':
      return '₹ 36L - 60L';
    case '>  ₹ 500k':
      return '> ₹ 60L';
    default:
      return monthlyVal;
  }
};
const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};
const formatDate = (date, variation = 1) =>
  `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
    date.getMonth() + 1
  )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

const formatTime = (time) => {
  const date = new Date(time);

  return getTimeIn12Hours(date) + ', ' + formatDate(date);
};
export default function (props) {
  const prevQuery = useRef(null);
  const prevPage = useRef(null);
  const classes = useStyles();

  const [editModalData, setEditModalData] = useState(null);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(
    sessionStorage.getItem('viewClosedJobPage')
      ? parseInt(sessionStorage.getItem('viewClosedJobPage'), 10)
      : 1
  );
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [lastPage, setLastPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});

  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const getData = () => {

    let pageVal = page;

    // let pageVal = prevQuery.current !== query ? 1 : page;
    // prevQuery.current = query;
    // prevPage.current = pageVal;

    setLoading(true);
    let url = `/jobs/get_all_closed_jobs?limit=${10}&page=${pageVal}`;
    if (query) {
      url += '&query=' + query;
    }
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      setData(response.data);
      let count = response.totalCount;
      if (page !== pageVal) setPage(pageVal);
      setTotalCount(count);
      setLastPage(Math.ceil(count / 10));
      setLoading(false);
    });
  };

  // useEffect(() => {
  //   // sessionStorage.removeItem('viewClosedJobPage');
  //   if (prevPage.current !== page || prevQuery.current !== query) getData();
  // }, [page, query]);

  useEffect(() => {
    getData();
    sessionStorage.setItem('viewClosedJobPage', page);
    // sessionStorage.removeItem('viewClosedJobPage');
  }, [page]);

  // for query
  useEffect(() => {
    getData();
  }, [query]);

  const [goToPage, setGoToPage] = useState('');

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const handleGoToPage = () => {
    let pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= lastPage) {
      setPage(pageNumber); // Assuming `onChange` accepts the target page number as its parameter
      setGoToPage('');
    } else {
      alert('Please enter a valid page number');
    }
  };
  return (
    <div>
      <div className="active_jobs__container">
        <div className="active_jobs__header">Closed Job Posts</div>
        <div className="active_jobs__search">
          <input
            type="text"
            placeholder="Search by user name or job title"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setQuery(e.target.value)
                //set the page to 1 so that when the query is searched the user is navigated to the first page instead of remaining on the current page
                setPage(1)
              };
            }}
          />
          <Search />
        </div>
        <div className="active_jobs__posts-container">
          {loading && (
            <div className="active_jobs__posts-loading"><Loader/></div>
          )}
          {!loading &&
            data.map((item) => (
              <JobCard
                status="INACTIVE"
                data={item}
                approveJob={null}
                rejectJob={null}
                currentPage={page}
                deleteJob={null}
                refreshData={getData}
                showToast={showToast}
              />
            ))}
        </div>
        <div className="active_jobs__pagination">
          <div className="active_jobs__pagination-count">
            Total Count : {totalCount}
          </div>
          <Pagination
            size="large"
            color="#297bca"
            count={lastPage}
            page={page}
            onChange={handleChange}
          />
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              size="small"
              label="Go to page"
              variant="outlined"
              value={goToPage}
              onChange={handleGoToPageChange}
              inputProps={{ type: 'number', min: 1, max: lastPage }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGoToPage();
                }
              }}
            />
            <Button variant="contained" style={{ backgroundColor: '#297bca' }} onClick={handleGoToPage}>
              Go
            </Button>
          </Box>

        </div>
      </div>
      <ReactModal
        isOpen={candidates.length > 0}
        onRequestClose={() => {
          setCandidates([]);
        }}
        className="active_jobs__modal-modal2"
        overlayClassName="active_jobs__modal-overlay2">
        <div className="active_jobs__modal2">
          <div className="candidate_list_title">Candidates</div>
          <div className="candidate_list_items">
            {candidates.map((a, index) => (
              <div className="candidate_list_item">
                <div className="candidate_list_item_no">{index + 1}</div>
                <div>
                  <img
                    src={a.image_id}
                    className="candidate_list_item_img"
                    alt=""
                  />
                  <div className="candidate_list_item_name">
                    {a.name}{' '}
                    {a.is_good_fit ? (
                      <span className="candidate_list_item_name_r">R</span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div
                  className={'candidate_list_item_status'}
                  style={{ fontSize: '1rem', marginRight: '.5rem' }}>
                  {a.profile_viewed ? 'Profile Viewed' : ''}
                </div>
                <div
                  className={
                    'candidate_list_item_status' +
                    (a.request_status === 'ACCEPTED' ? ' status_blue' : '') +
                    (a.request_status === 'REJECTED' ? ' status_red' : '')
                  }>
                  {a.request_status === 'ACCEPTED'
                    ? 'SHORTLISTED'
                    : a.request_status}
                </div>
                <Link
                  className="candidate_list_item_viewProfile"
                  to={`/account/${a.user_id}`}>
                  View Profile
                </Link>
              </div>
            ))}
          </div>
        </div>
      </ReactModal>
      {toastMessage && <div className="active_jobs__toast">{toastMessage}</div>}
    </div>
  );
}
