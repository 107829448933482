import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { selectoptions } from '../../config';
import './index.css';
import { TotalUsers } from './components';
import moment from 'moment';
import 'react-dropdown/style.css';
import { sendHttpCall, Toast } from 'shareService/httpService';
import DatePickerDialog from 'components/DatePickerDialog/DatePickerDialog';
import { filter } from 'lodash';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { marginTop: 15 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right'
  },
  space: {
    margin: 2
  }
}));

const DashboardOld = (props) => {
  const classes = useStyles();
  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem('period') ? localStorage.getItem('period') : 'Today'
  );
  let end = new Date();
  let start = new Date();
  switch (selectedPeriod) {
    case 'All time':
      start = new Date(2019, 0, 1);
      localStorage.removeItem('period');
      break;
    case 'Last Year':
      start.setDate(start.getDate() - 365);
      localStorage.removeItem('period');
      break;
    case 'Last 30 days':
      start.setDate(start.getDate() - 30);
      localStorage.removeItem('period');
      break;
    case 'Last 7 days':
      start.setDate(start.getDate() - 7);
      localStorage.removeItem('period');
      break;
    case 'Yesterday':
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      localStorage.removeItem('period');
      break;
    default:
      localStorage.removeItem('period');
  }

  // const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(true);
  const [compData, setCompData] = useState({});
  const [surveyCount, setSurveyCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [linkClickCount, setLinkClickCount] = useState(0);
  const [surveyDownloadCount, setSurveyDownloadCount] = useState(0);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );

  var reqBody = {
    start_date: moment(new Date(start)).format('YYYY-MM-DD'),
    end_date: moment(new Date(end)).format('YYYY-MM-DD'),
    city: selectedCity,
    gender: selectedGender
  };

 
  const handleDateChange = (newDateRange) => {
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    // Perform your search or fetch action here using the newDateRange
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    reqBody.start_date = moment(new Date(newDateRange.startDate)).format(
      'YYYY-MM-DD'
    );
    reqBody.end_date = moment(new Date(newDateRange.endDate)).format('YYYY-MM-DD');
    fetchAllData();
    console.log('Selected Date Range:', newDateRange.startDate);
  };

  //_______________________________________________
  const fetchCompleteData = async () => {
    setLoading(true);
    let yesterday = false;
    let date = new Date();

    date = date.setDate(date.getDate() - 1);
    date = moment(date).format('YYYY-MM-DD');
    let additionalQuery =
      '?start_date=' + reqBody.start_date + '&end_date=' + reqBody.end_date;
    let response = await sendHttpCall(
      'GET',
      'dashboard' + additionalQuery,
      null,
      props
    );

    if (response) {
      setCompData(response.data);
      setLoading(false);
    }
  };
  //_______________________________________________
  async function fetchAllData() {
    fetchCompleteData();
  }

  const handleNewDashboardClick = () => {
    props.history.push('/dashboard'); 
  };

  useEffect(() => {
    fetchAllData();
    setSelectedStartDate(reqBody.start_date);
    setSelectedEndDate(reqBody.end_date);
  }, []);

  return (
    <>
      <div className={classes.root}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
  <Grid item xs={8}>
    <DateRangePicker onDateChange={handleDateChange} />
  </Grid>

  <Grid item>
    <Button
      variant="contained"
      color="secondary"
      className={classes.legacyButton}
      onClick={handleNewDashboardClick}
    >
      View New Dashboard
    </Button>
  </Grid>
</Grid>
        <div className="dashboard-label">New User Sign Ups</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status3}
              loading={loading}
              Text="ACTIVE MEMBERS"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/active_member/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>{' '}
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status4}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status4/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Status 4 Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.activeSocialProfile}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                localStorage.setItem(
                  'selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                props.history.push(`/search-social-user`);
              }}
              Text="SOCIAL USERS"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status1}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status1/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Status 1 Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.blockedUsers}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/blocked/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Blocked Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.deactivatedUsers}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/deactivated/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Deactivated Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.resumeDownloaded}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/resume-downloaded/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Resume Downloaded"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.appFeedback}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/app-feedbacks/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="App Feedbacks"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.audioUploaded}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/resume-audio-uploaded/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Audio Uploaded"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.demoInterviews}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                sessionStorage.setItem('demointerview-dashboard', 'true');
                props.history.push(
                  `/auto-interview/demo/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
              Text="AutoRecruiter Demo"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.contactRequestCount}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                sessionStorage.setItem('demointerview-dashboard', 'true');
                props.history.push(
                  `/data/contactRequestForDemo/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Contact Request for demo"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Status 2 Users</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status2}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="All Status 2 Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.hirerStatus2}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2Hirer/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Hirer Status 2"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.memberStatus2}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2Member/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Job Seeker Status 2"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.onboardingStarted}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                localStorage.setItem(
                  'selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                props.history.push(`/incomplete-social-user`);
              }}
              Text="Social STATUS 2"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Status 2 Hirer</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.hirerStatus2}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2Hirer/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="TOTAL HIRER"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.verifiedHirerCount}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2HirerVerified/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="VERIFIED HIRER"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.rejectedHirerCount}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2HirerRejected/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="REJECTED HIRER"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.unVerifiedHirerCount}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                localStorage.setItem(
                  'selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                props.history.push(
                  `/data/status2HirerUnVerified/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="UNVERIFIED HIRER"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Social Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.connectionsSent}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/social_connect_requests/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Connection Requests"
            />{' '}
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.connectAccepted}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/social_connect_requests_accepted/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Connects Accepted"
            />{' '}
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.connectRejected}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/social_connect_requests_rejected/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Connects Rejected"
            />
          </Grid>
        </Grid>
        
        <div className="dashboard-label">Status 3 Hirers Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.totalHirer}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/active_hirers/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="TOTAL HIRERS"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.verifiedHirer3Count}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status3hirersVerified/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="VERIFIED HIRERS"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.unVerifiedHirer3Count}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status3hirersUnverified/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="UN VERIFIED HIRERS"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.rejectedHirer3Count}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status3hirersRejected/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="REJECTED HIRERS"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.JobPostshirers3Count}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status3hirersJobPosts/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="HIRERS WHO POSTED JOBS"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.hirerResumeDownload}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/hirer-resume-downloaded/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="HIRER WHO DOWNLOADED RESUMES"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.unassignedHirersStatus3}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/hirer-unassigned-sales-stage/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="UNASSIGNED HIRERS"
            />
          </Grid>
        </Grid>

        <div className="dashboard-label">Feedback Data</div>

        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>

            <TotalUsers
              totalUser={compData.totalFeedbacks}
              loading={loading}
              Text="TOTAL FEEDBACK"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/feedback/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'default' }}>

            <TotalUsers
              totalUser={compData.tempMembers}
              loading={loading}
              Text="TEMP MEMBERS"
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>

            <TotalUsers
              totalUser={compData.feedbackRequests}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/feedback-requests/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
              Text="TOTAL FEEDBACK REQUESTS"
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.feedbackConversions}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `data/feedback_conversions/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="TOTAL FEEDBACK CONVERSIONS"
            />
          </Grid>

        </Grid>


        {/* <div className="dashboard-label">Survey Messages Data</div> */}

        {/* <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>

            <TotalUsers
              totalUser={`${compData.successCount || 0}/${
                compData.failCount || 0
              }`}
              loading={loading}
              Text="Survey SMS: sent/failed"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/survey_sent/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.totalLinkClicks}
              loading={loading}
              percentage={
                (
                  (linkClickCount / (smsCount == 0 ? 1 : smsCount)) *
                  100
                ).toFixed(3) + '%'
              }
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/survey_link_clicks/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Link Clicks"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>

            <TotalUsers
              totalUser={compData.surveyReceived}
              loading={loading}
              percentage={
                ((surveyCount / (smsCount == 0 ? 1 : smsCount)) * 100).toFixed(
                  3
                ) + '%'
              }
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/survey_received/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Survey Received"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>

            <TotalUsers
              totalUser={compData.surveyConversion}
              loading={loading}
              percentage={
                (
                  (surveyDownloadCount / (smsCount == 0 ? 1 : smsCount)) *
                  100
                ).toFixed(3) + '%'
              }
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/survey_conversions/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              Text="Survey Download Count"
            />
          </Grid>
        </Grid> */}


        <div className="dashboard-label">Hire Now Data</div>

        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'default' }}>

            <TotalUsers
              totalUser={compData.totalSearches}
              loading={loading}
              Text="Number Of Search"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>

            <TotalUsers
              totalUser={compData.hireRequest}
              loading={loading}
              Text="Hire Now Requests"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/hire-request/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>

            <TotalUsers
              totalUser={compData.totalHires}
              loading={loading}
              Text="Hire Now Clicks"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/hire/click/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.hireNowAccepted}
              loading={loading}
              Text="Hire Now Accepted"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/hire/accepted/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.hireNowRejected}
              loading={loading}
              Text="Hire Now Rejected"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/hire/rejected/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.hireNowPending}
              loading={loading}
              Text="Hire Now Pending"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/hire/requested/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.connectClickCount}
              loading={loading}
              Text="Connect Click Count"
            // onClick={() => {
            // }}
            />
          </Grid>
        </Grid>

        <div className="dashboard-label">Job Posts Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.totalPosted}
              loading={loading}
              Text="TOTAL JOB POSTS"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/jobs/click/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.approvedJobs}
              loading={loading}
              Text="VERIFIED JOB POSTS"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/verifiedJobs/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.underReview}
              loading={loading}
              Text="UNDER REVIEW JOB POSTS"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/jobs-under-review/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.rejectedJobs}
              loading={loading}
              Text="REJECTED JOB POSTS"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/rejectedJobs/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.closedJobs}
              loading={loading}
              Text="CLOSED JOB POSTS"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/closedJobs/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.jobsOnHold}
              loading={loading}
              Text="NUMBER OF JOBS HOLD"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/jobs-on-hold/`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.reportedJobs}
              loading={loading}
              Text="Number of Job Post Reports"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/reportedJobs/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Job Applications Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.totalApplications}
              loading={loading}
              Text="Number of Jobs applications"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/job-application/click/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.redirectJobClicks}
              loading={loading}
              Text="Number of Redirect Job Apply Clicks"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/redirect_job_apply_clicks/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.applicationsAccepted}
              loading={loading}
              Text="Number of application accepted"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/job-application/accepted/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.applicationsRejected}
              loading={loading}
              Text="Number of applications rejected"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/job-application/rejected/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.pendingApplications}
              loading={loading}
              Text="Applications Pending"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/job-application/applied/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.profileViewed}
              loading={loading}
              Text="Number of Profile Viewed"
            // onClick={() => {
            //   localStorage.setItem('period', selectedPeriod);
            //   props.history.push(
            //     `/job-application/rejected/${moment(selectedStartDate).format(
            //       'YYYY-MM-DD'
            //     )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
            //   );
            // }}
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Auto Interview Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              // totalUser={compData.recommendedApplications}
              totalUser={compData.interviewsOffered}
              loading={loading}
              Text="Interviews Offered"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/recommended-candidates/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.stuckOnInstructionsInterviews}
              loading={loading}
              Text="Interviews Stuck on Instructions Page"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/stuck-interviews/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.startedInterviews}
              loading={loading}
              Text="Interviews Started"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/started-interviews/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.finishedInterviews}
              loading={loading}
              Text="Number of Finished Interviews"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/finished-interviews/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.RohanInteractionscount}
              loading={loading}
              Text="Rohan Bot Interactions"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/rohan-chatbot-logs/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.SakshiInteractionscount}
              loading={loading}
              Text="Sakshi Bot Interactions"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/sakshi-chatbot-logs/${moment(selectedStartDate).format(
                    'YYYY-MM-DD'
                  )}/${moment(selectedEndDate).format('YYYY-MM-DD')}`
                );
              }}
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Blood Donor Requests</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.bloodDonorRequests}
              loading={loading}
              Text="Blood Donor Requests"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/blood-donor-requests/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.bloodDonorRequestSentToAdmin}
              loading={loading}
              Text="Blood Donor Admin Requests"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/blood-donor-requests-admin/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.bloodDonorRequestAccepted}
              loading={loading}
              Text="Blood Donor Requests Accepted"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/blood-donor-requests-accepted/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.bloodDonorRequestRejected}
              loading={loading}
              Text="Blood Donor Requests Rejected"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/blood-donor-requests-rejected/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Premium Members Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              Text="Total Transactions"
              totalUser={compData.totalTransactions}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/transactions/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              Text="Failed Transactions"
              totalUser={compData.failedTransactions}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/failed-transactions/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              Text="Created Orders"
              totalUser={compData.createdOrders}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/created-orders/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={'Rs. ' + compData.totalRevenue}
              loading={loading}
              Text="Total Revenue"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/transactions/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'default' }}>
            <TotalUsers
              totalUser={compData.totalJobSeekerPremiumUsers}
              loading={loading}
              Text="Total Job Seeker Premium Users"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DashboardOld;
