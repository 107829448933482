import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { Textsms } from '@material-ui/icons';
//import {Row,Col,Container} from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  shimmer: {
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#dddddd40',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0) 100%
      )`,
      transform: 'translateX(-100%)',
      animation: '$shimmer 1s infinite'
    }
  },
  '@keyframes shimmer': {
    '0%': {
      transform: 'translateX(-100%)'
    },
    '100%': {
      transform: 'translateX(100%)'
    }
  },
  skeletonText: {
    borderRadius: '6px',
    margin: theme.spacing(1, 0),
    height: '20px'
  },
  skeletonTitle: {
    width: '50%',
    height: '22px',
    borderRadius: '6px'
  },
  title: {
    fontWeight: 900
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const TotalUsers = (props) => {
  const { className, loading, cardStyle,titleStyle, textStyle, ...rest } = props;
  const classes = useStyles();

  // Default styles
  const defaultCardStyle = cardStyle || {};
  const defaultTextStyle = textStyle || { };
  const defaultTitleStyle = titleStyle || { color: '#297bca' };

  return (
    <Card {...rest} className={clsx(classes.root, className)} style={defaultCardStyle}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            {loading ? (
              <div style={{ width: '200px' }}>
                <div className={`${classes.shimmer} ${classes.skeletonTitle}`} />
                <div className={`${classes.shimmer} ${classes.skeletonText}`} />
              </div>
            ) : (
              <>
                <Typography
                  className={classes.title}
                  style={{ ...defaultTitleStyle }}
                  gutterBottom
                  variant="body2">
                  {props.Text}
                </Typography>
                <Typography variant="h3" style={{ ...defaultTextStyle }}>
                  {props.totalUser}
                  <br />
                  <span style={{ color: 'grey', fontSize: '13px' }}>
                    {props.percentage}
                  </span>
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalUsers.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  cardStyle: PropTypes.object,
  textStyle: PropTypes.object,
  Text: PropTypes.string,
  totalUser: PropTypes.number,
  percentage: PropTypes.string,
  // ... any other prop types you're using
};

export default TotalUsers;
