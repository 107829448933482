import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sendHttpCall } from 'shareService/httpService';
// import { UsersToolbar } from '../components';
import MaterialTable from 'material-table';
import './style.css';
import ReactSelect from 'react-select';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UsersByCampaigns = (props) => {
  //   const [users, setUsers] = useState([]);
  //   const [filterByName, setFilterByName] = useState('');
  //   const [sortingData, setSortingData] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: ''
  });
  const [selectedOption2, setSelectedOption2] = useState({
    value: '',
    label: ''
  });
  //   const [totalCount, setTotalCount] = useState(0);
  //   const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const classes = useStyles();

  const getCampaignTypes = () => {
    setLoading1(true);
    let url = '/marketing_campaign/get_types';
    sendHttpCall('GET', url, null, props).then((response) => {
      if (response.state === 200) {
        console.log(response.data);
        let options_ = response.data.map((a) => ({
          value: a.marketing_campaign_type,
          label: a.marketing_campaign_type
        }));
        setOptions(options_);
        setSelectedOption(options_[0]);
        setLoading1(false);
      }
    });
  };
  const handleTypeChange = (option) => {
    setSelectedOption(option);
  };
  const handleTypeChange2 = (option) => {
    setSelectedOption2(option);
  };
  const getDataSpecific = (type) => {
    if (!type) return;
    setLoading(true);
    let url = 'marketing_campaign/get_specific';
    sendHttpCall('POST', url, { type }, props).then((response) => {
      let options_ = response.data.map((a) => ({
        label: a.marketing_campaign_name.substring(type.length + 1),
        value: a.marketing_campaign_id
      }));
      setOptions2(options_);
      if (options_.length) setSelectedOption2(options_[0]);

      //   setTotalCount(response.totalCount);
      setLoading(false);
    });
  };
  useEffect(() => {
    getDataSpecific(selectedOption.value);
  }, [selectedOption]);
  useEffect(() => {
    if (selectedOption2.value) getData();
  }, [selectedOption2.value, page, limit]);

  useEffect(() => {
    getCampaignTypes();
  }, []);
  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };
  const getData = () => {
    console.log(page);
    let url = '/marketing_campaign/getUsersByCampaignId';
    setLoading2(true);
    sendHttpCall(
      'POST',
      url,
      { marketing_campaign_id: selectedOption2.value, limit, page: page },
      props
    ).then((response) => {
      setTotalCount(response.data.count);
      setData(response.data.data);
      setLoading2(false);
    });
  };

  return (
    <div className={classes.root}>
      <div className="marketingCampaign__title">
        Marketing Campaigns Manager
      </div>
      <div className="marketingCampaign__row">
        <div className="campaignText">Choose Campaign Type</div>
        <div className="marketingCampaign__select">
          <ReactSelect
            options={options}
            isLoading={loading1}
            value={selectedOption}
            onChange={handleTypeChange}
          />
        </div>
        <div className="campaignText">Choose Campaign</div>
        <div className="marketingCampaign__select">
          <ReactSelect
            options={options2}
            isLoading={loading1}
            value={selectedOption2}
            onChange={handleTypeChange2}
          />
        </div>
      </div>
      <div className={classes.content}>
        <MaterialTable
          actions={[
            {
              icon: 'send',
              tooltip: 'Open User',
              onClick: (event, rowData) => {
                props.history.push(`/account/${rowData.user_id}`);
              }
            }
          ]}
          columns={[
            { title: 'id', field: 'user_id' },
            { title: 'Name', field: 'name' },
            { title: 'Phone', field: 'mobile_number' },
            { title: 'Email', field: 'email_id' },
            { title: 'Status', field: 'status' },
            {
              title: 'Registration Date',
              field: 'created_at',
              render: (rowData) => getLocaleTime(rowData.created_at)
            }
          ]}
          data={data}
          onChangeRowsPerPage={setLimit}
          page={page}
          isLoading={loading2}
          totalCount={totalCount}
          onChangePage={(e) => setPage(e)}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: limit,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          style={{ zIndex: 0 }}
          title={'Users from ' + selectedOption2.label + ' campaign'}
        />
      </div>
    </div>
  );
};

export default UsersByCampaigns;
