import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import Axios from 'axios';
import Loader from 'components/Loader';
import { esocialUrl } from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(3),
    maxWidth: '100%'
  },
  formContainer: {
    flex: 1,
    flexDirection: 'column',
    maxWidth: 500,
    visibility: 'visible'
  },
  detailsContainer: {
    flex: 1,
    padding: '20px',
    visibility: 'visible'
  },
  header: {
    paddingBottom: theme.spacing(2)
  },
  subTitle: {
    paddingBottom: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 120
  },
  switchBase: {
    color: theme.palette.secondary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main
    }
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  },
  inputElem: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ManualPaymentGateway = () => {
  const classes = useStyles();
  const [userId, setUserId] = useState('');
  const [amount, setAmount] = useState('');
  const [planName, setPlanName] = useState('RECRUITER_GCOIN_PURCHASE');
  const [isRecruiterGcoin, setIsRecruiterGcoin] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [paymentSuccessData, setPaymentSuccessData] = useState(null);
  const [lastPaymentTime, setLastPaymentTime] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [codeValidated, setCodeValidated] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLastPaymentTime(null);
    }, 10000); // Reset last payment time after 10 seconds
    return () => clearTimeout(timer);
  }, [lastPaymentTime]);

  const handleToggleChange = () => {
    setIsRecruiterGcoin(!isRecruiterGcoin);
    setPlanName(
      isRecruiterGcoin ? 'RECRUIT_PREMIUM' : 'RECRUITER_GCOIN_PURCHASE'
    );
  };

  const handleConfirmClose = (agree) => {
    setConfirmOpen(false);
    setAccessCode(''); 
    setCodeValidated(true); 
    if (agree && codeValidated) {
      submitPayment();
    }
  };
  

  const handleSubmit = () => {
    if (lastPaymentTime) {
      setMessage(
        'Are you sure you want to make another payment? You made one just a few seconds ago.'
      );
    } else {
      setConfirmOpen(true);
    }
  };

  const submitPayment = async () => {
    setLoading(true);
    const paymentData = {
      userId: Number(userId),
      planName: isRecruiterGcoin ? undefined : planName,
      amount: isRecruiterGcoin ? Number(amount) : undefined
    };
  
    try {
      const response = await Axios.post(
        `${esocialUrl}/admin/recruiter/create_payment_with_invoice`,
        paymentData
      );
      setMessage('Payment successfully processed');
      setPaymentSuccessData(response.data.data);
      setLastPaymentTime(Date.now());
      // Reset fields after successful payment
      setUserId('');
      setAmount('');
      setPlanName('RECRUITER_GCOIN_PURCHASE');
      setIsRecruiterGcoin(true);
    } catch (error) {
      setMessage('Payment processing failed');
      console.error('Error:', error);
    }
    setLoading(false);
  };
  

  const validateAccessCode = () => {
    const validCode = '4938';
    if (accessCode === validCode) {
      setCodeValidated(true);
      submitPayment();
      setAccessCode(''); // Reset access code after validation
      setConfirmOpen(false); // Close the dialog after successful validation
    } else {
      setCodeValidated(false);
      setMessage('Invalid access code.');
    }
  };
  

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      <div className={classes.formContainer}>
        <Typography variant="h2" className={classes.header}>
          Manual Payment Gateway
        </Typography>
        <Typography variant="h4" className={classes.subTitle}>
          Process payments manually and allocate Gcoins with Invoice.
        </Typography>
        {loading ? null : (
          <div className={classes.inputElem}>
            <TextField
              label="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              margin="normal"
              InputLabelProps={{ style: { fontSize: '1.2rem' } }}
              type="number"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isRecruiterGcoin}
                  onChange={handleToggleChange}
                  name="planSwitch"
                  classes={{ switchBase: classes.switchBase }}
                />
              }
              label={isRecruiterGcoin ? 'Enter Amount' : 'Choose Plan'}
            />
            {isRecruiterGcoin ? (
              <TextField
                label="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                margin="normal"
                type="number"
                min="100"
              />
            ) : (
              <FormControl className={classes.formControl}>
                <Select
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                  displayEmpty>
                  <MenuItem value="RECRUIT_PREMIUM">Recruit Premium</MenuItem>
                  <MenuItem value="RECRUIT_PLUS">Recruit Plus</MenuItem>
                </Select>
              </FormControl>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginTop: 20 }}
              disabled={
                userId === '' ||
                (isRecruiterGcoin ? amount < 100 : planName === '')
              }>
              Submit Payment
            </Button>
          </div>
        )}
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={6000}
          onClose={() => setMessage('')}
        />
      </div>
      {paymentSuccessData && (
        <div className={classes.detailsContainer}>
          <Typography variant="h6">Payment Details:</Typography>
          <Typography>
            Gcoins Added: {paymentSuccessData.gcoinsValue}
          </Typography>
          <Typography>
            Amount: {paymentSuccessData.amount} {paymentSuccessData.currency}
          </Typography>
          <Button
            variant="outlined"
            href={paymentSuccessData.invoiceLink}
            target="_blank"
            style={{ marginTop: 20 }}>
            Download Invoice
          </Button>
        </div>
      )}
      <Dialog
        open={confirmOpen}
        onClose={() => handleConfirmClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Confirm Payment Submission'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to{' '}
            {isRecruiterGcoin ? (
              <span>
                add <strong>{amount}</strong> to
              </span>
            ) : (
              <span>
                apply <strong>{planName}</strong> plan to
              </span>
            )}{' '}
            this user ID: <strong>{userId}</strong>'s account?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="accessCode"
            label="Admin Access Code"
            type="number"
            fullWidth
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            error={!codeValidated}
            helperText={!codeValidated ? 'Invalid access code.' : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmClose(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => validateAccessCode()}
            color="primary"
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManualPaymentGateway;
