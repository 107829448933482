import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    height: 500,
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const BackgroundImageOnlyCard = props => {
  const { card } = props;
  const classes = useStyles();
  return (
    <Grid item key={card} xs={12} sm={6} md={4}>
      <Card className={classes.card} variant="outlined">
        <CardMedia
          className={classes.cardMedia}
          image={card.background_image}
          title={card.description}
          alt={card.description}
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="h6">
            Code
          </Typography>
          <Typography variant="body1" gutterBottom>
            {card.code}
          </Typography>
          <Typography variant="h6">
            Description
          </Typography>
          <Typography gutterBottom variant="body1">
            {card.description}
          </Typography>
          <Typography variant="h6">
            Weight
          </Typography>
          <Typography variant="body1" gutterBottom>
            {card.weight}
          </Typography>
          <Typography variant="h6">
            Template
          </Typography>
          <Typography variant="body1" gutterBottom>
            {card.template}
          </Typography>
        </CardContent>
        <Button size="small" color="primary">
          Edit
        </Button>
      </Card>
    </Grid>
  );
};

BackgroundImageOnlyCard.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  card: PropTypes.object
};

export default BackgroundImageOnlyCard;
