import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Button, Typography, Paper } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import moment from 'moment';
import BusinessIcon from '@material-ui/icons/Business';
import { sendHttpCall } from 'shareService/httpService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  skill: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(4)
  },
  text: {
    fontWeight: 'bold',
    float: 'right',
    margin: 10,
    color: '#3f51b5',
    fontSize: 17,
    cursor: 'pointer'
  }
}));

const UserWorkDetails = (props) => {
  const { className, dataFromResume, ...rest } = props;
  const classes = useStyles();
  const [educationDetails, setEducationDetails] = useState([]);
  const user = props.user;
  console.log({ user });
  const [userWorkDetails, setWorkDetails] = useState([]);
  const [userSkills, setUserSkills] = useState([]);

  async function fetchUserWorkDetails() {
    sendHttpCall('GET', `users/work_details/${props.userid}`, null, props).then(
      (response) => {
        setWorkDetails(response);
      }
    );
  }

  async function fetchUserSkills() {
    sendHttpCall('GET', `users/skill/${props.userid}`, null, props).then(
      (response) => {
        setUserSkills(response);
      }
    );
  }

  async function fetchUserEducationDetails() {
    sendHttpCall(
      'GET',
      `users/educational_details/${props.userid}`,
      null,
      props
    ).then((response) => {
      setEducationDetails(response);
    });
  }
  useEffect(() => {
    fetchUserSkills();
    fetchUserWorkDetails();
    fetchUserEducationDetails();
  }, []);
  const selectedSkillIds =
    dataFromResume?.suggestedSkills?.map((item) => item.id) || [];
  const selectedDesignationsIds =
    dataFromResume?.suggestedDesignations?.map((item) => item.id) || [];
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.skill}>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Typography gutterBottom variant="h4">
                Skills
              </Typography>
              <Divider gutterBottom />
            </Grid>
            <Grid xs={12} item>
              {userSkills.map((item, key) => (
                <Button
                  variant="outlined"
                  color={
                    selectedSkillIds.indexOf(item.skill_id) > -1
                      ? 'secondary'
                      : 'primary'
                  }
                  style={{
                    margin: 10,
                    color:
                      selectedSkillIds.indexOf(item.skill_id) > -1
                        ? 'green'
                        : null,
                    borderColor:
                      selectedSkillIds.indexOf(item.skill_id) > -1
                        ? 'green'
                        : null
                  }}>
                  {' '}
                  {item.skill}
                </Button>
              ))}
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: '2rem' }}>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Typography gutterBottom variant="h4">
                Job Titles
              </Typography>
              <Divider gutterBottom />
            </Grid>
            <Grid xs={12} item>
              {user?.job_title?.map((item, key) => (
                <Button
                  variant="outlined"
                  color={
                    selectedDesignationsIds.indexOf(item.designation_id) > -1
                      ? 'secondary'
                      : 'primary'
                  }
                  style={{
                    margin: 10,
                    color:
                      selectedDesignationsIds.indexOf(item.designation_id) > -1
                        ? 'green'
                        : null,
                    borderColor:
                      selectedDesignationsIds.indexOf(item.designation_id) > -1
                        ? 'green'
                        : null
                  }}>
                  {' '}
                  {item.designation}
                </Button>
              ))}
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: '2rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Work
              </Typography>
              <Divider gutterBottom />
            </Grid>
            {userWorkDetails.map((item, key) => (
              <Grid item xs={6} container>
                <Grid item xs={2}>
                  <BusinessIcon color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" color="textSecondary">
                    Organisation : {item.organisation}
                  </Typography>
                </Grid>
                {item.industry_type && (
                  <>
                    <Grid item xs={2}>
                      <SchoolIcon color="primary" />
                    </Grid>

                    <Grid item xs={10}>
                      <Typography variant="h6" color="textSecondary">
                        Industry : {item.industry_type}
                      </Typography>
                    </Grid>
                  </>
                )}

                <Grid item xs={2}>
                  <ImportContactsIcon color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" color="textSecondary">
                    Designation : {item.designation}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <DateRangeIcon color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" color="textSecondary">
                    {item.duration ||
                      moment(item.start_date).format('DD-MMM-YYYY') +
                        ' - ' +
                        (item.end_date == null
                          ? 'Currently Working'
                          : moment(item.end_date).format('DD-MMM-YYYY'))}
                  </Typography>
                </Grid>
                {item.description && (
                  <>
                    <Grid item xs={2}>
                      <ImportContactsIcon color="primary" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" color="textSecondary">
                        Description : {item.description}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: '2rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                Education
              </Typography>
              <Divider gutterBottom />
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {educationDetails.map((item, _) => (
                <Grid item xs={6} container>
                  {/* <Grid
                    spacing={0}
                    container
                  > */}
                  <Grid item xs={2}>
                    <AccountBalanceIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6" color="textSecondary">
                      Organisation : {item.organisation}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <SchoolIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6" color="textSecondary">
                      Degree : {item.degree}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <DateRangeIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6" color="textSecondary">
                      From :{item.start_date ? item.start_date : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <DateRangeIcon color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6" color="textSecondary">
                      To: {item.end_date}
                    </Typography>
                  </Grid>
                  <Divider gutterBottom />
                  {/* </Grid> */}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

UserWorkDetails.propTypes = {
  className: PropTypes.string
};

export default UserWorkDetails;

//WorkDetails
