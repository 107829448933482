import React, { useEffect ,useState} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from "material-table";
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
}));



const UserRatingAnalytics = props => {
  const [title, setTitle] = useState("");

  const { className, ...rest } = props;
  const classes = useStyles();

  const path = `/account/${props.match.params.user_id}`;
  const onNavigate = (history, newPath=`/account/${props.match.params.user_id}`) =>{
    history.replace(newPath);
  }



  
  useEffect(() => {
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <MaterialTable
          // actions={[
          //   {
          //     icon: 'send',
          //     tooltip: 'Open User',
          //     onClick: (event, rowData) => {
          //       props.history.push(`/account/${rowData.user_id}`);
          //     }
          //   }
          // ]}
          columns={[
            { title: "Sl.No", field: "slno" },
            { title: "Contact Name", field: "contact_name" },
            { title: "Mobile Number", field: "mobile_number" },
            { title: "Relationship Name", field: "relationship" },
            { title: "Is Link Clicked", field: "is_viewed" },
            { title: "Score", field: "score" }
          ]}
          data={query => {
            
            setTitle(props.match.params.name)
            return new Promise((resolve, reject) => {
              let url = 'dashboard/user_action';
              sendHttpCall('POST', url, { user_id:props.match.params.user_id,limit: query.pageSize, offset: query.page,start_date: props.match.params.start_date, end_date: props.match.params.end_date }, props)
                .then(response => {
                  for (let i = 0; i < response.data.length; i++) {
                    response.data[i].slno = i + 1;
                  };
                  
                  resolve({
                    data: response.data,
                    page: response.page,
                    totalCount: response.count,
                  })
                })
                .catch((e)=>{
                })
            })
          }
          }
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50]
          }}
          title={<Link to={path} onNavigate={onNavigate}>{props.match.params.name}</Link>}        />
      </CardContent>
    </Card>
  );
};

UserRatingAnalytics.propTypes = {
  className: PropTypes.string,
};

export default withRouter(UserRatingAnalytics);