import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  MenuItem,
  Select
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = (props) => {
  const { className, users, ...rest } = props;

  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [user, setUser] = useState({});

  const handleSelectAll = (event) => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map((user) => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = (event) => {};

  const handleChange = (event, u) => {
    u.status = event.target.value;
    setUser(u);
    fetch(
      'http://localhost:3800/api/users/status_update/' + u.user_id,
      {
        method: 'POST',
        body: JSON.stringify({
          status: u.status
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      (res) => {
        res.json().then((data) => {});
      }
    ).catch((err) => {});
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table aria-label="caption table">
              {/* <caption>Number of users found: {users.length}</caption> */}
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedUsers.length === users.length}
                      color="primary"
                      indeterminate={
                        selectedUsers.length > 0 &&
                        selectedUsers.length < users.length
                      }
                      onChange={handleSelectAll}
                    />
                  
                  </TableCell> */}
                  <TableCell>User ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>OTP</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Registration date</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                  {/* <TableCell>Action</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.user_id}
                      selected={selectedUsers.indexOf(user.user_id) !== -1}>
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUsers.indexOf(user.user_id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, user.user_id)}
                          value="true"
                        />
                      </TableCell> */}
                      <TableCell>{user.user_id}</TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            src={user.image_id}>
                            {/* {getInitials(user.name)} */}
                          </Avatar>
                          <Typography variant="body1">{user.name}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{user.otp}</TableCell>
                      <TableCell>
                        {user.location_city},{user.location_area}
                      </TableCell>
                      <TableCell>{user.mobile_number}</TableCell>
                      <TableCell>
                        {moment(user.created_at).format('DD/MM/YYYY')}
                      </TableCell>
                      {/* <TableCell>
                        <div>
                          <Select
                            disableUnderline={true}
                            value={user.status}
                            onChange={(event) => {
                              handleChange(event, user)
                            }}>
                            <MenuItem onClick={handleClose} value="VERIFIED">VERIFIED</MenuItem>
                            <MenuItem onClick={handleClose} value="ACTIVE">ACTIVE</MenuItem>
                            <MenuItem onClick={handleClose} value="CONTACT">CONTACT</MenuItem>
                            <MenuItem onClick={handleClose} value="NEW">NEW</MenuItem>
                          </Select>

                        </div>
                      </TableCell> */}

                      <TableCell>
                        <Link
                          to={{
                            pathname: `/account/${user.user_id}`
                            // params: user.user_id
                          }}>
                          <SendIcon></SendIcon>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 30]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
