import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { sendHttpCall,Toast } from 'shareService/httpService';
import { Grid, Button, Typography, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { selectYesterday } from '../../../config';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const NewJoinRequestList = (props) => {
  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  // var reqBody = {
  //   start_date: moment(start.format('YYYY-MM-DD'),
  //   end_date: moment((end.getDate()-1) .format('YYYY-MM-DD')
  // }
  //  startdate.setDate(startdate.getDate() - 1);
  // enddate.setDate(enddate.getDate() - 1);
  // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate() - 1))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate() - 1))).format(
      'YYYY-MM-DD'
    )
  };

  const tableRef = React.createRef();
  // const { className, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = React.useState( moment(new Date(start.setDate(start.getDate()))).format('YYYY-MM-DD'));
  const [selectedEndDate, setSelectedEndDate] = React.useState( moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD'));
  const [okdate, setOkdate] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize,setPageSize]= useState(30);
  const [seletedPeriod, setSeletedPeriod] = useState('');
  const [isCustomDate, setIsCustomDate] = useState(false);
  const classes = useStyles();

  async function approveRequest(user) {
    setLoading(true);
    let reqBody = {
      user_id: user.user_id,
      mobile_number: user.mobile_number,
      country_code: user.country_code
    };
    sendHttpCall('POST', 'users/approve_user_request', reqBody, props)
      .then((res) => {
        setLoading(false);
        toast.success(
          'You approved ' + user.user_id + ' request successfully.'
        );
        toast.success('Invitation link sent to user.');
      })
      .catch((error) => {
        setLoading(false);
       
        toast.error(`${error.message}. Please try again.`);
      });
  }
  async function rejectRequest(user_id) {
    setLoading(true);
    let reqBody = { user_id: user_id };
    sendHttpCall('POST', 'users/reject_user_request', reqBody, props)
      .then((res) => {
        setLoading(false);
        toast.success('You rejected ' + user_id + ' request successfully.');
      })
      .catch((error) => {
        setLoading(false);
       
        toast.error(`${error.message}. Please try again.`);
      });
  }

  async function sendInvitatoinsms(user) {
    setLoading(true);
    let reqBody = {
      user_id: user.user_id,
      mobile_number: user.mobile_number,
      country_code: user.country_code
    };
    sendHttpCall('POST', 'users/send_user_invitation_sms', reqBody, props)
      .then((res) => {
        setLoading(false);
        alert(
          'SMS with Invitation link sent to' + user.user_id + ' successfully.'
        );
      })
      .catch((error) => {
        setLoading(false);
       
        toast.error(`${error.message}. Please try again.`);
      });
  }
  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        // reqBody.start_date = moment(new Date(start)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date()).format('YYYY-MM-DD');
        //   fetchAllData();
        //startInitial
        setSelectedStartDate(moment(new Date(startInitial)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        //   fetchAllData();
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        //  fetchAllData();
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        // fetchAllData();
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
           tableRef.current.onQueryChange();
        // fetchAllData();
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        tableRef.current.onQueryChange();
        // fetchAllData();
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
      //  tableRef.current.onQueryChange();
    }
  };

  const resetFilter = () => {
    setOkdate(false);
    setSeletedPeriod('All time');
    setSelectedStartDate(moment(new Date(start)).format('YYYY-MM-DD'));
    setSelectedEndDate(moment(new Date(end)).format('YYYY-MM-DD'));
    //fetchAllData();
    tableRef.current.onQueryChange();
  };
  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    // var d = new Date(event);
    if(event<selectedEndDate){
      setSelectedStartDate(event);
      setOkdate(true);
    }
  } 

  const handleChageEndDate = (event) => {
    if(event<selectedStartDate){
      Toast('You have selected date greater than End date'); 
      setOkdate(false);
    } else{
      setSelectedEndDate(event);
      setOkdate(true);
    }
  }

  const filterByDateRange = () => {
    setSelectedStartDate( moment(new Date(selectedStartDate)).format('YYYY-MM-DD'));
    setSelectedEndDate( moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
    tableRef.current.onQueryChange();
  }

  // user_id  | name                      | number     | city              | count
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={2} item>
          <Typography variant="body2" color="textSecondary" component="p">
            SELECT PERIOD
          </Typography>
          <TextField
            fullWidth
            // label="Select Period"
            margin="dense"
            name="status"
            onChange={handleTimeChange}
            required
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={seletedPeriod}
            variant="outlined"
            // placeholder="select period"
          >
            {/* <option value={""} > All time </option> */}
            {selectYesterday.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        </Grid>
        {/* <Grid item alignContent="center">
          <Button
            style={{ marginTop: '23px', marginLeft: '10px' }}
            variant="contained"
            color="primary"
            className={classes.srch_btn}
            onClick={resetFilter}>
            RESET
          </Button>
        </Grid> */}
      </Grid>
      {isCustomDate === true ? 
              <Grid container justify="space-around">
                <Grid xs={8}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="SELECT START DATE"
                          format="MM/dd/yyyy"
                          value={selectedStartDate}
                          onChange={handleChangeStartDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          format="MM/dd/yyyy"
                          id="date-picker-dialog"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          label="SELECT END DATE"
                          margin="normal"
                          onChange={handleChageEndDate}
                          value={selectedEndDate}
                        />
                      </Grid>
                      <Grid xs={2}>
                        {okdate===false?null:<Button color="primary" variant="outlined"  className={classes.srch_btn} onClick={filterByDateRange}>SEARCH</Button>}
                      </Grid>
                      <Grid xs={2}>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid> : null}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaterialTable
            title="Users onboarded "
            tableRef={tableRef}
            page={page}
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
            actions={[
              (rowData) => ({
                icon: 'send',
                tooltip: 'Go To Profile',
                disabled: false,
                onClick: (event, rowData) => {
                  props.history.push(`/account/${rowData.user_id}`);
                }
              }),
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () =>
                  tableRef.current && tableRef.current.onQueryChange()
              }
            ]}
            options={{
              search: false,
              actionsColumnIndex: -1,
              pageSize: `${pageSize}`,
              pageSizeOptions: [10, 20, 30,50,100]
              // selection: true,
              // selectionProps: rowData => ({
              //   disabled: rowData.status != 5,
              //   color: 'primary'
              // })
            }}
            columns={[
              {
              title: 'S No.',
              render: (rowData) => page*pageSize + rowData.tableData.id + 1
              },
              { title: 'Name', field: 'name' },
              { title: 'Phone', field: 'number' },
              { title: 'City', field: 'city' },
              { title: 'Contacts', field: 'count' }
            ]}
            data={(query) => {
              return new Promise((resolve, reject) => {
                let url =
                  'users/join_request_list?' +
                  'limit=' +
                  query.pageSize +
                  '&page=' +
                  query.page +
                  `&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}`;
                sendHttpCall('GET', url, null, props)
                  .then((response) => {
                    resolve({
                      data: response.data,
                      page: response.page,
                      totalCount: response.totalCount
                    });
                  })
                  .catch((error) => {
                    toast.error(`${error.message}. Please try again.`);
                    reject(error);
                  });
              });
            }}
            
            
          />
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={green[500]} size={24} />
      </Backdrop>
    </div>
  );
};

NewJoinRequestList.propTypes = {
  className: PropTypes.string
};

export default withRouter(NewJoinRequestList);
