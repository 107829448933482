import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import login from '../../assets/loginPage.svg'

import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';
import { sendHttpCall } from 'shareService/httpService';
import { apiUrl } from 'config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    position: 'relative',
    background: 'linear-gradient(307deg, #123D67 0%, #1E5D99 40%, #297BCA 76.69%, #297BCA 100%)',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(38),

    color: '#fff',
    // backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  bookGuy: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    width: 'auto',
    height: '100%',
    backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/login.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  quoteInner: {
    textAlign: 'left',
    flexBasis: '600px'
  },
  quoteHeader: {
    color: theme.palette.white,
    fontWeight: 700,
    fontSize: 56,
    fontFamily: 'Poppins',
    lineHeight: 'normal'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 500,
    fontSize: 18,
    fontFamily: 'Poppins',
    lineHeight: 'normal'
  },
  name: {
    color: theme.palette.white,
    fontWeight: 500,
    fontSize: 18,
    fontFamily: 'Poppins',
    lineHeight: 'normal'
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    //  height: '80%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '700px', // Adjust based on design
    width: '100%',
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    // paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    position: 'absolute',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  logoText: {
    color: theme.palette.white,
    fontWeight: 400,
    fontSize: 12,
    fontFamily: 'Poppins',
    lineHeight: '16px'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 50,
    paddingRight: 50,
    //paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(5),
    color: '#2D3135',
    fontWeight: 600,
    fontSize: 30,
    fontFamily: 'Poppins',
    lineHeight: 'normal'
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(4, 0)
  },
  circle: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end', // This will align the child SVG to the right
  },
  login: {
    bottom: 0,
    zIndex:'10',
    right: '-208px',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
  },
  wave: {
    bottom: 0,
    right: 0,
    display: 'flex',
    position: 'absolute',
  },
}));

const SignIn = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    var userData = localStorage.getItem('login_data');
    var parseUserData = JSON.parse(userData);

    if (userData === null) {
      props.history.push('sign-in');
    } else {
      props.history.push('dashboard');
    }
    toast.configure({
      autoClose: 2000,
      draggable: false
      //etc you get the idea
    });
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  async function fetchSignIn() {
    let response = await fetch(`${apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify(formState.values),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    response = await response.json();
    console.log(response);
    if (response.refreshToken) {
      localStorage.setItem('login_data', JSON.stringify(response));
      localStorage.setItem('admin_id', response.adminId);
      localStorage.setItem('permissions', response.permissions);
      localStorage.setItem('isSubscribed', response.isSubscribed);
      localStorage.setItem('user_type', response.userType);
      localStorage.setItem('user_name', response.name);
      localStorage.setItem('user_email', response.emailId);
      localStorage.setItem('user_imageId', response.imageId);
      toast.info('Sucessfully  Logged IN!', {
        position: toast.POSITION.BOTTOM_CENTER
      });

      if (response.userType === 'ADMIN' ) history.push('/dashboard');
      if (response.userType === 'SALES') history.push('/sales-dashboard');
       else if(response.userType === 'MARKETING') history.push('/report');
      else history.push('/search-user');
    } else {
      toast.warn(response.message, {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  }

  const handleSignIn = (event) => {
    event.preventDefault();

    fetchSignIn();
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>

      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={7} >
          <div className={classes.logoImage}>
            <img
              alt="Logo"
              src={process.env.PUBLIC_URL + '/images/logos/logo--white.svg'}
            />
            <Typography className={classes.logoText}> World’s #1st AI Recruitment Platform</Typography>
          </div>
          <div className={classes.wave}>
            <WaveSvg />
          </div>
          <div className={classes.login}>
            <img src={login} alt="login" />
          </div>
          <div className={classes.circle}>
            <CircleSvg />
          </div>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteHeader} variant="h1">
                Goodspace Admin
              </Typography>
              <Typography className={classes.quoteText} variant="h4">
                Come, awake, and stop not till the goal is reached.
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  -Swami Vivekananda
                </Typography>
                {/* <Typography className={classes.bio} variant="body2">
                  Manager at inVision
                </Typography> */}
                <div className={classes.bookGuy}>

                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={5} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              {/* <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton> */}
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Admin Dashboard
                </Typography>

                {/* <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1">
                  or login with email address
                </Typography> */}
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  Login
                </Button>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don't have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/sign-up"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);



const CircleSvg = () => (
  <svg width="380" height="229" viewBox="0 0 380 229" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="359.5" cy="-49.5" r="278" stroke="#D5D5D5" stroke-opacity="0.7" />
    <circle cx="278.5" cy="-75.5" r="278" stroke="#D5D5D5" stroke-opacity="0.7" />
  </svg>

)

const WaveSvg =()=>(
  <svg width="872" height="226" viewBox="0 0 872 226" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1245.68 323.457C901.572 306.888 424.399 118.571 468.434 53.0878C497.31 10.1601 835.614 11.1241 928.041 3.38204C1054.08 -7.1616 815.425 32.3093 766.818 77.9407C709.066 132.165 796.728 274.715 518.63 249.652C275.929 227.766 223.375 156.882 49.3978 158.524C-107.951 160.031 -230.529 219.527 -309.144 269.233" stroke="url(#paint0_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.7 319.112C916.037 300.638 456.983 118.263 493.68 55.521C517.743 14.4158 838.144 15.7865 923.665 8.82771C1042.22 -0.804662 813.744 37.1299 765.546 80.9086C707.794 133.401 787.491 269.821 519.018 245.653C279.517 224.152 231.174 151.619 55.6334 151.513C-102.005 151.604 -229.419 209.812 -311.499 258.456" stroke="url(#paint1_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.73 314.792C930.502 294.413 489.568 117.995 518.901 57.9789C538.152 18.6963 840.674 20.4736 919.289 14.2981C1030.39 5.6071 812.063 41.9751 764.274 83.9087C706.522 134.669 778.23 264.943 519.407 241.679C283.106 220.554 238.974 146.184 61.845 144.527C-96.0577 143.021 -228.309 200.115 -313.878 247.704" stroke="url(#paint2_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.73 310.433C944.94 288.141 522.126 117.711 544.12 60.3983C558.558 22.9608 843.201 25.1223 914.909 19.7299C1018.6 12.0331 810.355 46.812 762.998 86.8929C705.078 135.928 768.966 260.057 519.768 237.705C286.692 216.949 246.819 140.816 68.1016 137.54C-90.0658 134.61 -227.178 190.401 -316.236 236.952" stroke="url(#paint3_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.75 306.099C959.256 281.902 554.705 117.474 569.336 62.8424C578.961 27.2576 845.701 29.8032 910.504 25.2317C1006.76 18.4537 808.62 51.6511 761.721 89.8869C703.705 137.19 759.7 255.174 520.15 233.732C290.275 213.353 254.637 135.541 74.2837 130.555C-84.1003 126.18 -226.121 180.705 -318.62 226.201" stroke="url(#paint4_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.77 301.76C973.667 275.649 587.285 117.246 594.576 65.281C599.388 31.5715 848.202 34.4785 906.122 30.6903C994.867 24.8913 806.837 56.4921 760.443 92.8752C702.307 138.454 750.433 250.277 520.509 229.747C293.882 209.736 262.48 130.222 80.5138 123.557C-78.1348 117.743 -225.089 171.004 -321.005 215.438" stroke="url(#paint5_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.77 297.415C988.081 269.399 619.89 117.134 619.794 67.7141C619.794 35.91 850.801 39.171 901.742 36.1585C983.028 31.3611 805.057 61.3277 759.168 95.8581C700.887 139.72 741.193 245.405 520.942 225.763C297.516 206.137 270.396 124.921 86.7702 116.561C-72.0468 109.331 -223.861 161.274 -323.339 204.676" stroke="url(#paint6_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.8 293.076C1002.52 263.154 652.548 117.019 645.016 70.1528C640.204 40.2691 853.163 43.869 897.343 41.6172C971.193 37.8516 803.208 66.1613 757.776 98.8541C699.303 141.029 731.932 240.38 521.163 221.793C301.081 202.385 278.125 119.64 92.8383 109.578C-66.2916 100.933 -222.919 151.572 -325.861 193.928" stroke="url(#paint7_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.82 288.739C1016.93 256.905 685.296 116.953 670.256 72.5942C660.775 44.6611 855.543 48.5773 892.961 47.0861C959.303 44.4351 801.353 70.9976 756.595 101.83C697.953 142.258 722.907 235.471 521.642 217.81C304.785 198.764 286.039 114.37 99.1647 102.583C-60.1817 92.5368 -221.718 141.858 -328.125 183.167" stroke="url(#paint8_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.82 284.399C1031.35 250.66 718.142 116.944 695.474 75.0328C681.421 49.0728 857.901 53.3054 888.582 52.5447C947.392 51.0912 799.548 75.8311 755.32 104.819C696.413 143.521 713.546 230.589 521.906 213.817C308.273 195.132 293.763 109.096 105.276 95.5779C-54.3108 84.138 -220.732 132.142 -330.629 172.396" stroke="url(#paint9_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.85 280.066C1045.76 244.413 751.178 116.985 720.69 77.477C702.21 53.5128 860.064 58.0541 884.32 58.0089C935.43 57.9185 797.692 80.6627 754.162 107.813C694.967 144.798 704.423 225.69 522.506 209.845C312.121 191.514 301.846 103.851 111.723 88.593C-48.1049 75.79 -219.387 122.438 -332.748 161.645" stroke="url(#paint10_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.87 275.718C1060.18 238.167 784.437 117.066 745.912 79.9073C723.172 57.969 862.763 61.584 879.8 63.4743C922.825 61.9681 795.578 85.488 752.77 110.793C693.238 146.061 695.018 220.801 522.774 205.859C315.59 187.897 309.55 98.6072 117.839 81.5943C-42.2539 67.3981 -218.396 112.721 -335.247 150.881" stroke="url(#paint11_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.87 271.381C1074.62 231.918 818.03 117.18 771.155 82.3487C744.348 62.4363 865.218 66.1718 875.42 68.9357C910.889 68.2428 793.437 90.3092 751.495 113.784C691.554 147.328 685.778 215.914 523.159 201.899C319.2 184.298 317.371 93.3894 124.047 74.6217C-36.3098 59.0548 -217.289 103.029 -337.629 140.143" stroke="url(#paint12_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.9 267.042C1089.03 225.673 851.981 117.284 796.371 84.7874C765.762 66.8933 867.694 70.7568 870.966 74.4019C898.904 74.5299 791.245 95.1277 750.145 116.765C689.722 148.591 676.44 211.032 523.446 197.898C322.711 180.667 325.093 88.1463 130.181 67.6088C-30.4407 50.6862 -216.329 93.2976 -340.062 129.364" stroke="url(#paint13_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.92 262.702C1103.44 219.421 886.346 117.351 821.592 87.226C787.422 71.3277 870.152 75.3419 866.662 79.8606C887.044 80.817 789.131 99.9311 748.945 119.776C687.945 149.9 667.251 206.173 523.906 193.943C326.372 177.08 332.965 82.9559 136.489 60.641C-24.4217 42.3553 -215.098 83.6111 -342.369 118.631" stroke="url(#paint14_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.92 258.366C1117.86 213.179 921.069 117.314 846.834 89.6674C809.32 75.7122 872.63 79.9296 862.259 85.3219C875.109 87.0993 786.869 104.73 747.67 122.737C685.996 151.069 657.986 201.279 524.291 189.937C329.957 173.459 340.737 77.723 142.697 53.6232C-18.526 33.997 -213.991 73.8821 -344.751 107.848" stroke="url(#paint15_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.94 254.026C1132.27 206.926 956.32 117.125 872.051 92.106C831.36 80.0184 875.082 84.5146 857.877 90.788C863.171 93.3863 784.533 109.503 746.393 125.725C683.949 152.28 648.72 196.405 524.65 185.975C333.54 169.88 348.484 72.555 148.927 46.6478C-12.6087 25.681 -212.887 64.1879 -347.111 97.1067" stroke="url(#paint16_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.97 249.684C1146.71 200.686 991.696 116.736 897.272 94.5419C853.453 84.2467 877.564 89.1044 853.501 96.2515C851.239 99.6706 782.105 114.266 745.217 128.718C681.834 153.458 639.531 191.536 525.135 181.979C337.225 166.276 356.307 67.3393 155.235 39.6396C-6.61391 17.3398 -211.705 54.461 -349.394 86.3329" stroke="url(#paint17_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.97 245.345C1161.12 194.434 1027.12 116.14 922.49 96.9805C875.519 88.3799 876.554 92.5371 849.122 101.71C837.692 105.536 779.603 119.032 743.821 131.699C679.38 154.579 630.147 186.677 525.399 177.993C340.69 162.69 363.936 62.1564 161.372 32.6417C-0.838376 9.00886 -210.694 44.7518 -351.872 75.5694" stroke="url(#paint18_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1245.99 240.986C1175.54 188.17 1062.34 115.321 947.61 99.3999C897.294 92.411 880.811 97.6452 844.596 107.157C826.428 111.932 776.906 123.673 742.423 134.668C676.707 155.613 620.76 181.791 525.662 173.996C344.129 159.107 371.513 56.9542 167.457 25.6321C4.88649 0.673749 -209.709 35.0159 -354.353 64.7942" stroke="url(#paint19_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1246.02 236.669C1189.95 181.94 1097.23 114.392 972.947 101.861C919.117 96.4305 884.37 102.614 840.334 112.638C815.116 118.384 774.401 128.348 741.266 137.671C674.082 156.582 611.541 176.999 526.165 170.055C347.713 155.671 379.163 51.9062 173.808 18.6787C10.7557 -7.53733 -208.484 25.3513 -356.617 54.0752" stroke="url(#paint20_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <path d="M1246.02 232.329C1204.36 175.695 1131.69 113.337 998.165 104.299C825.704 92.5882 692.731 179.34 526.526 166.055C351.298 152.039 386.96 46.633 180.016 11.6657C16.6995 -15.9436 -207.401 15.612 -358.999 43.2966" stroke="url(#paint21_linear_7895_7059)" stroke-opacity="0.2" stroke-width="0.5" stroke-miterlimit="10" />
    <defs>
      <linearGradient id="paint0_linear_7895_7059" x1="468.266" y1="323.457" x2="468.266" y2="1.65973" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint1_linear_7895_7059" x1="467.102" y1="319.112" x2="467.102" y2="7.3183" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint2_linear_7895_7059" x1="465.926" y1="314.792" x2="465.926" y2="13.003" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint3_linear_7895_7059" x1="464.747" y1="310.433" x2="464.747" y2="18.6544" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint4_linear_7895_7059" x1="463.566" y1="306.099" x2="463.566" y2="24.3472" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint5_linear_7895_7059" x1="462.385" y1="301.76" x2="462.385" y2="29.9999" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint6_linear_7895_7059" x1="461.218" y1="297.415" x2="461.218" y2="35.6516" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint7_linear_7895_7059" x1="459.97" y1="293.076" x2="459.97" y2="41.2798" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint8_linear_7895_7059" x1="458.849" y1="288.739" x2="458.849" y2="46.9033" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint9_linear_7895_7059" x1="457.598" y1="284.399" x2="457.598" y2="52.4837" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint10_linear_7895_7059" x1="456.548" y1="280.066" x2="456.548" y2="57.6937" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint11_linear_7895_7059" x1="455.313" y1="275.718" x2="455.313" y2="62.0194" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint12_linear_7895_7059" x1="454.122" y1="271.381" x2="454.122" y2="66.7146" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint13_linear_7895_7059" x1="452.917" y1="267.042" x2="452.917" y2="63.7846" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint14_linear_7895_7059" x1="451.777" y1="262.702" x2="451.777" y2="56.1543" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint15_linear_7895_7059" x1="450.586" y1="258.366" x2="450.586" y2="48.4263" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint16_linear_7895_7059" x1="449.417" y1="254.026" x2="449.417" y2="40.6833" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint17_linear_7895_7059" x1="448.289" y1="249.684" x2="448.289" y2="32.8526" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint18_linear_7895_7059" x1="447.05" y1="245.345" x2="447.05" y2="24.9743" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint19_linear_7895_7059" x1="445.82" y1="240.986" x2="445.82" y2="17.0258" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint20_linear_7895_7059" x1="444.699" y1="236.669" x2="444.699" y2="9.10358" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
      <linearGradient id="paint21_linear_7895_7059" x1="443.508" y1="232.329" x2="443.508" y2="0.999648" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#D9ACDB" />
      </linearGradient>
    </defs>
  </svg>

)