import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import { getFormatedDate } from '../../helper';

import 'react-quill/dist/quill.snow.css';
import { Grid, Link } from '@material-ui/core';
import './blogCard.css';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    maxWidth: 445
  },
  contentBody: {
    padding: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: '#297bca'
  }
}));
const modules = {
  toolbar: false
};

const BlogCard = (data) => {
  const classes = useStyles();
  console.log(data);
  const handleEditButton = () => {
    data.props.history.push(`/edit-blogs/${data.data.blog_id}`);
  };
  return (
    <Grid item xs={3}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              G
            </Avatar>
          }
          action={
            <CreateSharpIcon
              color="secondary"
              onClick={handleEditButton}
              style={{ cursor: 'pointer' }}
            />
          }
          title="Good Space"
          subheader={getFormatedDate(data.data.created_at)}
        />
        <CardMedia
          className={classes.media}
          image={data.data.bannerImage}
          title={data.data.title}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <ReactQuill
              theme="snow"
              className="blogCard"
              modules={modules}
              value={data.data.text.substring(0, 180)}
              style={{}}
            />
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default BlogCard;
