import React, { useState } from 'react';
import {
  Drawer,
  Button,
  TextField,
  Typography,
  Snackbar,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Collapse,
  Grid
} from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Loader from 'components/Loader';
import { useJobBoostMaster } from '../../contexts/JobBoostMasterContext';
import { sendHttpCall } from 'shareService/httpService';
import styles from './BoostDrawer.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BoostDrawer = () => {
  const { isOpen, boostData, closeBoostDrawer } = useJobBoostMaster();
  const [boostType, setBoostType] = useState('new');
  const [emailRange, setEmailRange] = useState('');
  const [whatsappRange, setWhatsappRange] = useState(0);
  const [loading, setLoading] = useState(false);
  const [smsRange, setSmsRange] = useState(0);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success', 'error', 'warning', 'info'

  const potentialCount = boostData?.s3Count || 0;

  const handleBoost = async () => {
    if (
      emailRange > potentialCount ||
      whatsappRange > potentialCount ||
      smsRange > potentialCount
    ) {
      setSnackbarMessage(
        `Ranges cannot exceed the potential count of ${potentialCount}. Please adjust the values.`
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }
    if (boostType === 'new') {
      const whatsappRangeValue = whatsappRange || 0;
      const emailRangeValue = emailRange || 0;
      const smsRangeValue = smsRange || 0;

      const availableWhatsappRange = Math.max(
        potentialCount - boostData.whatsapp_checkpoint,
        0
      );
      const availableEmailRange = Math.max(
        potentialCount - boostData.email_checkpoint,
        0
      );
      const availableSmsRange = Math.max(
        potentialCount - boostData.sms_checkpoint,
        0
      );

      if (
        availableWhatsappRange < whatsappRangeValue ||
        availableEmailRange < emailRangeValue ||
        availableSmsRange < smsRangeValue
      ) {
        setSnackbarMessage(
          `Ranges for new communications cannot exceed the potential count of ${potentialCount}.`
        );
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
        return;
      }
    }

    const payload = {
      offerId: boostData.offerId,
      type: boostType
    };

    if (emailRange !== '') {
      payload.emailRange = emailRange;
    }
    if (whatsappRange !== '') {
      payload.whatsappRange = whatsappRange;
    }
    if (smsRange !== '') {
      payload.smsRange = smsRange;
    }

    try {
      setLoading(true);
      await sendHttpCall('POST', '/jobs/boost_job', payload);
      console.log(payload);
      setSnackbarMessage('Job boost initiated successfully!');
      setLoading(false);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => {
        handleDrawerClose();
      }, 2000);
    } catch (error) {
      console.log('Error boosting job:', error);
      setSnackbarMessage('Failed to initiate job boost. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setBoostType('new');
    setEmailRange('');
    setWhatsappRange('');
    setSmsRange('');
    setShowAdvancedOptions(false);
    handleCloseSnackbar();
    closeBoostDrawer();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
      className={styles.drawer}>
      <div className={styles.container}>
        <Typography variant="h5" className={styles.heading}>
          Job Boost Master
        </Typography>

        <Typography variant="h6" className={styles.subHeading}>
          S3 Potentials: {potentialCount || 'N/A'}
          <Typography
            variant="body2"
            color="textSecondary"
            className={styles.note}>
            These have not yet applied.
          </Typography>
        </Typography>

        <div className={styles.checkpoints}>
          <Typography variant="body1">Last Checkpoints:</Typography>
          <ul>
            {/* <li>SMS: {boostData?.sms_checkpoint || 'N/A'}</li> */}
            <li>WhatsApp: {boostData?.whatsapp_checkpoint || 'N/A'}</li>
            <li>Email: {boostData?.email_checkpoint || 'N/A'}</li>
          </ul>
        </div>

        <FormControl component="fieldset" className={styles.boostType}>
          <FormLabel component="legend">Boost Type</FormLabel>
          <RadioGroup
            aria-label="boostType"
            name="boostType"
            value={boostType}
            onChange={(e) => setBoostType(e.target.value)}>
            <FormControlLabel value="new" control={<Radio />} label="New" />
            <FormControlLabel
              value="resend"
              control={<Radio />}
              label="Resend"
            />
          </RadioGroup>
        </FormControl>

        <div className={styles.advancedOptions}>
          <Button
            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            endIcon={
              showAdvancedOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />
            }>
            Advanced Options
          </Button>
          <Collapse in={showAdvancedOptions}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`Email Range (Default: 1000)`}
                  type="number"
                  value={emailRange}
                  onChange={(e) => setEmailRange(e.target.value)}
                  variant="outlined"
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`WhatsApp Range (Default: 200)`}
                  type="number"
                  value={whatsappRange}
                  onChange={(e) => setWhatsappRange(e.target.value)}
                  variant="outlined"
                  className={styles.inputField}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`SMS Range (Default: 250)`}
                  type="number"
                  value={smsRange}
                  onChange={(e) => setSmsRange(e.target.value)}
                  variant="outlined"
                  className={styles.inputField}
                />
              </Grid> */}
            </Grid>
          </Collapse>
        </div>

        {loading ? (
          <div className={styles.loaderContainer}>
          <Loader />
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleBoost}
            className={styles.boostButton}>
            Boost
          </Button>
        )}
      </div>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Drawer>
  );
};

export default BoostDrawer;
