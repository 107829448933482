import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({}));

const SkillUsersList = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [page, setPage] = useState(
    parseInt(localStorage.getItem('skilluserpage'), 10) || 0
  );
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem('skilluserpagesize'), 10) || 10
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const getData = () => {
    if (loading) return;
    setLoading(true);
    let url =
      'skills/list_users_by_skill?' +
      'skill_id=' +
      props.match.params.skill_id +
      '&limit=' +
      pageSize +
      '&page=' +
      page;
    sendHttpCall('GET', url, null, props).then((response) => {
      setData(response.data);
      setPage(response.page);
      setCount(response.totalCount);
      setLoading(false);
    });
  };
  useEffect(() => {
    localStorage.removeItem('skilluserpage');
    localStorage.removeItem('skilluserpagesize');
    getData();
  }, [page, pageSize]);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          columns={[
            { title: 'id', field: 'user_id' },
            {
              title: 'Name',
              field: 'name',
              render: (rowData) => (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    localStorage.setItem('skilluserpage', page);
                    localStorage.setItem('skilluserpagesize', pageSize);
                    props.history.push(`/account/${rowData.user_id}`);
                  }}>
                  {rowData.name}
                </div>
              )
            },
            { title: 'Phone', field: 'mobile_number' },
            { title: 'Email', field: 'email_id' },
            { title: 'Score', field: 'score' },
            {
              title: 'FeedbackCount',
              render: (rowData) => (
                <div>
                  {rowData.feedback_received +
                    (rowData.feedback_received === 1 ? ' (SELF)' : '')}
                </div>
              )
            },
            { title: 'Registration Date', field: 'created_at', type: 'date' }
          ]}
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          data={data}
          totalCount={count}
          isLoading={loading}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30]
          }}
          title="All Users"
        />
      </CardContent>
    </Card>
  );
};

SkillUsersList.propTypes = {
  className: PropTypes.string
};

export default withRouter(SkillUsersList);
