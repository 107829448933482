import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import styles from './design.module.scss';
import CheckIcon from '@material-ui/icons/Check';
import { sendHttpCall } from 'shareService/httpService';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import { selectoptions } from '../../config';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';

function getPreviousDay(date = new Date()) {
  const previous = new Date();
  previous.setDate(date.getDate() - 1);
  return previous;
}

export default function WhatsAppCommunications(props) {
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);

  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate()))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  };

  const [selectedStartDate, setSelectedStartDate] = useState(
    localStorage.getItem('communicationStartDate')
      ? localStorage.getItem('communicationStartDate')
      : moment(getPreviousDay()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    localStorage.getItem('clickEndDate')
      ? localStorage.getItem('clickEndDate')
      : moment(getPreviousDay()).format('YYYY-MM-DD')
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('selectedPeriodUserSearch')
      ? localStorage.getItem('selectedPeriodUserSearch')
      : 'Today'
  );

  const [selectedJob, selectJob] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 20
  );

  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        setSelectedStartDate(
          moment(new Date(startInitial)).format('YYYY-MM-DD')
        );
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));
        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
    }
    setPage(0);
  };
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSelectedStartDate(
      moment(new Date(selectedStartDate)).format('YYYY-MM-DD')
    );
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    if (event < selectedEndDate) {
      setSelectedStartDate(event);
      setOkdate(true);
    }
  };

  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      setOkdate(false);
    } else {
      setSelectedEndDate(event);
      setOkdate(true);
    }
  };

  const filterByDateRange = () => {
    setSelectedStartDate(
      moment(new Date(selectedStartDate)).format('YYYY-MM-DD')
    );
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getTableData = async () => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      let url =
        'dashboard/communications?type=' +
        props.match.params.type.toLowerCase() +
        '&limit=' +
        limit +
        '&page=' +
        page +
        `&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}`;

      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
        setTotalCount(response.data.total_Count);
        resolve(null);
      });
    });
  };

  useEffect(() => {
    getTableData();
  }, [page, limit, selectedEndDate, selectedStartDate]);

  useEffect(() => {
    setData([]);
    setTotalCount(0);
    if (page === 0) getTableData();
    else setPage(0);
  }, [props?.match?.params?.type]);

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
  const classes = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.match.params.type} Communications</div>
      <div className="flex">
        <div>
          <div className="sm-width">
            <Typography variant="body2" color="textSecondary" component="p">
              SELECT PERIOD
            </Typography>
          </div>
          <div className="sm-width">
            <TextField
              fullWidth
              margin="dense"
              name="status"
              onChange={handleTimeChange}
              required
              select
              SelectProps={{ native: true }}
              value={seletedPeriod}
              variant="outlined"
            >
              {selectoptions.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>
        </div>
        {isCustomDate && (
          <Grid container justify="space-around">
            <Grid xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="SELECT START DATE"
                      format="MM/dd/yyyy"
                      value={selectedStartDate}
                      onChange={handleChangeStartDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      id="date-picker-dialog"
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      label="SELECT END DATE"
                      margin="normal"
                      onChange={handleChageEndDate}
                      value={selectedEndDate}
                    />
                  </Grid>
                  <Grid xs={2}>
                    {okdate && (
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.srch_btn}
                        onClick={filterByDateRange}>
                        SEARCH
                      </Button>
                    )}
                  </Grid>
                  <Grid xs={2}></Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )}
      </div>
      <MaterialTable
        isLoading={isLoading}
        columns={[
          {
            title: 'S No.',
            cellStyle: {
              position: 'sticky',
              left: 0,
              backgroundColor: '#FFF'
            },
            headerStyle: {
              position: 'sticky',
              left: 0,
              backgroundColor: '#FFF'
            },
            render: (rowData) => page * limit + rowData.tableData.id + 1
          },
          {
            title: 'Template ID',
            field: 'whatsapp_template_id',
            cellStyle: {
              position: 'sticky',
              left: 40,
              backgroundColor: '#FFF'
            },
            headerStyle: {
              position: 'sticky',
              left: 40,
              backgroundColor: '#FFF'
            }
          },
          { title: 'Total', field: 'total_count' },
          { title: 'Read', field: 'read_count' },
          { title: 'Delivered', field: 'delivered_count' },
          { title: 'Failed', field: 'failed_count' },
          { title: 'Queued', field: 'queued_count' }
        ]}
        data={data}
        totalCount={totalCount}
        page={page}
        title=""
        onChangePage={(e) => setPage(e)}
        onChangeRowsPerPage={(e) => setLimit(e)}
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions: [10, 20, 30, 40, 50],
          sorting: true,
          columnsButton: true,
          exportButton: localStorage.getItem('user_type') === 'SALES' ? false : true
        }}
      />
    </div>
  );
}
