import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom"
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
    Link
} from '@material-ui/core';



const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0,
        //  marginTop:100,
        height: 450
    },
    inner: {
        minWidth: 200
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const TzPointsList = props => {
    const classes = useStyles();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const { className, deleteRow, tz_points_data, editRow, ...rest } = props;
    const editData = (data) => {
        props.editRow(data);
    }
    const deleteData = (data) => {
        
        props.deleteRow(data);
    }
    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sl.No.</TableCell>
                                    <TableCell>Tz Points Code</TableCell>
                                    <TableCell>Tz Points Value</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell colspan="2">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tz_points_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((data, index) => (
                                        <TableRow

                                            className={classes.tableRow}
                                            hover
                                            key={data.tz_points_id}
                                            selected={selectedUsers.indexOf(data.tz_points_id) !== -1}
                                        >
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <div className={classes.nameContainer}>
                                                    <Typography variant="body1">{data.tz_points_code}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.nameContainer}>
                                                    <Typography variant="body1">{data.tz_points_value}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>{data.status}</TableCell>
                                            <TableCell>

                                                <EditIcon color="primary"
                                                    onClick={() => {
                                                        editData(data)
                                                    }}>
                                                </EditIcon>

                                            </TableCell>
                                            <TableCell>
                                                <DeleteIcon color="error"
                                                    onClick={() => {
                                                         deleteData(data)
                                                    }}>
                                                </DeleteIcon>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
                <TablePagination
                    component="div"
                    count={tz_points_data.length}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </CardActions>
        </Card>
    );
}


export default withRouter(TzPointsList)