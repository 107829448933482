import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import DegreeList from './DegreeList';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { status, apiUrl, headers, message } from '../../../config'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,

} from '@material-ui/core';



import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { sendHttpCall } from 'shareService/httpService';
import { toast } from 'react-toastify';

// For form validation
const schema = {
  degree_id: {},
  degree: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  status: {
    presence: { allowEmpty: false, message: 'is required' },
  },

};

const useStyles = makeStyles(theme => ({

  formControl: {
    margin: theme.spacing(1),
    minWidth: "95%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentList: {
    marginTop: '2%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));


const Degrees = props => {
  const { history } = props;
  const classes = useStyles();
  const [degree_data, setDegreeData] = useState([]);
  const [degree_levels, setDegreeLevel] = useState([]);

  const getDataFromChild = (childData) => {
    
    setFormState(formState => ({
      ...formState,
      isValid: true,
      values: {
        degree_id: childData.degree_id,
        degree_level_id: childData.degree_level_id,
        degree: childData.degree,
        status: childData.status
      },
    }));
    
  }

  async function getDegrees() {
    sendHttpCall('GET', 'degrees', null).then((data) => {
      setDegreeData(data);
    });
  }

  async function getDegreeLevel() {
    sendHttpCall('GET', 'degree-levels', null, props).then((level_data) => {
      if (level_data) {
        setDegreeLevel(level_data);

      }
    });
  }
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const handleChange = event => {
    event.persist();
    

    setFormState(formState => ({
      ...formState,
      isValid: true,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  // 
  const handleSignUp = event => {
    event.preventDefault();
    createDegree();
  };

  async function createDegree() {
    if (formState.values.degree_id != undefined) {
      // this is for Update
      const degree_id = formState.values.degree_id;
      sendHttpCall('PUT', `degrees/${degree_id}`, formState.values).then((data) => {
        toast.success('Degree Level' + message.UPADTE);

      });
    } else {
      // this is for Insert
      sendHttpCall('POST', 'degrees', formState.values).then((data) => {
        toast.success('Degree Level' + message.ADD);

      });
    }
    setFormState({
      values: {
        degree: '',
        degree_level_id: '',
        status: ''
      }
    });
  }

  // Delete Record
  const getDataforDelete = (childData) => {
    DeleteRecord(childData.degree_id);
  }

  async function DeleteRecord(degree_id) {
    sendHttpCall('PUT', `degrees/change-status/${degree_id}`).then((res) => {
      toast.success('Degree Level' + message.DELETE);

      getDegrees();
    })
  }

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getDegrees();
    getDegreeLevel();

    const errors = validate(formState.values, schema);
    

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={6}
          xs={6}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignUp}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Degree
                </Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('degree')}
                  fullWidth
                  // helperText={
                  //   hasError('degree') ? formState.errors.degree[0] : null
                  // }
                  label="Degree"
                  name="degree"
                  onChange={handleChange}
                  autoComplete='off'
                  type="text"
                  value={formState.values.degree || ''}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  label="Select Degree Level"
                  margin="dense"
                  name="degree_level_id"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  // value={formState.values.status || ''}
                  variant="outlined"
                >
                  <option
                    key={""}
                    value={""}
                  >
                    {""}
                  </option>
                  {degree_levels.map((degree_level, i) => (
                    <option
                      key={degree_level.degree_level_id}
                      value={degree_level.degree_level_id}
                    >
                      {degree_level.degree_level}
                    </option>
                  ))
                  }
                </TextField>

                <TextField
                  // error={hasError('status')}
                  fullWidth
                  // helperText={
                  //   hasError('status') ? formState.errors.status[0] : null
                  // }
                  label="Select State"
                  margin="dense"
                  name="status"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formState.values.status || ''}
                  variant="outlined"
                >
                  <option
                    key={""}
                    value={""}
                  >
                    {""}
                  </option>
                  {status.map(option => (
                    <option
                      key={option.id}
                      value={option.name}
                    >
                      {option.name}
                    </option>
                  ))}
                </TextField>


                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  SAVE
                    </Button>
              </form>
            </div>
          </div>
        </Grid>

        <Grid
          className={classes.contentList}
          item
          lg={6}
          xs={6}
        >
          <DegreeList degree_data={degree_data} editRow={getDataFromChild} deleteRow={getDataforDelete}></DegreeList>
        </Grid>
      </Grid>


    </div>
  );
}

Degrees.propTypes = {
  history: PropTypes.object
};

export default withRouter(Degrees);





