import React, { useEffect, useState, useRef } from 'react';
import { sendHttpCall } from 'shareService/httpService';
import ReactModal from 'react-modal';
import { makeStyles } from '@material-ui/styles';
import MaterialTable, { MTablePagination } from 'material-table';
import './styles.scss';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  }
}));
export default function SurveyMessage(props) {
  const { className, userid, ...rest } = props;
  const classes = useStyles();
  const timeout = useRef(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [showSelected, setShowSelected] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [surveyLoading, setSurveyLoading] = useState(false);
  const [surveySuccess, setSurveySuccess] = useState(false);
  const [surveyData, setSurveyData] = useState({});

  const userId = props.match.params.userId;
  const name = props.match.params.name;
  const selectContact = (userId, name) => {
    setSelectedContacts((arr) => [
      ...arr.filter((a) => a.userId !== userId),
      { userId, name }
    ]);
  };
  const removeContact = (userId) => {
    setSelectedContacts((arr) => [...arr.filter((a) => a.userId !== userId)]);
  };
  useEffect(() => {
    getContacts();
  }, [page, query]);
  const randomSelect = () => {
    let url = `users/selectUserContactAtRandom?userId=${userId}`;
    sendHttpCall('GET', url, null, props).then((response) => {
      setSelectedContacts(response.data);
      setShowSelected(true);
    });
  };
  const getContacts = () => {
    let url =
      `users/getUserContactForSurvey?userId=${userId}&query=${query}` +
      '&limit=' +
      25 +
      '&page=' +
      page;
    setLoading(true);
    sendHttpCall('GET', url, null, props).then((response) => {
      setData(response.data);
      setTotalCount(response.totalCount);
      setLoading(false);
    });
  };
  const sendSurvey = () => {
    if (selectedContacts.length < 1 || surveyLoading || surveySuccess) return;
    setSurveyLoading(true);
    let url = `users/sendSurvey`;
    sendHttpCall(
      'POST',
      url,
      {
        contactUserIdArray: selectedContacts.map((a) => a.userId),
        forUser: userId
      },
      props
    ).then((response) => {
      if (response.status) {
        setSurveyLoading(false);
        setSurveySuccess(true);
        setSurveyData(response);
      }
    });
  };
  return (
    <div className="sendSurvey__container">
      <div className="sendSurvey__title">Send Survey Message - {name}</div>
      <div className="sendSurvey__label">
        Selected {selectedContacts.length} Contacts for Survey{' '}
        {selectedContacts.length > 0 && (
          <>
            -{' '}
            <span
              style={{ marginLeft: '.25rem' }}
              onClick={() => setShowSelected(true)}>
              View
            </span>
          </>
        )}
        <div className="sendSurvey__filter">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              let val = e.target.value;
              clearTimeout(timeout.current);
              timeout.current = setTimeout(() => {
                setPage(0);
                setQuery(val);
              }, 200);
            }}
          />
        </div>
        <div className="sendSurvey__table"></div>
        <MaterialTable
          className={classes.root}
          onRowClick={(e, rowData) => {
            if (
              selectedContacts.findIndex(
                (a) => a.userId === rowData.user_id
              ) === -1
            )
              selectContact(rowData.user_id, rowData.contact_name);
            else removeContact(rowData.user_id);
          }}
          columns={[
            {
              title: 'Select',
              cellStyle: { cursor: 'pointer' },
              render: (rowData) => {
                return (
                  <input
                    type="checkbox"
                    checked={
                      selectedContacts.findIndex(
                        (a) => a.userId === rowData.user_id
                      ) !== -1
                    }
                    onClick={() => {
                      if (
                        selectedContacts.findIndex(
                          (a) => a.userId === rowData.user_id
                        ) === -1
                      )
                        selectContact(rowData.user_id, rowData.contact_name);
                      else removeContact(rowData.user_id);
                    }}
                  />
                );
              }
            },
            {
              title: 'Contact Name',
              cellStyle: { cursor: 'pointer' },
              field: 'contact_name'
            },
            {
              title: 'Phone',
              cellStyle: { cursor: 'pointer' },
              field: 'mobile_number'
            }
          ]}
          data={data}
          options={{
            search: false,
            pageSize: 25,
            pageSizeOptions: []
          }}
          page={page}
          onChangePage={(e) => setPage(e)}
          totalCount={totalCount}
          title=""
          isLoading={loading}
          components={{
            Pagination: (props) => (
              <div className="table__pagination-container">
                {localStorage.getItem('user_item') === "ADMIN" && (
                  <button
                    className="exportToCsvBtn"
                    style={{ backgroundColor: '#297bca', fontSize: '1.1rem' }}
                    onClick={randomSelect}>
                    Select 50 at Random
                  </button>)}

                <div className="table__pagination-default-container">
                  <MTablePagination {...props} />
                </div>
              </div>
            )
          }}
        />
      </div>
      <ReactModal
        isOpen={showSelected}
        overlayClassName="sendSurveyOverlay"
        onRequestClose={() => setShowSelected(false)}
        className="sendSurveyModal">
        <div className="sendSurvey__selectedModal">
          <div className="sendSurvey__selectedModal-label">
            Selected Contacts
          </div>
          <div className="sendSurvey__selectedList">
            {selectedContacts.map((a, index) => (
              <div className="sendSurvey__selectedItem">
                {index + 1 + '. ' + a.name}
              </div>
            ))}
          </div>
          <div className="sendSurvey__sendBtn" onClick={sendSurvey}>
            {surveyLoading
              ? 'Sending'
              : surveySuccess
                ? 'Survey Sent'
                : 'Send Survey'}
          </div>
          {surveySuccess ? (
            <div style={{ marginTop: '1rem' }}>
              Survey Result: <br />
              {`Successfully Delivered: ${surveyData.successCount} | Delivered Failed: ${surveyData.failCount}`}
            </div>
          ) : (
            ''
          )}
        </div>
      </ReactModal>
    </div>
  );
}
