import React, { useRef, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import styles from './design.module.scss';
import CheckIcon from '@material-ui/icons/Check';
import { sendHttpCall } from 'shareService/httpService';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import { selectYesterday } from '../../config';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { useEffect } from 'react';
function getPreviousDay(date = new Date()) {
  const previous = new Date();
  previous.setDate(date.getDate() - 1);
  return previous;
}

export default function ClickTracking(props) {
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);

  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate()))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  };
  const getTimeIn12Hours = (date) => {
    const hour = date.getHours();
    return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
      date.getMinutes()
    )} ${hour > 12 ? 'PM' : 'AM'}`;
  };
  const formatDate = (date, variation = 1) =>
    `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
      date.getMonth() + 1
    )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    localStorage.getItem('clickStartDate')
      ? localStorage.getItem('clickStartDate')
      : moment(getPreviousDay()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    localStorage.getItem('clickEndDate')
      ? localStorage.getItem('clickEndDate')
      : moment(getPreviousDay()).format('YYYY-MM-DD')
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('selectedPeriodUserSearch')
      ? localStorage.getItem('selectedPeriodUserSearch')
      : 'Yesterday'
  );
  const formatTime = (time) => {
    const date = new Date(time);

    return getTimeIn12Hours(date) + ', ' + formatDate(date);
  };
  const [selectedJob, selectJob] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 20
  );

  const getLocaleTime = (time) => {
    if (time)
      if (props.match.params.type === 'active_member')
        return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
      else return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    else return '';
  };
  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        // reqBody.start_date = moment(new Date(start)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date()).format('YYYY-MM-DD');
        //   fetchAllData();
        //startInitial
        setSelectedStartDate(
          moment(new Date(startInitial)).format('YYYY-MM-DD')
        );
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));

        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //   fetchAllData();
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //  fetchAllData();
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        // fetchAllData();
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
      //
    }
    setPage(0);
  };
  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    // var d = new Date(event);
    if (event < selectedEndDate) {
      setSelectedStartDate(event);
      setOkdate(true);
    }
  };

  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      // toast('You have selected date greater than End date');
      setOkdate(false);
    } else {
      setSelectedEndDate(event);
      setOkdate(true);
    }
  };

  const filterByDateRange = () => {
    setSelectedStartDate(
      moment(new Date(selectedStartDate)).format('YYYY-MM-DD')
    );
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getTableData = async () => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      console.log('loading');
      let url =
        'tracking/by_type?type=' +
        props.match.params.type.toLowerCase() +
        '&limit=' +
        limit +
        '&page=' +
        page +
        `&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}`;

      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
        setTotalCount(
          response.totalCount
            ? response.totalCount[0].count
            : response.count[0].count
        );
        resolve(null);
      });
    });
  };
  useEffect(() => {
    getTableData();
  }, [page, limit, selectedEndDate, selectedStartDate]);

  useEffect(() => {
    setData([]);
    setTotalCount(0);
    if (page === 0) getTableData();
    else setPage(0);
  }, [props?.match?.params?.type]);
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
  const classes = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.match.params.type} Clicks</div>
      <div className="flex">
        <div>
          <div className="sm-width">
            <Typography variant="body2" color="textSecondary" component="p">
              SELECT PERIOD
            </Typography>
          </div>
          <div className="sm-width">
            <TextField
              fullWidth
              // label="Select Period"
              margin="dense"
              name="status"
              onChange={handleTimeChange}
              required
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={seletedPeriod}
              variant="outlined"
              // placeholder="select period"
            >
              {/* <option value={""} > All time </option> */}
              {selectYesterday.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>
        </div>
        {isCustomDate === true ? (
          <Grid container justify="space-around">
            <Grid xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="SELECT START DATE"
                      format="MM/dd/yyyy"
                      value={selectedStartDate}
                      onChange={handleChangeStartDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      id="date-picker-dialog"
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      label="SELECT END DATE"
                      margin="normal"
                      onChange={handleChageEndDate}
                      value={selectedEndDate}
                    />
                  </Grid>
                  <Grid xs={2}>
                    {okdate === false ? null : (
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.srch_btn}
                        onClick={filterByDateRange}>
                        SEARCH
                      </Button>
                    )}
                  </Grid>
                  <Grid xs={2}></Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        ) : null}
      </div>
      <MaterialTable
        isLoading={isLoading}
        columns={
          props.match.params.type === 'notifications'
            ? [
                {
                  title: 'S No.',
                  cellStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  headerStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => page * 20 + rowData.tableData.id + 1
                },
                {
                  title: 'notification_type',
                  field: 'notification_type',
                  cellStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },

                  headerStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => {
                    let data = rowData[0]
                      ? rowData[0].Company || ''
                      : rowData.Company || '';
                    let orgId = data.split('>')[0];
                    data = data.replace(orgId + '>', '');
                    const verified = data.startsWith('1-');
                    return (
                      <div
                        style={{
                          cursor: 'pointer',
                          // fontWeight: 500,
                          textTransform: 'capitalize',
                          // color: '#297bca'
                          // fontSize: '.85rem',
                          fontFamily: 'Roboto',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        onClick={() => {
                          localStorage.setItem(
                            'clickStartDate',
                            selectedStartDate.toString()
                          );
                          localStorage.setItem(
                            'clickEndDate',
                            selectedEndDate.toString()
                          );
                          sessionStorage.setItem('everyTablePage', page);
                          sessionStorage.setItem('everyTableLimit', limit);
                          localStorage.setItem(
                            'selectedPeriodUserSearch',
                            seletedPeriod
                          );

                          props.history.push(
                            '/tracking/' +
                              props.match.params.type +
                              '/' +
                              'notification' +
                              '/' +
                              rowData.notification_type +
                              '/' +
                              selectedStartDate.toString() +
                              '/' +
                              selectedEndDate.toString() +
                              '/users'
                          );
                          // openUserPage(rowData.user_id);
                        }}>
                        {rowData.notification_type}
                      </div>
                    );
                  }
                },
                {
                  title: 'notification',
                  field: 'notification'
                },
                {
                  title: 'Count From Panel',
                  field: 'clickedOnPushNotification'
                },
                {
                  title: 'Count From In App',
                  field: 'inAppNotification'
                }
              ]
            : [
                {
                  title: 'S No.',
                  cellStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  headerStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => page * 20 + rowData.tableData.id + 1
                },
                {
                  title: 'Source',
                  field: 'source',
                  cellStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },

                  headerStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => {
                    let data = rowData[0]
                      ? rowData[0].Company || ''
                      : rowData.Company || '';
                    let orgId = data.split('>')[0];
                    data = data.replace(orgId + '>', '');
                    const verified = data.startsWith('1-');
                    return (
                      <div
                        style={{
                          cursor: 'pointer',
                          // fontWeight: 500,
                          textTransform: 'capitalize',
                          // color: '#297bca'
                          // fontSize: '.85rem',
                          fontFamily: 'Roboto',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        onClick={() => {
                          props.history.push(
                            '/tracking/' +
                              props.match.params.type +
                              '/' +
                              rowData.source +
                              '/' +
                              selectedStartDate.toString() +
                              '/' +
                              selectedEndDate.toString()
                          );
                          // openUserPage(rowData.user_id);
                        }}>
                        {rowData.source}
                      </div>
                    );
                  }
                },
                {
                  title: 'Unique Clicks',
                  field: 'uniqueClicks'
                },
                {
                  title: 'Total Clicks',
                  field: 'count'
                }
              ]
        }
        data={data}
        totalCount={totalCount}
        page={page}
        title=""
        onChangePage={(e) => {
          console.log('Changing page - ' + e);
          setPage(e);
        }}
        onChangeRowsPerPage={(e) => setLimit(e)}
        options={{
          search: false,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions:
            props.match.params.type === 'active_member'
              ? [20]
              : [10, 20, 30, 40, 50],
          sorting: false
        }}
      />
    </div>
  );
}
