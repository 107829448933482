import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import { selectoptions } from '../../../../config';
import TotalUsers from '../TotalUsers';
import moment from 'moment';
import 'react-dropdown/style.css';
import { sendHttpCall, Toast } from 'shareService/httpService';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { margin: 8 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right'
  },
  space: {
    margin: 2
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem('gcoins-period')
      ? localStorage.getItem('gcoins-period')
      : 'Yesterday'
  );
  let end = new Date();
  let start = new Date();
  switch (selectedPeriod) {
    case 'All time':
      start = new Date(2019, 0, 1);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Last Year':
      start.setDate(start.getDate() - 365);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Last 30 days':
      start.setDate(start.getDate() - 30);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Last 7 days':
      start.setDate(start.getDate() - 7);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Yesterday':
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      localStorage.removeItem('gcoins-period');
      break;
    default:
      localStorage.removeItem('gcoins-period');
  }

  // const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(true);
  const [compData, setCompData] = useState({});
  const [surveyCount, setSurveyCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [linkClickCount, setLinkClickCount] = useState(0);
  const [surveyDownloadCount, setSurveyDownloadCount] = useState(0);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState('2019-02-01');
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [okdate, setOkdate] = useState(false);
  var reqBody = {
    start_date: moment(new Date(start)).format('YYYY-MM-DD'),
    end_date: moment(new Date(end)).format('YYYY-MM-DD'),
    city: selectedCity,
    gender: selectedGender
  };

  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    // Perform your search or fetch action here using the newDateRange
    reqBody.start_date = moment(new Date(newDateRange.startDate)).format(
      'YYYY-MM-DD'
    );
    reqBody.end_date = moment(new Date(newDateRange.endDate)).format('YYYY-MM-DD');
    fetchAllData();
    console.log('Selected Date Range:', newDateRange.startDate);
  };
  
  //_______________________________________________
  const fetchCompleteData = async () => {
    setLoading(true);
    let yesterday = false;
    let date = new Date();

    date = date.setDate(date.getDate() - 1);
    date = moment(date).format('YYYY-MM-DD');
    let additionalQuery =
      '?start_date=' + reqBody.start_date + '&end_date=' + reqBody.end_date;
    let response = await sendHttpCall(
      'GET',
      'dashboard/uk' + additionalQuery,
      null,
      props
    );

    if (response) {
      setCompData(response);
      setLoading(false);
    }
  };
  //_______________________________________________
  async function fetchAllData() {
    fetchCompleteData();
  }

  useEffect(() => {
    fetchAllData();
    setSelectedStartDate(reqBody.start_date);
    setSelectedEndDate(reqBody.end_date);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item container justifyContent="flex-start">
          <DateRangePicker onDateChange={handleDateChange}/>
          </Grid>
        </Grid>

        <div className="dashboard-label">New User Sign Ups</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status3}
              loading={loading}
              Text="ACTIVE MEMBERS"
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/active_member-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.activeSocialProfile}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                localStorage.setItem(
                  'selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                props.history.push(`/search-social-user?country=USA`);
              }}
              Text="SOCIAL USERS"
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status1}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status1-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="Status 1 Users"
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.blockedUsers}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/blocked-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="Blocked Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={compData.deactivatedUsers}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/deactivated-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="Deactivated Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status4}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status4-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="Status 4 Users"
            />
          </Grid>
        </Grid>
        <div className="dashboard-label">Status 2 Users</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.status2}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="All Status 2 Users"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.hirerStatus2}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2Hirer-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="Hirer Status 2"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.memberStatus2}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/status2Member-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="Job Seeker Status 2"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.onboardingStarted}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                localStorage.setItem(
                  'selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                props.history.push(`/incomplete-social-user?country=USA`);
              }}
              Text="Social STATUS 2"
            />
          </Grid>
        </Grid>

        <div className="dashboard-label">Hirers Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.totalHirer}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/active_hirers-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="TOTAL HIRERS"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.verifiedHirer}
              loading={loading}
              onClick={() => {
                localStorage.setItem('period', selectedPeriod);
                props.history.push(
                  `/data/verified_hirers-country/${selectedPeriod}/${moment(
                    selectedStartDate
                  ).format('YYYY-MM-DD')}/${moment(selectedEndDate).format(
                    'YYYY-MM-DD'
                  )}?countryCode=+44`
                );
              }}
              Text="VERIFIED HIRERS"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
