import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sendHttpCall } from 'shareService/httpService';
// import { UsersToolbar } from '../components';
import MaterialTable from 'material-table';
import './style.css';
import ReactSelect from 'react-select';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const MarketingCampaigns = (props) => {
  //   const [users, setUsers] = useState([]);
  //   const [filterByName, setFilterByName] = useState('');
  //   const [sortingData, setSortingData] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState({
    label: 'Invite',
    value: 'invite'
  });
  const [loading1, setLoading1] = useState(false);
  const [options, setOptions] = useState([]);
  const [enableDisableCampaign, setEnableDisableCampaign] = useState('');
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem('campaign-selectedOption')
      ? JSON.parse(localStorage.getItem('campaign-selectedOption'))
      : {
          value: '',
          label: ''
        }
  );
  //   const [totalCount, setTotalCount] = useState(0);
  //   const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const classes = useStyles();

  const getCampaignTypes = () => {
    setLoading1(true);
    let url = '/marketing_campaign/get_types';
    sendHttpCall('GET', url, null, props).then((response) => {
      if (response.state === 200) {
        console.log(response.data);
        let options_ = response.data.map((a) => ({
          value: a.marketing_campaign_type,
          label: a.marketing_campaign_type
        }));
        setOptions(options_);
        if (!selectedOption.label) {
          setSelectedOption(options_[0]);
        }
        setLoading1(false);
      }
    });
  };
  const handleTypeChange = (option) => {
    setSelectedOption(option);
  };
  useEffect(() => {
    getDataSpecific(selectedOption.value);
  }, [selectedOption, filterType.value]);

  useEffect(() => {
    getCampaignTypes();
    // getData();
  }, []);
  const getDataSpecific = (type) => {
    if (!type) return;
    setLoading(true);
    let url = 'marketing_campaign/get_specific';
    sendHttpCall('POST', url, { type, filter: filterType.value }, props).then(
      (response) => {
        setData(response.data);
        localStorage.removeItem('campaign-selectedOption');
        //   setTotalCount(response.totalCount);
        setLoading(false);
      }
    );
  };
  const handleToggleStatus = (value) => {
    let url = '/marketing_campaign/change_campaign_status';
    let status =
      data.find((a) => a.marketing_campaign_name === value).status === 'ACTIVE';
    sendHttpCall(
      'POST',
      url,
      {
        marketing_campaign_name: value,
        status: status ? 'INACTIVE' : 'ACTIVE'
      },
      props
    )
      .then((response) => {
        setEnableDisableCampaign('');
        getDataSpecific(selectedOption.value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const archiveCampaign = (value) => {
    let url = '/marketing_campaign/archive_campaign';

    sendHttpCall(
      'POST',
      url,
      {
        marketing_campaign_name: value
      },
      props
    )
      .then((response) => {
        setEnableDisableCampaign('');
        getDataSpecific(selectedOption.value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };
  const getRedirectValue = (value) => {
    switch (value) {
      case 'profile':
        return 'Profile Page';
      case 'feedback':
        return 'Feedback Page';
      case 'notifications':
        return 'Notifications';
      case 'gcoins':
        return 'GCoins';
      case 'chats':
        return 'Chats Page';
      case 'my_job_offers':
        return 'My Job Offers';
      case 'hire':
        return 'Hire Page';
      case 'hirer':
        return 'Recruiter Landing Page';
      case 'jobs':
      case 'job':
        return 'Jobs Page';
      default:
        return '';
    }
  };
  return (
    <div className={classes.root}>
      <div className="marketingCampaign__title">
        Marketing Campaigns Manager
      </div>
      <div className="marketingCampaign__row">
        <div className="marketingCampaign__text">Choose Campaign Platform</div>
        <div className="marketingCampaign__select">
          <ReactSelect
            options={options}
            isLoading={loading1}
            value={selectedOption}
            onChange={handleTypeChange}
          />
        </div>
      </div>
      <div className="marketingCampaign__row">
        <div className="marketingCampaign__subtext">Filter Campaign Type</div>
        <div className="marketingCampaign__subselect">
          <ReactSelect
            options={[
              {
                label: 'All',
                value: 'all'
              },
              {
                label: 'Invite',
                value: 'invite'
              },
              {
                label: 'Redirect',
                value: 'redirect'
              }
            ]}
            value={filterType}
            onChange={(e) => {
              setFilterType(e);
            }}
          />
        </div>
      </div>
      <div className={classes.content}>
        <MaterialTable
          columns={[
            {
              title: 'S No.',
              render: (rowData) => rowData.tableData.id + 1
            },
            {
              title: 'Campaign Name',
              field: 'marketing_campaign_name',
              render: (rowData) => (
                <div style={{ cursor: 'pointer' }}>
                  {rowData.marketing_campaign_name.substring(
                    selectedOption.value.length + 1
                  )}
                </div>
              )
            },
            {
              title: 'Campaign Link',
              field: 'marketing_campaign_link',
              render: (rowData) => (
                <a
                  href={rowData.marketing_campaign_link}
                  className="campaign_link"
                  target="_blank">
                  {rowData.marketing_campaign_link}
                </a>
              )
            },
            {
              title: 'Campaign Link_web',
              field: 'marketing_campaign_link_web',
              render: (rowData) => (
                <a
                  href={rowData.marketing_campaign_link_web}
                  className="campaign_link"
                  target="_blank">
                  {rowData.marketing_campaign_link_web}
                </a>
              )
            },
            {
              title: 'Campaign Type',
              field: 'link_type',
              render: (rowData) =>
                rowData.link_type === 'redirect' ? 'Redirect' : 'Invite'
            },
            {
              title: 'Redirect TO',
              field: 'redirect_to',
              render: (rowData) =>
                rowData.link_type === 'redirect'
                  ? getRedirectValue(rowData.redirect_to)
                  : ''
            },
            {
              title: 'Specific Job',
              field: 'specificJobPost',
              render: (rowData) =>
                rowData.specificJobPost ? rowData.specificJobPost : ''
            },
            {
              title: 'No Of SignUps',
              field: 'NumberOfUsers'
            },

            {
              title: 'No Of Clicks',
              field: 'NumberOfClicks',
              render: (rowData) => (
                <div
                  onClick={() => {
                    localStorage.setItem(
                      'campaign-selectedOption',
                      JSON.stringify(selectedOption)
                    );
                    localStorage.setItem(
                      'link-selectedOption',
                      JSON.stringify(selectedOption)
                    );
                    localStorage.setItem(
                      'link-selectedOption2',
                      JSON.stringify({
                        label: rowData.marketing_campaign_name.substring(
                          selectedOption.value.length + 1
                        ),
                        value: rowData.marketing_campaign_id,
                        redirect_to: rowData.redirect_to
                      })
                    );
                    props.history.push('/marketing-campaigns-clicks');
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.link_type === 'redirect'
                    ? rowData.NumberOfClicks
                    : ''}
                </div>
              )
            },
            {
              title: 'No Of Unique Clicks',
              field: 'NumberOfUniqueClicks'
            },

            {
              title: 'No Of Full Users',
              field: 'NumberOfFullUsers'
            },

            {
              title: 'Created At',
              field: 'created_at',
              render: (rowData) => getLocaleTime(rowData.created_at)
            },
            // {
            //   title: 'Updated At',
            //   field: 'updated_at',
            //   render: (rowData) => getLocaleTime(rowData.updated_at)
            // },
            {
              title: 'Status',
              field: 'status'
            },
            {
              title: 'Action',
              render: (rowData) => (
                <button
                  className="disable_enable"
                  onClick={() => {
                    setEnableDisableCampaign(rowData.marketing_campaign_name);
                    // archiveCampaign(rowData.marketing_campaign_name);
                  }}>
                  Archive
                  {/* {rowData.status === 'ACTIVE' ? 'Disable' : 'Enable'} */}
                </button>
              )
            }
          ]}
          data={data}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20],
            // pageSizeOptions: [5, 10, 20, 30],
            sorting: false
          }}
          title={selectedOption.value + ' Marketing Campaigns'}
          isLoading={loading}
          style={{ zIndex: 0 }}
        />
        {/* <UsersTable users={users}/> */}
      </div>
      <ReactModal
        isOpen={enableDisableCampaign}
        overlayClassName="overlay"
        onRequestClose={() => {
          setEnableDisableCampaign('');
        }}
        className="modal">
        <div className="campaign_modal">
          <div className="campaign_modal_text">
            Are you sure you want to archive {enableDisableCampaign}?
          </div>
          <div className="campaign_modal_buttons">
            <button
              className="campaign_modal_buttons-cancel"
              onClick={() => {
                setEnableDisableCampaign('');
              }}>
              Cancel
            </button>
            <button
              className="campaign_modal_buttons-action"
              onClick={() => archiveCampaign(enableDisableCampaign)}>
              Continue
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default MarketingCampaigns;
