import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sendHttpCall } from 'shareService/httpService';
// import { UsersToolbar } from '../components';
import MaterialTable from 'material-table';
import './style.scss';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ReactSelect from 'react-select';
import _ from 'lodash';
let incStyle = {
  fontWeight: '700',
  fontSize: '1.05rem',
  color: '#00e25a',
  textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
};
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));
const getLocaleTime = (time) => {
  if (time) return new Date(time).toLocaleString();
  else return '';
};
const getRedirectValue = (value) => {
  switch (value) {
    case 'profile':
      return 'Profile Page';
    case 'feedback':
      return 'Feedback Page';
    case 'notifications':
      return 'Notifications';
    case 'gcoins':
      return 'GCoins';
    case 'chats':
      return 'Chats Page';
    case 'my_job_offers':
      return 'My Job Offers';
    case 'hire':
      return 'Hire Page';
    case 'jobs':
      return 'Jobs Page';
    default:
      return '';
  }
};
const getParamName = (val) => {
  switch (val) {
    case 'profile':
      return 'Percentage On Click';
    default:
      return '';
  }
};
const UsersByCampaigns = (props) => {
  //   const [users, setUsers] = useState([]);
  //   const [filterByName, setFilterByName] = useState('');
  //   const [sortingData, setSortingData] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(15);
  const [lastPage, setLastPage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem('link-selectedOption')
      ? JSON.parse(localStorage.getItem('link-selectedOption'))
      : {
          value: '',
          label: ''
        }
  );
  const [selectedOption2, setSelectedOption2] = useState(
    localStorage.getItem('link-selectedOption2')
      ? JSON.parse(localStorage.getItem('link-selectedOption2'))
      : {
          value: '',
          label: ''
        }
  );
  //   const [totalCount, setTotalCount] = useState(0);
  //   const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const classes = useStyles();

  const getCampaignTypes = () => {
    setLoading1(true);
    let url = '/marketing_campaign/get_types?type=redirect';
    sendHttpCall('GET', url, null, props).then((response) => {
      if (response.state === 200) {
        console.log(response.data);
        let options_ = response.data.map((a) => ({
          value: a.marketing_campaign_type,
          label: a.marketing_campaign_type
        }));
        setOptions(options_);
        if (!selectedOption.label) setSelectedOption(options_[0]);
        setLoading1(false);
      }
    });
  };
  const handleTypeChange = (option) => {
    setSelectedOption(option);
  };
  const handleTypeChange2 = (option) => {
    setSelectedOption2(option);
  };
  const getDataSpecific = (type) => {
    if (!type) return;
    setLoading(true);
    let url = 'marketing_campaign/get_specific_redirect';
    sendHttpCall('POST', url, { type }, props).then((response) => {
      let options_ = response.data.map((a) => ({
        label: a.marketing_campaign_name.substring(type.length + 1),
        value: a.marketing_campaign_id,
        redirect_to: a.redirect_to
      }));
      console.log(options);
      setOptions2(options_);
      if (!localStorage.getItem('link-selectedOption2') && options_.length)
        setSelectedOption2(options_[0]);
      localStorage.removeItem('link-selectedOption2');
      localStorage.removeItem('link-selectedOption');
      //   setTotalCount(response.totalCount);
      setLoading(false);
    });
  };
  useEffect(() => {
    getDataSpecific(selectedOption.value);
  }, [selectedOption]);
  useEffect(() => {
    console.log('hello');
    if (selectedOption2.value) getData();
  }, [selectedOption2.value, limit, page]);
  const goToFirstPage = () => {
    if (page > 0) setPage(0);
  };
  const goToPrevPage = () => {
    if (page > 0) setPage(page - 1);
  };
  const goToLastPage = () => {
    if (page < lastPage) setPage(lastPage);
  };
  const goToNextPage = () => {
    if (page < lastPage) setPage(page + 1);
  };
  useEffect(() => {
    getCampaignTypes();
  }, []);
  const getData = () => {
    console.log('gettingData');
    let url = '/marketing_campaign/get_specific_redirect_clicks';
    setLoading2(true);
    sendHttpCall(
      'POST',
      url,
      { marketing_campaign_id: selectedOption2.value, limit, page: page },
      props
    ).then((response) => {
      setData(response.data.data);
      setTotalCount(response.data.count);
      let lastPage = Math.ceil(response.data.count / limit) - 1;
      setLastPage(lastPage > -1 ? lastPage : 0);
      setLoading2(false);
    });
  };

  return (
    <div className={classes.root}>
      <div className="marketingCampaign__title">
        Marketing Campaigns Manager - Remarketing Links
      </div>
      <div className="marketingCampaign__row">
        <div className="campaignText">Choose Campaign Type</div>
        <div className="marketingCampaign__select">
          <ReactSelect
            options={options}
            isLoading={loading1}
            value={selectedOption}
            onChange={handleTypeChange}
          />
        </div>
        <div className="campaignText">Choose Campaign</div>
        <div className="marketingCampaign__select">
          <ReactSelect
            options={options2}
            isLoading={loading1}
            value={selectedOption2}
            onChange={handleTypeChange2}
          />
        </div>
      </div>
      <div className="customtable__super">
        <div className="customtable__title">
          {'Users from ' +
            selectedOption2.label +
            ' campaign. Redirecting to ' +
            getRedirectValue(selectedOption2.redirect_to) +
            '. Total Clicks - ' +
            totalCount}
        </div>
        <div className="customtable__container">
          <div className="customtable__column">
            <div className="customtable__column-header">S No.</div>
            <div className="customtable__column-body">
              {new Array(data.length).fill('').map((a, index) => (
                <div className="customtable__column-body-item">
                  {index + 1 + page * limit}.
                </div>
              ))}
            </div>
          </div>
          <div className="customtable__column">
            <div className="customtable__column-header">Name</div>
            <div className="customtable__column-body">
              {data.map((a) => (
                <div
                  className="customtable__column-body-item"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    localStorage.setItem(
                      'link-selectedOption',
                      JSON.stringify(selectedOption)
                    );
                    localStorage.setItem(
                      'link-selectedOption2',
                      JSON.stringify(selectedOption2)
                    );
                    props.history.push(`/account/${a.user_id}`);
                  }}>
                  {a.name}
                </div>
              ))}
            </div>
          </div>
          <div className="customtable__column-grouped">
            <div className="customtable__column-header-grouped-main">
              Parameters On Click
            </div>
            <div className="customtable__column-header-grouped-others">
              <div className="customtable__column-column">
                <div className="customtable__column-header">Profile %</div>
                <div className="customtable__column-body">
                  {data.map((a) => (
                    <div className="customtable__column-body-item customtable__column-body-item-center">
                      {a.percentageBefore}
                    </div>
                  ))}
                </div>
              </div>
              <div className="customtable__column-column">
                <div className="customtable__column-header">F Rec</div>
                <div className="customtable__column-body">
                  {data.map((a) => (
                    <div className="customtable__column-body-item customtable__column-body-item-center">
                      {a.feedbackReceivedBefore}
                    </div>
                  ))}
                </div>
              </div>
              <div className="customtable__column-column">
                <div className="customtable__column-header">F Giv</div>
                <div className="customtable__column-body">
                  {data.map((a) => (
                    <div className="customtable__column-body-item customtable__column-body-item-center">
                      {a.feedbackGivenBefore}
                    </div>
                  ))}
                </div>
              </div>
              <div className="customtable__column-column">
                <div className="customtable__column-header">F Req</div>
                <div className="customtable__column-body">
                  {data.map((a) => (
                    <div className="customtable__column-body-item customtable__column-body-item-center">
                      {a.feedbackRequestedBefore}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="customtable__column-grouped">
            <div className="customtable__column-header-grouped-main">
              Parameters Now
            </div>
            <div className="customtable__column-header-grouped-others">
              <div className="customtable__column-column">
                <div className="customtable__column-header">Profile %</div>
                <div className="customtable__column-body">
                  {data.map((a) => {
                    const { percentageNow, percentageBefore } = a;
                    let percentageInc =
                      parseInt(percentageNow) > parseInt(percentageBefore);
                    return (
                      <div
                        className="customtable__column-body-item customtable__column-body-item-center"
                        style={percentageInc ? incStyle : null}>
                        {a.percentageNow}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="customtable__column-column">
                <div className="customtable__column-header">F Rec</div>
                <div className="customtable__column-body">
                  {data.map((a) => {
                    const { feedbackReceivedBefore, feedbackReceivedNow } = a;
                    let receivedInc =
                      parseInt(feedbackReceivedNow) >
                      parseInt(feedbackReceivedBefore);
                    return (
                      <div
                        className="customtable__column-body-item customtable__column-body-item-center"
                        style={receivedInc ? incStyle : null}>
                        {a.feedbackReceivedNow}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="customtable__column-column">
                <div className="customtable__column-header">F Giv</div>
                <div className="customtable__column-body">
                  {data.map((a) => {
                    const { feedbackGivenBefore, feedbackGivenNow } = a;
                    let givenInc =
                      parseInt(feedbackGivenNow) >
                      parseInt(feedbackGivenBefore);
                    return (
                      <div
                        style={givenInc ? incStyle : null}
                        className="customtable__column-body-item customtable__column-body-item-center">
                        {a.feedbackGivenNow}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="customtable__column-column">
                <div className="customtable__column-header">F Req</div>
                <div className="customtable__column-body">
                  {data.map((a) => {
                    const { feedbackRequestedBefore, feedbackRequestedNow } = a;
                    let requestedInc =
                      parseInt(feedbackRequestedNow) >
                      parseInt(feedbackRequestedBefore);
                    return (
                      <div
                        style={requestedInc ? incStyle : null}
                        className="customtable__column-body-item customtable__column-body-item-center">
                        {a.feedbackRequestedNow}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="customtable__column">
            <div className="customtable__column-header">Device Type</div>
            <div className="customtable__column-body">
              {data.map((a) => (
                <div className="customtable__column-body-item">
                  {a.device_type}
                </div>
              ))}
            </div>
          </div>{' '}
          <div className="customtable__column">
            <div className="customtable__column-header">Clicked On</div>
            <div className="customtable__column-body">
              {data.map((a) => (
                <div className="customtable__column-body-item">
                  {getLocaleTime(a.created_at)}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="customtable__paginate">
          <div
            className={
              'customtable__paginate-first ' +
              (page === 0 ? 'customtable__paginate-first-disabled' : '')
            }
            onClick={goToFirstPage}>
            <FirstPageIcon />
          </div>
          <div
            className={
              'customtable__paginate-prev ' +
              (page === 0 ? 'customtable__paginate-first-disabled' : '')
            }
            onClick={goToPrevPage}>
            <ChevronLeftIcon />
          </div>
          <div className="customtable__paginate-data">{`${
            page * limit + 1
          } - ${Math.min(
            (page + 1) * limit,
            totalCount
          )} of ${totalCount}`}</div>
          <div
            className={
              'customtable__paginate-next ' +
              (page === lastPage ? 'customtable__paginate-first-disabled' : '')
            }
            onClick={goToNextPage}>
            <ChevronRightIcon />
          </div>
          <div
            className={
              'customtable__paginate-last ' +
              (page === lastPage ? 'customtable__paginate-first-disabled' : '')
            }
            onClick={goToLastPage}>
            <LastPageIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersByCampaigns;
