/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';
import MaterialTable, { MTablePagination } from 'material-table';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import ReactModal from 'react-modal';
import countries from '../ReportGenerator/countries';
import './style.scss';
import ReactSelect from 'react-select';
import { salesStages } from '../../../../config'

const getBloodGroupFromId = (id) => {
  const map = {
    0: 'Any',
    1: "I don't know",
    2: 'A+',
    3: 'A-',
    4: 'B+',
    5: 'B-',
    6: 'AB+',
    7: 'AB-',
    8: 'O+',
    9: 'O-'
  };
  return map[id] || 'Any';
};

export default function EveryTable(props) {
  const convertToYearly = (monthlyVal) => {
    switch (monthlyVal) {
      case '₹ 10k - 25k':
      case '₹10k - 25k':
        return '₹ 1.2L - 3L';
      case '₹ 25k - 50k':
      case '₹25k - 50k':
        return '₹ 3L - 6L';
      case '₹ 50k - 75k':
        return '₹ 6L - 9L';
      case '₹ 75k - 100k':
        return '₹ 9L - 12L';
      case '₹ 100k - 150k':
        return '₹ 12L - 18L';
      case '₹ 150k - 200k':
        return '₹ 18L - 24L';
      case '₹ 200k - 300k':
        return '₹ 24L - 36L';
      case '₹ 300k - 500k':
        return '₹ 36L - 60L';
      case '>  ₹ 500k':
        return '> ₹ 60L';
      default:
        return monthlyVal;
    }
  };
  const toggleVerification = (id, userId, verified) => {
    sendHttpCall(
      'POST',
      'users/user_hiring_organisation/update_verification_status',
      {
        user_hiring_organisation_id: id,
        user_id: userId,
        status: verified
      },
      props
    ).then((res) => {
      const d = [...data];

      const index = id
        ? d.findIndex((i) => i.uorg_id === id)
        : d.findIndex((i) => i.user_id === userId);
      if (index !== -1) {
        const item = { ...d[index] };
        console.log(item);
        if (verified && verified === 'VERIFIED') {
          item.Company = item.Company?.replace('>0-', '>1-');
          item.verifiedHirer = verified;
        } else {
          item.Company = item.Company?.replace('>1-', '>0-');
          item.verifiedHirer = verified;
        }
        console.log(item);
        d[index] = item;
        setData(d);
      }
    });
  };
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const getTimeIn12Hours = (date) => {
    const hour = date.getHours();
    return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
      date.getMinutes()
    )} ${hour > 12 ? 'PM' : 'AM'}`;
  };
  const formatDate = (date, variation = 1) =>
    `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
      date.getMonth() + 1
    )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

  const formatTime = (time) => {
    const date = new Date(time);

    return getTimeIn12Hours(date) + ', ' + formatDate(date);
  };

  const [selectedJob, selectJob] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 20
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [bloodDonorRequestId, setBloodDonorRequestId] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getLocaleTime = (time) => {
    if (time) {
      if (props.match.params.type === 'active_member')
        return moment(new Date(time + ' UTC')).format('DD/MM/YYYY h:mm:ss A');
      return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    }
    return '';
  };
  const startDate = useRef(
    props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
  );
  const endDate = useRef(
    props.match.params.end_date
      ? props.match.params.end_date
      : moment(new Date()).format('YYYY-MM-DD')
  );
  const exportEveryTableData = () => {
    let { type } = props.match.params;
    const countryCode = window.location.href.split('?countryCode=')[1];

    let url =
      'dashboard/exportEveryTableData?type=' +
      type +
      (type === 'by_country' ? '&dialCode=' + props.match.params.period : '') +
      (type === 'device_type'
        ? '&device_type=' + props.match.params.period
        : '') +
      (type === 'by_campaign' || type === 'by_campaign_status_2' || type === 'by_campaign_hirer_s3'
        ? '&campaignName=' + props.match.params.period
        : '') +
       '&limit=1000' +
       
     // limit +
      '&page=' +
      page +
      `&start_date=${startDate.current.toString()}&end_date=${endDate.current.toString()}&countryCode=${countryCode?.replace(
        '+',
        ''
      )}`;
    sendHttpCallBlob('GET', url, null, props)
      .then(async (blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `export${new Date().getTime()}.csv`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportDataToCSV = () => {
    let url =
      props.match.params.type === 'by_campaign'
        ? 'users/getCampaignUserDataInCsv'
        : props.match.params.type === 'active_member'
        ? 'users/getUserDataInCsv'
        : 'dashboard/exportEveryTableData';
    if (url === 'dashboard/exportEveryTableData') return exportEveryTableData();
    let data = {
      start_date: startDate.current.toString(),
      end_date: endDate.current.toString()
    };
    if (props.match.params.type === 'by_campaign') {
      data = { ...data, campaignName: props.match.params.period };
    }
    if (props.match.params.type === 'active_member-country') {
      const countryCode = window.location.href.split('?countryCode')[1];
      data = { ...data, countryCode: countryCode.replace('+', '') };
    }
    sendHttpCallBlob('POST', url, data, props)
      .then(async (blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download =
          props.match.params.type === 'by_campaign'|| props.match.params.type === 'by_campaign_hirer_s3'
            ? `usersFrom${props.match.params.period}Campaign.csv`
            : `usersFrom${props.match.params.period}${
                props.match.params.type === 'active_member-country'
                  ? '-country'
                  : ''
              }.csv`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openUserPage = (userId, social) => {
    sessionStorage.setItem('everyTablePage', page);
    sessionStorage.setItem('everyTableLimit', limit);
    props.history.push(`/account/${userId}${social ? '?type=SOCIAL' : ''}`);
  };

  const openJobPage = (offerId) => {
    props.history.push(`/job/${offerId}`);
  };
  const openJobApplyClicks = (offerId, unique) => {
    sessionStorage.setItem('everyTablePage', page);
    sessionStorage.setItem('everyTableLimit', limit);
    props.history.push(
      `/redirect_jobs_clicks/${unique ? 'unique' : 'all'}/${offerId}`
    );
  };
  const openReportedJobPage = (offerId) => {
    sessionStorage.setItem('everyTablePage', page);
    sessionStorage.setItem('everyTableLimit', limit);
    props.history.push(`/reports/jobs/${offerId}`);
  };
  const userColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'Name',
      cellStyle: {
        position: 'sticky',
        left: 40,
        backgroundColor: '#FFF'
      },

      headerStyle: {
        position: 'sticky',
        left: 40,
        backgroundColor: '#FFF'
      },
      render: (rowData) => {
        let data = rowData[0]
          ? rowData[0].Company || ''
          : rowData.Company || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        const verified =
          data.startsWith('1-') ||
          rowData.verifiedHirer === 'VERIFIED' ||
          rowData[0]?.verifiedHirer === 'VERIFIED';
        return (
          <div
            style={{
              cursor: 'pointer',
              // fontWeight: 500,
              textTransform: 'capitalize',
              // color: '#297bca'
              // fontSize: '.85rem',
              fontFamily: 'Roboto',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              openUserPage(rowData.UserId);
            }}>
            {rowData.Name}

            {verified ? (
              <div style={{ marginLeft: '.25rem', color: '#297bca' }}>
                {' '}
                <CheckIcon />{' '}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }
    },
    {
      title: 'Phone',
      field: 'MobileNumber',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => rowData.MobileNumber
    },
    {
      title: 'Age',
      field: 'Age',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => rowData.Age
    },
    {
      title: 'Location',
      field: 'City',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => rowData.City
    },
    {
      title: "Promo Code",
      field: "Promo Code",
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
    },
    // {
    //   title: 'Hiring Profile',
    //   field: 'organisation',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => {
    //     let data = rowData[0]
    //       ? rowData[0].Company || ''
    //       : rowData.Company || '';
    //     let orgId = data.split('>')[0];
    //     data = data.replace(orgId + '>', '');
    //     if (data.startsWith('1-')) data = data.replace('1-', '');
    //     if (data.startsWith('0-')) data = data.replace('0-', '');
    //     if (data.startsWith(' at')) data = data.replace(' at', '');
    //     if (data && data !== ' ') return data;
    //     if (orgId === '-2') return 'Undisclosed';
    //     return 'Self';
    //   }
    // },
    // {
    //   title: 'Contacts',
    //   field: 'NoOfContacts',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.NoOfContacts
    // },
    // {
    //   title: 'GCoins',
    //   field: 'GCoins',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.GCoins
    // },
    // {
    //   title: 'Goodness Score',
    //   field: 'GoodnessScore',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.GoodnessScore
    // },
    // {
    //   title: 'Users Invited',
    //   field: 'NoOfUsersInvited',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.NoOfUsersInvited
    // },
    // {
    //   title: 'Payment Plan',
    //   field: 'AccountTypeId',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => (rowData.AccountTypeId === 1 ? 'Free' : 'Paid')
    // },
    // { title: 'Payment Plan', field: 'NoOfContacts' },
    {
      title: 'Last Session',
      field: 'LastLoginTime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      type: 'datetime',
      render: (rowData) =>
        getLocaleTime(
          new Date(rowData.LastSessionTime) > new Date(rowData.UpdatedAtTime)
            ? rowData.LastSessionTime
            : rowData.UpdatedAtTime
        )
    },
    {
      title: 'Registration Date',
      field: 'JoinedOn',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.JoinedOn)
    },
    {
      title: 'Invited By',
      field: 'Source',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => rowData.Source
    },
    {
      title: "Remark",
      field: "remark",
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      }
    }
    // {
    //   title: 'Profile Completed (%)',
    //   field: 'PercentageComplete',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.PercentageComplete
    // },
    // {
    //   title: 'Feedback Given',
    //   field: 'FeedbackGiven',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.FeedbackGiven
    // },
    // {
    //   title: 'Feedback Received',
    //   field: 'FeedbackRecieved',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.FeedbackRecieved
    // },
    // {
    //   title: 'Feedback Requested',
    //   // field: 'FeedbackRecieved',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) =>
    //     rowData.UserAnalyticsData
    //       ? rowData.UserAnalyticsData.requestFeedbackClickCount.count
    //       : 0
    // },
    // {
    //   title: 'Message Sent',
    //   field: 'MessagesSent',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.MessagesSent
    // },
    // {
    //   title: 'Message Received',
    //   field: 'MessagesRecieved',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.MessagesRecieved
    // },
    // {
    //   title: 'Jobs Posted',
    //   field: 'JobsPosted',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.JobsPosted
    // },
    // {
    //   title: 'Jobs Applied',
    //   field: 'JobsApplied',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.JobsApplied
    // },
    // {
    //   title: 'Offers Received',
    //   field: 'OffersRecieved',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.OffersRecieved
    // },
    // {
    //   title: 'Offer Accepted',
    //   field: 'OffersAccepted',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.OffersAccepted
    // },
    // {
    //   title: 'Offer Sent',
    //   field: 'OffersSent',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.OffersSent || 0
    // },
    // {
    //   title: 'Offer Pending',
    //   field: 'OffersPending',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.OffersPending
    // }

    // { title: 'Offer', field: 'created_at', type: 'date' }
  ];
  const activeHirersColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        position: 'sticky',
        left: 40,
        backgroundColor: '#FFF'
      },

      headerStyle: {
        position: 'sticky',
        left: 40,
        backgroundColor: '#FFF'
      },
      render: (rowData) => {
        let data = rowData[0]
          ? rowData[0].Company || ''
          : rowData.Company || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        const verified =
          data.startsWith('1-') ||
          rowData.verifiedHirer === 'VERIFIED' ||
          rowData[0]?.verifiedHirer === 'VERIFIED';
        return (
          <div
            style={{
              cursor: 'pointer',
              // fontWeight: 500,
              textTransform: 'capitalize',
              // color: '#297bca'
              // fontSize: '.85rem',
              fontFamily: 'Roboto',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              openUserPage(rowData.user_id);
            }}>
            {rowData.name}

            {verified ? (
              <div style={{ marginLeft: '.25rem', color: '#297bca' }}>
                {' '}
                <CheckIcon />{' '}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }
    },
    {
      title: 'Email Address',
      field: 'email_id'
    },
    {
      title: 'Promo',
      field: 'promo'
    },
    {
      title: 'Assigned To',
      field: 'assignedToAdminName'
    },
    {
      title: 'Sales Stage',
      field: 'sales_stage'
    },
    {
      title: 'Phone',
      field: 'mobile_number',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      }
    },
    {
      title: 'Remark',
      field: 'remark',
      cellStyle: {
        width: '40%', 
        minWidth: 200 
    },
    headerStyle: {
        width: '40%',
        minWidth: 200
    }
    },
    {
      title: 'Verified',

      render: (rowData) => {
        let data = rowData[0] ? rowData[0].Company || '' : rowData.Company || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        
        // Check if verified
        const verified = data.startsWith('1-') ||
                         rowData.verifiedHirer === 'VERIFIED' ||
                         rowData[0]?.verifiedHirer === 'VERIFIED';
        
        // Setting the selectValue based on verification status
        let selectValue = { value: 'Select', label: 'Select' };
        if (verified) {
          selectValue = { value: 'Yes', label: 'Yes' };
        } else {
          selectValue = { value: 'No', label: 'No' };  // Default to 'No' if not verified
        }
        
        // Return the component
        return (
          <div>
            <ReactSelect
              options={[
                { value: 'Select', label: 'Select' },
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                { value: 'RnR', label: 'RnR' }
              ]}
              value={selectValue}
              onChange={(e) => {
                // console.log(e);
                if (e.value === 'Yes') {
                  console.log('Verified');
                  toggleVerification(rowData.uorg_id, rowData.user_id, 'VERIFIED');
                } else if (e.value === 'No') {
                  console.log('Unverified');
                  toggleVerification(rowData.uorg_id, rowData.user_id, 'FAKE');
                } else if (e.value === 'RnR') {
                  toggleVerification(rowData.uorg_id, rowData.user_id, 'RnR');
                } else {
                  toggleVerification(rowData.uorg_id, rowData.user_id, 'Select');
                }
              }}
            />
          </div>
        );
      }
    },
    {
      title: 'Source',
      render: (rowData) => (
        <div
          style={{ cursor: rowData.invite_source_name ? 'pointer' : 'default' }}
          onClick={() => {
            if (rowData.invite_source_name) openUserPage(rowData.invite_source);
          }}>
          {rowData.invite_source_name ||
            rowData.invite_source?.replace('campaign_', '')}
        </div>
      )
    },
    {
      title: 'Location',
      field: 'city',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      }
    },
    {
      title: 'Hiring Profile',
      field: 'organisation',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => {
        let data = rowData[0]
          ? rowData[0].Company || ''
          : rowData.Company || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        if (data.startsWith('1-')) data = data.replace('1-', '');
        if (data.startsWith('0-')) data = data.replace('0-', '');
        if (data.startsWith(' at')) data = data.replace(' at', '');
        if (data && data !== ' ') return data;
        if (orgId === '-2') return 'Undisclosed';
        return 'Self';
      }
    },
    {
      title: 'Registration Date',
      field: 'joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.joined_on)
    }
    // {
    //   title: 'Invited By',
    //   field: 'Source',
    //   headerStyle: {
    //     position: 'static',
    //     backgroundColor: '#FFF'
    //   },
    //   render: (rowData) => rowData.Source
    // },
  ];
  const verifiedHirersColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        position: 'sticky',
        left: 40,
        backgroundColor: '#FFF'
      },

      headerStyle: {
        position: 'sticky',
        left: 40,
        backgroundColor: '#FFF'
      },
      render: (rowData) => {
        let data = rowData[0]
          ? rowData[0].Company || ''
          : rowData.Company || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        const verified =
          data.startsWith('1-') ||
          rowData.verifiedHirer === 'VERIFIED' ||
          rowData[0]?.verifiedHirer === 'VERIFIED';
        return (
          <div
            style={{
              cursor: 'pointer',
              // fontWeight: 500,
              textTransform: 'capitalize',
              // color: '#297bca'
              // fontSize: '.85rem',
              fontFamily: 'Roboto',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              openUserPage(rowData.user_id);
            }}>
            {rowData.name}

            {verified ? (
              <div style={{ marginLeft: '.25rem', color: '#297bca' }}>
                {' '}
                <CheckIcon />{' '}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }
    },
    {
      title: 'Phone',
      field: 'mobile_number',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      }
    },
    {
      title: 'Source',
      field: '',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (rowData.invite_source_name) openUserPage(rowData.invite_source);
          }}>
          {rowData.invite_source_name
            ? rowData.invite_source_name
            : rowData.invite_source}
        </div>
      )
    },
    {
      title: 'Location',
      field: 'city',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      }
    },
    {
      title: 'Hiring Profile',
      field: 'organisation',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => {
        let data = rowData[0]
          ? rowData[0].Company || ''
          : rowData.Company || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        if (data.startsWith('1-')) data = data.replace('1-', '');
        if (data.startsWith('0-')) data = data.replace('0-', '');
        if (data.startsWith(' at')) data = data.replace(' at', '');
        if (data && data !== ' ') return data;
        if (orgId === '-2') return 'Undisclosed';
        return 'Self';
      }
    },
    {
      title: 'Verified At',
      field: 'verified_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.verified_at)
    }
  ];
  const feedbackColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Rater User',
      field: 'raterUserName',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.raterUserId);
          }}>
          {rowData.raterUserName}
        </div>
      )
    },
    {
      title: 'Rater Status',
      field: 'raterStatus'
    },

    {
      title: 'Rated User',
      field: 'ratedUserName',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.ratedUserId);
          }}>
          {rowData.ratedUserName}
        </div>
      )
    },
    {
      title: 'Rated Status',
      field: 'ratedStatus'
    },
    {
      title: 'Avg Feedback',
      field: 'ratingValue'
    },

    {
      title: 'Given At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    }
  ];
  const jobsAppliedColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Job Title',
      render: (rowData) => (
        <div
          style={{ cursor: rowData.offerID ? 'pointer' : 'default' }}
          onClick={() => {
            if (rowData.offerID) openJobPage(rowData.offerID);
          }}>
          {rowData.title}
        </div>
      )
    },
     {
      title: 'Application Status',
      field: 'status',
     },
     {
      title: 'Interview Status',
      field: 'interview_status',
     }
  ];
  const activeHirersColumns2 = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile'
    },
    {
      title: 'Promo',
      field: 'promo'
    },
    {
      title: 'Email Address',
      field: 'email_id'
    },
    {
      title: 'Jobs Posts',
      field: 'jobPosted'
    },
    {
      title: 'Offer Sent',
      field: 'offerSent'
    }
  ];
  const inactiveHirersColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile'
    },
    {
      title: 'Job Posts',
      field: 'jobPosts'
    },
    {
      title: 'Total Applied',
      field: 'totalApplied'
    }
  ];
  const contactRequestForDemoColumn = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => {
        const isClickable = rowData.userId && rowData.userId !== 27; 
        const style = {
          cursor: isClickable ? 'pointer' : 'default',
          color: isClickable ? '#297bca' : 'inherit',
        }; 
        return (
          <div
            style={style}
            onClick={() => {
              if (isClickable) {
                openUserPage(rowData.userId);
              }
            }}>
            {rowData.name}
          </div>
        );
      }
    },
     
    {
      title: 'Mobile Number',
      field: 'phoneNumber'
    },
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Message',
      field: 'message'
    },
    {
      title: 'Created At',
      field: 'createdAt',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.createdAt)
    },
    {
      title: 'Company Name',
      field: 'companyName'
    },
    {
      title: 'type',
      field: 'type'
    },
  ];
  const userStatusColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },

    {
      title: 'Email Id',
      field: 'email_id'
    },

    {
      title: 'Age',
      field: 'age'
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    }
  ];

  const resumeDownloadedColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Status',
      field: 'status'
    },
    {
      title: 'Generated At',
      field: 'generated_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.generated_at)
    }
  ];
  const resumeAudioUploadedColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Status',
      field: 'status'
    },
    {
      title: 'Uploaded At',
      field: 'uploaded_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.uploaded_at)
    }
  ];
  const socialUsersDataColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Sent By',
      field: 'sentBy',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.senderUserId, true);
          }}>
          {rowData.sentBy}
        </div>
      )
    },
    {
      title: 'Received By',
      field: 'receivedBy',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.receiverUserId, true);
          }}>
          {rowData.receivedBy}
        </div>
      )
    },

    {
      title: 'Request Status',
      field: 'request_status',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => (
        <div
          style={
            rowData.request_status === 'ACCEPTED' ? { cursor: 'pointer' } : null
          }
          onClick={() => {
            if (rowData.request_status === 'ACCEPTED')
              props.history.push(
                `/chats/SOCIAL/${rowData.senderUserId}-${rowData.receiverUserId}`
              );
          }}>
          {rowData.request_status}
        </div>
      )
    },
    {
      title: 'Sent At',
      field: 'sentAt',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.sentAt)
    },
    {
      title: 'Responded At',
      field: 'respondedAt',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) =>
        rowData.request_status === 'REQUESTED'
          ? ''
          : getLocaleTime(rowData.respondedAt)
    }
  ];
  const status2SpecificColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },

    {
      title: 'Stuck On',
      field: 'stuckOn'
    },

    {
      title: 'Source',
      render: (rowData) => (
        <div
          style={{ cursor: rowData.invite_source_name ? 'pointer' : 'default' }}
          onClick={() => {
            if (rowData.invite_source_name) openUserPage(rowData.invite_source);
          }}>
          {rowData.invite_source_name ||
            rowData.invite_source?.replace('campaign_', '')}
        </div>
      )
    },
    {
      title: 'Registration Date',
      field: 'joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.joined_on)
    }
  ];
  const hireRequestColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile'
    },

    {
      title: 'Designation',
      field: 'designation'
    },
    {
      title: 'Company Name',
      field: 'organisation'
    },

    {
      title: 'Requested At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    }
  ];
  const status2HirerColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => {
        let data = rowData[0]
          ? rowData[0].Company || ''
          : rowData.Company || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        const verified =
          data.startsWith('1-') ||
          rowData.verifiedHirer === 'VERIFIED' ||
          rowData[0]?.verifiedHirer === 'VERIFIED';
        return (
          <div
            style={{
              cursor: 'pointer',
              // fontWeight: 500,
              textTransform: 'capitalize',
              // color: '#297bca'
              // fontSize: '.85rem',
              fontFamily: 'Roboto',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              openUserPage(rowData.user_id);
            }}>
            {rowData.name}

            {verified ? (
              <div style={{ marginLeft: '.25rem', color: '#297bca' }}>
                {' '}
                <CheckIcon />{' '}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number',
      render: (rowData) => {
        return (
          <div
            style={{
              cursor: 'pointer',
              color: '#297bca',
              fontWeight: 'bold',
              textDecoration: 'underline',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              openUserPage(rowData.user_id);
            }}
          >
            {rowData.mobile_number}
          </div>
        );
      }
    },
    {
      title: 'Verified',

      render: (rowData) => {
        let data = rowData[0]
          ? rowData[0].verifiedHirer
          : rowData.verifiedHirer;
        let verified = data;
        const selectValue =
          verified === null || verified === 'Select'
            ? { value: 'Select', label: 'Select' }
            : verified === 'VERIFIED'
            ? { value: 'Yes', label: 'Yes' }
            : verified === 'RnR'
            ? { value: 'RnR', label: 'RnR' }
            : { value: 'No', label: 'No' };
        console.log(verified);
        return (
          <div>
            <ReactSelect
              options={[
                { value: 'Select', label: 'Select' },
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                { value: 'RnR', label: 'RnR' }
              ]}
              value={selectValue}
              onChange={(e) => {
                console.log(e);
                if (e.value === 'Yes') {
                  console.log('Verified');
                  toggleVerification(
                    rowData.uorg_id,
                    rowData.user_id,
                    'VERIFIED'
                  );
                } else if (e.value === 'No') {
                  console.log('Unverified');
                  toggleVerification(rowData.uorg_id, rowData.user_id, 'FAKE');
                } else if (e.value === 'RnR') {
                  toggleVerification(rowData.uorg_id, rowData.user_id, 'RnR');
                } else
                  toggleVerification(
                    rowData.uorg_id,
                    rowData.user_id,
                    'Select'
                  );
              }}
            />
            {/* {verified ? 'Yes' : 'No'} */}
          </div>
        );
      }
    },
    {
      title: 'Stuck On',
      field: 'stuckOn'
    },
    {
      title: 'Remarks',
      field: 'remark',
      cellStyle: {
        width: '30%',
        minWidth: 200
      },
    },
    {
      title: 'Source',
      render: (rowData) => (
        <div
          style={{ cursor: rowData.invite_source_name ? 'pointer' : 'default' }}
          onClick={() => {
            if (rowData.invite_source_name) openUserPage(rowData.invite_source);
          }}>
          {rowData.invite_source_name ||
            rowData.invite_source?.replace('campaign_', '')}
        </div>
      )
    },
    {
      title: 'Registration Date',
      field: 'joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.joined_on)
    }
  ];
  const hirerResumeDownload = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => {
        let data = rowData[0] ? rowData[0].name || '' : rowData.name || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        const verified =
          data.startsWith('1-') ||
          rowData.verifiedHirer === 'VERIFIED' ||
          rowData[0]?.verifiedHirer === 'VERIFIED';
        return (
          <div
            style={{
              cursor: 'pointer',
              // fontWeight: 500,
              textTransform: 'capitalize',
              // color: '#297bca'
              // fontSize: '.85rem',
              fontFamily: 'Roboto',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              openUserPage(rowData.user_id);
            }}>
            {rowData.name}

            {verified ? (
              <div style={{ marginLeft: '.25rem', color: '#297bca' }}>
                {' '}
                <CheckIcon />{' '}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }
    },
    {
      title: 'resume-link',
      field: 'resumeLink',
      render: (rowData) => (
        <a
          href={rowData.resumeLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#297bca' }}>
          Download Resume
        </a>
      )
    },
    {
      title: 'file type',
      field: 'fileType'
    }
  ];
  const unassignedHirersStatus3 = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => {
        let data = rowData[0] ? rowData[0].name || '' : rowData.name || '';
        let orgId = data.split('>')[0];
        data = data.replace(orgId + '>', '');
        const verified =
          data.startsWith('1-') ||
          rowData.verifiedHirer === 'VERIFIED' ||
          rowData[0]?.verifiedHirer === 'VERIFIED';
        return (
          <div
            style={{
              cursor: 'pointer',
              // fontWeight: 500,
              textTransform: 'capitalize',
              // color: '#297bca'
              // fontSize: '.85rem',
              fontFamily: 'Roboto',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              openUserPage(rowData.user_id);
            }}>
            {rowData.name}

            {verified ? (
              <div style={{ marginLeft: '.25rem', color: '#297bca' }}>
                {' '}
                <CheckIcon />{' '}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Email Id',
      field: 'email_id'
    },
    {
      title: 'Company',
      field: 'organisation'
    },
    {
      title: 'Joined On',
      field: 'joined_on'
    },
  ];
  const blockedUsersColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Email Id',
      field: 'email_id'
    },
    {
      title: 'Blocked At',
      field: 'updated_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.updated_at)
    }
  ];
  const appFeedbacksColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Sentiment',
      field: 'sentiment'
    },
    {
      title: 'Feedback',
      field: 'feedback'
    },
    {
      title: 'Sent At',
      field: 'updated_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.updated_at)
    }
  ];
  const deactivatedUsersColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Feedback',
      field: 'feedback'
    },
    {
      title: 'Deactivated At',
      field: 'updated_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.updated_at)
    }
  ];

  const closedJobsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Hirer Name',
      field: 'namehirer_name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.hirer_id);
          }}>
          {rowData.hirer_name}
        </div>
      )
    },
    {
      title: 'Job Title',
      field: 'title'
    },
    {
      title: 'Job Description',
      field: 'description'
    },

    {
      title: 'No Of Applications',
      field: 'applications'
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    },
    {
      title: 'Closed At',
      field: 'closed_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.closed_at)
    }
  ];
  const userByCountriesColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },

    {
      title: 'Email Id',
      field: 'email_id'
    },
    {
      title: 'Registered At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    }
  ];
  const userByCampaignsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },

    {
      title: 'Email Id',
      field: 'email_id'
    },
    {
      title: 'User Verified',
      field: 'email_id',
      render: (rowData) => <div>{rowData.user_verified ? 'True' : 'False'}</div>
    },
    {
      title: 'Registered At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    }
  ];

  const hirersByCampaignsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        width: '10%',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        width: '10%',
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Hirer Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
          {/* {rowData.verified ? (
             
                <CheckIcon />
              
            ) : (
              ''
            )} */}
        </div>
      )
    },
     {
      title: 'Verified',
      field: 'verified',
      render: (rowData) => <div>{rowData.verified ? <CheckIcon />: "-"}</div>
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Sales Stage',
      field: 'sales_stage'
    },
    {
      title: 'Location',
      field: 'location_city'
    },
    // {
    //   title: 'Paid Hirer',
    //   field: 'paid_hirer',
    //   render: (rowData) => <div>{rowData.paid_hirer ? "True" : "False"}</div>
    // },
    // {
    //   title: 'Total Hirer Revenue',
    //   field: 'hirerTotalRevenue',
    //   render: (rowData) => <div>{rowData.paid_hirer ? rowData.hirerTotalRevenue  : "-"}</div>
    // },
    {
      title: 'Remarks',
      field: 'remark',
      cellStyle: {
          width: '40%', 
          minWidth: 200 
      },
      headerStyle: {
          width: '40%',
          minWidth: 200
      }
  },
    {
      title: 'Registered At',
      field: 'joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.joined_on)
    },
 
  ];
  const userByCampaignsColumns2 = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.mobile_number}
        </div>
      )
    },

    {
      title: 'Email Id',
      field: 'email_id'
    },

    {
      title: 'OTP Verified At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    }
  ];
  const surveyColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Survey Sent For',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Survey Sent To',
      field: 'contact_name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.contact_user_id);
          }}>
          {rowData.contact_name}
        </div>
      )
    },

    {
      title: 'Message Sent At',
      field: 'message_sent_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.message_sent_at)
    },
    {
      title: 'Delivery Status',
      field: 'message_delivery_success',

      render: (rowData) => (
        <div
          style={!rowData.message_delivery_success ? { color: 'red' } : null}>
          {rowData.message_delivery_success ? 'SUCCESS' : 'FAILED'}
        </div>
      )
    },
    {
      title: 'Link Clicked At',
      field: 'link_clicked_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.link_clicked_at)
    },
    {
      title: 'Survey Recieved At',
      field: 'survey_received_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.survey_received_at)
    }
  ];
  const linkClickColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Survey Sent For',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Survey Sent To',
      field: 'contact_name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.contact_user_id);
          }}>
          {rowData.contact_name}
        </div>
      )
    },

    {
      title: 'Message Sent At',
      field: 'message_sent_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.message_sent_at)
    },

    {
      title: 'Link Clicked At',
      field: 'link_clicked_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.link_clicked_at)
    },
    {
      title: 'Survey Recieved At',
      field: 'survey_received_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.survey_received_at)
    }
  ];
  const surveyConversionColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Survey Sent For',
      field: 'survey_sent_for',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.survey_sent_for_id);
          }}>
          {rowData.survey_sent_for}
        </div>
      )
    },

    {
      title: 'Registration Date',
      field: 'joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.joined_on)
    },
    {
      title: 'Message Sent At',
      field: 'message_sent_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.message_sent_at)
    },

    {
      title: 'Link Clicked At',
      field: 'link_clicked_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.link_clicked_at)
    },
    {
      title: 'Survey Recieved At',
      field: 'survey_received_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.survey_received_at)
    }
  ];
  const reportedJobsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Job Title',
      field: 'title',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            // openUserPage(rowData.user_id);
            selectJob(rowData);
          }}>
          {rowData.title}
        </div>
      )
    },
    {
      title: 'No of Reports',
      field: 'reported',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openReportedJobPage(rowData.offerId);
          }}>
          {rowData.reported}
        </div>
      )
    }
  ];
  const redirectJobClicksColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Job Title',
      field: 'title',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            // openUserPage(rowData.user_id);
            selectJob(rowData);
          }}>
          {rowData.title}
        </div>
      )
    },
    {
      title: 'No of Clicks',
      field: 'clicks',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openJobApplyClicks(rowData.offerId);
          }}>
          {rowData.clicks}
        </div>
      )
    },
    {
      title: 'No of Unique Clicks',
      field: 'uniqueClicks',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openJobApplyClicks(rowData.offerId, true);
          }}>
          {rowData.uniqueClicks}
        </div>
      )
    }
  ];

  const status4Columns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },

    {
      title: 'Registration Date',
      field: 'joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.joined_on)
    }
  ];
  const onboardingChatColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Email Id',
      field: 'email_id'
    },
    {
      title: 'Transcript',
      field: 'transcript',
      render: (rowData) => (
        <button
          style={{
            cursor: 'pointer',
            padding: '.5rem .85rem',
            // sky blue like color
            backgroundColor: '#87ceeb',
            border: 'none',
            // color: 'white
            borderRadius: '5px'
          }}
          onClick={() =>
            props.history.push('/onboarding-chat/' + rowData.user_id)
          }>
          View Transcript
        </button>
      )
    },
    {
      title: 'Registration Date',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    }
  ];

  const transactionsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Email ID',
      field: 'email_id'
    },
    {
      title: 'Plan Name',
      field: 'plan_name'
    },
    {
      title: 'Amount',
      field: 'amount'
    },

    {
      title: 'Order Created At',
      field: 'order_created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.order_created_at)
    },
    {
      title: 'Order Created At',
      field: 'payment_timestamp',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.payment_timestamp)
    }
  ];
  const failedTransactionsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Email ID',
      field: 'email_id'
    },
    {
      title: 'Plan Name',
      field: 'plan_name'
    },
    {
      title: 'Amount',
      field: 'amount'
    },
    {
      title: 'Reason',
      field: 'notes'
    },

    {
      title: 'Order Created At',
      field: 'order_created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.order_created_at)
    },
    {
      title: 'Payment Failed At',
      field: 'payment_timestamp',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.payment_timestamp)
    }
  ];
  const createdOrdersColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Email ID',
      field: 'email_id'
    },
    {
      title: 'Plan Name',
      field: 'plan_name'
    },
    {
      title: 'Amount',
      field: 'amount'
    },
    {
      title: 'Order Id',
      field: 'order_id'
    },

    {
      title: 'Order Created At',
      field: 'order_created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.order_created_at)
    }
  ];
  const refferalsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Source Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Source Mobile Number',
      field: 'mobile_number'
    },
    {
      title: 'Invite Count',
      field: 'count'
      // render: (rowData) => (
      //   <div
      //     style={{ cursor: 'pointer' }}
      //     onClick={() => {
      //       props.history.push()
      //     }}>
      //     {rowData.name}
      //   </div>
      // )
    }
  ];
  const bloodDonorOnlyColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile'
    },
    {
      title: 'Find Blood Clicked On',
      field: 'social_user_created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.social_user_created_at)
    }
  ];
  const bloodDonorConversionColumns = [
    ...bloodDonorOnlyColumns,
    {
      title: 'Joined On',
      field: 'joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.joined_on)
    }
  ];
  const bloodDonorRequestColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Sent By',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Sent To',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.target_user_id);
          }}>
          {rowData.target_name}
        </div>
      )
    },
    {
      title: 'Blood Group',
      field: 'name',
      render: (rowData) => getBloodGroupFromId(rowData.blood_group_id)
    },
    {
      title: 'Request Status',
      field: 'request_status'
    },
    {
      title: 'Admin Approval Status',
      render: (rowData) =>
        rowData.status === 'UNDER_REVIEW'
          ? 'Under Review'
          : rowData.status === 'ACTIVE'
          ? 'Approved'
          : 'Rejected'
    },

    {
      title: 'Sent At',
      field: 'sent_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.sent_at)
    },
    {
      title: 'Admin Action At',
      field: 'admin_action_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) =>
        rowData.status !== 'UNDER_REVIEW'
          ? getLocaleTime(rowData.admin_action_at)
          : ''
    },
    {
      title: 'Responded At',
      field: 'updated_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) =>
        rowData.request_status !== 'REQUESTED'
          ? getLocaleTime(rowData.updated_at)
          : ''
    },
    {
      title: 'View',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => (
        <button
          onClick={() =>
            setBloodDonorRequestId(rowData.user_blood_donor_request_id)
          }
          style={{
            padding: '.5rem 1rem',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            backgroundColor: '#389fff',
            color: '#fff'
          }}>
          View
        </button>
      )
    }
  ];
  const bloodDonorRequestAdminColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Sent By',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Blood Group',
      field: 'name',
      render: (rowData) => getBloodGroupFromId(rowData.blood_group_id)
    },
    {
      title: 'Hospital Location',
      field: 'hospital_location'
    },

    {
      title: 'Sent At',
      field: 'sent_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.sent_at)
    }
  ];

  const workReportColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
        style={{ cursor: 'pointer', color: '#297bca' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Organisation',
      field: 'organisation'
    },
    {
      title: 'Remark',
      field: 'remark'
    },
    {
      title: 'Remarked At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    },
    {
      title: 'Follow Up At',
      field: 'next_follow_up_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.next_follow_up_at)
    }

  ];

  const managementReportColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
        style={{ cursor: 'pointer', color: '#297bca' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Organisation',
      field: 'organisation'
    },
    {
      title: 'Remark',
      field: 'remark'
    },
    {
      title: 'Remarked At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    },
    {
      title: 'Follow Up At',
      field: 'next_follow_up_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.next_follow_up_at)
    }

  ];
  const missedFollowupsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
        style={{ cursor: 'pointer', color: '#297bca' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name || rowData.user_id}
        </div>
      )
    },
    {
      title: 'Organisation',
      field: 'organisation'
    },
    {
      title: 'Remark',
      field: 'remark'
    },
    {
      title: 'Remarked At',
      field: 'created_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    },
    {
      title: 'Follow Up At',
      field: 'next_follow_up_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.next_follow_up_at)
    }

  ];

  const revenueColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
        style={{ cursor: 'pointer', color: '#297bca' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Organisation',
      field: 'organisation'
    },
    {
      title: 'Amount',
      field: 'amount'
    },
    {
      title: 'Plan Name',
      field: 'plan_name'
    },
    {
      title: 'Payment Timestamp',
      field: 'payment_timestamp',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.created_at)
    },

  ];
  const getUserData = async (countryCode) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      let url =
        'users/active_users_list_complete_data_with_sort?desc=true&sortBy=registrationDate&' +
        'limit=' +
        limit +
        '&page=' +
        page +
        `&start_date=${startDate.current.toString()}&end_date=${endDate.current.toString()}${
          countryCode ? `&countryCode=${countryCode.replace('+', '')}` : ''
        }`;
      sendHttpCall('GET', url, null, props).then((response) => {
        let data = [];
        for (const elem of response.data) {
          data.push(elem[0]);
        }
        setIsLoading(false);
        setData(data);
        setTotalCount(response.totalCount);
      });
    });
  };
  const getEveryOtherTableData = async (type, query) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      const countryCode = (window.location.href.split('?countryCode=')[1] || '').trim();
      console.log(countryCode)
      const salesDataType = window.location.href.split('?salesDataType=')[1];
      const adminId = window.location.href.split('?adminId=')[1];
      const stageId = window.location.href.split('?stageId=')[1];
      const userId = window.location.href.split('?userId=')[1];
      let url =
        'dashboard/getEveryTableData?type=' +
        type +
        (type === 'by_country'
          ? '&dialCode=' + props.match.params.period
          : '') +
        (type === 'device_type' || type === 'users2_device_type'
          ? '&device_type=' + props.match.params.period
          : '') +
        (type === 'by_campaign' || type === 'by_campaign_status_2' || type === 'by_campaign_hirer_s3'
          ? '&campaignName=' + props.match.params.period
          : '') +
        '&limit=' +
        limit +
        '&page=' +
        page +
        `&start_date=${startDate.current.toString()}&end_date=${endDate.current.toString()}&countryCode=${countryCode?.replace(
          '+',
          ''
        )}
        `;

        if (salesDataType) {
          url += `&salesDataType=${salesDataType}`;
        }
        if (stageId) {
          url += `&stageId=${stageId}`;
        }
        if (adminId) {
          url += `&adminId=${adminId}`;
        }
        if (userId) {
          url += `&userId=${userId}`;
        }

      if (type === 'status2Member') {
        getExtraData('status2MemberCounts');
      }
      if (type === 'status2Member-country') {
        getExtraData('status2MemberCounts-country');
      }
      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
        setTotalCount(response.totalCount);
      });
    });
  };
  const getExtraData = (type) => {
    return new Promise((resolve, reject) => {
      let url =
        'dashboard/getEveryTableData?type=' +
        type +
        `&start_date=${startDate.current.toString()}&end_date=${endDate.current.toString()}`;
      sendHttpCall('GET', url, null, props).then((response) => {
        let obj = response.data;
        let sortMap = {
          CHOOSE_REQUIRED_OPPORTUNITY: 1,
          TELL_US_ABOUT_YOU: 2,
          WORK_EXP_DETAILS: 2,
          CHOOSE_SKILLS: 3,
          CHOOSE_EXPECTED_COMPENSATION: 4,
          'SELECT LOCATION': 5,
          'ADD CONTACTS': 6,
          'CHOOSE PFP': 7,
          'GOODSPACE PREMIUM PAGE': 8,
          'SELF_FEEDBACK PAGE': 9,
          'SOCIAL SERVICES PAGE': 10
        };
        console.log(
          Object.keys(obj)
            .map((item) => ({
              label: item,
              value: obj[item],
              sortValue: sortMap[item]
            }))
            .sort((a, b) => b.sortValue - a.sortValue)
        );
        setExtraData(
          Object.keys(obj)
            .map((item) => ({
              label: item,
              value: obj[item],
              sortValue: sortMap[item]
            }))
            .sort((a, b) => a.sortValue - b.sortValue)
        );
      });
    });
  };
  const feedbackConversionsColumns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.user_id);
          }}>
          {rowData.name}
        </div>
      )
    },
    {
      title: 'Mobile Number',
      field: 'mobile_number'
    },

    {
      title: 'Email Id',
      field: 'email_id'
    },
    {
      title: 'Feedback Request Sent At',
      field: 'feedback_request_sent_at',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.feedback_request_sent_at)
    },
    {
      title: 'User Joined At',
      field: 'user_joined_on',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.user_joined_on)
    }
  ];
  const getQueryParams = () => {
    const hash = window.location.hash;
    const queryString = hash.split('?')[1] || '';
  
    return new URLSearchParams(queryString);
  };
  const getTableLabel = () => {
    let { type } = props.match.params;
  
    // Use URLSearchParams to handle query parameters
    const queryParams = getQueryParams()
    const countryCode = queryParams.get('countryCode') || '';
    const salesDataType = queryParams.get('salesDataType');
    const adminId = queryParams.get('adminId');
    const stageId = parseInt(queryParams.get('stageId'), 10); // Convert to integer
    const salesStage = salesStages.find(stage => stage.value === stageId);

    if (type === 'social_connect_requests') {
      return 'Social Connect Requests Table';
    }
    if (type === 'jobsApplied') {
      return 'All Applied Jobs';
    }
    if (type === 'social_connect_requests_accepted') {
      return 'Social Connect Requests Accepted Table';
    }
    if (type === 'social_connect_requests_rejected') {
      return 'Social Connect Requests Rejected Table';
    }
    if (type === 'active_member') {
      return 'New Users Table';
    }
    if (type === 'active_member-country') {
      return `New Users Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'active_hirers') {
      return 'Active Hirers Table';
    }
    if (type === 'active_hirers-country') {
      return `Active Hirers Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'verified_hirers') {
      return 'Verified Hirers Table';
    }
    if (type === 'verified_hirers-country') {
      return `Verified Hirers Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'reportedJobs') {
      return 'Reported Jobs';
    }
    if (type === 'feedback') {
      return 'Feedbacks Given';
    }
    if (type === 'status1') {
      return 'Status 1 Users';
    }
    if (type === 'status1-country') {
      return `Status 1 Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
        }`;
    }
    if (type === 'status2') {
      return 'Status 2 Users';
    }
    if (type === 'status2Hirer') {
      return 'Status 2 Hirer';
    }
    if (type === 'status2HirerVerified') {
      return 'Status 2 Verified Hirer';
    }
    if (type === 'status2HirerUnVerified') {
      return 'Status 2 Un-verified Hirer';
    }
    if (type === 'status2HirerRejected') {
      return 'Status 2 Rejected Hirer';
    }
    if (type === 'status2-country') {
      return `Status 2 Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'feedback_conversions') {
      return 'Users Converted From Feedback Requests';
    }
    if (type === 'app-feedbacks') {
      return 'App Feedbacks';
    }
    if (type === 'by_country') {
      return `Users From ${countries[props.match.params.period]}`;
    }
    if (type === 'device_type') {
      return `Users From ${props.match.params.period}`;
    }
    if (type === 'by_campaign') {
      return `Users From ${props.match.params.period} Campaign`;
    }
    if (type === 'by_campaign_hirer_s3') {
      return `S3 Hirers From ${props.match.params.period} Campaign`;
    }
    if (type === 'by_campaign_status_2') {
      return `Status 2 Users From ${props.match.params.period} Campaign`;
    }
    if (type === 'survey_sent') {
      return 'Survey Messages Sent';
    }
    if (type === 'survey_link_clicks') {
      return 'Survey Link Clicks';
    }
    if (type === 'survey_received') {
      return 'Survey Received';
    }
    if (type === 'rejectedJobs') {
      return 'Rejected Jobs';
    }
    if (type === 'verifiedJobs') {
      return 'Verified Jobs';
    }
    if (type === 'closedJobs') {
      return 'Closed Jobs';
    }

    if (type === 'deactivated') {
      return 'Deactivated Users';
    }
    if (type === 'deactivated-country') {
      return `Deactivated Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'inactive_hirers') {
      return 'Inactive Hirers';
    }
    if (type === 'contactRequestForDemo') {
      return 'Contact Request For Demo';
    }
    if (type === 'active_hirers-2') {
      return 'Active Hirers';
    }
    if (type === 'blocked') {
      return 'Blocked Users';
    }
    if (type === 'blocked-country') {
      return `Blocked Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'transactions') {
      return 'Transactions';
    }
    if (type === 'failed-transactions') {
      return 'Failed Transactions';
    }
    if (type === 'created-orders') {
      return 'Created Orders';
    }

    if (type === 'survey_conversions') {
      return 'Users Converted From Survey Messages';
    }
    if (type === 'referrals_campus_ambassadors')
      return 'Referrals (Campus Ambassadors)';
    if (type === 'hire-request') return 'Hire Now Requests';
    if (type === 'referrals_others') return 'Referrals (Others)';
    if (type === 'status2Hirer') return 'Hirer Status 2';
    if (type === 'status2HirerVerified') return 'Verified Hirer Status 2';
    if (type === 'status2HirerUnVerified') return 'Un Verified Hirer Status 2';
    if (type === 'status2HirerRejected') return 'Rejected Hirer Status 2';
    if (type === 'status3hirersVerified') return 'Verified Hirer Status 3';
    if (type === 'status3hirersUnverified') return 'Un Verified Hirer Status 3';
    if (type === 'status3hirersRejected') return 'Rejected Hirer Status 3';
    if (type === 'status3hirersJobPosts') return 'Rejected Hirer Status 3';
    if (type === 'status2Hirer-country')
      return `Hirer Status 2 ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    if (type === 'status2Member') return 'Job Seeker Status 2';
    if (type === 'status2Member-country')
      return `Job Seeker Status 2 ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    if (type === 'status4') return 'Status 4 Only Members';
    if (type === 'find-blood-conversions') return 'Find Blood Conversions';
    if (type === 'find-blood-only-users') return 'Find Blood Only Users';
    if (type === 'blood-donor-requests') return 'Blood Donor Requests';
    if (type === 'blood-donor-requests-accepted')
      return 'Blood Donor Requests Accepted';
    if (type === 'hirer-unassigned-sales-stage') return 'Unassigned Hirers';
    if (type === 'blood-donor-requests-rejected')
      return 'Blood Donor Requests Rejected';
    if (type === 'blood-donor-requests-admin')
      return 'Blood Donor Requests Admin';
    if (type === 'opportunities' ||type === 'leads') {
      const labelMap = {
        work_report: 'Work Report',
        management_report: 'Management Report',
        missed_followUps: 'Missed Follow-Ups',
        revenue: 'Revenue Generated',
        unassigned: 'Unassigned Opportunities'
      };
      const label = labelMap[salesDataType] || '';
      if (label) { // add admin name when static config is made
        return `${label}${salesStage ? ` | ${salesStage.label} Stage` : ''}${adminId ?  '' : '| All Admins'}`;
      }
      
    }
    
    if ('hirer-resume-downloaded') return 'Resume Downloaded By Hirer';
    if (type === 'resume-downloaded') return 'Resume Generated';
    if (type === 'resume-audio-uploaded') return 'Resume Audio Uploaded';
    if (type === 'status4-country')
      return `Status 4 Only Members ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
  };

  const getData = async () => {
    let { type } = props.match.params;
    if (type === 'active_member' || type === 'active_member-country') {
      const countryCode = window.location.href.split('?countryCode=')[1];

      await getUserData(countryCode);
    } else {
      await getEveryOtherTableData(type);
    }
  };
  useEffect(() => {
    getData();
    sessionStorage.removeItem('everyTablePage');
    sessionStorage.removeItem('everyTableLimit');
  }, [page]);
  useEffect(() => {
    if (!data.length) return;
    if (page === 0) getData();
    else setPage(0);
  }, [limit]);
  return (
    <div>
      <div className="everyTable__title">{getTableLabel()}</div>
      <div className="everyTable__table">
        <MaterialTable
          isLoading={isLoading}
          columns={
            (props.match.params.type === 'opportunities' || props.match.params.type === 'leads')
            ? (() => {
              const queryParams = getQueryParams()
              const salesDataType = queryParams.get('salesDataType');
                switch (salesDataType) {
                  case 'work_report':
                    return workReportColumns;
                  case 'management_report':
                    return managementReportColumns;
                  case 'missed_followUps':
                    return missedFollowupsColumns;
                  case 'revenue':
                    return revenueColumns;
                    case 'unassigned':
                      return unassignedHirersStatus3;
                  default:
                    return workReportColumns;
                }
              })() :
            props.match.params.type === 'active_member' ||
            props.match.params.type === 'active_member-country'
              ? userColumns
              : props.match.params.type === 'verified_hirers' ||
                props.match.params.type === 'verified_hirers-country'
              ? verifiedHirersColumns
              : props.match.params.type === 'feedback'
              ? feedbackColumns
              : props.match.params.type === 'jobsApplied'
              ? jobsAppliedColumns
              : props.match.params.type === 'status1' ||
                props.match.params.type === 'status2' ||
                props.match.params.type === 'status1-country' ||
                props.match.params.type === 'status2-country'
              ? userStatusColumns
              : props.match.params.type === 'blocked' ||
                props.match.params.type === 'blocked-country'
              ? blockedUsersColumns
              : props.match.params.type === 'deactivated' ||
                props.match.params.type === 'deactivated-country'
              ? deactivatedUsersColumns
              : props.match.params.type === 'inactive_hirers'
              ? inactiveHirersColumns
              : props.match.params.type === 'contactRequestForDemo'
              ? contactRequestForDemoColumn
              : props.match.params.type === 'resume-audio-uploaded'
              ? resumeAudioUploadedColumns
              : props.match.params.type === 'resume-downloaded'
              ? resumeDownloadedColumns
              : props.match.params.type === 'active_hirers-2'
              ? activeHirersColumns2
              : props.match.params.type === 'app-feedbacks'
              ? appFeedbacksColumns
              : props.match.params.type === 'feedback_conversions'
              ? feedbackConversionsColumns
              : props.match.params.type === 'by_country' ||
                props.match.params.type === 'device_type'
              ? userByCountriesColumns
              : props.match.params.type === 'by_campaign'
              ? props.match.params.period === 'aichatbot-onboarding-chat-flow'
              ? onboardingChatColumns
              : userByCampaignsColumns
              : props.match.params.type === 'by_campaign_status_2'
              ? props.match.params.period === 'aichatbot-onboarding-chat-flow'
              ? onboardingChatColumns
              : userByCampaignsColumns2
              : props.match.params.type === 'by_campaign_hirer_s3'
              ? hirersByCampaignsColumns 
              : props.match.params.type === 'find-blood-only-users'
              ? bloodDonorOnlyColumns
              : props.match.params.type === 'find-blood-conversions'
              ? bloodDonorConversionColumns
              : props.match.params.type === 'blood-donor-requests' ||
                props.match.params.type === 'blood-donor-requests-accepted' ||
                props.match.params.type === 'blood-donor-requests-rejected'
              ? bloodDonorRequestColumns
              : props.match.params.type === 'blood-donor-requests-admin'
              ? bloodDonorRequestAdminColumns
              : props.match.params.type === 'survey_sent'
              ? surveyColumns
              : props.match.params.type === 'survey_link_clicks' ||
                props.match.params.type === 'survey_received'
              ? linkClickColumns
              : props.match.params.type === 'survey_conversions'
              ? surveyConversionColumns
              : props.match.params.type === 'verifiedJobs'
              ? closedJobsColumns
              : props.match.params.type === 'rejectedJobs'
              ? closedJobsColumns
              : props.match.params.type === 'closedJobs'
              ? closedJobsColumns
              : props.match.params.type === 'referrals_campus_ambassadors' ||
                props.match.params.type === 'referrals_others'
              ? refferalsColumns
              : props.match.params.type === 'reportedJobs'
              ? reportedJobsColumns
              : props.match.params.type === 'transactions'
              ? transactionsColumns
              : props.match.params.type === 'failed-transactions'
              ? failedTransactionsColumns
              : props.match.params.type === 'created-orders'
              ? createdOrdersColumns
              : props.match.params.type === 'redirect_job_apply_clicks'
              ? redirectJobClicksColumns
              : props.match.params.type === 'social_connect_requests'
              ? socialUsersDataColumns
              : props.match.params.type.includes('social_connect')
              ? socialUsersDataColumns
              : props.match.params.type === 'status4' ||
                props.match.params.type === 'status4-country'
              ? status4Columns
              : props.match.params.type === 'status2Member' ||
                props.match.params.type === 'status2Member-country'
              ? status2SpecificColumns
              : props.match.params.type === 'hire-request'
              ? hireRequestColumns
              : props.match.params.type === 'status2Hirer' ||
                props.match.params.type === 'status2HirerVerified' ||
                props.match.params.type === 'status2HirerUnVerified' ||
                props.match.params.type === 'status2HirerRejected' ||
                props.match.params.type === 'status2Hirer-country'
              ? status2HirerColumns
              : props.match.params.type === 'hirer-resume-downloaded' ||
                props.match.params.type === 'hirer-resume-downloaded'
              ? hirerResumeDownload
              : activeHirersColumns
          }
          data={data}
          totalCount={totalCount}
          page={page}
          title=""
          onChangePage={(e) => {
            console.log('Changing page - ' + e);
            setPage(e);
          }}
          onChangeRowsPerPage={(e) => setLimit(e)}
          options={{
            search: true,
            actionsColumnIndex: -1,
            pageSize: limit,
            pageSizeOptions:
              props.match.params.type === 'active_member'
                ? [20]
                : [10, 20, 30, 40, 50],
            sorting: true,
            columnsButton: true,
            exportButton: localStorage.getItem('user_type') === 'SALES' ? false : true
          }}
          components={
            props.match.params.type === 'active_member' ||
            props.match.params.type === 'active_member-country' ||
            props.match.params.type === 'status2Hirer' ||
            props.match.params.type === 'active_hirers' ||
            props.match.params.type === 'status3hirersVerified' ||
            props.match.params.type === 'status3hirersUnverified' ||
            props.match.params.type === 'status3hirersRejected' ||
            props.match.params.type === 'status3hirersJobPosts' ||
            props.match.params.type === 'by_campaign' ||
            props.match.params.type === 'by_campaign_hirer_s3'
              ? {
                Pagination: (props) => (
                  <div className="table__pagination-container">
                    {localStorage.getItem('user_type')==='ADMIN' &&(
                    <div className="exportToCsvBtn" onClick={exportDataToCSV}>
                      <span> Export To CSV</span> <CloudDownload />
                    </div>
                    )}
                    <div className="table__pagination-default-container">
                      <MTablePagination {...props} />
                    </div>
                  </div>
                )
              }
              : null
          }
        />
      </div>{' '}
      <ReactModal
        isOpen={selectedJob}
        onRequestClose={() => selectJob(null)}
        className="jobs_under_review__modal-modal"
        overlayClassName="jobs_under_review__modal-overlay">
        {selectedJob && (
          <div
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
            className="jobs_under_review__posts-post-container">
            <div className="jobs_under_review__posts-post-postedAt">
              Posted At: {formatTime(new Date(selectedJob.created_at))}
            </div>
            <div className="jobs_under_review__posts-post-posted_by">
              <img
                alt=""
                src={selectedJob.userInfo[0].image_id}
                className="jobs_under_review__posts-post-posted_by-image"
              />
              <div>
                <Link
                  className="active_jobs__posts-post-posted_by-name"
                  to={`/account/${selectedJob.userInfo[0].user_id}`}>
                  {selectedJob.userInfo[0].name}
                </Link>
                <div>{selectedJob.userInfo[0].organisation}</div>
              </div>
            </div>
            <div className="jobs_under_review__posts-post-content">
              <div className="jobs_under_review__posts-post-details">
                <div className="jobs_under_review__posts-post-title">
                  {selectedJob.title}
                </div>
                <div className="jobs_under_review__row">
                  <div className="jobs_under_review__posts-post-jobType">
                    {selectedJob.jobType.length > 0
                      ? selectedJob.jobType[0].job_type
                      : ''}
                  </div>
                  -
                  <div className="jobs_under_review__posts-post-location">
                    {selectedJob.is_remote
                      ? 'Remote'
                      : selectedJob.location_city}
                  </div>
                </div>
                <div className="jobs_under_review__posts-post-data">
                  <div className="jobs_under_review__posts-post-label">
                    Description
                  </div>
                  <div className="jobs_under_review__posts-post-desc">
                    {selectedJob.description}
                  </div>
                  <div className="jobs_under_review__posts-post-skills-section">
                    <div className="jobs_under_review__posts-post-label">
                      Skills
                    </div>
                    <div className="jobs_under_review__posts-post-skills">
                      {selectedJob.skills.map((skill) => (
                        <div className="jobs_under_review__posts-post-skill">
                          {skill.skill}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="jobs_under_review__posts-post-compensation-section">
                    <div className="jobs_under_review__posts-post-label">
                      {selectedJob.monthly_compensation
                        ? 'Yearly Compensation'
                        : 'Hourly Compensation'}
                    </div>
                    <div className="jobs_under_review__posts-post-value">
                      {selectedJob.monthly_compensation
                        ? convertToYearly(selectedJob.monthly_compensation)
                        : selectedJob.hourly_compensation}
                    </div>
                  </div>
                  <div className="jobs_under_review__posts-post-exp-section">
                    <div className="jobs_under_review__posts-post-label">
                      Work Experience Required
                    </div>
                    <div className="jobs_under_review__posts-post-value">
                      {`${selectedJob.lowerworkex} - ${selectedJob.upperworkex} years`}
                    </div>
                  </div>
                  <div className="jobs_under_review__posts-post-industry-section">
                    <div className="jobs_under_review__posts-post-label">
                      Industry
                    </div>
                    <div className="jobs_under_review__posts-post-value">
                      {selectedJob.industry_type}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ReactModal>
      {extraData && (
        <div className="everyTable__table everyTable__table-2">
          <ReactTable
            data={extraData}
            columns={[
              {
                Header: 'Page name',
                accessor: 'label'
              },
              {
                Header: 'Count',
                accessor: 'value'
              }
            ]}
            sortable={false}
            defaultPageSize={50}
            showPageJump={false}
            showPageSizeOptions={false}
            showPagination={false}
            minRows={6}
          />
        </div>
      )}
      {bloodDonorRequestId && (
        <BloodDonorRequestPreview
          id={bloodDonorRequestId}
          prevProps={props}
          isOpen={!!bloodDonorRequestId}
          closeModal={() => setBloodDonorRequestId(null)}
          showToast={showToast}
          refresh={() => {
            getData();
          }}
        />
      )}
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}

const BloodDonorRequestPreview = ({
  id,
  isOpen,
  closeModal,
  refresh,
  prevProps,
  showToast
}) => {
  const updating = useRef(false);
  const [data, setData] = useState(null);
  const [salesDataType, setSalesDataType] = useState(null);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    setLoading(true);
    let url = `blood_donor_request/preview?id=${id}`;
    sendHttpCall('GET', url, null, prevProps).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };
  const changeRequestStatus = (approved) => {
    if (updating.current) return;
    updating.current = true;
    let url = `/blood_donor_request/update_status`;
    sendHttpCall(
      'POST',
      url,
      {
        bloodDonorRequestId: id,
        approved
      },
      prevProps
    ).then((response) => {
      console.log(response);
      updating.current = false;

      showToast(
        approved
          ? 'Successfully approved request.'
          : 'Successfully rejected request.'
      );
      closeModal();
      refresh();
    });
  };
  useEffect(() => {
    if (id) getData();
  }, [id]);
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="jobs_under_review__modal-modal"
      overlayClassName="jobs_under_review__modal-overlay">
      {loading && (
        <div
          style={{ maxHeight: '80vh', overflowY: 'auto' }}
          className="jobs_under_review__posts-post-container">
          Loading...
        </div>
      )}
      {!loading && data && (
        <div
          style={{ maxHeight: '80vh', overflowY: 'auto' }}
          className="jobs_under_review__posts-post-container">
          <div className="jobs_under_review__posts-post-title">
            Blood Donor Request
          </div>
          <div
            style={{ marginTop: '1rem' }}
            className="jobs_under_review__posts-post-exp-section">
            <div className="jobs_under_review__posts-post-label">
              Patient Name
            </div>
            <div className="jobs_under_review__posts-post-value">
              {data.patient_name}
            </div>
          </div>
          <div className="jobs_under_review__posts-post-exp-section">
            <div className="jobs_under_review__posts-post-label">
              Blood Group Required
            </div>
            <div className="jobs_under_review__posts-post-value">
              {data.blood_group || 'Any'}
            </div>
          </div>

          <div className="jobs_under_review__posts-post-exp-section">
            <div className="jobs_under_review__posts-post-label">
              Hospital Name
            </div>
            <div className="jobs_under_review__posts-post-value">
              {data.hospital_name}
            </div>
          </div>
          <div className="jobs_under_review__posts-post-exp-section">
            <div className="jobs_under_review__posts-post-label">
              Hospital Location
            </div>
            <div className="jobs_under_review__posts-post-value">
              {data.hospital_location}
            </div>
          </div>
          <div className="jobs_under_review__posts-post-exp-section">
            <div className="jobs_under_review__posts-post-label">
              Contact Person Name
            </div>
            <div className="jobs_under_review__posts-post-value">
              {data.contact_person_name}
            </div>
          </div>
          <div className="jobs_under_review__posts-post-exp-section">
            <div className="jobs_under_review__posts-post-label">
              Contact Person Mobile
            </div>
            <div className="jobs_under_review__posts-post-value">
              {data.contact_person_mobile_number}
            </div>
          </div>
          {data.status === 'UNDER_REVIEW' && (
            <div className="jobs_under_review__posts-post-btns">
              <button
                className="jobs_under_review__posts-post-rejectBtn"
                onClick={() => {
                  // setSelectedJob(item);
                  changeRequestStatus(false);
                }}>
                Reject
              </button>
              <button
                className="jobs_under_review__posts-post-approveBtn"
                onClick={() => {
                  // approveJob(item.offerId);
                  changeRequestStatus(true);
                }}>
                Approve
              </button>
            </div>
          )}
        </div>
      )}
    </ReactModal>
  );
};
