import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { selectoptions } from '../../../../config';
import TotalUsers from '../TotalUsers';
import moment from 'moment';
import 'react-dropdown/style.css';
import { sendHttpCall, Toast } from 'shareService/httpService';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { margin: 8 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right'
  },
  space: {
    margin: 2
  },
  highlightedTotal: {
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    marginTop: theme.spacing(2)
  }
}));

const ApplicationsSource = () => {
  const classes = useStyles();
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [selectedPeriod, setSelectedPeriod] = useState(localStorage.getItem('gcoins-period') || 'Today');
  const [loading, setLoading] = useState(true);
  const [sourceData, setSourceData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [okdate, setOkdate] = useState(true);
  const [selectedGender, setSelectedGender] = useState();
  const [selectedCity, setSelectedCity] = useState();
  let end = new Date();
  let start = new Date();

  useEffect(() => {
    fetchAllData(today, today);
  }, []);

  const handleTimeChange = (event) => {
    const period = event.target.value;
    setSelectedPeriod(period);
    const endDate = new Date();
    let startDate = new Date();

    switch (period) {
      case 'All time':
        startDate = new Date(2019, 0, 1);
        break;
      case 'Last Year':
        startDate.setDate(endDate.getDate() - 365);
        break;
      case 'Last 30 days':
        startDate.setDate(endDate.getDate() - 30);
        break;
      case 'Last 7 days':
        startDate.setDate(endDate.getDate() - 7);
        break;
      case 'Yesterday':
        startDate.setDate(endDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        break;
      default:
        // Today
        break;
    }

    if (period !== 'Custom Date') {
      setIsCustomDate(false);
      const formattedStart = moment(startDate).format('YYYY-MM-DD');
      const formattedEnd = moment(endDate).format('YYYY-MM-DD');
      setSelectedStartDate(formattedStart);
      setSelectedEndDate(formattedEnd);
      fetchAllData(formattedStart, formattedEnd);
    } else {
      setIsCustomDate(true);
    }
  };

  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    fetchAllData(newDateRange.startDate, newDateRange.endDate);
  };

  const fetchAllData = async (start, end) => {
    setLoading(true);
    const query = `?start_date=${start}&end_date=${end}`;
    try {
      const response = await sendHttpCall(
        'GET',
        `dashboard/applications_source${query}`,
        null
      );
      if (response) {
        setSourceData(response.bySource || []);
        setDeviceData(response.byDevice || []);
        setTotalData(response.total || { bySource: 0, byDevice: 0 });
      }
    } catch (error) {
      Toast('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justifyContent="flex-start">
        <DateRangePicker onDateChange={handleDateChange}/>
      </Grid>

      <div className="dashboard-label">Applications Sources</div>
      <Grid container spacing={3}>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
          style={{ cursor: 'pointer' }}>
          <TotalUsers
            totalUser={totalData.bySource}
            loading={loading}
            Text="Total"
          />
        </Grid>
        {sourceData.map((data, index) => (
          <Grid
            key={index}
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
            style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={data.user_count}
              loading={loading}
              Text={data.apply_source}
            />
          </Grid>
        ))}
      </Grid>

      <div className="dashboard-label">Applications Devices</div>
      <Grid container spacing={3}>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
          style={{ cursor: 'pointer' }}>
          <TotalUsers
            totalUser={totalData.byDevice}
            loading={loading}
            Text="Total"
          />
        </Grid>
        {deviceData.map((data, index) => (
          <Grid
            key={index}
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
            style={{ cursor: 'pointer' }}>
            <TotalUsers
              totalUser={data.user_count}
              loading={loading}
              Text={(data.apply_device || 'Whatsapp Direct').toUpperCase()}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ApplicationsSource;
