import React, { useState, useEffect, useRef, useCallback } from 'react';
import sanitizeHtml from 'sanitize-html';
import { sendHttpCall } from 'shareService/httpService';
import styles from '../Chats/Chats.module.scss';

const monthsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export default function Chats(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(0);
  const [page, setPage] = useState(0);
  const [lastMessageFetched, setLastMessageFetched] = useState(false);
  const userId = props.match.params.userId;
  const getData = () => {
    if (lastMessageFetched) return;
    const chatType = props.match.params.chatType;
    const url = `sakshibotsessions/get-interactions-uid?userid=${userId}`; 
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      setData(response.data[0].messages.reverse());
      if (response.data[0].messages.length < 20) setLastMessageFetched(true);
      setLoading(false);
    });
  };
  const observer = useRef();
  useEffect(() => {
    getData();
  }, [page]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Chat
      </div>
      <div className={styles.chatBoxContainer}>
        <div className={styles.chatBox}>
          {data.map((item, index) => {
            return (
              <Message
                item={item}
                showDate={false}
                messageSent={item.sentBy !== "USER"}
                data={data}
              />
            );
          })}
          {/* {!lastMessageFetched && (
            <div className={styles.loaderContainer} ref={firstMsg}>
              <div
                style={
                  loading && data.length
                    ? { display: 'block' }
                    : { display: 'none' }
                }>
                <div>Loading... </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
const Message = ({ item, showDate, messageSent }) => {
  return (
    <>
      <div
        className={`${styles.messageContainer} ${
          messageSent
            ? styles.messageContainerSent
            : styles.messageContainerReceived
        }`}>
        <div className={styles.userPfp}>
          {/* <img
            src={messageSent ? user1Info.image_id : user2Info.image_id}
            alt=""
          /> */}
        </div>
        <div className={styles.messageBubble}>
          <div
            className={styles.messageText}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(item.message)
            }}
          />
        </div>
      </div>
    </>
  );
};
const formatChatDate = (date) =>
  `${date.getDate()} ${monthsFull[date.getMonth()]} ${date.getFullYear()}`;

const DateItem = ({ crtd }) => (
  <div className={styles.chatDateContainer}>
    {/* <div className={styles.chatDateLine} /> */}
    <div className={styles.chatDateValue}>{formatChatDate(new Date(crtd))}</div>
  </div>
);
const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  interval = seconds / 2592000;
  interval = seconds / 86400;
  interval = seconds / 3600;
  interval = seconds / 60;
  if (interval > 16) {
    return { thresholdCompleted: true, time: getTimeIn12Hours(date) };
  }
  if (interval > 1) {
    return {
      thresholdCompleted: false,
      time: `${Math.floor(interval)} min ago`
    };
  }
  return { thresholdCompleted: false, time: 'Just now' };
};

const useCustomMessageTimeHook = (date) => {
  const [relativeFormattedTime, setRelativeFormattedTime] = useState(
    timeSince(new Date(date))
  );
  let interval;
  useEffect(() => {
    if (!relativeFormattedTime.thresholdCompleted)
      interval = setInterval(() => {
        setRelativeFormattedTime(timeSince(new Date(date)));
      }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (relativeFormattedTime.thresholdCompleted) clearInterval(interval);
  }, [relativeFormattedTime.thresholdCompleted]);

  return relativeFormattedTime;
};
