import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider
} from '@material-ui/core';
import {  options } from './chart';
import { merge } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 250,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LineChart = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  merge(options, {
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          stepSize: 10
        }
      }]
    },
  });

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={props.title}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Line
            data={props.LineChart}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
      </CardActions>
    </Card>
  );
};

LineChart.propTypes = {
  className: PropTypes.string
};

export default LineChart;
