/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';
import AudioPlayer from './AudioPlayer';
import MaterialTable, { MTablePagination } from 'material-table';
import moment from 'moment';
import styles from './InterviewDetails.module.scss';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import ReactModal from 'react-modal';

export default function EveryTable(props) {
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);

  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);
  const [finishedInterviewsCount, setFinishedInterviewsCount] = useState(0);
  const [bloodDonorRequestId, setBloodDonorRequestId] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const getLocaleTime = (time) => {
    if (time) {
      if (props.match.params.type === 'active_member')
        return moment(new Date(time + ' UTC')).format('DD/MM/YYYY h:mm:ss A');
      return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    }
    return '';
  };
  const startDate = useRef(
    props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
  );
  const endDate = useRef(
    props.match.params.end_date
      ? props.match.params.end_date
      : moment(new Date()).format('YYYY-MM-DD')
  );

  const getEveryOtherTableData = async () => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      let url = `users/onboarding-chat/${props.match.params.userId}`;
      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
      });
    });
  };
  const getData = async () => {
    getEveryOtherTableData();
  };
  useEffect(() => {
    getData();
    sessionStorage.removeItem('everyTablePage');
    sessionStorage.removeItem('everyTableLimit');
  }, [page]);
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>Onboarding Chat Details</div>
        <div className={styles.row}>
          <div className={styles.chatboxContainer}>
            <div className={styles.chatBoxMessages}>
            {data?.messages?.length > 0 ? (
              data?.messages?.map((item) =>
                item.sentBy === 'BOT' ? (
                  <MessageReceived
                    text={item.message}
                    date={new Date(item.createdAt)}
                    playAudio={setAudioSrc}
                    extraFunction={item.extraFunction}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : item.sentBy === 'USER' ? (
                  <MessageSent
                    text={item.message}
                    playAudio={setAudioSrc}
                    date={new Date(item.createdAt)}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : null
              )):('No message availabe')}
            </div>
          </div>
          {/* <div className={styles.summaryContainer}>
            <div className={styles.summaryTitle}>Summary</div>
            <div className={styles.summaryText}>{data.interviewSummary}</div>
          </div> */}
        </div>
      </div>{' '}
      <AudioPlayer src={audioSrc} onPause={() => setAudioSrc(null)} />
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}
const MessageSent = ({
  text,
  date,
  audioLink,
  playAudio,
  playingAudioLink
}) => (
  <div className={styles.messageSent}>
    <div className={styles.messageSentText}>{text}</div>
    <div className={styles.messageSentExtra}>
      {audioLink && (
        <div
          className={styles.messageAudioButton}
          onClick={() => playAudio(audioLink)}>
          <AudioIcon playing={playingAudioLink === audioLink} />
        </div>
      )}
      <div className={styles.messageSentTime}>
        {moment(date).format('hh:mm A')}
      </div>
    </div>
  </div>
);

const MessageReceived = ({
  text,
  date,
  audioLink,
  playAudio,
  extraFunction,
  playingAudioLink
}) => (
  <div className={styles.messageReceived}>
    <div className={styles.messageReceivedText}>
      {text}
      <div
        style={{
          marginTop: '.5rem',
          color: '#333',
          fontWeight: 500,
          fontSize: '.8rem'
        }}>
        {extraFunction}
      </div>
    </div>
    <div className={styles.messageReceivedExtra}>
      {audioLink && (
        <div
          className={styles.messageAudioButton}
          onClick={() => playAudio(audioLink)}>
          <AudioIcon playing={playingAudioLink === audioLink} />
        </div>
      )}
      <div className={styles.messageReceivedTime}>
        {moment(date).format('hh:mm A')}
      </div>
    </div>
  </div>
);
