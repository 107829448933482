import React from 'react';
import moment from 'moment';
import MaterialTable, { MTablePagination } from 'material-table';
import { sendHttpCall } from 'shareService/httpService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import './style.scss';
import { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export default function LogsExplorer(props) {
//   const [loading, setLoading] = React.useState(false);
//   const [data, setData] = React.useState(null);
//   const [startDateVal, setStartDateVal] = React.useState('');
//   const [endDateVal, setEndDateVal] = React.useState('');
//   const [selectedInterval, setSelectedInterval] = React.useState(null);

//   // const openUserPage = (userId, social) => {
//   //   // sessionStorage.setItem('everyTablePage', page);
//   //   // sessionStorage.setItem('everyTableLimit', limit);
//   //   props.history.push(`/account/${userId}${social ? '?type=SOCIAL' : ''}`);
//   // };
//   const getData = () => {
//     setLoading(true);
//     console.log("check");
//     sendHttpCall(
//       'GET',
//       `dashboard/dashboard_get_hirer_data_v2`,
//       null,
//       props
//     )
//       .then((response) => {
//         setLoading(false);
//         setData(response);
//       })
//       .catch((err) => {
//         toast.error('Log file not found.');
//       });
//   };


//   React.useEffect(() => {
//     console.log("here");
//       getData();
      
//       console.log(data)
    
//   }, [selectedInterval]);

//   return (
//     <div className="logsExplorer__container">
//       <div className="logsExplorer__title">Total Count</div>
//       <div>
//       Hirer Count Total : {data.totalHirer}
//       </div>
//       <div>
//       Hirer Count Verified : {data.verifiedHirer}
//       </div>
//     </div>
//   );
// }


const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ totalHirer: 0, verifiedHirer: 0 });
  const [userdata, setuserData] = useState({ totalCount: 0});
  const [userDemographics, setuserDemographics] = useState({ totalUnqualifiedUsers: 0});
  const [selectedInterval, setSelectedInterval] = useState(null);

  // Function to fetch data
  const getData = () => {
    setLoading(true);
    sendHttpCall('GET', `dashboard/dashboard_get_hirer_data_v2`, null, props)
      .then((response) => {
        setData(response);
        return sendHttpCall('GET', `users/active_users_list_count`, null, props);
      })
      .then((resp) => {
        setuserData(resp);
        // return sendHttpCall('GET', `users/active_users_demographics`, null, props);
      })
      // .then((res) => {
      //   setuserDemographics(res);
      // })
      .catch((err) => {
        toast.error('Log file not found.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  // useEffect hook to call getData when selectedInterval changes
  useEffect(() => {
    getData();
  }, [selectedInterval]);

// Custom styles for the Material-UI components
const useStyles = makeStyles({
  gridContainer: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  root: {
    minWidth: 275,
    margin: '8px', // Spacing between cards
  },
  title: {
    fontSize: 14,
    color: '#1976d2', // This is a Material-UI blue color
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 5,
  },
  count: {
    fontSize: '2rem',
    textAlign: 'left',
  },
});

  const classes = useStyles();

  return (
    <div className="logsExplorer__container">
      <div className="logsExplorer__title">Total Count</div>
      <Grid container className={classes.gridContainer} spacing={2}>
      <Grid item xs={12} sm={4}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              HIRER TOTAL
            </Typography>
            <Typography variant="h5" component="h2" className={classes.count}>
              {data.totalHirer}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              HIRER VERIFIED
            </Typography>
            <Typography variant="h5" component="h2" className={classes.count}>
            {data.verifiedHirer}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              ACTIVE USERS
            </Typography>
            <Typography variant="h5" component="h2" className={classes.count}>
            {userdata.totalCount}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item xs={12} sm={4}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Unqualified Users
            </Typography>
            <Typography variant="h5" component="h2" className={classes.count}>
            {userDemographics.totalUnqualifiedUsers}
            </Typography>
          </CardContent>
        </Card>
      </Grid> */}
    </Grid>
    </div>
  );
}