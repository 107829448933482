import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sendHttpCall } from 'shareService/httpService';
import ReactModal from 'react-modal';
import './style.scss';
import { Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import EditJobPost from './EditJobPost';
import EditJobPostV2 from './EditJobPostV2';
import JobCard from 'components/JobCard/JobCard';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const planNames = {
  HIRER_ADD_ON_1: 'Bronze',
  HIRER_ADD_ON_2: 'Silver',
  HIRER_ADD_ON_3: 'Gold',
  HIRER_PREMIUM: 'Enterprise',
  HIRER_ADD_ON_4: 'Basic',
  HIRER_ADD_ON_5: 'Premium',
  HIRER_ADD_ON_6: 'Enterprise',
  HIRER_ADD_ON_7: 'Starter',
  HIRER_ADD_ON_8: 'Basic',
  HIRER_ADD_ON_9: 'Premium',
  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_JOB_PLAN_1: 'Jobs Basic',

  HIRER_JOB_PLAN_2: 'Jobs Standard',

  HIRER_JOB_PLAN_3: 'Jobs Gold',

  HIRER_OFFER_PLAN_1: 'Offers Basic',

  HIRER_OFFER_PLAN_2: 'Offers Standard',

  HIRER_OFFER_PLAN_3: 'Offers Gold',

  HIRER_CV_PLAN_1: 'CV Basic',

  HIRER_CV_PLAN_2: 'CV Standard',

  HIRER_CV_PLAN_3: 'CV Gold'
};
const convertToYearly = (monthlyVal) => {
  switch (monthlyVal) {
    case '₹ 10k - 25k':
    case '₹10k - 25k':
      return '₹ 1.2L - 3L';
    case '₹ 25k - 50k':
    case '₹25k - 50k':
      return '₹ 3L - 6L';
    case '₹ 50k - 75k':
      return '₹ 6L - 9L';
    case '₹ 75k - 100k':
      return '₹ 9L - 12L';
    case '₹ 100k - 150k':
      return '₹ 12L - 18L';
    case '₹ 150k - 200k':
      return '₹ 18L - 24L';
    case '₹ 200k - 300k':
      return '₹ 24L - 36L';
    case '₹ 300k - 500k':
      return '₹ 36L - 60L';
    case '>  ₹ 500k':
      return '> ₹ 60L';
    default:
      return monthlyVal;
  }
};
const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};
const formatDate = (date, variation = 1) =>
  `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
    date.getMonth() + 1
  )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

const formatTime = (time) => {
  const date = new Date(time);

  return getTimeIn12Hours(date) + ', ' + formatDate(date);
};
export default function (props) {
  const prevQuery = useRef(null);
  const prevPage = useRef(null);
  const classes = useStyles();

  const [editModalData, setEditModalData] = useState(null);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [lastPage, setLastPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});

  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const getData = () => {
    let pageVal = prevQuery.current !== query ? 1 : page;
    prevQuery.current = query;
    prevPage.current = pageVal;
    setLoading(true);
    let url = `/jobs/get_all_by_user_by_type?type=${
      props.match.params.type
    }&userId=${props.match.params.userId}&limit=${10}&page=${pageVal}`;
    if (query) {
      url += '&query=' + query;
    }
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      setData(response.data);
      let count = response.totalCount;
      if (page !== pageVal) setPage(pageVal);
      setTotalCount(count);
      setLastPage(Math.ceil(count / 10));
      setLoading(false);
    });
  };

  const changeJobStatus = (offerId, approved, message, deleteJob) => {
    if (loading) return;
    if (!deleteJob && !approved && !message) {
      showToast('Cannot reject job without reason.');
      return;
    }
    setLoading(true);
    let url = `/jobs/update_job_post_status`;
    sendHttpCall(
      'POST',
      url,
      {
        offerId,
        approved,
        message,
        delete: deleteJob
      },
      props
    ).then((response) => {
      console.log(response);
      if (deleteJob) showToast('Successfully deleted job post.');
      else
        showToast(
          approved
            ? 'Successfully approved job post.'
            : 'Successfully rejected job post.'
        );
      showToast(
        approved
          ? 'Successfully approved job post.'
          : 'Successfully rejected job post.'
      );
      setLoading(false);
      setTimeout(getData, 500);
    });
  };
  const boostJob = (offerId) => {
    let url = `/jobs/boost_job`;
    sendHttpCall(
      'POST',
      url,
      {
        offerId
      },
      props
    ).then((response) => {
      showToast('Successfully boosted job post.');
    });
  };
  const setJobAsHold = (offerId) => {
    if (loading) return;
    setLoading(true);
    let url = `/jobs/update_job_post_as_on_hold`;
    sendHttpCall(
      'POST',
      url,
      {
        offerId
      },
      props
    ).then((response) => {
      console.log(response);
      showToast('Successfully Added On Hold.');
      setLoading(false);
      setTimeout(getData, 500);
    });
  };
  const approveJob = (offerId, isFreeActive = false) => {
    changeJobStatus(offerId, true, isFreeActive);
  };

  const setJobOnHold = (offerId) => {
    // console.log("offerId",offerId)
    setJobAsHold(offerId);
  };
  const rejectJob = (offerId, reason) => {
    changeJobStatus(offerId, false, reason);
  };
  const deleteJob = (offerId) => changeJobStatus(offerId, null, null, true);

  useEffect(() => {
    if (prevPage.current !== page || prevQuery.current !== query) getData();
  }, [page, query]);
  return (
    <div>
      <div className="active_jobs__container">
        <div className="active_jobs__header">
          {props.match.params.type.toLowerCase()} Jobs for{' '}
          {props.match.params.userId}
        </div>

        <div className="active_jobs__posts-container">
          {loading && (
            <div className="active_jobs__posts-loading">Loading...</div>
          )}
          {!loading &&
            data.map((item) => (
              <JobCard
                status={item.jobStatus}
                data={item}
                approveJob={approveJob}
                rejectJob={rejectJob}
                currentPage={page}
                deleteJob={deleteJob}
                refreshData={getData}
                showToast={showToast}
                boostJob={boostJob}
                setJobOnHold={setJobOnHold}
              />
            ))}
        </div>
        <div className="active_jobs__pagination">
          <Pagination
            size="large"
            color="#297bca"
            count={lastPage}
            page={page}
            onChange={handleChange}
          />
          <div className="active_jobs__pagination-count">
            Total Count : {totalCount}
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={candidates.length > 0}
        onRequestClose={() => {
          setCandidates([]);
        }}
        className="active_jobs__modal-modal2"
        overlayClassName="active_jobs__modal-overlay2">
        <div className="active_jobs__modal2">
          <div className="candidate_list_title">Candidates</div>
          <div className="candidate_list_items">
            {candidates.map((a, index) => (
              <div className="candidate_list_item">
                <div className="candidate_list_item_no">{index + 1}</div>
                <div>
                  <img
                    src={a.image_id}
                    className="candidate_list_item_img"
                    alt=""
                  />
                  <div className="candidate_list_item_name">
                    {a.name}{' '}
                    {a.is_good_fit ? (
                      <span className="candidate_list_item_name_r">R</span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div
                  className={'candidate_list_item_status'}
                  style={{ fontSize: '1rem', marginRight: '.5rem' }}>
                  {a.profile_viewed ? 'Profile Viewed' : ''}
                </div>
                <div
                  className={
                    'candidate_list_item_status' +
                    (a.request_status === 'ACCEPTED' ? ' status_blue' : '') +
                    (a.request_status === 'REJECTED' ? ' status_red' : '')
                  }>
                  {a.request_status === 'ACCEPTED'
                    ? 'SHORTLISTED'
                    : a.request_status}
                </div>
                <Link
                  className="candidate_list_item_viewProfile"
                  to={`/account/${a.user_id}`}>
                  View Profile
                </Link>
              </div>
            ))}
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={selectedJob.offerId}
        className="active_jobs__modal-modal"
        overlayClassName="active_jobs__modal-overlay">
        <div className="active_jobs__modal">
          Are you sure you want to reject job post for {selectedJob.title} by{' '}
          {selectedJob.userInfo && selectedJob.userInfo[0].name}?
          <div className="active_jobs__modal-btns">
            <button
              className="active_jobs__modal-btn-reject"
              onClick={() => {
                rejectJob(selectedJob.offerId);
              }}>
              Reject
            </button>
            <button
              className="active_jobs__modal-btn-cancel"
              onClick={() => {
                setSelectedJob({});
              }}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={editModalData}
        className="active_jobs__modal-modal"
        overlayClassName="active_jobs__modal-overlay">
        <EditJobPostV2
          cancel={() => setEditModalData(null)}
          dataProps={editModalData}
          showToast={showToast}
          id={editModalData?.offerId}
          refresh={() => setTimeout(getData, 500)}
        />
      </ReactModal>
      {toastMessage && <div className="active_jobs__toast">{toastMessage}</div>}
    </div>
  );
}
