
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Link,
  Box,
  Grid,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';

import { sendHttpCall, Toast } from 'shareService/httpService';
import { message } from '../../../config';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 10
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DataTable = props => {
  const { className, ...rest } = props;



  const classes = useStyles();
  //const [arrayDatas, setarrayDatas] = useState([]);
  const [Requests, setRequests] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleSelectAll = event => {

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = Requests.map(Request => Request.score_permission_request_id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getAllscoreRequest = () => {
    sendHttpCall('GET', 'user_score_permission_request', null, props).then((response) => {
      if (response) {
        setRequests(response);
        setSelectedStatus(response.status)
      }

    });
  }

  const handleChange = (event, data) => {
    
    setSelectedStatus(event.target.value)
    let body = {
      status: event.target.value
    }
    sendHttpCall('POST', `update_status/${data.score_permission_request_id}`, body, props).then((response) => {
      
      Toast('Score Permission Request Satus' + message.UPADTE);

      getAllscoreRequest();
    });
  }

  useEffect(() => {
   
    getAllscoreRequest()
  }, [])


  return (
    <div className={classes.content}>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.length === Requests.length}
                        color="primary"
                        indeterminate={
                          selectedUsers.length > 0 &&
                          selectedUsers.length < Requests.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Source User</TableCell>
                    <TableCell>Target User</TableCell>
                    <TableCell>Source User</TableCell>
                    <TableCell>Status</TableCell>



                  </TableRow>
                </TableHead>
                <TableBody>
                  {Requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((Request, index) => (
                      <TableRow

                        className={classes.tableRow}
                        hover
                        key={Request.score_permission_request_id}
                        selected={selectedUsers.indexOf(Request.score_permission_request_id) !== -1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUsers.indexOf(Request.score_permission_request_id) !== -1}
                            color="primary"
                            onChange={event => handleSelectOne(event, Request.score_permission_request_id)}
                            value="true"
                          />
                        </TableCell>


                        <TableCell>{Request.source_user_id}</TableCell>
                        <TableCell>{Request.target_user_id}</TableCell>
                        <TableCell>{Request.message}</TableCell>
                        <TableCell>
                          <div style={{ width: 100 }}>
                            <Select
                              disableUnderline={true}
                              value={Request.status}
                              onChange={(event) => {
                                handleChange(event, Request)
                              }}>
                              <MenuItem value="REQUESTED">REQUESTED</MenuItem>
                              <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
                              <MenuItem value="REJECTED">REJECTED</MenuItem>
                              <MenuItem value="IGNORED">IGNORED</MenuItem>

                            </Select>
                          </div>
                        </TableCell>

                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={Requests.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

DataTable.propTypes = {
  //  className: PropTypes.string,
  // users: PropTypes.array.isRequired,
  //parentCallBack:propTypes.func,
};


export default DataTable;