import React, { useRef, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import styles from './design.module.scss';
import CheckIcon from '@material-ui/icons/Check';
import { sendHttpCall } from 'shareService/httpService';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import { selectYesterday } from '../../config';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { useEffect } from 'react';

export default function ClickTracking(props) {
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);

  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate()))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  };
  const getTimeIn12Hours = (date) => {
    const hour = date.getHours();
    return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
      date.getMinutes()
    )} ${hour > 12 ? 'PM' : 'AM'}`;
  };
  const formatDate = (date, variation = 1) =>
    `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
      date.getMonth() + 1
    )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    localStorage.getItem('startDate')
      ? localStorage.getItem('startDate')
      : moment(new Date(start.setFullYear(2019))).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    localStorage.getItem('endDate')
      ? localStorage.getItem('endDate')
      : moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('selectedPeriodUserSearch')
      ? localStorage.getItem('selectedPeriodUserSearch')
      : 'All time'
  );
  const formatTime = (time) => {
    const date = new Date(time);

    return getTimeIn12Hours(date) + ', ' + formatDate(date);
  };
  const [selectedJob, selectJob] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 20
  );

  const getLocaleTime = (time) => {
    if (time)
      if (props.match.params.type === 'active_member')
        return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
      else return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    else return '';
  };

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getTableData = async () => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);

      let url =
        'tracking/users_by_template?type=' +
        props.match.params.type.toLowerCase() +
        '&template=' +
        props.match.params.source.toLowerCase() +
        '&link=' +
        props.match.params.link.toLowerCase() +
        '&limit=' +
        limit +
        '&page=' +
        page +
        `&start_date=${props.match.params.startDate}&end_date=${props.match.params.endDate}`;

      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
        setTotalCount(
          response.totalCount
            ? response.totalCount[0].count
            : response.count[0].count
        );
        resolve(null);
      });
    });
  };
  useEffect(() => {
    getTableData();
  }, [page, limit, props.match.params.startDate, props.match.params.endDate]);

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
  const classes = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.match.params.type} Clicks</div>
      <MaterialTable
        isLoading={isLoading}
        columns={
          props.match.params.type === 'notifications'
            ? [
                {
                  title: 'S No.',
                  cellStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  headerStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => page * 20 + rowData.tableData.id + 1
                },
                {
                  title: 'Name',
                  field: 'name',
                  cellStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },

                  headerStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => {
                    let data = rowData[0]
                      ? rowData[0].Company || ''
                      : rowData.Company || '';
                    let orgId = data.split('>')[0];
                    data = data.replace(orgId + '>', '');
                    const verified = data.startsWith('1-');
                    return (
                      <div
                        style={{
                          cursor: 'pointer',
                          // fontWeight: 500,
                          textTransform: 'capitalize',
                          // color: '#297bca'
                          // fontSize: '.85rem',
                          fontFamily: 'Roboto',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        onClick={() => {
                          props.history.push(`/account/${rowData.user_id}`);
                        }}>
                        {rowData.name}
                      </div>
                    );
                  }
                },
                {
                  title: 'Mobile Number',
                  field: 'mobile_number'
                },
                {
                  title: 'Push Notification Clicked',
                  render: (rowData) =>
                    !!rowData.notification_from_panel ? 'Yes' : ''
                },
                {
                  title: 'Clicked at',
                  field: 'clicked_at'
                }
              ]
            : [
                {
                  title: 'S No.',
                  cellStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  headerStyle: {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => page * 20 + rowData.tableData.id + 1
                },
                {
                  title: 'Name',
                  field: 'name',
                  cellStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },

                  headerStyle: {
                    position: 'sticky',
                    left: 40,
                    backgroundColor: '#FFF'
                  },
                  render: (rowData) => {
                    let data = rowData[0]
                      ? rowData[0].Company || ''
                      : rowData.Company || '';
                    let orgId = data.split('>')[0];
                    data = data.replace(orgId + '>', '');
                    const verified = data.startsWith('1-');
                    return (
                      <div
                        style={{
                          cursor: 'pointer',
                          // fontWeight: 500,
                          textTransform: 'capitalize',
                          // color: '#297bca'
                          // fontSize: '.85rem',
                          fontFamily: 'Roboto',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        onClick={() => {
                          props.history.push(`/account/${rowData.user_id}`);
                        }}>
                        {rowData.name}
                      </div>
                    );
                  }
                },
                {
                  title: 'Mobile Number',
                  field: 'mobile_number'
                },
                {
                  title: 'Clicked at',
                  field: 'clicked_at'
                }
              ]
        }
        data={data}
        totalCount={totalCount}
        page={page}
        title=""
        onChangePage={(e) => {
          console.log('Changing page - ' + e);
          setPage(e);
        }}
        onChangeRowsPerPage={(e) => setLimit(e)}
        options={{
          search: false,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions:
            props.match.params.type === 'active_member'
              ? [20]
              : [10, 20, 30, 40, 50],
          sorting: false
        }}
      />
    </div>
  );
}
