import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import ReactModal from 'react-modal';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({}));

export default function PotentialCandidates(props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [uniqueJobCount, setUniqueJobCounts] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('potentialCandidatePage'), 10) || 0
  );
  const [pageSize, setPageSize] = useState(
    parseInt(sessionStorage.getItem('potentialCandidateSize'), 10) || 10
  );
  const getData = () => {
    console.log(props.match.params.offerId);
    let url = `/jobs/potential_candidates?page=${page}&limit=${pageSize}&offerId=${props.match.params.offerId}`;
    setIsLoading(true);
    sendHttpCall('GET', url, null, props).then((response) => {
      sessionStorage.removeItem('potentialCandidateSize');
      sessionStorage.removeItem('potentialCandidatePage');
      setData(response.data);
      setTotalCount(response.count);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, [page]);
  useEffect(() => {
    if (!data.length) return;
    if (page === 0) getData();
    else setPage(0);
  }, [pageSize]);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          isLoading={isLoading}
          columns={[
            {
              title: 'S No.',
              render: (rowData) => page * pageSize + rowData.tableData.id + 1
            },
            {
              title: 'Name',
              field: 'name',
              render: (rowData) => (
                <div
                  onClick={() => {
                    sessionStorage.setItem('potentialCandidatePage', page);
                    sessionStorage.setItem('potentialCandidateSize', pageSize);
                    props.history.push(`/account/${rowData.user_id}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.name}
                </div>
              )
            },
            // { title: 'Mobile Number', field: 'mobile_number' },
            { title: 'Skills', field: 'skills' },
            { title: 'Designations', field: 'designations' },
            { title: 'Last Session', field: 'last_session' }
          ]}
          data={data}
          totalCount={totalCount}
          title="Potential Candidates"
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 50]
          }}
        />
      </CardContent>{' '}
    </Card>
  );
}
