import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {
        loaded: false,
        allAdmins: [],
        allInActiveAdmins: []
    }
}

const staticConfigReducer = createSlice({
    name: "staticConfig",
    initialState: initialState,
    reducers: {
        loadStaticData: (state, action) => {
            console.log("Action CAllled");
            console.log(action);
            state.data = {
                ...state.data,
                ...action.payload,
                loaded: true
            }
        }
    }
});

export const { loadStaticData } = staticConfigReducer.actions;
export default staticConfigReducer.reducer;