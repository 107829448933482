import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function InterviewIcon(props) {
    return (
        <SvgIcon viewBox="0 0 128 128">
            <path class="cls-1" d="M38.109,58.652c-2.33047.96751-4.48018,2.20618-5.346,4.79444-.17461.52383-.92951,4.64863-1.27669,6.56721a.826.826,0,0,0,.81448.97472H69.68485a.8171.8171,0,0,0,.41187-.10989.82619.82619,0,0,0,.40157-.86484c-.34611-1.91858-1.10205-6.04338-1.27669-6.56721-.86479-2.58826-3.0145-3.82693-5.34495-4.79444-.29787-.12326-.5988-.24241-.89975-.35949q-3.04735-1.18169-6.09372-2.36334a6.39833,6.39833,0,0,1-11.78071,0q-3.04742,1.18166-6.09374,2.36334C38.70782,58.40961,38.40686,58.52876,38.109,58.652Z" />

            <path class="cls-1" d="M50.992,53.74957a7.51008,7.51008,0,0,0,7.3692-6.07387l1.14635-5.90717a7.90851,7.90851,0,0,0-7.8349-8.974H50.31139a7.90766,7.90766,0,0,0-7.83422,8.974l1.146,5.90717A7.51028,7.51028,0,0,0,50.992,53.74957Z" />
            <path class="cls-2" d="M123.79879,95.88317a6.36343,6.36343,0,0,0-3.42983-4.618c-5.96369-2.97475-19.051-7.36776-19.051-7.36776V79.57689l.36476-.27451a12.50068,12.50068,0,0,0,4.74555-7.94683l.07437-.45867h.35588a4.82817,4.82817,0,0,0,4.46918-3.00836,5.24188,5.24188,0,0,0,.657-2.54623,4.83546,4.83546,0,0,0-.34361-1.792,2.50437,2.50437,0,0,0-.96854-1.55822L109.465,61.259l.301-1.3103c2.17976-9.50861-5.18631-18.0717-15.07564-18.309-.2408-.00342-.47987-.007-.71533-.00173-.23734-.00529-.47457-.00168-.7154.00173-9.89105.23734-17.2572,8.80043-15.07573,18.309L78.485,61.259l-1.20763.73306A2.50686,2.50686,0,0,0,76.307,63.5503a4.87245,4.87245,0,0,0-.34347,1.792,5.26193,5.26193,0,0,0,.65686,2.54623,4.83265,4.83265,0,0,0,4.47106,3.00836h.35591l.07262.45867a12.49592,12.49592,0,0,0,4.74716,7.94683l.36476.27451v4.32051s-13.08719,4.393-19.05083,7.36776a6.3588,6.3588,0,0,0-3.42986,4.618,138.5869,138.5869,0,0,0-1.20234,16.02476H125.001A138.57227,138.57227,0,0,0,123.79879,95.88317Z" />

            <path class="cls-2" d="M77.50834,79.79419a19.62084,19.62084,0,0,1-1.656-3.22917,11.63934,11.63934,0,0,1-1.24964-.73527H9.22473V24.27027a1.51964,1.51964,0,0,1,1.30991-1.66852H91.445a1.52662,1.52662,0,0,1,1.31485,1.66852V34.76253c.11176-.00421.22227-.01434.33435-.017l.069-.00125c.28305-.004.54926-.00633.8115-.00337.2618-.0038.52633-.00043.7934.00315l.08756.00147a24.08007,24.08007,0,0,1,4.13.4611V21.88953a5.79657,5.79657,0,0,0-5.79746-5.79746H8.79643A5.79657,5.79657,0,0,0,2.999,21.88953V80.16292a5.80086,5.80086,0,0,0,5.79746,5.7875H37.71394L32.315,99.7368a1.37008,1.37008,0,0,0,1.275,1.87269H56.55368c.18989-2.39048.44856-4.83662.80033-6.89049A13.3026,13.3026,0,0,1,64.509,85.09125C67.89218,83.40344,73.213,81.35785,77.50834,79.79419Z" />
        </SvgIcon>
    )
}