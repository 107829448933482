/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
// import ResumeUploadImg from "assets/svg/resumeUpload.svg";
import styles from './ResumeUploader.module.scss';

export default function ResumeUploader({
  restoredData,
  onUploadSuccess = () => {},
  showApplyButton = false,
  type = 'AS_IS',
  readOnly,
  onActionButtonClick
}) {
  const [resumeData, setResumeData] = useState({
    name: '',
    fileType: '',
    link: ''
  });
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);

  const fileUploader = useRef(null);
  const chooseFile = () => {
    fileUploader.current.click();
  };
  const uploadResume = async (resume) => {};
  const attachFile = async (evt) => {
    const resumeFile = evt.target.files[0];
    if (resumeFile) {
      fileUploader.current.value = null;
    }

    uploadResume(resumeFile);
  };
  useEffect(() => {
    console.log(restoredData);
    if (restoredData) setResumeData(restoredData);
  }, [!!restoredData]);

  return (
    <div className={styles.container}>
      <div>
        {(file || resumeData.name) && (
          <div
            className={`${styles.uploadItem} ${
              error ? styles.uploadItemError : ''
            }`}
            style={readOnly ? { cursor: 'pointer' } : null}
            onClick={(e) => {
              if (readOnly) {
                window.open(resumeData.link, '_blank').focus();
              }
            }}>
            <div className={styles.uploadItemLabel}>{resumeData.fileType}</div>
            <div className={styles.uploadItemField}>
              <div
                className={styles.uploadItemValue}
                style={readOnly ? { width: '100%' } : null}>
                <div>{resumeData.name}</div>
                {resumeData.lastUpdatedOn && !readOnly ? (
                  <div className={styles.uploadItemUpdatedOn}>
                    Last Updated On{' '}
                    {moment(new Date(resumeData.lastUpdatedOn)).format(
                      'DD/MM/YYYY'
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {!readOnly && (
                <div className={styles.uploadItemButton} onClick={chooseFile}>
                  {error ? 'Try Again' : 'Change'}
                </div>
              )}
            </div>
          </div>
        )}
        {showApplyButton && resumeData.name && !error ? (
          <button className={styles.actionButton} onClick={onActionButtonClick}>
            Apply
          </button>
        ) : type === 'MINIMAL' ? (
          resumeData.name ? null : (
            <div className={styles.uploadItemAlternate} onClick={chooseFile}>
              <div className={styles.uploadItemAlternateText}>
                Upload Resume
              </div>

              <div className={styles.uploadItemAlternateButton}>
                <UploadIcon />
              </div>
            </div>
          )
        ) : (
          <button className={styles.uploadButton} onClick={chooseFile}>
            Upload Resume
          </button>
        )}
        {!readOnly && (
          <div
            className={`${styles.uploadButtonTxt} ${
              type === 'MINIMAL' ? styles.uploadButtonTxt2 : ''
            }`}>
            DOC, DOCX, PDF (2 MB)
          </div>
        )}
        {error ? <div className={styles.uploadButtonError}>{error}</div> : ''}
      </div>
      <input
        type="file"
        style={{
          position: 'absolute',
          opacity: 0,
          zIndex: '-222',
          maxWidth: '0'
        }}
        id="file"
        ref={fileUploader}
        name=""
        accept=".pdf,.doc,.docx"
        onChange={attachFile}
      />
    </div>
  );
}

const UploadIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8043 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z"
      fill="#5D5D5D"
    />
  </svg>
);
