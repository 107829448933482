import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { data, options } from './chart';
import { merge } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 500,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const TZPoints = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  merge(options, {
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          stepSize: 100
        }
      }]
    },
  });

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="TZ Points"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <HorizontalBar
            data={props.data}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
      </CardActions>
      {/* {options.responsive} */}
    </Card>
  );
};

TZPoints.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

export default TZPoints;
