import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UserSkillRquest from './UserSkillRquest';
import UserDesignationRequest from './UserDesignationRequest';
import UserOrganisationRequest from './UserOrganisationRequest';
import UserInterestRequest from './UserInterestRequest';
import SignIn from 'views/SignIn';
import UserImageRequest from './UseImageRequest';
import UserJobFunctionRequest from './UserJobFunctionRequest';
import UserIndustryTypeRequest from './UserIndustryTypeRequest';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CustomTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [storage, setStorage] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {

  })

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs

            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            centered={true}
          >
            <Tab label="Designation" {...a11yProps(0)} />
            <Tab label="Organisation" {...a11yProps(1)} />
            <Tab label="Skills" {...a11yProps(2)} />
            {/* <Tab label="Interests" {...a11yProps(3)} /> */}
            <Tab label="Job Function" {...a11yProps(3)} />
            <Tab label="Industry Type" {...a11yProps(4)} />

          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} >
          <UserDesignationRequest prop={props}></UserDesignationRequest>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserOrganisationRequest prop={props}></UserOrganisationRequest>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserSkillRquest prop={props}></UserSkillRquest>
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
       <UserInterestRequest prop={props}></UserInterestRequest>
      </TabPanel> */}
        {/* <TabPanel value={value} index={4}>
       <UserImageRequest prop={props}></UserImageRequest>
      </TabPanel> */}
        <TabPanel value={value} index={3}>
          <UserJobFunctionRequest prop={props}></UserJobFunctionRequest>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <UserIndustryTypeRequest prop={props}></UserIndustryTypeRequest>
        </TabPanel>
      </div>
    </>
  );
}

function SIN() {
  return (<SignIn></SignIn>)
}