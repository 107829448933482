import React from 'react';
import CreatableSelect from 'react-select/creatable';
import styles from './TimeDropDown.module.scss';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#f5f5f5', // Soft background color
    borderColor: '#dcdcdc', // Soft border color
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#b3b3b3' // Slightly darker on hover
    },

  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999 // Ensure the dropdown menu is above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#e6e6e6'
      : state.isFocused
      ? '#f0f0f0'
      : '#fff',
    color: '#333'
  }),
  dropdownIndicator: () => ({
    display: 'none' // Remove the dropdown icon
  }),
  indicatorSeparator: () => ({
    display: 'none' // Remove the line between the input and dropdown
  })
};

const TimeDropdown = ({ value, onChange, options }) => {
  const formattedOptions = options.map((time) => ({
    value: time,
    label: time
  }));

  const handleChange = (selectedOption) => {
    const newValue = selectedOption ? selectedOption.value : '';
    onChange({ target: { value: newValue } });
  };

  const handleInputChange = (inputValue) => {
    const [hours, minutes] = inputValue.split(':');
    if (hours && minutes) {
      let formattedHours = parseInt(hours, 10);
      const period = formattedHours >= 12 ? 'PM' : 'AM';
      formattedHours = formattedHours % 12 || 12;
      const formattedMinutes = minutes.padStart(2, '0');
      const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
      onChange({ target: { value: formattedTime } });
    }
  };

  return (
    <CreatableSelect
      className={styles.customScrollbar}
      value={formattedOptions.find((option) => option.value === value)}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={formattedOptions}
      isSearchable
      styles={customStyles}
      placeholder="Select or type time"
    />
  );
};

export default TimeDropdown;
