import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { VictoryPie, VictoryTheme, VictoryLabel } from 'victory';
import { sendHttpCall } from 'shareService/httpService';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import countries from './countries';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ReactSelect from 'react-select';
import Loader from 'components/Loader';
import './style.scss';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';

const startInitial = new Date(2019, 1, 1);
const dateToday = new Date();
const getLabelVal = (val) => {
  switch (val) {
    case 'totalSignUps':
      return 'Total SignUps';
    case 'joinedViaCampaigns':
      return 'Campaigns';
    case 'directlyJoined':
      return 'Directly';
    case 'joinedViaSecondarySource':
      return 'Others';
    case 'joinedViaReferrals':
      return 'Referrals';
    default:
      return val;
  }
};
const options = [
  // {
  //   label: 'All Time',
  //   value: 'All Time',
  //   startDate: moment(startInitial).format('YYYY-MM-DD'),
  //   endDate: moment(dateToday).format('YYYY-MM-DD')
  // },
  {
    label: 'Today',
    value: 'Today',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD')
  },
  {
    label: 'Yesterday',
    value: 'Yesterday',
    startDate: moment(new Date().setDate(new Date().getDate() - 1)).format(
      'YYYY-MM-DD'
    ),
    endDate: moment(new Date().setDate(new Date().getDate() - 1)).format(
      'YYYY-MM-DD'
    )
  },
  {
    label: 'Last 7 Days',
    value: 'Last 7 Days',
    startDate: moment(new Date().setDate(new Date().getDate() - 7)).format(
      'YYYY-MM-DD'
    ),
    endDate: moment(dateToday).format('YYYY-MM-DD')
  },
  {
    label: 'Last 30 Days',
    value: 'Last 30 Days',
    startDate: moment(new Date().setDate(new Date().getDate() - 30)).format(
      'YYYY-MM-DD'
    ),
    endDate: moment(dateToday).format('YYYY-MM-DD')
  },
  // {
  //   label: 'Last Year',
  //   value: 'Last Year',
  //   startDate: moment(new Date().setDate(new Date().getDate() - 365)).format(
  //     'YYYY-MM-DD'
  //   ),
  //   endDate: moment(dateToday).format('YYYY-MM-DD')
  // },
  {
    label: 'Custom Date',
    value: 'Custom Date'
  }
];

export default function ReportGenerator(props) {
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataForChart, setDataForChart] = useState([]);
  const [dataForChart2, setDataForChart2] = useState([]);
  const [dataForUser2Chart, setdataForUser2Chart] = useState([]);
  const [dataForChart3, setDataForChart3] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [totalSignUps, setTotalSignUps] = useState(0);
  const [selectedCampaignType, setSelectedCampaignType] = useState('');
  const [selectedCampaignTypeHirer, setSelectedCampaignTypeHirer] = useState('');
  const [selectedCampaignType2, setSelectedCampaignType2] = useState('');
  const [dataForJobSeekerCampaigns, setDataForJobSeekerCampaigns] = useState([]);
  const [dataForHirerCampaigns, setDataForHirerCampaigns] = useState([]);
  // const [dataForJobSeekerStatus2Campaigns, setDataForJobSeekerStatus2Campaigns] = useState([]);
  // const [dataForHirerStatus2Campaigns, setDataForHirerStatus2Campaigns] = useState([]);
  const [dataForCampaigns2, setDataForCampaigns2] = useState([]);
  const getPercent = (val, total) => {
    return ((val / total) * 100).toString().substring(0, 4) + '%';
  };
  const getData = () => {
    const url = `dashboard/reportData?start_date=${startDate.toString()}&end_date=${endDate.toString()}`;
    setLoading(true)
    sendHttpCall('GET', url, null, props).then((response) => {
      let { importantData, additionalData } = response;
      let chartArray = [];
      let tableArray = [
     
        {
          Source: 'Marketing Campaigns',
          SignUps: importantData.joinedViaCampaigns,
          Percentage: getPercent(
            importantData.joinedViaCampaigns,
            importantData.totalSignUps
          )
        },
        {
          Source: 'Directly Joined Job Seekers',
          SignUps: importantData.directlyJoined-importantData.directlyJoinedHirers,
          Percentage: getPercent(
            importantData.directlyJoined-importantData.directlyJoinedHirers,
            importantData.totalSignUps
          )
        },
        {
          Source: 'Directly Joined Hirers',
          SignUps: importantData.directlyJoinedHirers,
          Percentage: getPercent(
            importantData.directlyJoinedHirers,
            importantData.totalSignUps
          )
        },
        {
          Source: 'Total Users',
          SignUps: importantData.totalSignUps,
          Percentage: ''
        }
      ];
      const arrayChartCampaign = [];
      const chartArrayDeviceUser2=[];
      for (const d of additionalData.users2DataByDeviceType) {
        let percent = getPercent(d.count, importantData.totalSignUps);
        chartArrayDeviceUser2.push({
          x: d.device_type,
          y: d.count,
          percent
        });
      }
      const chartArrayDevice = [];
      for (const d of additionalData.dataByDeviceType) {
        let percent = getPercent(d.count, importantData.totalSignUps);
        chartArrayDevice.push({
          x: d.device_type,
          y: d.count,
          percent
        });
      }
      const chartArrayCountry = [];
      for (const d of additionalData.dataByCountry) {
        let percent = getPercent(d.count, importantData.totalSignUps);
        chartArrayCountry.push({
          x: countries[d.countyCode],
          y: d.count,
          percent,
          countryCode: d.countyCode
        });
      }
      for (const d in importantData) {
        if (
          importantData.hasOwnProperty(d) &&
          d !== 'totalSignUps' &&
          d !== 'referralData' &&
          d !== 'joinedViaReferrals' &&
          d !== 'directlyJoinedHirers' &&
          getLabelVal(d) !== 'Others'
        ) {
          chartArray.push({
            x: getLabelVal(d),
            y: importantData[d]
          });
        }
      }
      setLoading(false)
     console.log("chartarray"+chartArray);
      setData(response);
      setTotalSignUps(importantData.totalSignUps);
      setDataForChart(chartArray);
      setDataForChart2(chartArrayDevice);
      setdataForUser2Chart(chartArrayDeviceUser2)
      setDataForChart3(chartArrayCountry);
      setDataForTable(tableArray);
      setDataForHirerCampaigns(additionalData.hirerCampaignSourceDataByType);
      console.log("hirer data: "+ JSON.stringify(additionalData,null,2))
      setDataForJobSeekerCampaigns(additionalData.jobSeekersCampaignSourceDataByType);
      setDataForCampaigns2(additionalData.status2ViaCampaignsDataByType);
      // setDataForHirerStatus2Campaigns(additionalData.hirerStatus2ViaCampaignsDataByType);
      // setDataForHirerStatus2Campaigns();
      // setDataForCampaigns(additionalData.hirerStatus2ViaCampaignsDataByType);
      // setDataForCampaigns2(additionalData.status2ViaCampaignsDataByType);
      setSelectedCampaignType('');
      setSelectedCampaignTypeHirer('');
      setSelectedCampaignType2('');
    });
  };
  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const getTableData = (campaignType) => {
    const campaignData = data.additionalData.hirerCampaignSourceData[campaignType];
    if (!campaignData || !campaignData.campaigns) return [];
  
    return Object.entries(campaignData.campaigns).map(([campaignName, campaignDetails]) => ({
      marketing_campaign_name: campaignName || 'N/A', // Handle empty campaign names
      count: campaignDetails.count,
      verified_count: campaignDetails.verified_count,
      job_posted: campaignDetails.job_posted,
      paid_hirers: campaignDetails.paid_hirers,
    }));
  };
  
  // ReactTable component for displaying details based on selected campaign type
  const CampaignDetailsTable = ({ campaignType }) => {
    const tableData = getTableData(campaignType);
  
    return (
      <ReactTable
        data={tableData}
        getTdProps={(state, rowInfo, column, instance) => ({
          onClick: (e, handleOriginal) => {
            if (!rowInfo || !rowInfo.row) return;
            if (rowInfo.row.marketing_campaign_name === 'Total Sign Ups') return;
            
            console.log('Row details:', rowInfo.row);
            const isDirectlyJoined = campaignType.includes('Directly Joined');
            let url = `#/data/by_campaign_hirer_s3/${campaignType}`;

            if (!isDirectlyJoined) {
                url += `-${rowInfo.row.marketing_campaign_name}`;
            }
            url += `/${startDate.toString()}/${endDate.toString()}`;
            window.open(url);
        },
        style: {
          cursor: rowInfo && rowInfo.row ? 'pointer' : 'default'
        },
        })}
        columns={[
          {
            Header: `${campaignType} Campaign`,
            accessor: 'marketing_campaign_name',
            width: 230,
          },
          {
            Header: '#Onboarded',
            accessor: 'count',
          },
          {
            Header: '#Verified',
            accessor: 'verified_count',
          },
          {
            Header: '#Job Posted',
            accessor: 'job_posted',
          },
          {
            Header: '#Paid Hirers',
            accessor: 'paid_hirers',
          },
        ]}
        
        sortable={false}
        showPagination={tableData.length > 20}
        minRows={0}
      />
    );
  };

  if(loading) {
    return (
      <Loader/>
    )
  }
  return (
    <div className="reportGen__container">
      <div className="reportGen__title">Report Generator</div>
      <div className="reportGen__period-filter">
        <div className="reportGen__period-filter-label">SELECT PERIOD</div>
        <div className="reportGen__period-filter-select">
          <ReactSelect
            options={options}
            value={
              showCustomDate
                ? { value: 'Custom Date', label: 'Custom Date' }
                : options.find((e) => e.startDate === startDate)
            }
            onChange={(e) => {
              if (e.value === 'Custom Date') {
                setShowCustomDate(true);
              } else if (startDate !== e.startDate) {
                setStartDate(e.startDate);
                setEndDate(e.endDate);
                setShowCustomDate(false);
              }
            }}
          />
        </div>
      </div>
      {showCustomDate && (
        <div style={{ marginTop: '1rem' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="SELECT START DATE"
              format="yyyy-dd-MM"
              maxDate={new Date(endDate)}
              value={new Date(startDate)}
              onChange={(e) => setStartDate(moment(e).format('YYYY-MM-DD'))}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
            <KeyboardDatePicker
              style={{ marginLeft: '2rem' }}
              margin="normal"
              id="date-picker-dialog"
              label="SELECT END DATE"
              maxDate={new Date()}
              value={new Date(endDate)}
              format="yyyy-dd-MM"
              onChange={(e) => setEndDate(moment(e).format('YYYY-MM-DD'))}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      )}
      <div className="reportGen__section-container">
        <div className="reportGen__section-label">User Sign Up Source</div>
        <div className="reportGen__section-data">
          <div className="reportGen__table-container">
            <ReactTable
              data={dataForTable}
              columns={[
                {
                  Header: 'Source',
                  accessor: 'Source'
                },
                {
                  Header: 'Sign Ups',
                  accessor: 'SignUps'
                },
                {
                  Header: 'Percentage',
                  accessor: 'Percentage'
                }
              ]}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (!rowInfo || !rowInfo.row) return;
                    // if (
                    //   rowInfo.row.Source === 'Referrals (Campus Ambassadors)'
                    // ) {
                    //   props.history.push(
                    //     `data/referrals_campus_ambassadors/general/${startDate.toString()}/${endDate.toString()}`
                    //   );
                    // }
                    // if (rowInfo.row.Source === 'Referrals (Others)') {
                    //   props.history.push(
                    //     `data/referrals_others/general/${startDate.toString()}/${endDate.toString()}`
                    //   );
                    // }
                  },
                  style: {
                    cursor:
                      rowInfo &&
                      rowInfo.row &&
                      (rowInfo.row.Source ===
                        'Referrals (Campus Ambassadors)' ||
                        rowInfo.row.Source === 'Referrals (Others)')
                        ? 'pointer'
                        : 'default'
                  }
                };
              }}
              sortable={false}
              showPagination={false}
              defaultPageSize={4}
            />
          </div>
          <div className="reportGen__chart-container">
            <VictoryPie
              colorScale={[
                'tomato',
                'DarkCyan',
                'orange',
                'gold',
              ]}
              data={dataForChart}
              animate={{ duration: 1300 }}
              theme={VictoryTheme.material}
              labelComponent={
                <VictoryLabel renderInPortal style={{ fontWeight: 500 }} />
              }
              style={{ parent: { overflow: 'visible' } }}
              labels={({ datum }) =>
                `${datum.x}: ${((datum.y / totalSignUps) * 100)
                  .toString()
                  .substring(0, 4)}%`
              }
            />
          </div>
        </div>
      </div>{' '}
      <div className="reportGen__section-container">
        <div className="reportGen__section-label">
          Job Seeker status 3 By Campaign Types
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="reportGen__section-data">
            <div className="reportGen__table-container">
              <ReactTable
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo.row.type === 'Total Sign Ups') return;
                      setSelectedCampaignType(rowInfo.row.type);
                    },
                    style: {
                      cursor:
                        rowInfo.row.type === 'Total Sign Ups'
                          ? 'default'
                          : 'pointer'
                    }
                  };
                }}
                data={
                  data &&
                  data.additionalData &&
                  data.additionalData.jobSeekersCampaignSourceData	
                    ? [
                        ...dataForJobSeekerCampaigns,
                        {
                          type: 'Total Sign Ups',
                          count: data.additionalData.jobSeekerStatus3TotalSignUps
                        }
                      ]
                    : []
                }
                columns={[
                  {
                    Header: 'Campaign Type',
                    accessor: 'type'
                  },
                  {
                    Header: 'Sign Ups',
                    accessor: 'count'
                  }
                ]}
                sortable={false}
                showPagination={false}
                minRows={0}
              />
            </div>
          </div>
          {selectedCampaignType !== '' && (
            <div
              className="reportGen__section-data"
              style={{ marginLeft: '1rem' }}>
              <div className="reportGen__table-container2">
                <ReactTable
                  data={
                    data &&
                    data.additionalData &&
                    data.additionalData.jobSeekersCampaignSourceDataByType2 &&
                    data.additionalData.jobSeekersCampaignSourceDataByType2[
                      selectedCampaignType
                    ] &&
                    data.additionalData.jobSeekersCampaignSourceDataByType2[
                      selectedCampaignType
                    ].val
                      ? [
                          ...data.additionalData.jobSeekersCampaignSourceDataByType2[
                            selectedCampaignType
                          ].val
                        ]
                      : []
                  }
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (!rowInfo || !rowInfo.row) return;
                        if (rowInfo.row.x === 'Total Sign Ups') return;
                        console.log(rowInfo.row);
                        // props.history.push(
                          window.open(
                          `#/data/by_campaign/${
                            selectedCampaignType +
                            '-' +
                            rowInfo.row.marketing_campaign_name
                          }/${startDate.toString()}/${endDate.toString()}`
                        );
                      },
                      style: {
                        cursor: rowInfo && rowInfo.row ? 'pointer' : 'default'
                      }
                    };
                  }}
                  columns={
                    selectedCampaignType === 'Others'
                      ? [
                          {
                            Header: 'NA',
                            id: 'directlyJoinedJobSeekers',
                            accessor: () => 'NA', 
                            width: 230,
                          },
                        ]
                      : [
                          
                    {
                      Header: selectedCampaignType + ' Campaign Name',
                      accessor: 'marketing_campaign_name'
                    },
                    {
                      Header: 'Sign Ups',
                      accessor: 'count'
                    }
                  ]}
                  sortable={false}
                  showPagination={
                    data?.additionalData?.jobSeekersCampaignSourceDataByType2[
                      selectedCampaignType
                    ]?.val?.length > 20
                  }
                  minRows={0}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="reportGen__section-container">
        <div className="reportGen__section-label">
          Hirer status 3 By Campaign Types
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="reportGen__section-data">
            <div className="reportGen__table-container">
              <ReactTable
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo.row.type === 'Total Sign Ups') return;
                      setSelectedCampaignTypeHirer(rowInfo.row.type);
                    },
                    style: {
                      cursor:
                        rowInfo.row.type === 'Total Sign Ups'
                          ? 'default'
                          : 'pointer'
                    }
                  };
                }}
                data={
                  data &&
                  data.additionalData &&
                  data.additionalData.hirerCampaignSourceData
                    ? [
                        ...dataForHirerCampaigns,
                        {
                          type: 'Total Sign Ups', 
                          count: data.additionalData.hirerStatus3TotalSignUps 
                        },
                        
                      ]
                    : []
                }
                columns={[
                  {
                    Header: 'Source',
                    accessor: 'type'
                  },
                  {
                    Header: '#Hirers Onboarded',
                    accessor: 'count'
                  },
                  

                ]}
                sortable={false}
                showPagination={false}
                minRows={0}
              />
            </div>
          </div>
          {selectedCampaignTypeHirer !== '' && (
  <div className="reportGen__section-data" style={{ marginLeft: '1rem' }}>
    <div className="reportGen__table-container2">
      <CampaignDetailsTable campaignType={selectedCampaignTypeHirer} />
    </div>
  </div>
)}
        </div>
      </div>
      <div className="reportGen__section-container">
        <div className="reportGen__section-label">
          User Status 2 By Campaign Types
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="reportGen__section-data">
            <div className="reportGen__table-container">
              <ReactTable
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo.row.type === 'Total Status 2') return;
                      setSelectedCampaignType2(rowInfo.row.type);
                    },
                    style: {
                      cursor:
                        rowInfo.row.type === 'Total Status 2'
                          ? 'default'
                          : 'pointer'
                    }
                  };
                }}
                data={
                  data &&
                  data.additionalData &&
                  data.additionalData.status2ViaCampaignsData
                    ? [
                        ...dataForCampaigns2,
                        {
                          type: 'Total Status 2',
                          count: data.additionalData.status2ViaCampaigns
                        }
                      ]
                    : []
                }
                columns={[
                  {
                    Header: 'Campaign Type',
                    accessor: 'type'
                  },
                  {
                    Header: 'Status 2',
                    accessor: 'count'
                  }
                ]}
                sortable={false}
                showPagination={false}
                minRows={0}
              />
            </div>
          </div>
          {selectedCampaignType2 !== '' && (
            <div
              className="reportGen__section-data"
              style={{ marginLeft: '1rem' }}>
              <div className="reportGen__table-container">
                <ReactTable
                  data={
                    data &&
                    data.additionalData &&
                    data.additionalData.status2ViaCampaignsDataByType2 &&
                    data.additionalData.status2ViaCampaignsDataByType2[
                      selectedCampaignType2
                    ] &&
                    data.additionalData.status2ViaCampaignsDataByType2[
                      selectedCampaignType2
                    ].val
                      ? [
                          ...data.additionalData.status2ViaCampaignsDataByType2[
                            selectedCampaignType2
                          ].val
                        ]
                      : []
                  }
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (!rowInfo || !rowInfo.row) return;
                        if (rowInfo.row.x === 'Total Status 2') return;
                        console.log(rowInfo.row);
                        window.open(
                          `#/data/by_campaign_status_2/${
                            selectedCampaignType2 +
                            '-' +
                            rowInfo.row.marketing_campaign_name
                          }/${startDate.toString()}/${endDate.toString()}`
                        );
                      },
                      style: {
                        cursor: rowInfo && rowInfo.row ? 'pointer' : 'default'
                      }
                    };
                  }}
                  columns={[
                    {
                      Header: selectedCampaignType + ' Campaign Name',
                      accessor: 'marketing_campaign_name'
                    },
                    {
                      Header: 'Status 2',
                      accessor: 'count'
                    }
                  ]}
                  sortable={false}
                  showPagination={
                    data?.additionalData?.status2ViaCampaignsDataByType2[
                      selectedCampaignType2
                    ]?.val?.length > 20
                  }
                  minRows={0}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="reportGen__section-container">
        <div className="reportGen__section-label">
          Status 2 By Campaign Types
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="reportGen__section-data">
            <div className="reportGen__table-container">
              <ReactTable
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo.row.type === 'Total Status 2') return;
                      setSelectedCampaignType2(rowInfo.row.type);
                    },
                    style: {
                      cursor:
                        rowInfo.row.type === 'Total Status 2'
                          ? 'default'
                          : 'pointer'
                    }
                  };
                }}
                data={
                  data &&
                  data.additionalData &&
                  data.additionalData.hirerStatus2ViaCampaignsData
                    ? [
                        ...dataForHirerStatus2Campaigns,
                        {
                          type: 'Total Status 2',
                          count: data.additionalData.hirerStatus2ViaCampaignsData
                        }
                      ]
                    : []
                }
                columns={[
                  {
                    Header: 'Campaign Type',
                    accessor: 'type'
                  },
                  {
                    Header: 'Status 2',
                    accessor: 'count'
                  }
                ]}
                sortable={false}
                showPagination={false}
                minRows={0}
              />
            </div>
          </div>
          {selectedCampaignType2 !== '' && (
            <div
              className="reportGen__section-data"
              style={{ marginLeft: '1rem' }}>
              <div className="reportGen__table-container">
                <ReactTable
                  data={
                    data &&
                    data.additionalData &&
                    data.additionalData.hirerCampaignSourceDataByType2 &&
                    data.additionalData.hirerCampaignSourceDataByType2[
                      selectedCampaignType2
                    ] &&
                    data.additionalData.hirerCampaignSourceDataByType2[
                      selectedCampaignType2
                    ].val
                      ? [
                          ...data.additionalData.hirerCampaignSourceDataByType2[
                            selectedCampaignType2
                          ].val
                        ]
                      : []
                  }
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (!rowInfo || !rowInfo.row) return;
                        if (rowInfo.row.x === 'Total Status 2') return;
                        console.log(rowInfo.row);
                        props.history.push(
                          `data/by_campaign_status_2/${
                            selectedCampaignType2 +
                            '-' +
                            rowInfo.row.marketing_campaign_name
                          }/${startDate.toString()}/${endDate.toString()}`
                        );
                      },
                      style: {
                        cursor: rowInfo && rowInfo.row ? 'pointer' : 'default'
                      }
                    };
                  }}
                  columns={[
                    {
                      Header: selectedCampaignType + ' Campaign Name',
                      accessor: 'marketing_campaign_name'
                    },
                    {
                      Header: 'Status 2',
                      accessor: 'count'
                    }
                  ]}
                  sortable={false}
                  showPagination={
                    data?.additionalData?.hirerCampaignSourceDataByType2[
                      selectedCampaignType2
                    ]?.val?.length > 20
                  }
                  minRows={0}
                />
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div
        className="reportGen__section-container"
        style={{ marginBottom: '5rem' }}>
        <div className="reportGen__section-label">User Sign Up By Country</div>
        <div className="reportGen__section-data">
          <div className="reportGen__table-container">
            <ReactTable
              data={[
                ...dataForChart3,
                { x: 'Total SignUps', y: totalSignUps, percent: '' }
              ]}
              columns={[
                {
                  Header: 'Country',
                  accessor: 'x'
                },
                {
                  Header: 'County Code',
                  accessor: 'countryCode'
                },
                {
                  Header: 'Sign Ups',
                  accessor: 'y'
                },
                {
                  Header: 'Percentage',
                  accessor: 'percent'
                }
              ]}
              sortable={false}
              defaultPageSize={10}
              showPageJump={false}
              showPageSizeOptions={false}
              // showPagination={false}
              minRows={2}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (!rowInfo || !rowInfo.row) return;
                    if (rowInfo.row.x === 'Total Sign Ups') return;
                    console.log(rowInfo.row);
                    window.open(
                      `#/data/by_country/${
                        rowInfo.row.countryCode
                      }/${startDate.toString()}/${endDate.toString()}`
                    );
                  },
                  style: {
                    cursor:
                      rowInfo &&
                      rowInfo.row &&
                      rowInfo.row.x === 'Total Sign Ups'
                        ? 'default'
                        : 'pointer'
                  }
                };
              }}
            />
          </div>
        </div>
      </div>{' '}
      <div className="reportGen__section-container">
        <div className="reportGen__section-label">
          User Sign Up By Device Type
        </div>
        <div className="reportGen__section-data">
          <div className="reportGen__table-container">
            <ReactTable
              data={[
                ...dataForChart2,
                { x: 'Total SignUps', y: totalSignUps, percent: '' }
              ]}
              columns={[
                {
                  Header: 'Device Type',
                  accessor: 'x'
                },
                {
                  Header: 'Sign Ups',
                  accessor: 'y'
                },
                {
                  Header: 'Percentage',
                  accessor: 'percent'
                }
              ]}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (!rowInfo || !rowInfo.row) return;
                    if (rowInfo.row.x === 'Total SignUps') return;
                    console.log(rowInfo.row);
                    window.open(
                      `#/data/device_type/${
                        rowInfo.row.x
                      }/${startDate.toString()}/${endDate.toString()}`
                    );
                  },
                  style: {
                    cursor:
                      rowInfo &&
                      rowInfo.row &&
                      rowInfo.row.x === 'Total SignUps'
                        ? 'default'
                        : 'pointer'
                  }
                };
              }}
              sortable={false}
              showPagination={false}
              defaultPageSize={4}
            />
          </div>
          <div className="reportGen__chart-container reportGen__chart-container-2">
            <VictoryPie
              colorScale={['Crimson', 'orange', 'DodgerBlue']}
              data={dataForChart2}
              animate={{ duration: 1300 }}
              theme={VictoryTheme.material}
              labelComponent={
                <VictoryLabel renderInPortal style={{ fontWeight: 500 }} />
              }
              style={{ parent: { overflow: 'visible' } }}
              labels={({ datum }) =>
                `${datum.x}: ${((datum.y / totalSignUps) * 100)
                  .toString()
                  .substring(0, 4)}%`
              }
            />
          </div>
        </div>
      </div>
      <div className="reportGen__section-container">
        <div className="reportGen__section-label">
          User With Status 2 Sign Up By Device Type
        </div>
        <div className="reportGen__section-data">
          <div className="reportGen__table-container">
            <ReactTable
              data={[
                ...dataForUser2Chart,
                { x: 'Total SignUps', y: totalSignUps, percent: '' }
              ]}
              columns={[
                {
                  Header: 'Device Type',
                  accessor: 'x'
                },
                {
                  Header: 'Sign Ups',
                  accessor: 'y'
                },
                {
                  Header: 'Percentage',
                  accessor: 'percent'
                }
              ]}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (!rowInfo || !rowInfo.row) return;
                    if (rowInfo.row.x === 'Total SignUps') return;
                    console.log(rowInfo.row);
                    window.open(
                      `#/data/users2_device_type/${
                        rowInfo.row.x
                      }/${startDate.toString()}/${endDate.toString()}`
                    );
                  },
                  style: {
                    cursor:
                      rowInfo &&
                      rowInfo.row &&
                      rowInfo.row.x === 'Total SignUps'
                        ? 'default'
                        : 'pointer'
                  }
                };
              }}
              sortable={false}
              showPagination={false}
              defaultPageSize={4}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
