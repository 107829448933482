import React, { useState, useEffect } from 'react';
import styles from './EditJobPost.module.scss';
import Typewriter from 'typewriter-effect';
import { CustomInputText } from 'components/CustomFormComponents';

import Axios from 'axios';
import CustomSelectWrapper from './CustomSelectWrapper';
import { sendHttpCall } from 'shareService/httpService';
import { esocialUrl } from 'config';
const convertToYearly = (monthlyVal) => {
  switch (monthlyVal) {
    case '₹ 10k - 25k':
    case '₹10k - 25k':
      return '₹ 1.2L - 3L';
    case '₹ 25k - 50k':
    case '₹25k - 50k':
      return '₹ 3L - 6L';
    case '₹ 50k - 75k':
      return '₹ 6L - 9L';
    case '₹ 75k - 100k':
      return '₹ 9L - 12L';
    case '₹ 100k - 150k':
      return '₹ 12L - 18L';
    case '₹ 150k - 200k':
      return '₹ 18L - 24L';
    case '₹ 200k - 300k':
      return '₹ 24L - 36L';
    case '₹ 300k - 500k':
      return '₹ 36L - 60L';
    case '>  ₹ 500k':
      return '> ₹ 60L';
    default:
      return monthlyVal;
  }
};
const notLoggedInTokenProd =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYyMjYwNDY4MH0.ZQxGVUbEZFpSyRYNEeTQysh4fNomDM_RS5XUSqx7YyY'; // prod
const getCompensationFromIdNew = (id) => {
  if (typeof id !== 'number') {
    // eslint-disable-next-line no-param-reassign
    id = getIdFromCompensation(convertToYearly(id));
  }
  switch (id) {
    case 1:
      return '1.2-3 LPA';
    case 2:
      return '3-6 LPA';
    case 3:
      return '6-9 LPA';
    case 4:
      return '9-12 LPA';
    case 5:
      return '12-18 LPA';
    case 6:
      return '18-24 LPA';
    case 7:
      return '24-36 LPA';
    case 8:
      return '36-60 LPA';
    case 9:
      return '>60 LPA';
    case 10:
      return '₹ 100 - 200';
    case 11:
      return '₹ 200 - 350';
    case 12:
      return '₹ 350 - 500';
    case 13:
      return '₹ 500 - 700';
    case 14:
      return '₹ 700 - 1000';
    case 15:
      return '₹ 1k - 1.5k';
    case 16:
      return '₹ 1.5k - 2k';
    case 17:
      return '₹ 2k - 3k';
    case 18:
      return '₹ 3k - 5k';
    case 19:
      return '> ₹ 5k';
    default:
      return '';
  }
};

let cache = null;
function addUserDefinedValueToMasterDBApi(data) {
  return new Promise((resolve, reject) => {
    Axios.post(`${esocialUrl}/config/master`, data, {
      headers: {
        authorization: notLoggedInTokenProd
      }
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error('Error in addUserDefinedValueToMasterDBApi', e.response);
        reject(e);
      });
  });
}
const fetchSpecificConfigApi = async (url) => {
  let data = await Axios.get(
    'https://api.ourgoodspace.com/api/d2/config/staticConfig',
    {
      headers: {
        authorization: notLoggedInTokenProd
      }
    }
  );
  cache = data.data.data;
  return data;
};
const getIdFromCompensation = (value) => {
  switch (value) {
    case '₹ 1.2L - 3L':
      return 1;
    case '₹ 3L - 6L':
      return 2;
    case '₹ 6L - 9L':
      return 3;
    case '₹ 9L - 12L':
      return 4;
    case '₹ 12L - 18L':
      return 5;
    case '₹ 18L - 24L':
      return 6;
    case '₹ 24L - 36L':
      return 7;
    case '₹ 36L - 60L':
      return 8;
    case '> ₹ 60L':
      return 9;
    case '₹ 100 - 200':
      return 10;
    case '₹ 200 - 350':
      return 11;
    case '₹ 350 - 500':
      return 12;
    case '₹ 500 - 700':
      return 13;
    case '₹ 700 - 1000':
      return 14;
    case '₹ 1k - 1.5k':
      return 15;
    case '₹ 1.5k - 2k':
      return 16;
    case '₹ 2k - 3k':
      return 17;
    case '₹ 3k - 5k':
      return 18;
    case '> ₹ 5k':
      return 19;
    default:
      return null;
  }
};

export default function EditJobPostV2({ cancel, refresh, id }) {
  const [page, setPage] = useState(1);
  const [staticConfig, setStaticConfig] = useState(null);
  const [showSuggestedJd, setShowSuggestedJd] = useState(false);
  const [resourceType, setResourceType] = useState(null);
  const [typeOfWorkOptions, setTypeOfWorkOptions] = useState([]);
  const [highestQualificationId, setHighestQualificationId] = useState(null);
  const [expRequiredData, setExpRequiredData] = useState(null);
  const [popularSkills, setPopularSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [designationData, setDesignationData] = useState(null);
  const [industryData, setIndustryData] = useState(null);
  const [description, setDescription] = useState('');
  const [typeOfWorkData, setTypeOfWorkData] = useState(null);
  const [relatedSkills, setRelatedSkills] = useState([]);
  const [hourlyCompensationData, setHourlyCompensationData] = useState(null);
  const [yearlyCompensationData, setYearlyCompensationData] = useState(null);
  const [typeOfServiceData, setTypeOfServiceData] = useState(null);
  const [earningPotentialData, setEarningPotentialData] = useState(null);
  const [investmentPotentialData, setInvestmentPotentialData] = useState(null);
  const [hoursPerWeekEntreData, setHoursPerWeekEntreData] = useState(null);
  const [location, setLocation] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const [screeningQuestions, setScreeningQuestions] = useState([]);
  const [compensationOptions, setCompensationOptions] = useState({
    yearly: [],
    hourly: []
  });
  const [hourlyCompensationOptions, setHourlyCompensationOptions] = useState(
    []
  );
  const [investmentPotentialOptions, setInvestmentPotentialOptions] = useState(
    []
  );
  const [currency, setCurrency] = useState('INR');
  const [suggestedJd, setSuggestedJd] = useState('');
  const [workTypeOptions, setWorkTypeOptions] = useState([]);
  const [showJobPostSuccessModal, setShowJobPostSuccessModal] = useState(false);
  const [showJobCountExceededModal, setShowJobCountExceededModal] =
    useState(false);
  const [earningPotentialOptions, setEarningPotentialOptions] = useState([]);
  const [limitExhausted, setLimitExhausted] = useState(false);
  const getStaticData = async () => {
    if (cache) setStaticConfig(cache);
    else {
      const data = await fetchSpecificConfigApi();
      setStaticConfig(data.data.data);
    }
  };
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const saveData = () => {
    if (!resourceType) {
      showToast('Resource Type cannot be empty.');
      return;
    }
    if (!highestQualificationId) {
      showToast('Highest qualification required cannot be empty.');
      return;
    }
    if (!expRequiredData) {
      showToast('Work Experience required cannot be empty.');
      return;
    }
    const selectedSkillsOnly = selectedSkills.filter((item) => item.selected);
    if (!selectedSkillsOnly || !selectedSkillsOnly.length) {
      showToast('Preferred Skills are required.');
      return;
    }
    if (!designationData) {
      showToast('Job Title cannot be empty.');
      return;
    }
    if (!description) {
      showToast('Job Description cannot be empty.');
      return;
    }
    let typeFound = false;
    if (resourceType?.value.toLowerCase() === 'job') {
      typeFound = true;
      if (!typeOfWorkData) {
        showToast('Type of work cannot be empty.');
        return;
      }
      if (
        typeOfWorkData?.label?.toLowerCase().includes('office') &&
        !location
      ) {
        showToast('Location cannot be empty.');
        return;
      }
      if (!yearlyCompensationData) {
        showToast('Annual Compensation cannot be empty.');
        return;
      }
    } else if (resourceType?.value.toLowerCase() === 'freelance') {
      typeFound = true;
      if (!typeOfServiceData) {
        showToast('Type of service cannot be empty.');
        return;
      }
      if (!hourlyCompensationData) {
        showToast('Hourly Rate cannot be empty.');
        return;
      }
    } else if (resourceType?.value.toLowerCase() === 'entrepreneurship') {
      typeFound = true;
      if (!earningPotentialData) {
        showToast('Earning Potential cannot be empty.');
        return;
      }
      if (!hoursPerWeekEntreData) {
        showToast('Work type cannot be empty.');
        return;
      }
      if (!investmentPotentialData) {
        showToast('Investment required cannot be empty.');
        return;
      }
    }
    if (!typeFound) {
      showToast('Something seems wrong.');
      return;
    }
    const jobData = {
      userOfferId: id,
      description,
      currency,
      designation_id: designationData && designationData.value,
      monthly_compensation_id:
        resourceType?.value.toLowerCase() === 'job' && yearlyCompensationData
          ? yearlyCompensationData.value
          : undefined,
      hourly_compensation_id:
        resourceType?.value.toLowerCase() === 'freelance' &&
        hourlyCompensationData
          ? hourlyCompensationData.value
          : undefined,
      job_function_id: 15,
      jobType: typeOfWorkData ? [typeOfWorkData.value] : [],
      job_type_id: typeOfWorkData?.value,
      isRemote: false,
      locationCity: typeOfWorkData?.label.toLowerCase().includes('remote')
        ? null
        : location?.label,
      latitude: typeOfWorkData?.label.toLowerCase().includes('remote')
        ? null
        : location?.latitude,
      longitude: typeOfWorkData?.label.toLowerCase().includes('remote')
        ? null
        : location?.longitude,
      lowerworkex: expRequiredData && expRequiredData.lowerworkex,
      offerTypeId: 1,
      showCompany: true,
      screening_questions: screeningQuestions.map((question) => ({
        question: question.que,
        isRequired: question.isRequired
      })),
      skill: selectedSkillsOnly.map((a) => a.value),
      title: designationData && designationData.label,
      industry_type: industryData && industryData.label,
      industry_type_id: industryData && industryData.value,
      upperworkex: expRequiredData && expRequiredData.upperworkex,
      resourceTypeId: resourceType?.id,
      highestQualificationId,
      typeOfServiceId:
        resourceType?.value.toLowerCase() === 'freelance'
          ? typeOfServiceData?.value
          : null,
      workTypeId:
        resourceType?.value.toLowerCase() === 'entrepreneurship'
          ? hoursPerWeekEntreData?.value
          : null,
      earningPotentialId:
        resourceType?.value.toLowerCase() === 'entrepreneurship'
          ? earningPotentialData?.value
          : null,
      investmentPotentialId:
        resourceType?.value.toLowerCase() === 'entrepreneurship'
          ? investmentPotentialData?.value
          : null
    };
    console.log(jobData);
    sendHttpCall('POST', `/jobs/update`, jobData, {}).then((response) => {
      if (response) {
        showToast('Updated Successfully.');
        cancel();
        refresh();
      }
    });
  };
  const fetchJobTitleRelatedSkills = (designationId) => {
    sendHttpCall('GET', `designations/related/${designationId}`, null, {}).then(
      (response) => {
        if (response) {
          setRelatedSkills(response.data);
        }
      }
    );
  };
  const fetchAiGeneratedJD = () => {
    setSuggestedJd('Loading...');

    sendHttpCall(
      'GET',
      `designations/related_jd/${designationData?.value}?lowerworkex=${
        expRequiredData?.lowerworkex || 0
      }&upperworkex=${expRequiredData?.upperworkex || 1}&location=${
        location?.value || ''
      }`,
      null,
      {}
    )
      .then((response) => {
        if (response) {
          setSuggestedJd(response?.data.trim() || response?.message);
          console.log(response?.data);
        }
      })
      .catch((err) => {
        setSuggestedJd('Error in fetching JD');
      });
  };
  useEffect(() => {
    if (designationData?.value) fetchAiGeneratedJD();
  }, [designationData?.value, location, expRequiredData]);
  useEffect(() => {
    if (designationData?.value) {
      fetchJobTitleRelatedSkills(designationData.value);
    }
  }, [designationData]);

  const getJobData = (offerId) => {
    let url = `/jobs/preview?offerId=${offerId}`;
    sendHttpCall('GET', url, null, {}).then((response) => {
      if (response) {
        const jobData = response;
        setResourceType({
          value: jobData.opportunity,
          id: jobData.opportunity_id,
          altDisplayName: jobData.opportunity
        });
        setCurrency(jobData.currency);

        // SCREENING QUESTIONS
        const formattedScreeningQuestions =
          jobData.screening_questions?.map((q) => ({
            que: q.question,
            isRequired: q.isRequired
          })) || [];
        setScreeningQuestions(formattedScreeningQuestions);

        setTypeOfWorkData(
          jobData.jobType.length
            ? jobData.jobType.map((item) => ({
                label: item.job_type,
                value: item.job_type_id
              }))[0]
            : null
        );
        setHighestQualificationId(jobData.highest_qualification_required_id);
        setExpRequiredData({
          lowerworkex: jobData.lowerworkex,
          upperworkex: jobData.upperworkex
        });
        setSelectedSkills(
          jobData.skills.map((item) => ({
            label: item.skill,
            value: item.skill_id,
            selected: true
          }))
        );
        setDesignationData({
          label: jobData.designation,
          value: jobData.designation_id
        });

        setIndustryData({
          label: jobData.industry_type,
          value:jobData.industry_type_id
        });
        
        setDescription(jobData.description);
        if (jobData.hourly_compensation_id)
          setHourlyCompensationData({
            label: '₹ ' + jobData.displayCompensation,
            value: jobData.hourly_compensation_id
          });
        const yearlyCompensationOptions = staticConfig.monthlyMaster;
        const yearlyCompensationObj = yearlyCompensationOptions.find(
          (item) => item.id === jobData.monthly_compensation_id
        );
        setYearlyCompensationData({
          label:
            jobData.currency === 'USD'
              ? yearlyCompensationObj.value_usd
              : getCompensationFromIdNew(jobData.monthly_compensation_id),
          value: jobData.monthly_compensation_id
        });
        setTypeOfServiceData(
          jobData.type_of_service_id
            ? {
                label: jobData.type_of_service,
                value: jobData.type_of_service_id
              }
            : null
        );
        setEarningPotentialData(
          jobData.entrepreneur_earning_potential
            ? {
                label: jobData.entrepreneur_earning_potential,
                value: jobData.entrepreneur_earning_potential_id
              }
            : null
        );
        setInvestmentPotentialData(
          jobData.entrepreneur_earning_potential_id
            ? {
                label: jobData.entrepreneur_investment_potential,
                value: jobData.entrepreneur_investment_potential_id
              }
            : null
        );
        setHoursPerWeekEntreData(
          jobData.entrepreneur_work_type_id
            ? {
                label: jobData.entrepreneur_work_type,
                value: jobData.entrepreneur_work_type_id
              }
            : null
        );

        setLocation({
          label: jobData.location_city,
          value: jobData.location_city,
          latitude: jobData.location_latitude,
          longitude: jobData.location_longitude
        });
      }
    });
  };
  const loadOptions = () => {
    setTypeOfWorkOptions(
      staticConfig.typeOfWork.map((a) => ({ label: a.value, value: a.id }))
    );
    const yearly = staticConfig.monthlyMaster.map((a) => ({
      label: getCompensationFromIdNew(a.id),
      value: a.id
    }));
    const hourly = staticConfig.hourlyMaster.map((a) => ({
      label: a.value,
      value: a.id
    }));
    const yearlyUsd = staticConfig.monthlyMaster.map((a) => ({
      label: a.value_usd,
      value: a.id
    }));
    setCompensationOptions({ yearly, hourly, yearlyUsd });
    setHourlyCompensationOptions(
      staticConfig.hourlyCompensation.map((a) => ({
        label: a.value,
        labelUsd: a.value_usd,
        value: a.id
      }))
    );
    setEarningPotentialOptions(
      staticConfig.earningPotential.map((a) => ({
        label: a.value,
        labelUsd: a.value_usd,
        value: a.id
      }))
    );
    setWorkTypeOptions(
      staticConfig.entrepreneurWorkType.map((a) => ({
        label: a.value,
        labelUsd: a.value_usd,
        value: a.id
      }))
    );
    setInvestmentPotentialOptions(
      staticConfig.investmentPotential.map((a) => ({
        label: a.value,
        labelUsd: a.value_usd,
        value: a.id
      }))
    );
    setTypeOfWorkOptions(
      staticConfig.typeOfWork.map((a) => ({ label: a.value, value: a.id }))
    );
    setResourceType(staticConfig?.opportunities[0]);
  };
  const addSkill = (item) => {
    const selectedSkills_ = [...selectedSkills];
    const index = selectedSkills_.findIndex((a) => a.value === item.value);
    if (index < 0)
      setSelectedSkills((a) => [
        { ...item, selected: true, popular: false },
        ...a
      ]);
    else {
      selectedSkills_[index] = { ...selectedSkills_[index], selected: true };
      setSelectedSkills(selectedSkills_);
    }
  };
  const removeSkill = (item) => {
    const selectedSkills_ = [...selectedSkills];
    const index = selectedSkills_.findIndex((a) => a.value === item.value);
    if (index < 0) return;
    if (selectedSkills_[index].popular) {
      selectedSkills_[index] = { ...selectedSkills_[index], selected: false };
      setSelectedSkills(selectedSkills_);
    } else setSelectedSkills((a) => a.filter((b) => b.value !== item.value));
  };

  useEffect(() => {
    getStaticData();
  }, []);

  useEffect(() => {
    if (staticConfig) {
      loadOptions();
      if (id) getJobData(id);
    }
  }, [staticConfig, id]);

  const addNewQuestion = () => {
    setScreeningQuestions([...screeningQuestions, { que: '', isRequired: 0 }]);
  };

  const handleDeleteQuestion = (index) => {
    if (screeningQuestions.length > 0) {
      const updatedQuestions = screeningQuestions.filter(
        (_, idx) => idx !== index
      );
      setScreeningQuestions(updatedQuestions);
    }
  };

  const handleQuestionChange = (index, value) => {
    setScreeningQuestions((currentQuestions) => {
      const newQuestions = [...currentQuestions];
      newQuestions[index] = { ...newQuestions[index], que: value };
      return newQuestions;
    });
  };
  const toggleIsRequired = (index) => {
    setScreeningQuestions((currentQuestions) =>
      currentQuestions.map((question, i) => {
        if (i === index) {
          return { ...question, isRequired: question.isRequired === 1 ? 0 : 1 };
        }
        return question;
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Edit Job Post</div>
      {page === 1 && (
        <>
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Resource Type</div>
            <div className={styles.formCards}>
              {staticConfig?.opportunities?.map((item) => (
                <div
                  className={`${styles.formCard} ${
                    resourceType?.id === item.id ? styles.formCardActive : ''
                  }`}
                  onClick={() => {
                    setResourceType(item);
                  }}>
                  {item.altDisplayName}
                </div>
              ))}
            </div>
          </div>{' '}
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Job Title</div>
            <div className={styles.formSelect}>
              <CustomSelectWrapper
                theme="ONBOARDING_HIRER"
                type="designation"
                value={designationData}
                onChange={(e) => {
                  if (e.__isNew__) {
                    addUserDefinedValueToMasterDBApi({
                      value: e.label,
                      master: 'designation'
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          const d = {
                            value: res.data.data.id,
                            label: res.data.data.value
                          };
                          console.log(d);
                          showToast('Designation added successfully.');
                          setDesignationData(d);
                        }
                      })
                      .catch((err) => {
                        showToast(err.response.data.message);
                      });
                  } else setDesignationData(e);
                }}
                allowNewOptions
                placeholder="eg Add or Search"
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Qualification Required</div>
            <div className={styles.formCards}>
              {staticConfig?.highestQualification?.map((item) => (
                <div
                  className={`${styles.formCard} ${
                    highestQualificationId === item.id
                      ? styles.formCardActive
                      : ''
                  }`}
                  onClick={() => {
                    setHighestQualificationId(item.id);
                  }}>
                  {item.value}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Experience Required</label>
            <div className={styles.formCards}>
              {staticConfig?.workExperienceRange?.map((item) => (
                <div
                  className={`${styles.formCard} ${
                    expRequiredData?.upperworkex === item.upperworkex
                      ? styles.formCardActive
                      : ''
                  }`}
                  onClick={() => {
                    setExpRequiredData(item);
                  }}>
                  {item.upperworkex !== 60
                    ? `${item.lowerworkex}-${item.upperworkex} `
                    : 'More than 30 '}
                  Yrs
                </div>
              ))}
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Skills Required</div>
            <div className={styles.formSelect}>
              <CustomSelectWrapper
                theme="ONBOARDING_HIRER"
                type="skill"
                onChange={(e) => {
                  if (e.__isNew__) {
                    addUserDefinedValueToMasterDBApi({
                      value: e.label,
                      master: 'skill'
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          const d = {
                            value: res.data.data.id,
                            label: res.data.data.value
                          };
                          console.log(d);
                          showToast('Skill added successfully.');
                          addSkill(d);
                        }
                      })
                      .catch((err) => {
                        showToast(err.response.data.message);
                      });
                  } else addSkill(e);
                }}
                allowNewOptions
                placeholder="eg Java"
              />
            </div>
            <div className={styles.formCards}>
              {selectedSkills.map((item) => (
                <div
                  className={`${styles.formCard} ${
                    item.selected ? styles.formCardActive : ''
                  }`}
                  onClick={() => {
                    if (item.selected) removeSkill(item);
                    else addSkill(item);
                  }}>
                  {item.label}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formLabel}>Suggested Skills</div>

            <div className={styles.formCards}>
              {relatedSkills.map((item) => (
                <div
                  className={`${styles.formCard} ${
                    false ? styles.formCardActive : ''
                  }`}>
                  {item.skill}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {page === 2 && (
        <>
          <div className={styles.formGroup}>
            <div className={styles.formLabelRow}>
              <div className={styles.formLabel}>Job Description</div>
              <div className={styles.planSwitchRow}>
                {/* <div className={styles.planSwitchLabel}>Switch Currency</div> */}
                <div className={styles.planSwitch}>
                  <div
                    className={styles.planSwitchItem}
                    onClick={() => setShowSuggestedJd(false)}>
                    Raw
                  </div>
                  <div
                    className={styles.planSwitchComp}
                    onClick={() => setShowSuggestedJd((a) => !a)}>
                    <div
                      className={`${styles.planSwitchCompSwitch} ${
                        showSuggestedJd ? styles.planSwitchCompSwitchActive : ''
                      }`}
                    />
                  </div>

                  <div
                    className={styles.planSwitchItem}
                    onClick={() => setShowSuggestedJd(true)}>
                    Suggested
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.formTextAreaContainer}>
              <textarea
                className={styles.formTextArea}
                style={{ height: '10rem', resize: 'none' }}
                value={showSuggestedJd ? suggestedJd : description}
                readOnly={showSuggestedJd}
                placeholder="Describe your profession"
                onChange={(e) =>
                  e.target.value.length <= 4000
                    ? setDescription(e.target.value)
                    : null
                }
              />
              <div className={styles.formTextareaLength}>
                {' '}
                {description.length}/4000
              </div>
            </div>
          </div>
          <div className={styles.planSwitchRow}>
            {/* <div className={styles.planSwitchLabel}>Switch Currency</div> */}
            <div className={styles.planSwitch}>
              <div
                className={styles.planSwitchItem}
                onClick={() => {
                  if (currency === 'INR') {
                    setCurrency('USD');
                    const originalCurrency = 'INR';
                    if (yearlyCompensationData) {
                      const yearlyCompensationOptions =
                        staticConfig.monthlyMaster;
                      const yearlyCompensationObj =
                        yearlyCompensationOptions.find(
                          (item) => item.id === yearlyCompensationData.value
                        );
                      if (yearlyCompensationObj)
                        setYearlyCompensationData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? yearlyCompensationObj.value_usd
                              : getCompensationFromIdNew(
                                  yearlyCompensationData.value
                                )
                        }));
                    }
                    if (hourlyCompensationData) {
                      const compensationObj = hourlyCompensationOptions.find(
                        (item) => item.value === hourlyCompensationData.value
                      );
                      if (compensationObj)
                        setHourlyCompensationData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? compensationObj.labelUsd
                              : compensationObj.label
                        }));
                    }
                    if (earningPotentialData) {
                      const compensationObj = earningPotentialOptions.find(
                        (item) => item.value === earningPotentialData.value
                      );
                      if (compensationObj)
                        setEarningPotentialData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? compensationObj.labelUsd
                              : compensationObj.label
                        }));
                    }
                    if (investmentPotentialData) {
                      const compensationObj = investmentPotentialOptions.find(
                        (item) => item.value === investmentPotentialData.value
                      );
                      if (compensationObj)
                        setInvestmentPotentialData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? compensationObj.labelUsd
                              : compensationObj.label
                        }));
                    }
                  }
                }}>
                USD
              </div>
              <div
                className={styles.planSwitchComp}
                onClick={() => {
                  const originalCurrency = currency;
                  setCurrency((a) => (a === 'INR' ? 'USD' : 'INR'));
                  if (yearlyCompensationData) {
                    const yearlyCompensationOptions =
                      staticConfig.monthlyMaster;
                    const yearlyCompensationObj =
                      yearlyCompensationOptions.find(
                        (item) => item.id === yearlyCompensationData.value
                      );
                    if (yearlyCompensationObj)
                      setYearlyCompensationData((a) => ({
                        ...a,
                        label:
                          originalCurrency !== 'USD'
                            ? yearlyCompensationObj.value_usd
                            : getCompensationFromIdNew(
                                yearlyCompensationData.value
                              )
                      }));
                  }
                  if (hourlyCompensationData) {
                    const compensationObj = hourlyCompensationOptions.find(
                      (item) => item.value === hourlyCompensationData.value
                    );
                    if (compensationObj)
                      setHourlyCompensationData((a) => ({
                        ...a,
                        label:
                          originalCurrency !== 'USD'
                            ? compensationObj.labelUsd
                            : compensationObj.label
                      }));
                  }
                  if (earningPotentialData) {
                    const compensationObj = earningPotentialOptions.find(
                      (item) => item.value === earningPotentialData.value
                    );
                    if (compensationObj)
                      setEarningPotentialData((a) => ({
                        ...a,
                        label:
                          originalCurrency !== 'USD'
                            ? compensationObj.labelUsd
                            : compensationObj.label
                      }));
                  }
                  if (investmentPotentialData) {
                    const compensationObj = investmentPotentialOptions.find(
                      (item) => item.value === investmentPotentialData.value
                    );
                    if (compensationObj)
                      setInvestmentPotentialData((a) => ({
                        ...a,
                        label:
                          originalCurrency !== 'USD'
                            ? compensationObj.labelUsd
                            : compensationObj.label
                      }));
                  }
                }}>
                <div
                  className={`${styles.planSwitchCompSwitch} ${
                    currency === 'INR' ? styles.planSwitchCompSwitchActive : ''
                  }`}
                />
              </div>
              <div
                className={styles.planSwitchItem}
                onClick={() => {
                  if (currency === 'USD') {
                    setCurrency('INR');
                    const originalCurrency = 'USD';
                    if (yearlyCompensationData) {
                      const yearlyCompensationOptions =
                        staticConfig.monthlyMaster;
                      const yearlyCompensationObj =
                        yearlyCompensationOptions.find(
                          (item) => item.id === yearlyCompensationData.value
                        );
                      if (yearlyCompensationObj)
                        setYearlyCompensationData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? yearlyCompensationObj.value_usd
                              : getCompensationFromIdNew(
                                  yearlyCompensationData.value
                                )
                        }));
                    }
                    if (hourlyCompensationData) {
                      const compensationObj = hourlyCompensationOptions.find(
                        (item) => item.value === hourlyCompensationData.value
                      );
                      if (compensationObj)
                        setHourlyCompensationData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? compensationObj.labelUsd
                              : compensationObj.label
                        }));
                    }
                    if (earningPotentialData) {
                      const compensationObj = earningPotentialOptions.find(
                        (item) => item.value === earningPotentialData.value
                      );
                      if (compensationObj)
                        setEarningPotentialData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? compensationObj.labelUsd
                              : compensationObj.label
                        }));
                    }
                    if (investmentPotentialData) {
                      const compensationObj = investmentPotentialOptions.find(
                        (item) => item.value === investmentPotentialData.value
                      );
                      if (compensationObj)
                        setInvestmentPotentialData((a) => ({
                          ...a,
                          label:
                            originalCurrency !== 'USD'
                              ? compensationObj.labelUsd
                              : compensationObj.label
                        }));
                    }
                  }
                }}>
                INR
              </div>
            </div>
          </div>
          {resourceType?.value?.toLowerCase() === 'job' && (
            <>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Type of Work</label>
                <div className={styles.formCards}>
                  {typeOfWorkOptions.map((item) => (
                    <div
                      className={`${styles.formCard} ${
                        typeOfWorkData?.value === item.value
                          ? styles.formCardActive
                          : ''
                      }`}
                      onClick={() => {
                        setTypeOfWorkData(item);
                      }}>
                      {item.label}
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Annual Compensation</label>
                <CustomSelectWrapper
                  options={
                    currency === 'USD'
                      ? compensationOptions.yearlyUsd
                      : compensationOptions.yearly
                  }
                  onChange={setYearlyCompensationData}
                  theme="ONBOARDING_HIRER"
                  value={yearlyCompensationData}
                  placeholder="Eg 4 LPA"
                />
              </div>

              {/* Industry */}
              <div className={styles.formGroup}>
                <div className={styles.formLabel}>Industry</div>
                <div className={styles.formSelect}>
                  <CustomSelectWrapper
                    theme="ONBOARDING_HIRER"
                    type="industry_type"
                    value={industryData}
                    onChange={(e) => {
                      if (e.__isNew__) {
                        addUserDefinedValueToMasterDBApi({
                          value: e.label,
                          master: 'industry_type'
                        })
                          .then((res) => {
                            if (res.status === 200) {
                              const d = {
                                value: res.data.data.id,
                                label: res.data.data.value
                              };
                              console.log(d);
                              showToast('Industry added successfully.');
                              setIndustryData(d);
                            }
                          })
                          .catch((err) => {
                            showToast(err.response.data.message);
                          });
                      } else setIndustryData(e);
                    }}
                    allowNewOptions
                    placeholder="eg Add or Search"
                  />
                </div>
              </div>

              {(typeOfWorkData?.label?.toLowerCase().includes('onsite') ||
                typeOfWorkData?.label?.toLowerCase().includes('hybrid')) && (
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>Location</label>
                  <CustomSelectWrapper
                    placeholder="eg Delhi"
                    async
                    theme="ONBOARDING_HIRER"
                    onChange={setLocation}
                    value={location}
                    type="location"
                  />
                </div>
              )}
            </>
          )}

          <div className={styles.screeningGroup}>
            <div onClick={addNewQuestion} className={styles.AddQuestion}>
              + Custom Screening Questions
            </div>
            {screeningQuestions.length > 0
              ? screeningQuestions.map((question, index) => (
                  <div className={styles.ques} key={index}>
                    <div className={styles.quesLabel}>
                      Question {index + 1}
                      <div className={styles.planSwitchRow}>
                        <div className={styles.planSwitch}>
                          <div
                            className={`${styles.planSwitchComp} ${
                              question.isRequired === 1
                                ? styles.planSwitchCompActive
                                : ''
                            }`}
                            onClick={() => toggleIsRequired(index)}>
                            <div
                              className={`${styles.planSwitchCompSwitch} ${
                                question.isRequired === 1
                                  ? styles.planSwitchCompSwitchActive
                                  : ''
                              }`}
                            />
                          </div>
                        </div>
                        <div className={styles.planSwitchLabel}>
                          Required
                          <span
                            className={styles.delete}
                            onClick={() => handleDeleteQuestion(index)}>
                            <Delete />
                          </span>
                        </div>
                      </div>
                    </div>
                    <CustomInputText
                      placeholder="Screening Question"
                      value={question.que || ''}
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                    />
                  </div>
                ))
              : null}
          </div>
          {resourceType?.value?.toLowerCase() === 'freelance' && (
            <>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Type of Service</label>
                <CustomSelectWrapper
                  type="service_type"
                  onChange={(e) => {
                    if (e.__isNew__) {
                      addUserDefinedValueToMasterDBApi({
                        value: e.label,
                        master: 'service_type'
                      })
                        .then((res) => {
                          if (res.status === 200) {
                            const d = {
                              value: res.data.data.id,
                              label: res.data.data.value
                            };
                            console.log(d);
                            showToast('Type of Service added successfully.');
                            setTypeOfServiceData(d);
                          }
                        })
                        .catch((err) => {
                          showToast(err.response.data.message);
                        });
                    } else setTypeOfServiceData(e);
                  }}
                  value={typeOfServiceData}
                  theme="ONBOARDING_HIRER"
                  allowNewOptions
                  placeholder="Eg 3D modelling"
                />
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Hourly Rate</label>
                <CustomSelectWrapper
                  options={
                    currency === 'USD'
                      ? hourlyCompensationOptions.map((item) => ({
                          ...item,
                          label: item.labelUsd
                        }))
                      : hourlyCompensationOptions
                  }
                  onChange={setHourlyCompensationData}
                  value={hourlyCompensationData}
                  theme="ONBOARDING_HIRER"
                  windowed={false}
                  placeholder="Eg 1500"
                />
              </div>
            </>
          )}
          {resourceType?.value?.toLowerCase() === 'entrepreneurship' && (
            <>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>
                  Earning Potential <span>(Annually)</span>
                </label>
                <label className={`${styles.formLabel} ${styles.formLabel2}`}>
                  <span> How much would can this candidate earn</span>
                </label>
                <CustomSelectWrapper
                  options={
                    currency === 'USD'
                      ? earningPotentialOptions.map((item) => ({
                          ...item,
                          label: item.labelUsd
                        }))
                      : earningPotentialOptions
                  }
                  onChange={setEarningPotentialData}
                  value={earningPotentialData}
                  theme="ONBOARDING_HIRER"
                  windowed={false}
                  placeholder="Eg 12 LPA"
                />
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Investment Required</label>
                <label className={`${styles.formLabel} ${styles.formLabel2}`}>
                  <span> How much time and/or capital is required</span>
                </label>
                <div className={styles.row}>
                  <div className={styles.formGroup}>
                    <CustomSelectWrapper
                      options={workTypeOptions}
                      onChange={setHoursPerWeekEntreData}
                      value={hoursPerWeekEntreData}
                      theme="ONBOARDING_HIRER"
                      windowed={false}
                      placeholder="Eg Full Time"
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <CustomSelectWrapper
                      options={
                        currency === 'USD'
                          ? investmentPotentialOptions.map((item) => ({
                              ...item,
                              label: item.labelUsd
                            }))
                          : investmentPotentialOptions
                      }
                      onChange={setInvestmentPotentialData}
                      value={investmentPotentialData}
                      theme="ONBOARDING_HIRER"
                      windowed={false}
                      placeholder="Capital in ₹"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <div className={styles.buttons}>
        {page === 1 && (
          <button
            className={styles.prevPage}
            onClick={() => {
              cancel();
            }}>
            Cancel
          </button>
        )}
        {page === 2 && (
          <button
            className={styles.prevPage}
            onClick={() => {
              if (page === 2) setPage(1);
              // if(page===2)
            }}>
            Previous
          </button>
        )}
        {page === 1 && (
          <button
            className={styles.nextPage}
            onClick={() => {
              if (page === 1) setPage(2);
              // if(page===2)
            }}>
            Next
          </button>
        )}
        {page === 2 && (
          <button
            className={styles.button}
            onClick={() => {
              if (page === 2) saveData();
              // if(page===2)
            }}>
            Save Job
          </button>
        )}
      </div>
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}

const Delete = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.125 18.875C5.64375 18.875 5.23192 18.7038 4.8895 18.3614C4.54708 18.019 4.37558 17.6068 4.375 17.125V5.75H3.5V4H7.875V3.125H13.125V4H17.5V5.75H16.625V17.125C16.625 17.6062 16.4538 18.0184 16.1114 18.3614C15.769 18.7044 15.3568 18.8756 14.875 18.875H6.125ZM14.875 5.75H6.125V17.125H14.875V5.75ZM7.875 15.375H9.625V7.5H7.875V15.375ZM11.375 15.375H13.125V7.5H11.375V15.375Z"
      fill="#FF2038"
      fillOpacity="0.7"
    />
  </svg>
);
