import React, { useState, useEffect } from 'react';
import { sendHttpCall } from 'shareService/httpService';

import CustomSelectWrapper from './CustomSelect';
import './EditJobPost.scss';
const workExpOptions = [
  {
    value: '<1 Year',
    lowerworkex: 0,
    upperworkex: 1
  },
  {
    value: '1-3 Years',
    lowerworkex: 1,
    upperworkex: 3
  },
  {
    value: '3-5 Years',
    lowerworkex: 3,
    upperworkex: 5
  },
  {
    value: '5-10 Years',
    lowerworkex: 5,
    upperworkex: 10
  },
  {
    value: '10-15 Years',
    lowerworkex: 10,
    upperworkex: 15
  },
  {
    value: '15+ Years',
    lowerworkex: 15,
    upperworkex: 30
  }
];
const compensations = [
  { value: 1, label: '₹ 1.2L - 3L per year' },
  { value: 2, label: '₹ 3L - 6L per year' },
  { value: 3, label: '₹ 6L - 9L per year' },
  { value: 4, label: '₹ 9L - 12L per year' },
  { value: 5, label: '₹ 12L - 18L per year' },
  { value: 6, label: '₹ 18L - 24L per year' },
  { value: 7, label: '₹ 24L - 36L per year' },
  { value: 8, label: '₹ 36L - 60L per year' },
  { value: 9, label: '> ₹ 60L per year' },
  { value: 10, label: '₹ 100 - 200 per hour' },
  { value: 11, label: '₹ 200 - 350 per hour' },
  { value: 12, label: '₹ 350 - 500 per hour' },
  { value: 13, label: '₹ 500 - 700 per hour' },
  { value: 14, label: '₹ 700 - 1000 per hour' },
  { value: 15, label: '₹ 1k - 1.5k per hour' },
  { value: 16, label: '₹ 1.5k - 2k per hour' },
  { value: 17, label: '₹ 2k - 3k per hour' },
  { value: 18, label: '₹ 3k - 5k per hour' },
  { value: 19, label: '> ₹ 5k per hour' }
];
export default function EditJobPost({ dataProps, showToast, cancel, refresh }) {
  const [page, setPage] = useState(0);
  const [data, setData] = useState(null);
  console.log(dataProps);
  const loadData = (d) => {
    let req = {};
    req.designation = {
      label: d.designation,
      value: d.designation_id
    };
    req.jobType = {
      label: d.jobType[0].job_type,
      value: d.jobType[0].job_type_id
    };
    let compensationId = getIdFromCompensation(
      convertToYearly(d.monthly_compensation || d.hourly_Compensation)
    );
    req.compensation = compensations.find((a) => a.value === compensationId);
    req.skills = d.skills;
    req.location_city = d.location_city;
    req.is_remote = d.is_remote;
    let expOption = workExpOptions.find(
      (a) => a.upperworkex === d.upperworkex && a.lowerworkex === d.lowerworkex
    );
    req.experience = expOption;
    req.showCompany = !!d.show_organisation;
    req.isBusinessOpportunity = !!d.is_business_opportunity;
    req.industry = {
      label: d.industry_type,
      value: d.industry_type_id
    };
    req.description = d.description;
    setData(req);
  };
  const updateJob = () => {
    let reqData = {
      description: data.description,
      designation_id: data.designation.value,
      industry_type_id: data.industry.value,
      is_remote: data.is_remote,
      jobType: [data.jobType.value],
      job_function_id: 14,
      job_type_id: 14,
      locationCity: data.is_remote ? 'Remote' : data.location_city,
      lowerworkex: data.experience.lowerworkex,
      monthly_compensation_id:
        data.compensation.value <= 9 ? data.compensation.value : undefined,
      hourly_compensation_id:
        data.compensation.value > 9 ? data.compensation.value : undefined,
      offerTypeId: 1,
      skills: data.skills.map((a) => a.skill_id),
      title: data.designation.label,
      upperworkex: data.experience.upperworkex,
      userOfferId: dataProps.offerId,
      showCompany: data.showCompany,
      isBusinessOpportunity: data.isBusinessOpportunity
    };
    console.log(reqData);
    sendHttpCall('POST', `/jobs/update`, reqData, {}).then((response) => {
      if (response) {
        showToast('Updated Successfully.');
        cancel();
        refresh();
      }
    });
  };
  useEffect(() => {
    if (dataProps) loadData(dataProps);
  }, [dataProps]);
  if (!dataProps) return null;
  return (
    <div className="editJob__container">
      <div className="editJob__title">Edit Job Post</div>
      <div className="editJob__form">
        {page === 0 ? (
          <>
            <div className="editJob__label">Select Job Title</div>
            <div className="editJob__select">
              <CustomSelectWrapper
                type="designation"
                selectedOption={data && data.designation}
                onChange={(e) => setData({ ...data, designation: e })}
              />
            </div>
            <div className="editJob__label">Choose Type of Work</div>
            <div className="editJob__select">
              <CustomSelectWrapper
                options={[
                  { label: 'Work from Home', value: 24 },
                  { label: 'Freelance', value: 25 },
                  { label: 'Onsite Office Job', value: 27 }
                ]}
                selectedOption={data && data.jobType}
                onChange={(e) => setData({ ...data, jobType: e })}

                // selectedOption={{
                //   label: data.jobType[0].job_type,
                //   value: data.jobType[0].job_type_id
                // }}
              />
            </div>
            <div className="editJob__label">Select Compensation</div>
            <div className="editJob__select">
              <CustomSelectWrapper
                options={compensations}
                selectedOption={data && data.compensation}
                onChange={(e) => setData({ ...data, compensation: e })}

                //  selectedOption={{label:data.jobType[0].job_type,value:data.jobType[0].job_type_id}}
              />
            </div>
            <div className="editJob__label">Select skills</div>
            <div className="editJob__select">
              <CustomSelectWrapper
                type="skill"
                onChange={
                  (e) => {
                    let skills = [
                      { skill_id: e.value, skill: e.label },
                      ...data.skills.filter((a) => a.skill_id !== e.value)
                    ];
                    setData({ ...data, skills: skills });
                  }
                  // setData({ ...data, skills: data.skills.filter(a=>a.skill_id!==e.id) })
                }
              />
            </div>
            <div className="editJob__selectedItems">
              {data &&
                data.skills.map((skill) => (
                  <div
                    className="editJob__selectedItems-item"
                    onClick={
                      (e) => {
                        let skills = data.skills.filter(
                          (a) => a.skill_id !== skill.skill_id
                        );
                        setData({ ...data, skills: skills });
                      }
                      // setData({ ...data, skills: data.skills.filter(a=>a.skill_id!==e.id) })
                    }>
                    {skill.skill}
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            <div className="editJob__label">Choose location</div>
            <input
              type="text"
              className="editJob__text"
              onChange={(e) =>
                setData({ ...data, location_city: e.target.value })
              }
              value={data.is_remote ? 'Remote' : data.location_city}
              readOnly={!!data.is_remote}
            />
            <div
              className="editJob__checkbox"
              onClick={() => setData({ ...data, is_remote: !data.is_remote })}>
              <input type="checkbox" checked={!!data.is_remote} />
              {'  '}Remote Job
            </div>
            <div className="editJob__label">Choose Experience</div>
            <div className="editJob__selectedItems">
              {workExpOptions.map((a) => (
                <div
                  className={
                    'editJob__selectedItems-exp' +
                    (data.experience && data.experience.value === a.value
                      ? ' editJob__selectedItems-exp-active'
                      : '')
                  }
                  onClick={() => setData({ ...data, experience: a })}>
                  {a.value}
                </div>
              ))}
            </div>
            <div className="editJob__label">Choose Industry</div>
            <div className="editJob__select">
              {' '}
              <CustomSelectWrapper
                type="industry_type"
                selectedOption={data && data.industry}
                onChange={(e) => setData({ ...data, industry: e })}
              />
            </div>
            <div className="editJob__label">Choose Description</div>
            <textarea
              className="editJob__textarea"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              value={data.description}></textarea>
            <div
              className="editJob__checkbox"
              onClick={() =>
                setData({ ...data, showCompany: !data.showCompany })
              }>
              <input type="checkbox" checked={data.showCompany} />
              {'  '}Show Company
            </div>{' '}
            <div
              className="editJob__checkbox"
              onClick={() =>
                setData({
                  ...data,
                  isBusinessOpportunity: !data.isBusinessOpportunity
                })
              }>
              <input type="checkbox" checked={data.isBusinessOpportunity} />
              {'  '}Business Opportunity
            </div>{' '}
          </>
        )}
        <div className="editJob__btns">
          <div
            className="editJob__btns-cancel"
            onClick={() => {
              if (page === 0) cancel();
              if (page === 1) setPage(0);
            }}>
            {page === 0 ? 'Cancel' : 'Previous'}
          </div>
          <div
            className="editJob__btns-next"
            onClick={() => {
              if (page === 0) setPage(1);
              else updateJob();
            }}>
            {page === 0 ? 'Next' : 'Update'}
          </div>
        </div>
      </div>
    </div>
  );
}

const getIdFromCompensation = (value) => {
  switch (value) {
    case '₹ 1.2L - 3L':
      return 1;
    case '₹ 3L - 6L':
      return 2;
    case '₹ 6L - 9L':
      return 3;
    case '₹ 9L - 12L':
      return 4;
    case '₹ 12L - 18L':
      return 5;
    case '₹ 18L - 24L':
      return 6;
    case '₹ 24L - 36L':
      return 7;
    case '₹ 36L - 60L':
      return 8;
    case '> ₹ 60L':
      return 9;
    case '₹ 100 - 200':
      return 10;
    case '₹ 200 - 350':
      return 11;
    case '₹ 350 - 500':
      return 12;
    case '₹ 500 - 700':
      return 13;
    case '₹ 700 - 1000':
      return 14;
    case '₹ 1k - 1.5k':
      return 15;
    case '₹ 1.5k - 2k':
      return 16;
    case '₹ 2k - 3k':
      return 17;
    case '₹ 3k - 5k':
      return 18;
    case '> ₹ 5k':
      return 19;
    default:
      return null;
  }
};
const convertToYearly = (monthlyVal) => {
  switch (monthlyVal) {
    case '₹ 10k - 25k':
    case '₹10k - 25k':
      return '₹ 1.2L - 3L';
    case '₹ 25k - 50k':
    case '₹25k - 50k':
      return '₹ 3L - 6L';
    case '₹ 50k - 75k':
      return '₹ 6L - 9L';
    case '₹ 75k - 100k':
      return '₹ 9L - 12L';
    case '₹ 100k - 150k':
      return '₹ 12L - 18L';
    case '₹ 150k - 200k':
      return '₹ 18L - 24L';
    case '₹ 200k - 300k':
      return '₹ 24L - 36L';
    case '₹ 300k - 500k':
      return '₹ 36L - 60L';
    case '>  ₹ 500k':
      return '> ₹ 60L';
    default:
      return monthlyVal;
  }
};
