import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/styles';
import MaterialTable, { MTablePagination } from 'material-table';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  }
}));

const UserContacts = (props) => {
  const { className, userid, name, ...rest } = props;
  const classes = useStyles();

  var contact_count = 0;

  const [val, setVal] = useState(0);

  useEffect(() => {}, []);
  const downloadCsv = () => {
    sendHttpCallBlob('POST', 'users/get_user_contact_data_for_export', {
      user_id: userid
    }).then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = `contacts-${userid}.csv`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });
  };
  return (
    <div className={classes.root}>
      <MaterialTable
        className={classes.root}
        columns={[
          { title: 'User ID', field: 'user_id' },
          { title: 'Name', field: 'name' },
          { title: 'Phone', field: 'mobile_number' },
          { title: 'Date Added', field: 'created_at', type: 'date' }
        ]}
        data={(query) => {
          return new Promise((resolve, reject) => {
            let url =
              `users/contact_list/${props.userid}?` +
              'limit=' +
              query.pageSize +
              '&page=' +
              query.page;
            sendHttpCall('GET', url, null, props)
              .then((response) => {
                setVal(response.totalCount);
                resolve({
                  data: response.data,
                  page: response.page,
                  totalCount: response.totalCount
                });
              })
              .catch(() => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          });
        }}
        options={{
          search: false,
          pageSize: 10,
          pageSizeOptions: [10, 30, 50, 100]
        }}
        title={`User Contacts: ${val}`}
        components={{
          Pagination: (props) => (
            <div className="table__pagination-container">
              {localStorage.getItem('user_type')==='ADMIN'&&(
              <Link
                to={`/send_survey/${name + '/' + userid}`}
                className="exportToCsvBtn"
                style={{ backgroundColor: '#297bca', marginLeft: '17rem' }}>
                Send Survey Message
              </Link>  )}
              {localStorage.getItem('user_type')==='ADMIN'&&(
              <div
                className="exportToCsvBtn"
                onClick={downloadCsv}
                style={{ backgroundColor: '#297bca' }}>
                Download CSV <CloudDownload />
              </div>
              )}
            

              <div className="table__pagination-default-container">
                <MTablePagination {...props} />
              </div>
            </div>
          )
        }}
      />
    </div>
  );
};

UserContacts.propTypes = {
  className: PropTypes.string
};

export default UserContacts;
