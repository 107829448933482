import { sendHttpCall, sendFileUploadHttpCall } from 'shareService/httpService';
import React, { useState, useEffect } from 'react';
import CustomSelectWrapper from '../ViewAllActiveJobs/ViewJobsUnderReview/CustomSelect';
import './style.scss';
const workExpOptions = [
  {
    value: '<1 Year',
    lowerworkex: 0,
    upperworkex: 1
  },
  {
    value: '1-3 Years',
    lowerworkex: 1,
    upperworkex: 3
  },
  {
    value: '3-5 Years',
    lowerworkex: 3,
    upperworkex: 5
  },
  {
    value: '5-10 Years',
    lowerworkex: 5,
    upperworkex: 10
  },
  {
    value: '10-15 Years',
    lowerworkex: 10,
    upperworkex: 15
  },
  {
    value: '15+ Years',
    lowerworkex: 15,
    upperworkex: 30
  }
];
const compensations = [
  { value: 1, label: '₹ 1.2L - 3L per year' },
  { value: 2, label: '₹ 3L - 6L per year' },
  { value: 3, label: '₹ 6L - 9L per year' },
  { value: 4, label: '₹ 9L - 12L per year' },
  { value: 5, label: '₹ 12L - 18L per year' },
  { value: 6, label: '₹ 18L - 24L per year' },
  { value: 7, label: '₹ 24L - 36L per year' },
  { value: 8, label: '₹ 36L - 60L per year' },
  { value: 9, label: '> ₹ 60L per year' },
  { value: 10, label: '₹ 100 - 200 per hour' },
  { value: 11, label: '₹ 200 - 350 per hour' },
  { value: 12, label: '₹ 350 - 500 per hour' },
  { value: 13, label: '₹ 500 - 700 per hour' },
  { value: 14, label: '₹ 700 - 1000 per hour' },
  { value: 15, label: '₹ 1k - 1.5k per hour' },
  { value: 16, label: '₹ 1.5k - 2k per hour' },
  { value: 17, label: '₹ 2k - 3k per hour' },
  { value: 18, label: '₹ 3k - 5k per hour' },
  { value: 19, label: '> ₹ 5k per hour' }
];
export default function AddJob(props) {
  const [page, setPage] = useState(0);
  const [data, setData] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const addJob = async () => {
    let reqData = {
      description: data.description,
      designation_id: data.designation.value,
      industry_type_id: data.industry.value,
      is_remote: data.is_remote,
      jobType: [data.jobType.value],
      job_function_id: 14,
      job_type_id: 14,
      locationCity: data.is_remote ? 'Remote' : data.location_city,
      lowerworkex: data.experience.lowerworkex,
      monthly_compensation_id:
        data.compensation.value <= 9 ? data.compensation.value : undefined,
      hourly_compensation_id:
        data.compensation.value > 9 ? data.compensation.value : undefined,
      offerTypeId: 1,
      skills: data.skills.map((a) => a.skill_id),
      title: data.designation.label,
      upperworkex: data.experience.upperworkex,
      showCompany: true,
      redirectUrl: data.redirectUrl,
      hardcodedOrganisation: data.hardcodedCompany,
      isBusinessOpportunity: !!data.isBusinessOpportunity
    };
    if (data.image) {
      const data_ = new FormData();
      data_.append('file', data.image);
      let res = await sendFileUploadHttpCall(
        'POST',
        `/jobs/upload_img`,
        data_,
        {}
      );
      reqData.imgLink = res.link;
    } else {
      reqData.imgLink = null;
    }
    sendHttpCall('POST', `/jobs/add_job`, reqData, {}).then((response) => {
      if (response) {
        setData(null);
        setPage(0);
        showToast('Added Successfully.');
      }
    });
  };
  const checkLink = (link) => {
    const expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return link.match(regex);
  };
  const verifyPage = (page) => {
    if (page === 0) {
      if (!data || !data.designation) {
        showToast('Designation cannot be empty.');
        return false;
      }
      if (!data.hardcodedCompany) {
        showToast('Company cannot be empty.');
        return false;
      }
      if (!data.redirectUrl) {
        showToast('Redirect URL cannot be empty.');
        return false;
      }
      if (!data.redirectUrl) {
        showToast('Redirect URL cannot be empty.');
        return false;
      }
      if (!checkLink(data.redirectUrl)) {
        showToast('Redirect URL is invalid.');
        return false;
      }
      if (!data.jobType) {
        showToast('Job Type cannot be empty.');
        return false;
      }
      if (!data.compensation) {
        showToast('Compensation cannot be empty.');
        return false;
      }
      if (!data.skills || !data.skills.length) {
        showToast('Skills cannot be empty.');
        return false;
      }
    }
    if (page === 1) {
      if (!data.location_city && !data.is_remote) {
        showToast('Location cannot be empty.');
        return false;
      }
      if (!data.experience) {
        showToast('Experience cannot be empty.');
        return false;
      }
      if (!data.industry) {
        showToast('Industry cannot be empty.');
        return false;
      }
      if (!data.description) {
        showToast('Description cannot be empty.');
        return false;
      }
    }
    return true;
  };
  return (
    <div className="addJob__container">
      <div className="editJob__container">
        <div className="editJob__title">Add Job Post</div>
        <div className="editJob__form">
          {page === 0 ? (
            <>
              <div className="editJob__label">Select Job Title</div>
              <div className="editJob__select">
                <CustomSelectWrapper
                  type="designation"
                  selectedOption={data && data.designation}
                  onChange={(e) => setData({ ...data, designation: e })}
                />
              </div>
              <div className="editJob__label">Enter Company Name</div>
              <input
                type="text"
                style={{ marginBottom: '1rem' }}
                className="editJob__text editJob__select"
                placeholder="Company Name"
                onChange={(e) =>
                  setData({ ...data, hardcodedCompany: e.target.value })
                }
                value={data && data.hardcodedCompany}
              />
              <div className="editJob__label">Upload Company Image</div>
              <input
                type="file"
                style={{ marginBottom: '1rem' }}
                className="editJob__text editJob__select"
                placeholder="Company Name"
                onChange={(e) => {
                  const file = e.target.files[0];
                  console.log(file);
                  if (file) {
                    setData({ ...data, image: file });
                  }
                }}
                // value={data && data.image}
              />
              <div className="editJob__label">Enter Redirect Url</div>
              <input
                type="text"
                style={{ marginBottom: '1rem' }}
                className="editJob__text editJob__select"
                placeholder="Redirect Url"
                onChange={(e) =>
                  setData({ ...data, redirectUrl: e.target.value })
                }
                value={data && data.redirectUrl}
              />

              <div className="editJob__label">Choose Type of Work</div>
              <div className="editJob__select">
                <CustomSelectWrapper
                  options={[
                    { label: 'Work from Home', value: 24 },
                    { label: 'Freelance', value: 25 },
                    { label: 'Onsite Office Job', value: 27 },
                    { label: 'Hybrid Work', value: 100 }
                  ]}
                  selectedOption={data && data.jobType}
                  onChange={(e) => setData({ ...data, jobType: e })}

                  // selectedOption={{
                  //   label: data.jobType[0].job_type,
                  //   value: data.jobType[0].job_type_id
                  // }}
                />
              </div>
              <div className="editJob__label">Select Compensation</div>
              <div className="editJob__select">
                <CustomSelectWrapper
                  options={compensations}
                  selectedOption={data && data.compensation}
                  onChange={(e) => setData({ ...data, compensation: e })}

                  //  selectedOption={{label:data.jobType[0].job_type,value:data.jobType[0].job_type_id}}
                />
              </div>
              <div className="editJob__label">Select skills</div>
              <div className="editJob__select">
                <CustomSelectWrapper
                  type="skill"
                  onChange={
                    (e) => {
                      let skills =
                        data && data.skills
                          ? [
                              { skill_id: e.value, skill: e.label },
                              ...data.skills.filter(
                                (a) => a.skill_id !== e.value
                              )
                            ]
                          : [{ skill_id: e.value, skill: e.label }];
                      setData({ ...data, skills: skills });
                    }
                    // setData({ ...data, skills: data.skills.filter(a=>a.skill_id!==e.id) })
                  }
                />
              </div>
              <div className="editJob__selectedItems">
                {data &&
                  data.skills &&
                  data.skills.map((skill) => (
                    <div
                      className="editJob__selectedItems-item"
                      onClick={
                        (e) => {
                          let skills = data.skills.filter(
                            (a) => a.skill_id !== skill.skill_id
                          );
                          setData({ ...data, skills: skills });
                        }
                        // setData({ ...data, skills: data.skills.filter(a=>a.skill_id!==e.id) })
                      }>
                      {skill.skill}
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <div className="editJob__label">Choose location</div>
              <input
                type="text"
                className="editJob__text"
                placeholder="Location"
                onChange={(e) =>
                  setData({ ...data, location_city: e.target.value })
                }
                value={
                  !data ? null : data.is_remote ? 'Remote' : data.location_city
                }
                readOnly={data && !!data.is_remote}
              />
              <div
                className="editJob__checkbox"
                onClick={() =>
                  setData({ ...data, is_remote: !data.is_remote })
                }>
                <input type="checkbox" checked={data && !!data.is_remote} />
                {'  '}Remote Job
              </div>
              <div className="editJob__label">Choose Experience</div>
              <div className="editJob__selectedItems">
                {workExpOptions.map((a) => (
                  <div
                    className={
                      'editJob__selectedItems-exp' +
                      (data &&
                      data.experience &&
                      data.experience.value === a.value
                        ? ' editJob__selectedItems-exp-active'
                        : '')
                    }
                    onClick={() => setData({ ...data, experience: a })}>
                    {a.value}
                  </div>
                ))}
              </div>
              <div className="editJob__label">Choose Industry</div>
              <div className="editJob__select">
                {' '}
                <CustomSelectWrapper
                  type="industry_type"
                  selectedOption={data && data.industry}
                  onChange={(e) => setData({ ...data, industry: e })}
                />
              </div>
              <div className="editJob__label">Choose Description</div>
              <textarea
                className="editJob__textarea"
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                value={data && data.description}></textarea>
              <div
                className="editJob__checkbox"
                onClick={() =>
                  setData({
                    ...data,
                    isBusinessOpportunity: !data.isBusinessOpportunity
                  })
                }>
                <input
                  type="checkbox"
                  checked={data && data.isBusinessOpportunity}
                />
                {'  '}Business Opportunity
              </div>{' '}
            </>
          )}
          <div className="editJob__btns">
            {page !== 0 && (
              <div
                className="editJob__btns-cancel"
                onClick={() => {
                  // if (page === 0) cancel();
                  if (page === 1) setPage(0);
                }}>
                {page === 0 ? 'Cancel' : 'Previous'}
              </div>
            )}
            <div
              className="editJob__btns-next"
              onClick={() => {
                if (page === 0 && verifyPage(0)) {
                  setPage(1);
                } else if (page === 1 && verifyPage(1)) {
                  //   setPage(1);
                  addJob();
                }
                // else updateJob();
              }}>
              {page === 0 ? 'Next' : 'Add Job'}
            </div>
          </div>
        </div>
        {toastMessage && (
          <div className="active_jobs__toast">{toastMessage}</div>
        )}
      </div>
    </div>
  );
}
