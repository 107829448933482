import React, { useState, useEffect } from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  score: {
    // margin: theme.spacing(3),
    border: '2px solid #3f51b5',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 10
  }
}));

export default function UserCompensationDetails(props) {
  const classes = useStyles();
  const { className, userid, data, ...rest } = props;
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography gutterBottom variant="h4">
            User Certificates
          </Typography>
          <Divider gutterBottom />
          <div className="com_container">
            <div className="com_data">
              {data?.certificates?.map((item, index) => (
                <div
                  className="com_data-grp"
                  style={{ display: 'block', marginBottom: '1rem' }}>
                  <div className="com_data-key">
                    {index + 1}. {item.title}
                  </div>
                  <div
                    className="com_data-value"
                    style={{ paddingLeft: '1rem', marginTop: '.25rem' }}>
                    {item.description}
                    {item.certificate_url ? (
                      <>
                        <br />
                        <a href={item.certificate_url}>{item.certificate_url}</a>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}