import { headers, apiUrl } from '../config';

const PUBLIC_VAPID_KEY = 'BBEVCqMcRr-pwrxBdIC9Pp3ca_k4l7faQkpCU0T1rpyK94heTtxjq-LzvY78rcsn4JoxeuaIEtqxaoKmMfSy518'; 

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.register('/serviceWorker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
        return registration;
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  }
  return Promise.reject(new Error('Service Worker not supported in this browser'));
};

export const checkSubscriptionStatus = async () => {
  const isSubscribed = localStorage.getItem('isSubscribed') === 'true';
  console.log('isSubscribed:', isSubscribed);

  // if (!isSubscribed) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      console.log('registration:', registration);
      console.log('subscription:', subscription);

      // if (!subscription) {
        // User is not subscribed, prompt for subscription
        return promptForSubscription(registration);
      // } else {
      //   // User is already subscribed
      //   console.log('User is already subscribed:', subscription);
      //   return subscription;
      // }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      throw error;
    }
  // }
  return Promise.resolve();
};

export const promptForSubscription = async (registration) => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
      });
      console.log("User subscribed:", subscription);

      // Send subscription to backend
      await fetch(`${apiUrl}/notifications/subscribe`, {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: headers
      });

      return subscription;
    } else {
      console.error('Permission not granted for Notification');
      throw new Error('Permission not granted for Notification');
    }
  } catch (error) {
    console.error('Failed to subscribe user:', error);
    throw error;
  }
};
