import React, { useState, useEffect } from 'react';
import { sendHttpCall } from 'shareService/httpService';
import ReactModal from 'react-modal';
import Pagination from '@material-ui/lab/Pagination';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export default function PitchUnderReview(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rejectReason, setRejectReason] = useState(null);
  const [selectedJob, setSelectedJob] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [selectedJobForDelete, setSelectedJobForDelete] = useState({});
  const [rejectReasonCustom, setRejectReasonCustom] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [toastMessage, setToastMessage] = useState('');
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const getData = () => {
    setLoading(true);
    let url = `pitch/intro_under_review?limit=${10}&page=${page}`;
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      setData(response.data);
      let count = response.totalCount;
      setTotalCount(count);
      setLastPage(Math.ceil(count / 10));
      setLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, [page]);

  const changeJobStatus = (pitchId, approved, message, deleteJob) => {
    if (!deleteJob && !approved && !message) {
      showToast('Cannot reject job without reason.');
      return;
    }
    let url = `pitch/intro_update_status`;
    sendHttpCall(
      'POST',
      url,
      {
        introId: pitchId,
        approved,
        message,
        delete: deleteJob
      },
      props
    ).then((response) => {
      console.log(response);
      setSelectedJob({});
      setSelectedJobForDelete({});
      if (deleteJob) showToast('Successfully deleted job post.');
      else
        showToast(
          approved
            ? 'Successfully approved job post.'
            : 'Successfully rejected job post.'
        );
      showToast(
        approved
          ? 'Successfully approved pitch.'
          : 'Successfully rejected pitch.'
      );
      setRejectReason(null);
      setRejectReasonCustom('');
      setTimeout(getData, 500);
    });
  };
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const getTimeIn12Hours = (date) => {
    const hour = date.getHours();
    return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
      date.getMinutes()
    )} ${hour > 12 ? 'PM' : 'AM'}`;
  };
  const formatDate = (date, variation = 1) =>
    `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
      date.getMonth() + 1
    )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

  const formatTime = (time) => {
    const date = new Date(time);

    return getTimeIn12Hours(date) + ', ' + formatDate(date);
  };
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  const approveJob = (offerId) => {
    changeJobStatus(offerId, true);
  };
  const rejectJob = (offerId) => {
    changeJobStatus(offerId, false, rejectReasonCustom || rejectReason?.value);
  };
  const deleteJob = (offerId) => changeJobStatus(offerId, null, null, true);

  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <div>
      <div className="jobs_under_review__container">
        <div className="jobs_under_review__header">Intro Under Review</div>
        <div className="jobs_under_review__posts-container">
          {loading && (
            <div className="jobs_under_review__posts-loading">Loading...</div>
          )}
          {!loading &&
            data.map((item) => (
              <div className="jobs_under_review__posts-post-container">
                <div className="jobs_under_review__posts-post-postedAt">
                  {formatTime(new Date(item.time))}
                </div>
                <div className="jobs_under_review__posts-post-posted_by">
                  <img
                    src={item.userPfp}
                    className="jobs_under_review__posts-post-posted_by-image"
                  />
                  <div>
                    <Link
                      onClick={() => {
                        sessionStorage.setItem('viewJobPage', page);
                      }}
                      className="active_jobs__posts-post-posted_by-name"
                      to={`/account/${item.userId}?type=SOCIAL`}>
                      {item.name}
                    </Link>
                  </div>
                </div>
                <div className="jobs_under_review__posts-post-content">
                  <div className="jobs_under_review__posts-post-details">
                    {/* <div className="jobs_under_review__posts-post-title">
                    {item.title}
                  </div> */}
                    <div className="jobs_under_review__row">
                      {/* <div className="jobs_under_review__posts-post-jobType">
                      {item.jobType.length > 0
                        ? item.jobType[0].job_type
                        : item.type_of_service || item.entrepreneur_work_type}
                    </div>
                    -
                    <div className="jobs_under_review__posts-post-location">
                      {item.location_city || 'Remote'}
                    </div> */}
                    </div>
                    <div className="jobs_under_review__posts-post-data">
                      {item.whyDoTheyWantToBeIntroduced && (
                        <div>
                          <div className="jobs_under_review__posts-post-label">
                            Why do they want to be introduced
                          </div>
                          <div className="jobs_under_review__posts-post-desc">
                            {item.whyDoTheyWantToBeIntroduced}
                          </div>
                        </div>
                      )}

                      {item.introduction && (
                        <div>
                          <div className="jobs_under_review__posts-post-label">
                            Introduction
                          </div>
                          <div className="jobs_under_review__posts-post-desc">
                            {item.introduction}
                          </div>
                        </div>
                      )}

                      {item.userWork && (
                        <div>
                          <div className="jobs_under_review__posts-post-label">
                            User Work
                          </div>
                          <div className="jobs_under_review__posts-post-desc">
                            {item.userWork}
                          </div>
                        </div>
                      )}

                      {item.whatsDifferentAboutThem && (
                        <div>
                          <div className="jobs_under_review__posts-post-label">
                            Whats different about them
                          </div>
                          <div className="jobs_under_review__posts-post-desc">
                            {item.whatsDifferentAboutThem}
                          </div>
                        </div>
                      )}

                      <div className="jobs_under_review__posts-post-compensation-section">
                        <div className="jobs_under_review__posts-post-label">
                          Location
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                          {item.location}
                        </div>
                      </div>
                      <div className="jobs_under_review__posts-post-compensation-section">
                        <div className="jobs_under_review__posts-post-label">
                          Sent To
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                          {item.hirerList.length} profiles
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jobs_under_review__posts-post-btns">
                  <button
                    className="jobs_under_review__posts-post-rejectBtn"
                    onClick={() => {
                      setSelectedJobForDelete(item);
                    }}>
                    DELETE
                  </button>
                  {item.hirerList.length > 0 && (
                    <button
                      className="active_jobs__posts-post-rejectBtn active_jobs__posts-post-viewCandidates"
                      onClick={() => {
                        setCandidates(item.hirerList);
                      }}>
                      View Profiles
                    </button>
                  )}
                  <button
                    className="jobs_under_review__posts-post-rejectBtn"
                    onClick={() => {
                      setSelectedJob(item);
                    }}>
                    Remove
                  </button>
                  <button
                    className="jobs_under_review__posts-post-approveBtn"
                    onClick={() => {
                      approveJob(item.introId);
                    }}>
                    Approve
                  </button>
                </div>
              </div>
            ))}
        </div>
        <div className="jobs_under_review__pagination">
          <Pagination
            size="large"
            color="primary"
            count={lastPage}
            page={page}
            onChange={handleChange}
          />
          <div className="jobs_under_review__pagination-count">
            Total Count : {totalCount}
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={selectedJob.introId}
        className="jobs_under_review__modal-modal"
        overlayClassName="jobs_under_review__modal-overlay">
        <div className="jobs_under_review__modal">
          <div className={styles.title}>
            Select a reason to reject this intro for by {selectedJob.name}?
          </div>
          <ReactSelect
            value={rejectReason}
            onChange={(e) => {
              setRejectReasonCustom('');
              setRejectReason(e);
            }}
            options={[
              {
                label: 'Insufficient information to Apply',
                value: 'Insufficient information to Apply'
              },
              {
                label: 'Role Description not appropriate',
                value: 'Role Description not appropriate'
              },
              {
                label: 'Please add more skills',
                value: 'Please add more skills'
              },
              {
                label: 'Salary does not commensurate with skills / experience',
                value: 'Salary does not commensurate with skills / experience'
              },
              { label: 'Post not Appropriate', value: 'Post not Appropriate' },
              {
                label: 'Official Email not there',
                value: 'Official Email not there'
              },
              {
                label: 'Official Website not there',
                value: 'Official Website not there'
              },
              {
                label: 'Official Email & Website not there',
                value: 'Official Email & Website not there'
              },
              { value: 'Custom message', label: 'Custom message' }
            ]}
          />
          {rejectReason?.value === 'Custom message' && (
            <input
              className={styles.textField}
              placeholder="Choose custom message"
              type="text"
              value={rejectReasonCustom}
              onChange={(e) => setRejectReasonCustom(e.target.value)}
            />
          )}
          <div className={styles.buttons}>
            <div className="jobs_under_review__modal-btns">
              <button
                className="jobs_under_review__modal-btn-reject"
                onClick={() => {
                  rejectJob(selectedJob.introId);
                }}>
                Reject
              </button>
              <button
                className="jobs_under_review__modal-btn-cancel"
                onClick={() => {
                  setSelectedJob({});
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={selectedJobForDelete.introId}
        className="jobs_under_review__modal-modal"
        overlayClassName="jobs_under_review__modal-overlay">
        <div className="jobs_under_review__modal">
          <div className={styles.title}>
            Do you really want to delete this intro from database. This action
            is irreversible.{' '}
          </div>
          <div className={styles.buttons}>
            <div className="jobs_under_review__modal-btns">
              <button
                className="jobs_under_review__modal-btn-reject"
                onClick={() => {
                  deleteJob(selectedJobForDelete.introId);
                }}>
                DELETE
              </button>
              <button
                className="jobs_under_review__modal-btn-cancel"
                onClick={() => {
                  setSelectedJobForDelete({});
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ReactModal>{' '}
      <ReactModal
        isOpen={candidates.length > 0}
        onRequestClose={() => {
          setCandidates([]);
        }}
        className="active_jobs__modal-modal2"
        overlayClassName="active_jobs__modal-overlay2">
        <div className="active_jobs__modal2">
          <div className="candidate_list_title">Sent to Hirers</div>
          <div className="candidate_list_items">
            {candidates.map((a, index) => (
              <div className="candidate_list_item">
                <div className="candidate_list_item_no">{index + 1}</div>
                <div>
                  <img
                    src={a.image_id}
                    className="candidate_list_item_img"
                    alt=""
                  />
                  <div className="candidate_list_item_name">
                    {a.name}{' '}
                    {a.is_good_fit ? (
                      <span className="candidate_list_item_name_r">R</span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div
                  className={'candidate_list_item_status'}
                  style={{ fontSize: '1rem', marginRight: '.5rem' }}>
                  {a.profile_viewed ? 'Profile Viewed' : ''}
                </div>
                <div
                  className={
                    'candidate_list_item_status' +
                    (a.request_status === 'ACCEPTED' ? ' status_blue' : '') +
                    (a.request_status === 'REJECTED' ? ' status_red' : '')
                  }>
                  {a.request_status}
                </div>
                <Link
                  className="candidate_list_item_viewProfile"
                  to={`/account/${a.user_id}?type=SOCIAL`}>
                  View Profile
                </Link>
              </div>
            ))}
          </div>
        </div>
      </ReactModal>
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}
