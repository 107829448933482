import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory, createHashHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { Worker } from '@react-pdf-viewer/core';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LeadDrawerProvider } from './contexts/LeadDrawerContext';
import { JobBoostMasterProvider } from './contexts/JobBoostMasterContext';
import NotificationModal from 'components/NotificationModal/NotificationModal';
import {
  registerServiceWorker,
  checkSubscriptionStatus
} from './shareService/pushNotifications';
import { sendHttpCall } from 'shareService/httpService';
import { loadStaticData } from './redux/reducer/staticConfigReducer';
import { connect } from 'react-redux';

const browserHistory = createBrowserHistory();
const hashHistory = createHashHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      exist: 'sign-in',
      notification: { isOpen: false, data: '' }
    };
    toast.configure({
      autoClose: 2000,
      draggable: false
    });

    const userDataString = localStorage.getItem('login_data');
    const userType = localStorage.getItem('user_type');
    if (userDataString && !userType) {
      localStorage.clear();
    }
    const userDataParse = JSON.parse(userDataString);
    
    if (userDataParse !== null) {
      this.state.exist = 'dashboard';
    } else {
      this.state.exist = 'sign-in';
    }
  }

  fetchStaticConfigs() {
    const url = "config/admin_static_config";
    sendHttpCall('GET', url, null, null)
      .then((response) => {
        this.props.loadStaticData(response);
        console.log("response", response);
      })
      .catch((err) => { console.log(err) });
  }

  componentDidMount() {
    this.registerAndCheckSubscription();
    this.fetchStaticConfigs();
    navigator.serviceWorker.addEventListener(
      'message',
      this.handleServiceWorkerMessage
    );
  }

  registerAndCheckSubscription = async () => {
    try {
      await registerServiceWorker();
      await checkSubscriptionStatus();
    } catch (error) {
      console.error(
        'Error during service worker registration or subscription check:',
        error
      );
    }
  };

  handleServiceWorkerMessage = (event) => {
    console.log('Service Worker message received:', event.data);
    if (event.data.type === 'SHOW_NOTIFICATION_MODAL') {
      console.log('SHOW_NOTIFICATION_MODAL event received');
      this.setState({
        notification: {
          isOpen: true,
          data: event.data
        }
      });
    }
  };

  handleCloseNotification = () => {
    this.setState({ notification: { isOpen: false, data: '' } });
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <LeadDrawerProvider>
        <JobBoostMasterProvider>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Router history={hashHistory}>
              <Routes path={this.state.exist} />
              {this.state.notification.isOpen && (
                <NotificationModal
                    isOpen={this.state.notification.isOpen}
                    onRequestClose={this.handleCloseNotification}
                    notification={this.state.notification.data}
                />
              )}
            </Router>
          </Worker>
          </JobBoostMasterProvider>
        </LeadDrawerProvider>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = {
  loadStaticData, // Maps someAction directly to props
};

export default connect(null, mapDispatchToProps)(App);