/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import Highlighter from 'react-highlight-words';
import React, { useState, useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { FixedSizeList as List } from 'react-window';
import ReactSelect, { createFilter, components } from 'react-select';
import PropTypes from 'prop-types';
import './EditJobPost.scss';
import debounce from 'debounce-promise';

import Axios from 'axios';
const accessToken =
  'pk.eyJ1IjoiZ29vZHNwYWNlbWFwcyIsImEiOiJjbHM4dGV1N28wMGY4MnZseGg1aWpxZHVjIn0.VOukyKp2CXk6Pq9E0IGSxA';
const notLoggedInTokenProd =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYyMjYwNDY4MH0.ZQxGVUbEZFpSyRYNEeTQysh4fNomDM_RS5XUSqx7YyY'; // prod
const getPlacesPrediction = (val) =>
  new Promise((resolve, reject) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      val
    )}.json?access_token=${accessToken}&types=place,country,district,region&worldview=in&limit=7&fuzzyMatch=false`;
    Axios.get(url)
      .then((res) => {
        resolve(res.data.features);
      })
      .catch((err) => {
        console.log('Error in getPlacesPrediction api ', err.response);
        reject(err);
      });
  });
const fetchSpecificConfigApi = async (url) => {
  let data = await Axios.get('https://api.ourgoodspace.com/api/d2/' + url, {
    headers: {
      authorization: notLoggedInTokenProd
    }
  });
  return data;
};
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const height = 35;
  const initialOffset = options.indexOf(value) * height;
  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

MenuList.propTypes = {
  options: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
  maxHeight: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired
};
const DropdownIndicator = (props) => {
  // eslint-disable-next-line react/prop-types
  const { theme } = props;
  return (
    <components.DropdownIndicator {...props}>
      {theme.dropIcon === 'arrowDown' ? (
        <ArrowDownIcon color={theme.colors.iconColor} />
      ) : (
        <SearchIcon color={theme.colors.iconColor} />
      )}
    </components.DropdownIndicator>
  );
};
DropdownIndicator.propTypes = {
  theme: PropTypes.any.isRequired
};
const SearchIcon = ({ color }) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M20 20L15.514 15.506L20 20ZM18 9.5C18 11.7543 17.1045 13.9163 15.5104 15.5104C13.9163 17.1045 11.7543 18 9.5 18C7.24566 18 5.08365 17.1045 3.48959 15.5104C1.89553 13.9163 1 11.7543 1 9.5C1 7.24566 1.89553 5.08365 3.48959 3.48959C5.08365 1.89553 7.24566 1 9.5 1C11.7543 1 13.9163 1.89553 15.5104 3.48959C17.1045 5.08365 18 7.24566 18 9.5V9.5Z"
      // eslint-disable-next-line react/prop-types
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
SearchIcon.propTypes = {
  color: PropTypes.string.isRequired
};
const ArrowDownIcon = ({ color }) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.94409 7.62117L13.6051 2.19101C13.7302 2.07195 13.8295 1.93031 13.8973 1.77424C13.9651 1.61818 14 1.45078 14 1.28171C14 1.11265 13.9651 0.945252 13.8973 0.789187C13.8295 0.633122 13.7302 0.491475 13.6051 0.372418C13.3549 0.133886 13.0165 -4.29892e-08 12.6638 -5.84073e-08C12.3111 -7.38253e-08 11.9727 0.133886 11.7225 0.372417L6.99614 4.90609L2.26976 0.372417C2.0196 0.133886 1.68121 -5.38472e-07 1.32848 -5.5389e-07C0.975759 -5.69308e-07 0.637366 0.133886 0.387212 0.372417C0.26347 0.492086 0.16557 0.634008 0.0991277 0.790045C0.0326852 0.946082 -0.000992824 1.11316 2.28322e-05 1.28171C-0.000992838 1.45026 0.0326852 1.61734 0.0991277 1.77338C0.16557 1.92942 0.26347 2.07134 0.387212 2.19101L6.04819 7.62117C6.17231 7.74121 6.31998 7.83649 6.48268 7.90151C6.64538 7.96653 6.81989 8 6.99614 8C7.17239 8 7.3469 7.96653 7.5096 7.90151C7.6723 7.83649 7.81997 7.74121 7.94409 7.62117Z"
      fill={color}
    />
  </svg>
);
ArrowDownIcon.propTypes = {
  color: PropTypes.string.isRequired
};
const HighlightedOptionsComponent = ({ label }, { inputValue }) => (
  <Highlighter
    searchWords={[inputValue]}
    textToHighlight={label}
    highlightStyle={{
      fontWeight: 400,
      marginRight: 0,
      paddingRight: 0,
      background: 'none',
      backgroundColor: 'none'
    }}
    unhighlightStyle={{ fontWeight: 500, marginLeft: 0, paddingLeft: 0 }}
    // highlightClassName="customSelect-highlightedText"
  />
);
HighlightedOptionsComponent.propTypes = {
  label: PropTypes.string.isRequired
};
function CustomSelectWindowed({
  options,
  placeholder,
  theme,
  value,
  onChange,
  allowNewOptions,
  async,
  readOnly,
  loadOptions,
  windowed
}) {
  const customStyles = {
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color:
        theme === 'filter'
          ? '#b1c7ff'
          : theme === 'hireSearchBar'
          ? '#3c72ff'
          : theme === 'hireSearchBar2'
          ? '#AFAFAF'
          : theme === 'social'
          ? '#387eff80'
          : theme === 'contactCard'
          ? '#2D9AFF'
          : theme === 'ONBOARDING_HIRER' || theme === 'EDIT_HIRER'
          ? '#cccccc'
          : '#989BA4',
      fontWeight:
        theme === 'hireSearchBar'
          ? 600
          : theme === 'ONBOARDING_HIRER' || theme === 'EDIT_HIRER'
          ? 300
          : 400,
      fontSize: theme === 'ONBOARDING_HIRER' ? '14px' : '.875rem'
    }),
    control: (base, state) => ({
      ...base,
      background:
        theme === 'filter' ||
        theme === 'social' ||
        theme === 'hireSearchBar' ||
        theme === 'socialCustom' ||
        theme === 'ONBOARDING' ||
        theme === 'ONBOARDING_HIRER' ||
        theme === 'edit_profile' ||
        theme === 'hireSearchBar2' ||
        theme === 'EDIT_HIRER'
          ? '#ffffff'
          : theme === 'contactCard'
          ? '#EBF4FF'
          : '#eeeeee',
      padding:
        theme === 'hireSearchBar' || theme === 'hireSearchBar2'
          ? '0'
          : theme === 'ONBOARDING_HIRER' || theme === 'EDIT_HIRER'
          ? '0.25rem .5rem'
          : '0.125rem 0.35rem',
      border:
        theme === 'social'
          ? '1px solid #8FB7FF;'
          : theme === 'ONBOARDING'
          ? '1px solid #000000 !important'
          : theme === 'edit_profile'
          ? '1px solid #EAF1F5'
          : 'none',

      fontFamily: theme === 'hireSearchBar' ? 'Montserrat' : 'Poppins',
      borderRadius:
        theme === 'filter'
          ? '11px'
          : theme === 'edit_profile' ||
            theme === 'ONBOARDING_HIRER' ||
            theme === 'EDIT_HIRER'
          ? '12px'
          : '9px',
      fontSize: theme === 'hireSearchBar' ? '.875rem' : '15px',
      fontWeight: theme === 'hireSearchBar' ? 600 : 400,

      boxShadow:
        theme === 'filter'
          ? '0px 2px 10px rgba(0, 0, 0, 0.15)'
          : theme === 'socialCustom'
          ? ' 0px 1px 2px rgba(0, 64, 255, 0.15),inset 0px 1px 6px rgba(0, 64, 255, 0.15);'
          : theme === 'edit_profile'
          ? '0px 4px 10px rgba(0, 77, 255, 0.04)'
          : theme === 'ONBOARDING_HIRER'
          ? '0px 1px 7px rgba(136, 136, 136, 0.25)'
          : theme === 'EDIT_HIRER'
          ? '0px 1px 7px rgba(148, 94, 255, 0.1)'
          : null
    }),
    input: (base, state) => ({
      ...base,
      color:
        theme === 'filter' || theme === 'hireSearchBar'
          ? '#3c72ff'
          : theme === 'contactCard'
          ? '#2D9AFF'
          : '#4E525C'
    }),
    option: (base, state) => ({
      ...base,
      overflow: 'hidden',
      whiteSpace: 'pre',
      textOverflow: 'ellipsis'
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 4
    })
  };
  const selectRef = useRef(null);
  return (
    <div className="customSelect-container">
      {allowNewOptions && !async ? (
        <CreatableSelect
          styles={customStyles}
          components={windowed ? { MenuList, DropdownIndicator } : {}}
          options={options}
          isDisabled={readOnly}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          classNamePrefix="customSelect"
          filterOption={createFilter({ ignoreAccents: false })}
          theme={(theme_) => ({
            ...theme_,
            borderRadius: 0,
            dropIcon: 'arrowDown',
            colors: {
              ...theme_.colors,
              primary25: '#deebff',
              primary: '#6ea7ff',
              iconColor:
                theme === 'filter'
                  ? '#b1c7ff'
                  : theme === 'social'
                  ? '#387EFF'
                  : theme === 'contactCard'
                  ? '#2D9AFF'
                  : '#C4C4C4'
            }
          })}
        />
      ) : async ? (
        allowNewOptions ? (
          <AsyncCreatableSelect
            styles={customStyles}
            components={
              theme === 'hireSearchBar' || theme === 'hireSearchBar2'
                ? {
                    DropdownIndicator: (props) => (
                      <components.DropdownIndicator {...props}>
                        <div />
                      </components.DropdownIndicator>
                    )
                  }
                : {}
            }
            // Control: (props) => (
            //   <>
            //     <components.Control {...props}>
            //       <SearchIcon color="#b1c7ff" />
            //       <components.Input {...props} />
            //     </components.Control>
            //   </>
            // ),

            classNamePrefix={theme === 'hireSearchBar' ? 'customSelect' : ''}
            placeholder={placeholder}
            isDisabled={readOnly}
            value={value}
            ref={selectRef}
            onChange={(e) => e.value !== '' && onChange(e)}
            onBlur={(e) => e.preventDefault()}
            loadOptions={loadOptions}
            formatOptionLabel={
              theme === 'hireSearchBar' ? HighlightedOptionsComponent : null
            }
            defaultOptions={[{ label: 'Type to start searching', value: '' }]}
          />
        ) : (
          <AsyncSelect
            styles={customStyles}
            components={
              theme === 'hireSearchBar' || theme === 'hireSearchBar2'
                ? {
                    DropdownIndicator: (props) => (
                      <components.DropdownIndicator {...props}>
                        <div />
                      </components.DropdownIndicator>
                    )
                  }
                : theme === 'social'
                ? { MenuList, DropdownIndicator }
                : {}
            }
            classNamePrefix={
              theme === 'hireSearchBar' || theme === 'hireSearchBar2'
                ? 'customSelect'
                : ''
            }
            placeholder={placeholder}
            theme={(theme_) => ({
              ...theme_,
              borderRadius: 0,
              dropIcon: 'arrowDown',
              colors: {
                ...theme_.colors,
                primary25: '#deebff',
                primary: '#6ea7ff',
                iconColor:
                  theme === 'filter'
                    ? '#b1c7ff'
                    : theme === 'social'
                    ? '#387EFF'
                    : theme === 'contactCard'
                    ? '#2D9AFF'
                    : '#C4C4C4'
              }
            })}
            isDisabled={readOnly}
            value={value}
            ref={selectRef}
            onChange={(e) => e.value !== '' && onChange(e)}
            onBlur={(e) => e.preventDefault()}
            loadOptions={loadOptions}
            formatOptionLabel={
              theme === 'hireSearchBar' ? HighlightedOptionsComponent : null
            }
            defaultOptions={[{ label: 'Type to start searching', value: '' }]}
          />
        )
      ) : (
        <ReactSelect
          styles={customStyles}
          components={windowed ? { MenuList, DropdownIndicator } : {}}
          options={options}
          isDisabled={readOnly}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          classNamePrefix="customSelect"
          filterOption={createFilter({ ignoreAccents: false })}
          theme={(theme_) => ({
            ...theme_,
            dropIcon: 'arrowDown',
            borderRadius: 0,
            colors: {
              ...theme_.colors,
              primary25: '#deebff',
              primary: '#6ea7ff',
              iconColor:
                theme === 'filter'
                  ? '#b1c7ff'
                  : theme === 'social'
                  ? '#387EFF'
                  : theme === 'contactCard'
                  ? '#2D9AFF'
                  : '#C4C4C4'
            }
          })}
        />
      )}
    </div>
  );
}

export default function CustomSelectWrapper({
  type,
  searchType,
  placeholder,
  theme,
  options,
  value,
  onChange,
  allowNewOptions,
  async,
  readOnly,
  windowed,
  dataServer
}) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const getUrl = (typeArg, searchTypeArg = 'config', val) => {
    switch (searchTypeArg) {
      case 'config':
        return `config/searchConfig?config=${typeArg}&search=${'%'}`;
      case 'config_async':
        return `config/searchConfig?config=${typeArg}&search=${val}`;
      case 'contact':
        return `/contact/search?query=${`${val}%` || '%'}`;
      case 'organisation':
        return `/config/organisation?search=${val}`;
      case 'company':
        return `/config/organisation?search=${val}&edu=0`;
      case 'school':
        return `/config/organisation?search=${val || ''}&edu=1`;
      case 'degree':
        return `config/searchConfig?config=degree&search=${val || ''}`;
      case 'field':
        return `config/searchConfig?config=degree_field&search=${val}`;
      case 'interest':
        return `config/search_config?config=interest${
          val ? `&search=${val}` : ''
        }`;
      default:
        return '';
    }
  };

  const getData = (val = null) => {
        if (async) return;
    setLoading(true);
    if (options && options.length) {
      setList(options);
      setLoading(false);
    } else if (type || searchType) {
      const url = getUrl(type, searchType, val);
      fetchSpecificConfigApi(url, dataServer).then((res) => {
                if (searchType === 'school') {
          setList(
            res.data.data.map((a) => ({
              ...a,
              value: a.organisation_id,
              label: a.organisation
            }))
          );
        } else
          setList(res.data.data.map((a) => ({ value: a.id, label: a.value })));
        setLoading(false);
      });
    }
  };
  const getPredictionFromTables = async (val) => {
    const url = getUrl(type, searchType || 'config_async', val);
    const res = await fetchSpecificConfigApi(url);
    return res.data.data;
  };
  const fetchPredictionsFromTables = async (val) => {
    const predictions = await getPredictionFromTables(val);
    const data = predictions.map((a) => ({
      ...a,
      value: a.id || a.organisation_id,
      label: a.label || a.value || a.organisation
    }));
    return data;
  };
  const fetchPredictions = async (val) => {
    const predictions = await getPlacesPrediction(val);
    const data = predictions.map((a) => ({
      label: a.place_name,
      value: a.place_name,
      longitude: a.geometry.coordinates[0],
      latitude: a.geometry.coordinates[1]
    }));
    return data;
  };
  const loadOptions_ = async (inputValue) => {
    if (type === 'location') {
      return fetchPredictions(inputValue);
    }
    if (searchType === 'contact') {
      const url = getUrl(type, searchType, inputValue);
      const res = await fetchSpecificConfigApi(url, dataServer);
      return res.data.data.map((a) => ({
        value: a.contact_user_id,
        label: a.contact_name
      }));
    }
    return fetchPredictionsFromTables(inputValue);
  };
  const loadOptions = debounce(loadOptions_, 500, { leading: true });
  useEffect(() => {
    getData();
  }, [options]);
  return (
    <CustomSelectWindowed
      options={list}
      value={value}
      onChange={onChange}
      theme={theme}
      placeholder={placeholder}
      filterOption={createFilter({ ignoreAccents: false })}
      allowNewOptions={allowNewOptions}
      async={async}
      readOnly={readOnly}
      windowed={windowed}
      loadOptions={async ? loadOptions : () => {}}
    />
  );
}

CustomSelectWindowed.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  allowNewOptions: PropTypes.bool.isRequired,
  async: PropTypes.bool.isRequired,
  loadOptions: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  windowed: PropTypes.bool.isRequired
};
CustomSelectWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  searchType: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  theme: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  allowNewOptions: PropTypes.bool,
  dataServer: PropTypes.string,
  async: PropTypes.bool,
  readOnly: PropTypes.bool,
  windowed: PropTypes.bool
};
CustomSelectWrapper.defaultProps = {
  theme: 'default',
  options: [],
  value: null,
  onChange: () => {},
  allowNewOptions: false,
  async: false,
  windowed: true,
  readOnly: false,
  dataServer: 'GENERAL'
};
