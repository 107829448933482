import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent, Button } from '@material-ui/core';
import clsx from 'clsx';
import CloudDownload from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles((theme) => ({}));

export default function PotentialCandidatess8(props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [uniqueJobCount, setUniqueJobCounts] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('potentialCandidates8Page'), 10) || 0
  );
  const [pageSize, setPageSize] = useState(
    parseInt(sessionStorage.getItem('potentialCandidates8Size'), 10) || 10
  );
  const getData = () => {
    const location = props.history.location.pathname;
    let url = `/jobs/potential_candidates_s8e_v2${
      location.includes('cutoff') ? '_cutoff' : ''
    }?page=${page}&limit=${pageSize}&offerId=${props.match.params.offerId}`;
    setIsLoading(true);
    sendHttpCall('GET', url, null, props).then((response) => {
      sessionStorage.removeItem('potentialCandidates8Size');
      sessionStorage.removeItem('potentialCandidates8Page');
      setData(response.data);
      setTotalCount(response.count);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, [page]);
  useEffect(() => {
    if (!data.length) return;
    if (page === 0) getData();
    else setPage(0);
  }, [pageSize]);

  // NEW COMPLETE CSV EXPORTER
const exportAllDataToCSV = async () => {
  let allData = [];
  let page = 0;
  const limit = 100; 
  let hasMoreData = true;

  while (hasMoreData) {
    const url = `/jobs/potential_candidates_s8e_v2${props.history.location.pathname.includes('cutoff') ? '_cutoff' : ''}?page=${page}&limit=${limit}&offerId=${props.match.params.offerId}`;
    
    try {
      const response = await sendHttpCall('GET', url, null, props);
      if (response.data && response.data.length > 0) {
        allData = allData.concat(response.data);
        page++;
        hasMoreData = response.data.length === limit;
      } else {
        hasMoreData = false;
      }
    } catch (error) {
      console.error('Failed to fetch all data for export:', error);
      hasMoreData = false;
    }
  }

  if (allData.length === 0) {
    console.log("No data available to export.");
    return;
  }

  const convertToCSV = (data) => {
    if (!data || data.length === 0) {
      return '';
    }
    const headers = Object.keys(data[0])
      .filter(key => key !== '_id' && key !== 'score')
      .join(',') + '\n';
    const rows = data.map(row =>
      Object.entries(row)
        .filter(([key, _]) => key !== '_id' && key !== 'score') // Exclude '_id' and 'score'
        .map(([_, value]) =>
          `"${String(value).replace(/"/g, '""')}"`
        ).join(',')
    ).join('\n');
  
    return headers + rows;
  };

  const csvData = convertToCSV(allData);
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'email_potentials.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          isLoading={isLoading}
          columns={[
            // {
            //   title: 'S No.',
            //   render: (rowData) => page * pageSize + rowData.tableData.id + 1
            // },
            {
              title: 'Name',
              field: 'Name',
              render: (rowData) => (
                <div
                  onClick={() => {
                    sessionStorage.setItem('potentialCandidates8Page', page);
                    sessionStorage.setItem('potentialCandidates8Size', pageSize);
                    // props.history.push(`/account/${rowData.Name}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.name}
                </div>
              )
            },
            // { title: 'Mobile Number', field: 'mobile_number' },
            { title: 'Email', field: 'email' },
            { title: 'Skill', field: 'skill' },
            { title: 'Job Title', field: 'job_title' },
            { title: 'Experience', field: 'experience' },
            { title: 'CTC', field: 'ctc' },
            { title: 'Qualification', field: 'qualification' },
            { title: 'Location', field: 'location' } 
          ]}
          data={data}
          totalCount={totalCount}
          title={  
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              fontSize: '25px', 
              fontWeight: '600' 
            }}>
              <span>Potential Candidates Status8 (Email)</span>
          
              <Button
                variant="contained"
                onClick={exportAllDataToCSV}
                style={{ 
                  padding: '10px', 
                  margin: '10px',
                  backgroundColor: '#3ca7ff', 
                  color: '#fff'
                }}
              >
                <span style={{ marginRight: '5px' }}>Export To CSV</span> <CloudDownload />
              </Button>
            </div>
          }
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 50],
          }}
          
        />
      </CardContent>
    </Card>
  );
}
