import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { BackgroundImageOnlyCard } from '../BackgroundImageOnlyCard';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import LibraryAddRoundedIcon from '@material-ui/icons/LibraryAddRounded';
import { Card_template } from '../../../../../config';
import TextField from '@material-ui/core/TextField';
import { sendHttpCall, sendFileUploadHttpCall } from 'shareService/httpService';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    flexGrow: 1
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    height: 500,
  },
  cardContent: {
    flexGrow: 1,
  },
  containerpop: {
    padding: theme.spacing(2),
    width: '60%',
    borderColor: '#000',
    borderWidth: 2,
    alignItems: 'center',
  },
  form: {
    // width: '50%', // Fix IE 11 issue.
    padding: theme.spacing(3)
  },
  fileinput: {
    margin: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const BackgroundImageOnlyCardList = props => {
  const { cards, onChange } = props;
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const [anchorElChangeImage, setAnchorElChanegeImage] = useState(null);
  const [cardList, setCardList] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [cardId, setCardId] = useState();
  const [code, setCode] = useState();
  const [description, setDescription] = useState();
  const [weight, setWeight] = useState();
  const [status, setStatus] = useState('INACTIVE');
  const [loading, setLoading] = useState(false);
  const openAddForm = Boolean(anchorElAdd);
  const openEditForm = Boolean(anchorElEdit);
  const openChangeImageForm = Boolean(anchorElChangeImage);
  // const id = openAddForm ? 'simple-popover' : undefined;
  var reqBody = {
    code: code,
    description: description,
    weight: weight,
    status: status,
  };

  const onListUpdate=()=>{
    onChange();
  }

  const handleClick = event => {
    setAnchorElAdd(event.currentTarget);
  };

  const handleAddFormClose = () => {
    setAnchorElAdd(null);
  };
  
  const handleEditClick = (event, card) => {
    setSelectedCard(card)
    setCardId(card.aux_card_id);
    setCode(card.code);
    setDescription(card.description);
    setWeight(card.weight);
    setStatus(card.status);
    setAnchorElEdit(event.currentTarget);
  };

  const handleEditFormClose = () => {
    setSelectedCard(null)
    setCardId(null)
    setCode(null);
    setDescription(null);
    setWeight(null);
    setStatus('INACTIVE');
    setAnchorElEdit(null);
  };

  const handleChangeImageClick = (event, card) => {
    setSelectedCard(card);
    setCardId(card.aux_card_id);
    setCode(card.code);
    setAnchorElChanegeImage(event.currentTarget);
  }

  const handleChangeImageFormClose = () => {
    setSelectedCard(null);
    setCardId(null);
    setCode(null);
    setAnchorElChanegeImage(null);
  }

  const addNewCard = (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('code', code);
    formData.append('description', description);
    formData.append('weight', weight);
    formData.append('status', status);
    formData.append('template', Card_template.BACKGROUND_IMAGE_ONLY);
    formData.append('type', 'INFO');
    sendFileUploadHttpCall('POST', 'cards/add_new_card', formData, props)
      .then((response) => {
        if(response.state) {
          setAnchorElAdd(null);
          setCardList(response.data);
          onListUpdate();
          toast.success("Card added successfully");
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        toast.error(`Something went wrong ${error.code}. Please try again.`);
      });
  }

  const editCard = (event) => {
    setLoading(true);
    event.preventDefault();
    sendHttpCall('POST', `cards/edit_card/${cardId}`, reqBody, props)
      .then((response) => {
        if(response.state) {
          setAnchorElEdit(null);
          setCardList(response.data);
          onListUpdate();
          toast.success("Card updated successfully");
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        toast.error(`Something went wrong ${error.code}. Please try agin`);
      });
  }

  const ChangeCardImage = (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('code', code);
    sendFileUploadHttpCall('POST', `cards/change_card_image/${cardId}`, formData, props)
      .then((response) => {
        if(response.state) {
          setAnchorElChanegeImage(null);
          setCardList(response.data);
          onListUpdate();
          toast.success("Card image updated successfully");
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        toast.error(`Something went wrong ${error.code}. Please try agin`);
      });
  }

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  }

  const handleCodeChange = (event) => {
    setCode(event.target.value);
    reqBody.code = event.target.value;
  }
  
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    reqBody.description = event.target.value;
  }

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
    reqBody.weight = event.target.value;
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    reqBody.status = event.target.value;
  }

  return (
    <div className={classes.root}>
      <div className={classes.popbtn}>
        <div style={{ width: 50, height: 50, borderRadius: 25, boxShadow: "1px 1px 1px 1px #9E9E9E", float: 'right', }}>
          <LibraryAddRoundedIcon style={{ margin: 10, cursor: 'pointer', fontSize: 30 }} color="primary" onClick={handleClick} />
        </div>
        <Popover
          // id={id}
          className={classes.containerpop}
          open={openAddForm}
          anchorEl={anchorElAdd}
          onClose={handleAddFormClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <form className={classes.form} onSubmit={addNewCard} >
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  name="code"
                  variant="outlined"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  autoFocus
                  helperText="Code should be unique e.g. PRECARD_SA, INFO_SA_POSITIVE"
                  value={code}
                  onChange={handleCodeChange}
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  helperText="describe when the card should be displayed to user"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="weight"
                  label="Weight"
                  name="weight"
                  helperText="Assign a weight to the card in number. Give high number for high Priority Card"
                  value={weight}
                  onChange={handleWeightChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="status"
                  label="Status"
                  id="status"
                  select
                  SelectProps={{ native: true }}
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value={""} > {""} </option>
                  <option key="active" value="ACTIVE" > ACTIVE </option>
                  <option key="inactive" value="INACTIVE" > INACTIVE </option>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                    Select Image File
                </Typography>
                <input className={classes.fileinput}
                  label='upload file' type='file' onChange={handleFileUpload} />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Add new Card
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </form>
        </Popover>
      </div>  
      <div className={classes.popbtn}>
        <Popover
          // id={id}
          className={classes.containerpop}
          open={openEditForm}
          anchorEl={anchorElEdit}
          onClose={handleEditFormClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <form className={classes.form} onSubmit={editCard} >
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  name="code"
                  variant="outlined"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  autoFocus
                  helperText="Code should be unique e.g. PRECARD_SA, INFO_SA_POSITIVE"
                  value={code}
                  onChange={handleCodeChange}
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  helperText="describe when the card should be displayed to user"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="weight"
                  label="Weight"
                  name="weight"
                  helperText="Assign a weight to the card in number. Give high number for high Priority Card"
                  value={weight}
                  onChange={handleWeightChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="status"
                  label="Status"
                  id="status"
                  select
                  SelectProps={{ native: true }}
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value={""} > {""} </option>
                  <option key="active" value="ACTIVE" > ACTIVE </option>
                  <option key="inactive" value="INACTIVE" > INACTIVE </option>
                </TextField>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Submit Updates
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </form>
        </Popover>
      </div> 
      <div className={classes.popbtn}>
        <Popover
          // id={id}
          className={classes.containerpop}
          open={openChangeImageForm}
          anchorEl={anchorElChangeImage}
          onClose={handleChangeImageFormClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <form className={classes.form} onSubmit={ChangeCardImage} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                    Select New Image File
                </Typography>
                <input className={classes.fileinput}
                  label='upload file' type='file' onChange={handleFileUpload} />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Update Image
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </form>
        </Popover>
      </div>  
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={2}>
          {cards.map((card, index)=> (
            <Grid item key={card.aux_card_id} xs={12} sm={6} md={4}>
              <Card className={classes.card} variant="outlined">
                <CardMedia
                  className={classes.cardMedia}
                  image={card.background_image}
                  title={card.description}
                  alt={card.description}
                />
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6">
                    Code
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {card.code}
                  </Typography>
                  <Typography variant="h6">
                    Description
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    {card.description}
                  </Typography>
                  <Typography variant="h6">
                    Weight
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {card.weight}
                  </Typography>
                  <Typography variant="h6">
                    Status
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {card.status}
                  </Typography>
                </CardContent>
                <Button size="small" color="primary" onClick={(event) => handleEditClick(event, card)}>
                  Edit
                </Button>
                <Button size="small" color="primary" onClick={(event) => handleChangeImageClick(event, card)}>
                  Change Image
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

BackgroundImageOnlyCardList.propTypes = {
  cards: PropTypes.array,
  onChange: PropTypes.func
};

export default BackgroundImageOnlyCardList;
