import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import styles from './AlertsReportDownload.module.scss';
import {
  sendFileUploadHttpCallWithReturnBlob,
  sendHttpCall,
  sendHttpCallBlob
} from 'shareService/httpService';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
function getPreviousDay(date = new Date()) {
  const previous = new Date();
  previous.setDate(date.getDate() - 1);
  return previous;
}
export default function AlertsReportDownload() {
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);

  const [okdate, setOkdate] = useState(false);
  const formatDate = (date, variation = 1) =>
    `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
      date.getMonth() + 1
    )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;
  const [file, setFile] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    localStorage.getItem('clickStartDate')
      ? localStorage.getItem('clickStartDate')
      : moment(getPreviousDay()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    localStorage.getItem('clickEndDate')
      ? localStorage.getItem('clickEndDate')
      : moment(getPreviousDay()).format('YYYY-MM-DD')
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
  const classes = useStyles();
  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    // var d = new Date(event);
    if (event < selectedEndDate) {
      setSelectedStartDate(event);
    }
  };

  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      // toast('You have selected date greater than End date');
    } else {
      setSelectedEndDate(event);
    }
  };
  const [toastMessage, setToastMessage] = useState('');
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const downloadReport = async () => {
    const startDate = moment(selectedStartDate).format('YYYY-MM-DD');
    const endDate = moment(selectedEndDate).format('YYYY-MM-DD');
    const data_ = new FormData();
    data_.append('file', file);
    data_.append('start_date', startDate);
    data_.append('end_date', endDate);
    let blob = await sendFileUploadHttpCallWithReturnBlob(
      'POST',
      `tracking/download_alerts_report`,
      data_,
      {}
    );
    if (blob.message) {
      showToast(blob.message);
      return;
    }
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;

    const fileName =
      endDate === startDate
        ? `report_${endDate}`
        : `report_${endDate}_to_${startDate}`;
    a.download = `${fileName}.csv`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Download Alerts Report</div>
      <div className={styles.label}>Select Report Period</div>
      <div className={styles.item}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <Grid xs={4}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="SELECT START DATE"
                format="MM/dd/yyyy"
                value={selectedStartDate}
                onChange={handleChangeStartDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </Grid>
            <Grid xs={4}>
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                id="date-picker-dialog"
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                label="SELECT END DATE"
                margin="normal"
                onChange={handleChageEndDate}
                value={selectedEndDate}
              />
            </Grid>
            <Grid xs={2}>
              {okdate === false ? null : (
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.srch_btn}
                  // onClick={filterByDateRange}
                >
                  SEARCH
                </Button>
              )}
            </Grid>
            <Grid xs={2}></Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
      <div className={styles.label}>Upload Email Report</div>
      <input
        type="file"
        className={styles.fileInput}
        placeholder="Company Name"
        onChange={(e) => {
          const file = e.target.files[0];
          console.log(file);
          if (file) {
            setFile(file);
          } else setFile(null);
        }}
        // value={file}
      />
      <div className={styles.button} onClick={downloadReport}>
        Download Report
      </div>{' '}
      {toastMessage && <div className="active_jobs__toast">{toastMessage}</div>}
    </div>
  );
}
