/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';
import MaterialTable, { MTablePagination } from 'material-table';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import ReactModal from 'react-modal';

export default function EveryTable(props) {
    const convertToYearly = (monthlyVal) => {
        switch (monthlyVal) {
            case '₹ 10k - 25k':
            case '₹10k - 25k':
                return '₹ 1.2L - 3L';
            case '₹ 25k - 50k':
            case '₹25k - 50k':
                return '₹ 3L - 6L';
            case '₹ 50k - 75k':
                return '₹ 6L - 9L';
            case '₹ 75k - 100k':
                return '₹ 9L - 12L';
            case '₹ 100k - 150k':
                return '₹ 12L - 18L';
            case '₹ 150k - 200k':
                return '₹ 18L - 24L';
            case '₹ 200k - 300k':
                return '₹ 24L - 36L';
            case '₹ 300k - 500k':
                return '₹ 36L - 60L';
            case '>  ₹ 500k':
                return '> ₹ 60L';
            default:
                return monthlyVal;
        }
    };
    const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
    const getTimeIn12Hours = (date) => {
        const hour = date.getHours();
        return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
            date.getMinutes()
        )} ${hour > 12 ? 'PM' : 'AM'}`;
    };
    const formatDate = (date, variation = 1) =>
        `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
            date.getMonth() + 1
        )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

    const formatTime = (time) => {
        const date = new Date(time);

        return getTimeIn12Hours(date) + ', ' + formatDate(date);
    };

    const [selectedJob, selectJob] = useState(null);
    const [page, setPage] = useState(
        parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
    );
    const [limit, setLimit] = useState(
        parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 20
    );
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [extraData, setExtraData] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [bloodDonorRequestId, setBloodDonorRequestId] = useState(null);
    const [toastMessage, setToastMessage] = useState('');
    const showToast = (msg) => {
        setToastMessage(msg);
    };
    const hideToast = () => {
        setToastMessage('');
    };
    useEffect(() => {
        if (toastMessage !== '') {
            setTimeout(hideToast, 2000);
        }
    }, [toastMessage]);
    const getLocaleTime = (time) => {
        if (time) {
            if (props.match.params.type === 'active_member')
                return moment(new Date(time + ' UTC')).format('DD/MM/YYYY h:mm:ss A');
            return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
        }
        return '';
    };
    const startDate = useRef(
        props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
    );
    const endDate = useRef(
        props.match.params.end_date
            ? props.match.params.end_date
            : moment(new Date()).format('YYYY-MM-DD')
    );

    const openUserPage = (userId, social) => {
        sessionStorage.setItem('everyTablePage', page);
        sessionStorage.setItem('everyTableLimit', limit);
        props.history.push(`/account/${userId}${social ? '?type=SOCIAL' : ''}`);
    };
    const columns = [
        {
            title: 'S No.',
            cellStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
            },
            headerStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
            },
            render: (rowData) => page * 20 + rowData.tableData.id + 1
        },
        {
            title: 'Name',
            field: 'name',
            render: (rowData) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        openUserPage(rowData.userDetails.user_id);
                    }}>
                    {rowData.userDetails.name}
                </div>
            )
        },
        {
            title: 'Job Title',
            field: 'job_title',
            render: (rowData) => rowData.jobDetails?.title
        },
        {
            title: 'Type',
            field: 'fromDemo',
            render: (rowData) => (
                <div>
                    {rowData.fromDemo ? "DEMO" : "APPLIED"}
                </div>
            )
        },
        {
            title: 'Message Count',
            field: 'messageCount',
            render: (rowData) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  props.history.push('/auto-interview/interview/' + rowData._id)
                }>
                {rowData.messages.length - 1}
              </div>
            )
          },

        {
            title: 'Status',
            render: (rowData) => {
                // Check if the status is 'Recommended'
                if (rowData.isRecomended === "Recommended") {
                    return (
                        <div style={{
                            backgroundColor: '#e1f4e3', // Light green background
                            color: '#25d366', // Green text
                            padding: '5px 10px',
                            borderRadius: '5px',
                            textAlign: 'center',
                            display: 'inline-block'
                        }}>
                            Recommended
                        </div>
                    );
                } else if (rowData.isRecomended === "Not Recommended") {
                    return (
                        <div style={{
                            backgroundColor: '#fde8e9', 
                            color: '#ff5a4f', 
                            padding: '5px 10px',
                            borderRadius: '5px',
                            textAlign: 'center',
                            display: 'inline-block'
                        }}>
                            Not Recommended
                        </div>
                    );
                } else {
                    // Fallback content if neither
                    return <div style={{ textAlign: 'center' }}>Unknown</div>;
                }
            }
        },
        {
            title: 'Started At',
            field: 'startedAt',
            type: 'datetime',
            headerStyle: {
                position: 'static',
                backgroundColor: '#FFF'
            },
            render: (rowData) => getLocaleTime(rowData.startedAt)
        },
        {
            title: 'Finished At',
            field: 'completedAt',
            type: 'datetime',
            headerStyle: {
                position: 'static',
                backgroundColor: '#FFF'
            },
            render: (rowData) => getLocaleTime(rowData.completedAt)
        },
        {
            title: 'Grade',
            field: 'interviewScore'
        }
    ];
    const getTableLabel = () => {
        let { type } = props.match.params;
        const countryCode = window.location.href.split('?countryCode=')[1];

        if (type === 'social_connect_requests') {
            return 'Social Connect Requests Table';
        }
        if (type === 'social_connect_requests_accepted') {
            return 'Social Connect Requests Accepted Table';
        }
        if (type === 'social_connect_requests_rejected') {
            return 'Social Connect Requests Rejected Table';
        }
        if (type === 'active_member') {
            return 'New Users Table';
        }
        if (type === 'active_member-country') {
            return `New Users Table ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        }
        if (type === 'active_hirers') {
            return 'Active Hirers Table';
        }
        if (type === 'active_hirers-country') {
            return `Active Hirers Table ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        }
        if (type === 'verified_hirers') {
            return 'Verified Hirers Table';
        }
        if (type === 'verified_hirers-country') {
            return `Verified Hirers Table ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        }
        if (type === 'reportedJobs') {
            return 'Reported Jobs';
        }
        if (type === 'feedback') {
            return 'Feedbacks Given';
        }
        if (type === 'status1') {
            return 'Status 1 Users';
        }
        if (type === 'status1-country') {
            return `Status 1 Users ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        }
        if (type === 'status2') {
            return 'Status 2 Users';
        }
        if (type === 'status2-country') {
            return `Status 2 Users ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        }
        if (type === 'feedback_conversions') {
            return 'Users Converted From Feedback Requests';
        }
        if (type === 'by_country') {
            return `Users From ${countries[props.match.params.period]}`;
        }
        if (type === 'device_type') {
            return `Users From ${props.match.params.period}`;
        }
        if (type === 'by_campaign') {
            return `Users From ${props.match.params.period} Campaign`;
        }
        if (type === 'survey_sent') {
            return 'Survey Messages Sent';
        }
        if (type === 'survey_link_clicks') {
            return 'Survey Link Clicks';
        }
        if (type === 'survey_received') {
            return 'Survey Received';
        }
        if (type === 'closedJobs') {
            return 'Closed Jobs';
        }
        if (type === 'deactivated') {
            return 'Deactivated Users';
        }
        if (type === 'deactivated-country') {
            return `Deactivated Users ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        }
        if (type === 'inactive_hirers') {
            return 'Inactive Hirers';
        }
        if (type === 'active_hirers-2') {
            return 'Active Hirers';
        }
        if (type === 'blocked') {
            return 'Blocked Users';
        }
        if (type === 'blocked-country') {
            return `Blocked Users ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        }
        if (type === 'transactions') {
            return 'Transactions';
        }
        if (type === 'failed-transactions') {
            return 'Failed Transactions';
        }
        if (type === 'created-orders') {
            return 'Created Orders';
        }

        if (type === 'survey_conversions') {
            return 'Users Converted From Survey Messages';
        }
        if (type === 'referrals_campus_ambassadors')
            return 'Referrals (Campus Ambassadors)';
        if (type === 'referrals_others') return 'Referrals (Others)';
        if (type === 'status2Hirer') return 'Hirer Status 2';
        if (type === 'status2Hirer-country')
            return `Hirer Status 2 ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        if (type === 'status2Member') return 'Job Seeker Status 2';
        if (type === 'status2Member-country')
            return `Job Seeker Status 2 ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
        if (type === 'status4') return 'Status 4 Only Members';
        if (type === 'find-blood-conversions') return 'Find Blood Conversions';
        if (type === 'find-blood-only-users') return 'Find Blood Only Users';
        if (type === 'blood-donor-requests') return 'Blood Donor Requests';
        if (type === 'blood-donor-requests-accepted')
            return 'Blood Donor Requests Accepted';
        if (type === 'blood-donor-requests-rejected')
            return 'Blood Donor Requests Rejected';
        if (type === 'blood-donor-requests-admin')
            return 'Blood Donor Requests Admin';
        if (type === 'resume-downloaded') return 'Resume Generated';
        if (type === 'resume-audio-uploaded') return 'Resume Audio Uploaded';
        if (type === 'status4-country')
            return `Status 4 Only Members ${countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
                }`;
    };
    const getEveryOtherTableData = async () => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            let url =
                'interview/finished?limit=' +
                limit +
                '&page=' +
                page +
                `&startDate=${startDate.current.toString()}&endDate=${endDate.current.toString()}`;
            sendHttpCall('GET', url, null, props).then((response) => {
                setIsLoading(false);
                setData(response.data);
                setTotalCount(response.totalCount);
            });
        });
    };
    const getData = async () => {
        getEveryOtherTableData();
    };
    useEffect(() => {
        getData();
        sessionStorage.removeItem('everyTablePage');
        sessionStorage.removeItem('everyTableLimit');
    }, [page]);
    useEffect(() => {
        if (!data.length) return;
        if (page === 0) getData();
        else setPage(0);
    }, [limit]);
    return (
        <div>
            <div className="everyTable__title">Interviews Finished</div>
            <div className="everyTable__table">
                <MaterialTable
                    isLoading={isLoading}
                    columns={
                        columns}
                    data={data}
                    totalCount={totalCount}
                    page={page}
                    title=""
                    onChangePage={(e) => {
                        console.log('Changing page - ' + e);
                        setPage(e);
                    }}
                    onChangeRowsPerPage={(e) => setLimit(e)}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        pageSize: limit,
                        pageSizeOptions:
                            [10, 20, 30, 40, 50],
                        sorting: false
                    }}
                />
            </div>{' '}

            {toastMessage && (
                <div className="jobs_under_review__toast">{toastMessage}</div>
            )}
        </div>
    );
}

const BloodDonorRequestPreview = ({
    id,
    isOpen,
    closeModal,
    refresh,
    prevProps,
    showToast
}) => {
    const updating = useRef(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const getData = async () => {
        setLoading(true);
        let url = `blood_donor_request/preview?id=${id}`;
        sendHttpCall('GET', url, null, prevProps).then((response) => {
            setData(response.data);
            setLoading(false);
        });
    };
    const changeRequestStatus = (approved) => {
        if (updating.current) return;
        updating.current = true;
        let url = `/blood_donor_request/update_status`;
        sendHttpCall(
            'POST',
            url,
            {
                bloodDonorRequestId: id,
                approved
            },
            prevProps
        ).then((response) => {
            console.log(response);
            updating.current = false;

            showToast(
                approved
                    ? 'Successfully approved request.'
                    : 'Successfully rejected request.'
            );
            closeModal();
            refresh();
        });
    };
    useEffect(() => {
        if (id) getData();
    }, [id]);
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="jobs_under_review__modal-modal"
            overlayClassName="jobs_under_review__modal-overlay">
            {loading && (
                <div
                    style={{ maxHeight: '80vh', overflowY: 'auto' }}
                    className="jobs_under_review__posts-post-container">
                    Loading...
                </div>
            )}
            {!loading && data && (
                <div
                    style={{ maxHeight: '80vh', overflowY: 'auto' }}
                    className="jobs_under_review__posts-post-container">
                    <div className="jobs_under_review__posts-post-title">
                        Blood Donor Request
                    </div>
                    <div
                        style={{ marginTop: '1rem' }}
                        className="jobs_under_review__posts-post-exp-section">
                        <div className="jobs_under_review__posts-post-label">
                            Patient Name
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                            {data.patient_name}
                        </div>
                    </div>
                    <div className="jobs_under_review__posts-post-exp-section">
                        <div className="jobs_under_review__posts-post-label">
                            Blood Group Required
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                            {data.blood_group || 'Any'}
                        </div>
                    </div>

                    <div className="jobs_under_review__posts-post-exp-section">
                        <div className="jobs_under_review__posts-post-label">
                            Hospital Name
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                            {data.hospital_name}
                        </div>
                    </div>
                    <div className="jobs_under_review__posts-post-exp-section">
                        <div className="jobs_under_review__posts-post-label">
                            Hospital Location
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                            {data.hospital_location}
                        </div>
                    </div>
                    <div className="jobs_under_review__posts-post-exp-section">
                        <div className="jobs_under_review__posts-post-label">
                            Contact Person Name
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                            {data.contact_person_name}
                        </div>
                    </div>
                    <div className="jobs_under_review__posts-post-exp-section">
                        <div className="jobs_under_review__posts-post-label">
                            Contact Person Mobile
                        </div>
                        <div className="jobs_under_review__posts-post-value">
                            {data.contact_person_mobile_number}
                        </div>
                    </div>
                    {data.status === 'UNDER_REVIEW' && (
                        <div className="jobs_under_review__posts-post-btns">
                            <button
                                className="jobs_under_review__posts-post-rejectBtn"
                                onClick={() => {
                                    // setSelectedJob(item);
                                    changeRequestStatus(false);
                                }}>
                                Reject
                            </button>
                            <button
                                className="jobs_under_review__posts-post-approveBtn"
                                onClick={() => {
                                    // approveJob(item.offerId);
                                    changeRequestStatus(true);
                                }}>
                                Approve
                            </button>
                        </div>
                    )}
                </div>
            )}
        </ReactModal>
    );
};
