/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownload from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import download from 'downloadjs';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import { UsersToolbar } from '../components';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import MaterialTable, { MTablePagination } from 'material-table';
import './style.css';
import ReactSelect from 'react-select';
import { selectYesterday } from '../../../config';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

let timeoutId;
const UserSearch = (props) => {
  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate()))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  };
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    localStorage.getItem('startDate')
      ? localStorage.getItem('startDate')
      : moment(new Date(start.setFullYear(2019))).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    localStorage.getItem('endDate')
      ? localStorage.getItem('endDate')
      : moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('selectedPeriodUserSearch')
      ? localStorage.getItem('selectedPeriodUserSearch')
      : 'All time'
  );
  const [goToPageValue, setGoToPageValue] = useState('');

  const [users, setUsers] = useState([]);
  const [filterByName, setFilterByName] = useState(
    localStorage.getItem('searchData') ? true : false
  );
  const [sortingData, setSortingData] = useState(
    localStorage.getItem('sortingData')
      ? localStorage.getItem('sortingData')
      : 'registrationDate'
  );
  const [loading, setLoading] = useState(false);
  const [searchData, setSearch] = useState(
    localStorage.getItem('searchData') ? localStorage.getItem('searchData') : ''
  );
  const [filterDefault, setFilterDefault] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const [page, setPage] = useState(
    localStorage.getItem('pageNo') ? localStorage.getItem('pageNo') : 0
  );
  const [data, setData] = useState([]);
  const [ageFilter, setAgeFilter] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    if (seletedPeriod !== 'All time' && sortingData) {
      setSortingData('');
    }
  }, [seletedPeriod]);
  useEffect(() => {
    console.log(selectedStartDate);
    if (
      ('' + selectedStartDate).includes(' ') ||
      ('' + selectedEndDate).includes(' ')
    ) {
      return;
    }
    if (filterByName) seachByName(searchData, false);
    else if (sortingData || seletedPeriod !== 'All time')
      getSortedResults(sortingData, false);
    else getUnsortedData();
  }, [page, selectedStartDate, selectedEndDate, ageFilter]);
  const goToPage = (val) => {
    setPage(val - 1);
  };
  const onChange = (data) => {
    if (data.length > 0 && /^[0-9]+$/.test(data)) {
      // Search with name is a time consuming query
      setSearch(data);
      seachByName(data, true);
    } else {
      localStorage.removeItem('searchData');
      localStorage.removeItem('sortingData');
      localStorage.removeItem('pageNo');
      localStorage.removeItem('startDate', selectedStartDate);
      localStorage.removeItem('endDate', selectedEndDate);
      localStorage.removeItem('selectedPeriodUserSearch', seletedPeriod);

      setSearch('');
      setPage(0);
      // console.log(filterByName)
      if (filterByName) getUnsortedData(true);
    }
  };

  const onPageChange = (i) => {
    // console.log("page changed")
    // localStorage.setItem('pageNo',i);
    setPage(i);
  };
  const getUnsortedData = (reset) => {
    // console.log("unsorted")
    let pageNum;
    if (reset) pageNum = 0;
    else pageNum = page;
    setLoading(true);
    let url =
      'users/active_users_list_complete_data?' +
      'limit=' +
      20 +
      '&page=' +
      pageNum +
      `&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}`;
    sendHttpCall('GET', url, null, props).then((response) => {
      if (reset) setPage(0);
      setFilterByName(false);
      setData(response.data);
      setTotalCount(response.totalCount);
      localStorage.removeItem('searchData');
      localStorage.removeItem('pageNo');
      localStorage.removeItem('sortingData');
      localStorage.removeItem('startDate', selectedStartDate);
      localStorage.removeItem('endDate', selectedEndDate);
      localStorage.removeItem('selectedPeriodUserSearch', seletedPeriod);
      setLoading(false);
    });
  };
  const seachByName = (data, reset) => {
    let pageNum;
    // console.log(data)
    if (reset) pageNum = 0;
    else pageNum = page;
    setFilterByName(true);
    setSortingData('');
    setLoading(true);
    let url =
      'users/active_users_list_complete_data?nameFilter=' +
      data +
      '&limit=' +
      20 +
      '&page=' +
      page;
    sendHttpCall('GET', url, null, props).then((response) => {
      if (reset) setPage(0);
      // console.log(response.data)
      setData(response.data);
      // console.log(data)
      setTotalCount(response.totalCount);
      setLoading(false);
      localStorage.removeItem('searchData');
      localStorage.removeItem('sortingData');
      localStorage.removeItem('pageNo');
      localStorage.removeItem('startDate', selectedStartDate);
      localStorage.removeItem('endDate', selectedEndDate);
      localStorage.removeItem('selectedPeriodUserSearch', seletedPeriod);
    });
  };
  const getSortedResults = (val, reset, forced) => {
    // console.log(sortingData)
    let pageNum;
    if (reset) pageNum = 0;
    else pageNum = page;
    setLoading(true);
    let url =
      'users/active_users_list_complete_data_with_sort?' +
      'sortBy=' +
      (seletedPeriod !== 'All time' && !forced ? 'registrationDate' : val) +
      '&ageLower=' +
      (ageFilter ? ageFilter.ageLower : 13) +
      '&ageUpper=' +
      (ageFilter ? ageFilter.ageUpper : 200) +
      '&desc=' +
      (seletedPeriod !== 'All time' && !forced ? false : true) +
      '&limit=' +
      20 +
      '&page=' +
      pageNum +
      (seletedPeriod !== 'All time' && !forced
        ? `&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}`
        : '');
    sendHttpCall('GET', url, null, props).then((response) => {
      if (reset) setPage(0);
      setFilterByName(false);
      setData(response.data);
      localStorage.removeItem('searchData');
      localStorage.removeItem('sortingData');
      localStorage.removeItem('pageNo');
      localStorage.removeItem('startDate', selectedStartDate);
      localStorage.removeItem('endDate', selectedEndDate);
      localStorage.removeItem('selectedPeriodUserSearch', seletedPeriod);
      setTotalCount(response.totalCount);
      setLoading(false);
    });
  };

  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        // reqBody.start_date = moment(new Date(start)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date()).format('YYYY-MM-DD');
        //   fetchAllData();
        //startInitial
        setSelectedStartDate(
          moment(new Date(startInitial)).format('YYYY-MM-DD')
        );
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));

        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //   fetchAllData();
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //  fetchAllData();
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        // fetchAllData();
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
      //
      default:
        break;
    }
    setPage(0);
  };
  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    // var d = new Date(event);
    if (event < selectedEndDate) {
      setSelectedStartDate(event);
      setOkdate(true);
    }
  };

  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      // toast('You have selected date greater than End date');
      setOkdate(false);
    } else {
      setSelectedEndDate(event);
      setOkdate(true);
    }
  };

  const filterByDateRange = () => {
    setSelectedStartDate(
      moment(new Date(selectedStartDate)).format('YYYY-MM-DD')
    );
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const exportDataToCSV = () => {
    let url = 'users/getUserDataInCsv';
    sendHttpCallBlob(
      'POST',
      url,
      {
        start_date: selectedStartDate.toString(),
        end_date: selectedEndDate.toString()
      },
      props
    )
      .then(async (blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'filename.csv';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clearSorting = () => {
    if (!sortingData && !filterByName) return;
    setSortingData('');
    setFilterByName('');
    // setSeletedPeriod('All time');
    // setIsCustomDate(false);
    // getUnsortedData(true);
    // setSelectedStartDate(
    //   moment(new Date(start.setFullYear(2019))).format('YYYY-MM-DD')
    // );
    // setSelectedEndDate(
    //   moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
    // );
  };
  const options = [
    { label: 'No Of Contacts', value: 'contacts' },
    { label: 'G Coins', value: 'gcoins' },
    { label: 'Score', value: 'score' },
    { label: 'Hiring Profile', value: 'hiring' },
    { label: 'Users Invited', value: 'usersInvited' },
    { label: 'Last Session', value: 'lastLoginDate' },
    { label: 'Registration Date', value: 'registrationDate' },
    { label: 'Feedbacks Given', value: 'feedbackGiven' },
    { label: 'Feedbacks Received', value: 'feedbackReceived' },
    { label: 'Messages Sent', value: 'messageSent' },
    { label: 'Messages Received', value: 'messageReceived' },
    { label: 'Jobs Applied', value: 'jobsApplied' },
    { label: 'Jobs Posted', value: 'jobsPosted' },
    { label: 'Offers Received', value: 'offersReceived' },
    { label: 'Offers Accepted', value: 'offersAccepted' },
    { label: 'Offers Pending', value: 'offersPending' }
  ];
  const ageOptions = [
    { ageLower: 13, ageUpper: 200, label: 'All Ages' },
    { ageLower: 13, ageUpper: 17, label: 'Less Than 18' },
    { ageLower: 18, ageUpper: 200, label: '18+' },
    { ageLower: 24, ageUpper: 200, label: '24+' },
    { ageLower: -1, ageUpper: -1, label: 'Verified' }
  ];
  const getLocaleTime = (time) => {
    if (time) return new Date(time + ' UTC').toLocaleString();
    return '';
  };
  const onChangeRemark = (e) => {
    clearTimeout(timeoutId);
    let { value } = e.target;

    let userId = e.target.id;
    timeoutId = setTimeout(() => {
      saveRemarkToDb(value, userId);
      // Runs 1 second (1000 ms) after the last change
    }, 750);
  };
  const saveRemarkToDb = (remark, userId) => {
    console.log('Saving to db :', remark, ' - ', userId);
    let url = 'users/addRemark';
    sendHttpCall('POST', url, { userId, remark }, props).then((response) => { });
  };
  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <UsersToolbar onChange={onChange} data={searchData} />
        {/* <div className="select_container">
          Sort Criteria
          <div className="select_empty_space">
            <ReactSelect
              options={options}
              classNamePrefix="custom-select"
              placeholder="Sort By"
              value={{
                label: sortingData,
                value: sortingData
              }}
              onChange={(e) => {
                localStorage.removeItem('searchData');
                localStorage.removeItem('sortingData');
                localStorage.removeItem('pageNo');
                localStorage.removeItem('searchData');
                localStorage.removeItem('sortingData');
                localStorage.removeItem('pageNo');
                setSeletedPeriod('All time');
                setIsCustomDate(false);
                setSearch('');
                setPage(0);
                setSortingData(e.value);
                getSortedResults(e.value, true, true);
              }}
            />
          </div>
          <button
            className={
              'sort_clear_btn' +
              (sortingData || filterByName ? '' : ' sort_clear_btn-disabled')
            }
            onClick={sortingData || filterByName ? clearSorting : null}>
            Reset Sort
          </button>
        </div> */}
      </div>
      <div className={classes.content}>
        <MaterialTable
          actions={[
            {
              icon: 'send',
              tooltip: 'Open User',
              onClick: (event, rowData) => {
                // console.log(searchData)
                localStorage.setItem('searchData', searchData);
                localStorage.setItem('sortingData', sortingData);
                localStorage.setItem('startDate', selectedStartDate);
                localStorage.setItem('endDate', selectedEndDate);
                localStorage.setItem('selectedPeriodUserSearch', seletedPeriod);
                // console.log(page)
                localStorage.setItem('pageNo', page);
                props.history.push(
                  `/account/${rowData[0] ? rowData[0].UserId : rowData.UserId}`
                );
              }
            }
          ]}
          columns={[
            {
              title: 'S No.',
              cellStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
              },
              headerStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
              },
              render: (rowData) => page * 20 + rowData.tableData.id + 1
            },
            {
              title: 'Name',
              field: 'Name',
              cellStyle: {
                position: 'sticky',
                left: 40,
                backgroundColor: '#FFF'
              },

              headerStyle: {
                position: 'sticky',
                left: 40,
                backgroundColor: '#FFF'
              },
              render: (rowData) => {
                let data = rowData[0]
                  ? rowData[0].Company || ''
                  : rowData.Company || '';
                let orgId = data.split('>')[0];
                data = data.replace(orgId + '>', '');
                const verified = data.startsWith('1-');
                return (
                  <div
                    style={{
                      cursor: 'pointer',
                      // fontWeight: 500,
                      textTransform: 'capitalize',
                      // color: '#297bca'
                      // fontSize: '.85rem',
                      fontFamily: 'Roboto',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClick={() => {
                      localStorage.setItem('searchData', searchData);
                      localStorage.setItem('sortingData', sortingData);
                      localStorage.setItem('startDate', selectedStartDate);
                      localStorage.setItem('endDate', selectedEndDate);
                      localStorage.setItem(
                        'selectedPeriodUserSearch',
                        seletedPeriod
                      );
                      // console.log(page)
                      localStorage.setItem('pageNo', page);
                      props.history.push(
                        `/account/${rowData[0] ? rowData[0].UserId : rowData.UserId
                        }`
                      );
                    }}>
                    {rowData[0] ? rowData[0].Name : rowData.Name}

                    {verified && sortingData === 'hiring' ? (
                      <div style={{ marginLeft: '.25rem', color: '#297bca' }}>
                        {' '}
                        <CheckIcon />{' '}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
            },
            {
              title: 'Phone',
              field: 'MobileNumber',
              headerStyle: {
                position: 'static',
                backgroundColor: '#FFF'
              },
              render: (rowData) =>
                rowData[0] ? rowData[0].MobileNumber : rowData.MobileNumber
            },
            // {
            //   title: 'Age',
            //   field: 'Age',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) => (rowData[0] ? rowData[0].Age : rowData.Age)
            // },
            {
              title: 'Location',
              field: 'City',
              headerStyle: {
                position: 'static',
                backgroundColor: '#FFF'
              },
              render: (rowData) => (rowData[0] ? rowData[0].City : rowData.City)
            },
            // {
            //   title: 'Hiring Profile',
            //   field: 'organisation',
            //   hidden: sortingData !== 'hiring',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) => {
            //     let data = rowData[0]
            //       ? rowData[0].Company || ''
            //       : rowData.Company || '';
            //     let orgId = data.split('>')[0];
            //     data = data.replace(orgId + '>', '');
            //     if (data.startsWith('1-')) data = data.replace('1-', '');
            //     if (data.startsWith('0-')) data = data.replace('0-', '');
            //     if (data.startsWith(' at')) data = data.replace(' at', '');
            //     if (data && data !== ' ') return data;
            //     if (orgId === '-2') return 'Undisclosed';
            //     return 'Self';
            //   }
            // },
            // {
            //   title: 'Contacts',
            //   field: 'NoOfContacts',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].NoOfContacts : rowData.NoOfContacts
            // },
            // {
            //   title: 'GCoins',
            //   field: 'GCoins',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].GCoins : rowData.GCoins
            // },
            // {
            //   title: 'Goodness Score',
            //   field: 'GoodnessScore',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].GoodnessScore : rowData.GoodnessScore
            // },
            // {
            //   title: 'Users Invited',
            //   field: 'NoOfUsersInvited',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0]
            //       ? rowData[0].NoOfUsersInvited
            //       : rowData.NoOfUsersInvited
            // },
            // {
            //   title: 'Payment Plan',
            //   field: 'AccountTypeId',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     (rowData[0] && rowData[0].AccountTypeId === 1) ||
            //       rowData.AccountTypeId === 1
            //       ? 'Free'
            //       : 'Paid'
            // },
            // { title: 'Payment Plan', field: 'NoOfContacts' },
            // {
            //   title: 'Last Session',
            //   field: 'LastLoginTime',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   type: 'datetime',
            //   render: (rowData) =>
            //     rowData[0]
            //       ? getLocaleTime(
            //         new Date(rowData[0].LastSessionTime) >
            //           new Date(rowData[0].UpdatedAtTime)
            //           ? rowData[0].LastSessionTime
            //           : rowData[0].UpdatedAtTime
            //       )
            //       : getLocaleTime(
            //         new Date(rowData.LastSessionTime) >
            //           new Date(rowData.UpdatedAtTime)
            //           ? rowData.LastSessionTime
            //           : rowData.UpdatedAtTime
            //       )
            // },
            {
              title: 'Registration Date',
              field: 'JoinedOn',
              type: 'datetime',
              headerStyle: {
                position: 'static',
                backgroundColor: '#FFF'
              },
              render: (rowData) =>
                rowData[0]
                  ? getLocaleTime(rowData[0].JoinedOn)
                  : getLocaleTime(rowData.JoinedOn)
            },
            {
              title: 'Invited By',
              field: 'Source',
              headerStyle: {
                position: 'static',
                backgroundColor: '#FFF'
              },
              render: (rowData) =>
                rowData[0] ? rowData[0].Source : rowData.Source
            },
            // {
            //   title: 'Profile Completed (%)',
            //   field: 'PercentageComplete',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0]
            //       ? rowData[0].PercentageComplete
            //       : rowData.PercentageComplete
            // },
            // {
            //   title: 'Feedback Given',
            //   field: 'FeedbackGiven',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].FeedbackGiven : rowData.FeedbackGiven
            // },
            // {
            //   title: 'Feedback Received',
            //   field: 'FeedbackRecieved',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0]
            //       ? rowData[0].FeedbackRecieved
            //       : rowData.FeedbackRecieved
            // },
            // {
            //   title: 'Feedback Requested',
            //   // field: 'FeedbackRecieved',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData.length
            //       ? rowData[0].UserAnalyticsData
            //         ? rowData[0].UserAnalyticsData.requestFeedbackClickCount
            //           .count
            //         : 0
            //       : rowData.UserAnalyticsData
            //         ? rowData.UserAnalyticsData.requestFeedbackClickCount.count
            //         : 0
            // },
            // {
            //   title: 'Message Sent',
            //   field: 'MessagesSent',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].MessagesSent : rowData.MessagesSent
            // },
            // {
            //   title: 'Message Received',
            //   field: 'MessagesRecieved',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0]
            //       ? rowData[0].MessagesRecieved
            //       : rowData.MessagesRecieved
            // },
            // {
            //   title: 'Jobs Posted',
            //   field: 'JobsPosted',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].JobsPosted : rowData.JobsPosted
            // },
            // {
            //   title: 'Jobs Applied',
            //   field: 'JobsApplied',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].JobsApplied : rowData.JobsApplied
            // },
            // {
            //   title: 'Offers Received',
            //   field: 'OffersRecieved',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].OffersRecieved : rowData.OffersRecieved
            // },
            // {
            //   title: 'Offer Accepted',
            //   field: 'OffersAccepted',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].OffersAccepted : rowData.OffersAccepted
            // },
            // {
            //   title: 'Offer Sent',
            //   field: 'OffersSent',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0]
            //       ? rowData[0].OffersSent || 0
            //       : rowData.OffersSent || 0
            // },
            // {
            //   title: 'Offer Pending',
            //   field: 'OffersPending',
            //   headerStyle: {
            //     position: 'static',
            //     backgroundColor: '#FFF'
            //   },
            //   render: (rowData) =>
            //     rowData[0] ? rowData[0].OffersPending : rowData.OffersPending
            // },
            {
              title: 'Remarks',
              field: 'HiringOrgRemarks',
              hidden: sortingData !== 'hiring',
              headerStyle: {
                position: 'static',
                backgroundColor: '#FFF'
              },
              render: (rowData) => {
                return (
                  <input
                    className="remarks-input"
                    onChange={onChangeRemark}
                    id={rowData[0] ? rowData[0].UserId : rowData.UserId}
                    defaultValue={
                      rowData[0] ? rowData[0].Remarks : rowData.Remarks
                    }
                  />
                );
              }
            }
            // { title: 'Offer', field: 'created_at', type: 'date' }
          ]}
          data={data}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20],
            // pageSizeOptions: [5, 10, 20, 30],
            sorting: false
          }}
          title="Active Users"
          page={page}
          isLoading={loading}
          onChangePage={setPage}
          totalCount={totalCount}
          style={{ zIndex: 0 }}
          components={{
            Pagination: (props) => (
              <div className="table__pagination-container">
                {localStorage.getItem('user_type') === "ADMIN" && (
                  <div className="exportToCsvBtn" onClick={exportDataToCSV}>
                    <span> Export To CSV</span> <CloudDownload />
                  </div>
                )}
                <GoToPageComponent goToPage={goToPage} />
                <div className="table__pagination-default-container">
                  <MTablePagination {...props} />
                </div>
              </div>
            )
          }}
        />
        {/* <UsersTable users={users}/> */}
      </div>
    </div>
  );
};

export default UserSearch;

const GoToPageComponent = ({ goToPage }) => {
  const [val, setVal] = useState('');
  const goToPage_ = () => {
    if (!Number.isNaN(parseInt(val, 10)) && parseInt(val, 10) !== 0)
      goToPage(parseInt(val, 10));
  };
  return (
    <>
      {' '}
      <div className="table__pagination-goToPage-desc">Go To Page</div>
      <div className="table__pagination-goToPage">
        <input
          type="text"
          value={val}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              goToPage_();
            }
          }}
          onChange={(e) => setVal(e.target.value)}
        />
        <button className="table__pagination-goToPage-btn" onClick={goToPage_}>
          Go
        </button>
      </div>
    </>
  );
};
