import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { selectoptions } from '../../../../config';
import TotalUsers from '../TotalUsers';
import moment from 'moment';
import 'react-dropdown/style.css';
import { sendHttpCall, Toast } from 'shareService/httpService';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { margin: 8 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right'
  },
  space: {
    margin: 2
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem('gcoins-period')
      ? localStorage.getItem('gcoins-period')
      : 'Yesterday'
  );
  let end = new Date();
  let start = new Date();
  switch (selectedPeriod) {
    case 'All time':
      start = new Date(2019, 0, 1);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Last Year':
      start.setDate(start.getDate() - 365);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Last 30 days':
      start.setDate(start.getDate() - 30);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Last 7 days':
      start.setDate(start.getDate() - 7);
      localStorage.removeItem('gcoins-period');
      break;
    case 'Yesterday':
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      localStorage.removeItem('gcoins-period');
      break;
    default:
      localStorage.removeItem('gcoins-period');
  }

  // const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(true);
  const [compData, setCompData] = useState({});
  const [surveyCount, setSurveyCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [linkClickCount, setLinkClickCount] = useState(0);
  const [surveyDownloadCount, setSurveyDownloadCount] = useState(0);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState('2019-02-01');
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [okdate, setOkdate] = useState(false);
  var reqBody = {
    start_date: moment(new Date(start)).format('YYYY-MM-DD'),
    end_date: moment(new Date(end)).format('YYYY-MM-DD'),
    city: selectedCity,
    gender: selectedGender
  };
 
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    // Perform your search or fetch action here using the newDateRange
    reqBody.start_date = moment(new Date(newDateRange.startDate)).format(
      'YYYY-MM-DD'
    );
    reqBody.end_date = moment(new Date(newDateRange.endDate)).format('YYYY-MM-DD');
    fetchAllData();
    console.log('Selected Date Range:', newDateRange.startDate);
  };
  const filterByDateRange = () => {
    reqBody.start_date = moment(new Date(selectedStartDate)).format(
      'YYYY-MM-DD'
    );
    reqBody.end_date = moment(new Date(selectedEndDate)).format('YYYY-MM-DD');
    fetchAllData();
  };

  //_______________________________________________
  const fetchCompleteData = async () => {
    setLoading(true);
    let yesterday = false;
    let date = new Date();

    date = date.setDate(date.getDate() - 1);
    date = moment(date).format('YYYY-MM-DD');
    let additionalQuery =
      '?start_date=' + reqBody.start_date + '&end_date=' + reqBody.end_date;
    let response = await sendHttpCall(
      'GET',
      'dashboard/gcoins' + additionalQuery,
      null,
      props
    );

    if (response) {
      setCompData(response);
      setLoading(false);
    }
  };
  //_______________________________________________
  async function fetchAllData() {
    fetchCompleteData();
  }

  useEffect(() => {
    fetchAllData();
    setSelectedStartDate(reqBody.start_date);
    setSelectedEndDate(reqBody.end_date);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item container justifyContent="flex-star">
          
            <DateRangePicker
              onDateChange={handleDateChange}
            />
            {/* {isCustomDate === true ? (
              <Grid container justify="space-around">
                <Grid xs={8}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="SELECT START DATE"
                          format="MM/dd/yyyy"
                          value={selectedStartDate}
                          onChange={handleChangeStartDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </Grid>
                      <Grid xs={4}>
                        <KeyboardDatePicker
                          format="MM/dd/yyyy"
                          id="date-picker-dialog"
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          label="SELECT END DATE"
                          margin="normal"
                          onChange={handleChageEndDate}
                          value={selectedEndDate}
                        />
                      </Grid>
                      <Grid xs={2}>
                        {okdate === false ? null : (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.srch_btn}
                            onClick={filterByDateRange}>
                            SEARCH
                          </Button>
                        )}
                      </Grid>
                      <Grid xs={2}></Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            ) : null} */}
          </Grid>
        </Grid>

        <div className="dashboard-label">Gcoins Data</div>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.earnedCoins}
              loading={loading}
              onClick={() => {
                localStorage.setItem('gcoins-period', selectedPeriod);
                localStorage.setItem(
                  'gcoins-selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'gcoins-endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'gcoins-startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                props.history.push(`/gcoins/earned`);
              }}
              Text="Earned Gcoins"
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12} style={{ cursor: 'pointer' }}>
            {/* total user onclick to be changed from /hire/click to correct one once api is Received */}
            <TotalUsers
              totalUser={compData.debitedCoins}
              loading={loading}
              onClick={() => {
                localStorage.setItem('gcoins-period', selectedPeriod);
                localStorage.setItem(
                  'gcoins-selectedPeriodUserSearch',
                  selectedPeriod
                );
                localStorage.setItem(
                  'gcoins-endDate',
                  moment(selectedEndDate).format('YYYY-MM-DD')
                );
                localStorage.setItem(
                  'gcoins-startDate',
                  moment(selectedStartDate).format('YYYY-MM-DD')
                );
                props.history.push(`/gcoins/debited`);
              }}
              Text="Debited Gcoins"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
