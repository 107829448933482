// LeadDrawerContext.js
import React, { createContext, useContext, useState } from 'react';
import { sendHttpCall } from 'shareService/httpService';
const LeadDrawerContext = createContext();

export function useLeadDrawer() {
  return useContext(LeadDrawerContext);
}

export const LeadDrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [leadData, setLeadData] = useState(null);

  const openDrawer = async (leadId) => {
    setIsOpen(true);
    console.log(leadId);
    let url = `sales/lead/${leadId}`;
    sendHttpCall('GET', url, null)
      .then((response) => {
        setLeadData(response.data);
        console.log(response);
      })
      .catch((error) => {
        console.log('Error fetching lead data', error);
      });
  };

  const closeDrawer = () => {
    setIsOpen(false);
    setLeadData(null);
  };

  return (
    <LeadDrawerContext.Provider
      value={{ isOpen, leadData, openDrawer, closeDrawer }}>
      {children}
    </LeadDrawerContext.Provider>
  );
};
