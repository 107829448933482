import React from "react";
import "./AnimatedLogo.scss";

export default function AnimatedLogo({ color = '#297BCA' }) {
  return (
    <svg
      id="eds390quKSE1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="-11 -30 63 96.43"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g id="eds390quKSE2_to" transform="translate(2.482257,43.25301)">
        <g id="eds390quKSE2_tr" transform="rotate(0)">
          <path
            d="M2.5,47.3c-.1.6-.2,1.5.3,2.9c1.6,4.3,6.7,6.8,7.8,7.3c4.2,2,8,2.1,9.7,2.1.8,0,4,0,8-1.3c1.5-.5,3.7-1.2,6-2.9c2.2-1.6,3.1-3.1,3.4-3.5c0,0,0,0,0-.1.3-.5,1.3-2.1,1-4.1c0-.3-.4-2.2-2-3.4c0,0-2.7-1.8-5.7-.2-.1,0-.2.1-.2.1-.2.2-.4.3-.4.3-.3.3-1.4,1.3-1.5,1.4c0,0-.7.6-2.5,1.8-.2.1-.4.3-.4.3s-.1.1-.1.1c-.5.3-1.5.8-2.7,1.1-.9.2-2.8.8-5.2.2-2-.4-3.4-1.4-4-1.8-.9-.6-1-.9-3-2.5-1.5-1.2-1.8-1.4-2.3-1.5-1.8-.6-3.5.3-3.7.5-.2-.1-2.1,1-2.5,3.2Z"
            transform="translate(-2.545574,-43.412716)"
            fill={color}
          />
        </g>
      </g>
      <g id="eds390quKSE3_to" transform="translate(20.45,21.85)">
        <g id="eds390quKSE3_tr" transform="rotate(0)">
          <path
            d="M37.7,21.3c0-4.8.1-9.7.1-14.5-.1-.4-.3-.9-.8-1.5-.3-.5-1.1-1.5-2.5-2-.3-.1-1.4-.4-2.6,0-1.6.5-2.3,1.9-2.5,2.2-.3.5-.4,1-.4,1.3c0,.2,0,.4,0,.6-2.7-1.6-5.8-2.6-9.2-2.6C10,4.9,2,12.9,2,22.8s8,17.8,17.8,17.8s17.8-8,17.8-17.8c.1-.5,0-1,.1-1.5-.1,0,0,0,0,0ZM19.8,30.8c-4.5,0-8-3.6-8-8.1s3.6-8.1,8.1-8.1s8.1,3.6,8.1,8.1-3.7,8.1-8.2,8.1Z"
            transform="translate(-19.9,-21.848922)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
