import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { sendHttpCall } from 'shareService/httpService';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const TopSkill = (props) => {
  const tableRef = React.createRef();
  // const { className, ...rest } = props;
  const [page, setPage] = useState(
    parseInt(localStorage.getItem('topskillpage'), 10) || 0
  );
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem('topskillpagesize'), 10) || 10
  );
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    localStorage.removeItem('topskillpage');
    localStorage.removeItem('topskillpagesize');
  }, []);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const getData = () => {
    if (loading) return;
    setLoading(true);
    let url = 'skills/top_skills?' + 'limit=' + pageSize + '&page=' + page;
    sendHttpCall('GET', url, null, props)
      .then((response) => {
        setData(response.data);
        setPage(response.page);
        setCount(response.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`${error.message}. Please try again.`);
      });
  };
  useEffect(() => {
    getData();
  }, [page, pageSize]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaterialTable
            title="Top Skills"
            tableRef={tableRef}
            columns={[
              { title: 'Skill id', field: 'skill_id' },
              {
                title: 'Name',
                field: 'skill',
                render: (rowData) => (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      localStorage.setItem('topskillpage', page);
                      localStorage.setItem('topskillpagesize', pageSize);
                      props.history.push(`/skill-detail/${rowData.skill_id}`);
                    }}>
                    {rowData.skill}
                  </div>
                )
              },
              { title: 'Count', field: 'count' }
            ]}
            page={page}
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
            data={data}
            totalCount={count}
            isLoading={loading}
            options={{
              search: false,
              actionsColumnIndex: -1,
              pageSize: pageSize,
              pageSizeOptions: [10, 30, 50, 100]
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

TopSkill.propTypes = {
  className: PropTypes.string
};

export default withRouter(TopSkill);
