import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Alarm, Info } from '@material-ui/icons';
import styles from './NotificationModal.module.scss';
import { useLeadDrawer } from '../../contexts/LeadDrawerContext';

const NotificationModal = ({ isOpen, onRequestClose, notification }) => {
  const { openDrawer } = useLeadDrawer();
  const { title, body, sender, alertType, userId, leadId } = notification?.data || { title: '', body: '', sender: '', alertType: '' };
  console.log(notification)
  const [timeLeft, setTimeLeft] = useState(8000);

  const handleDrawerOpen = (leadId) => {
    openDrawer(leadId);
  };

  useEffect(() => {
    let audio;
    let timer;
    let countdown;

    if (isOpen) {
      const soundFile = alertType === 'info' ? '/info.wav' : '/alert.wav';
      audio = new Audio(soundFile);
      audio.play();

      countdown = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) return prevTime - 1000;
          clearInterval(countdown);
          return prevTime;
        });
      }, 1000);

      timer = setTimeout(() => {
        onRequestClose();
      }, 8000);
    }

    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
      clearInterval(countdown);
      clearTimeout(timer);
    };
  }, [isOpen, onRequestClose, alertType]);

  const openUserPage = (id, type) => {
    const url = `/dashboard#/account/${id}`;
    window.open(url, '_blank');
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`${styles.modal} ${styles[alertType]}`}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.loadingBar} style={{ width: `${(timeLeft / 8000) * 100}%`, transition: 'width 1s linear' }}></div>

      <div className={styles.container}>
        <div className={styles.alertIcon}>
          {alertType === 'info' ? <Info style={{ fontSize: '100px', color: '#34A853' }} /> : <Alarm style={{ fontSize: '100px', color: '#EA4335' }} />}
        </div>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.message} dangerouslySetInnerHTML={{ __html: body }}></p>
        <button className={styles.dismissButton} onClick={() => onRequestClose()}>Dismiss</button>
        {userId && <button className={styles.linkButton} onClick={() => openUserPage(userId, 'user')}>Visit Opportunity</button>}
        {leadId && <button className={styles.linkButton} onClick={() => handleDrawerOpen(leadId)}>Visit Lead</button>}
      </div>
    </ReactModal>
  );
};

export default NotificationModal;
