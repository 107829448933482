import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sendHttpCall } from 'shareService/httpService';
import './styles.css';
import { ca } from 'date-fns/locale';
import axios, { Axios } from 'axios';
import { esocialUrl } from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const CreatePaymentLink = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [userId, setUserId] = useState('');
  const [amount, setAmount] = useState('');
  const classes = useStyles();

  const handleSubmit = async () => {
    if (!userId || !amount) {
      setError('User ID and Amount are required.');
      return;
    }
    setLoading(true);
    const postData = {
      amount: amount,
      userId: userId
    }
    // const url = `create-payment-link?userId=${userId}&amount=${amount}`;
    const url = 'admin/recruiter/create_payment_gcoin'
    try {
      const response = await axios.post(
        `${esocialUrl}/${url}`, postData,{
          validateStatus:function(status){
            return status>=200 &&status<500;
          }
        }
      );
      if (response.data.status === 200) {
        setPaymentLink(response.data.data.link);
        console.log(response.data.link)
        console.log(response.data.data.link)
        setError('');
      }
      else if (response.data.status === 400) {
        setPaymentLink('')
        console.log(`Status 400 Error: ${response.data.data}`)
        setError(response.data.message);
      }
      else {
        setPaymentLink('')
        console.log(response.data.data.message)
        setError(response.data.message);
      }
    } catch (error) {
      setPaymentLink('')
      setError(`An error occured while creating payment ${error}`)
      console.error('An error occured while creating the payment link:', error.message)
    }
    setLoading(false);
  };

  //   sendHttpCall('POST', url, postData, props)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setPaymentLink(response.data.link);
  //         setError('');
  //       } else {
  //         setError(response.message);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       // setError('An error occurred while creating the payment link.');
  //       setLoading(false);
  //       console.log(err);
  //       setError('An error occurred while creating the payment link. ' + err.error);
  //     });
  // };

  return (
    <div className={classes.root}>
      <div className="createPaymentLink__title">
        Create Payment Link
      </div>
      <div className="createPaymentLink__text">
        Enter User ID and Amount
      </div>
      <div className="createNewPayment_container">
        <div>
          <div className="createNewPayment_label">Enter User ID</div>
          <input
            type="number"
            className="createNewPayment_input"
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
          />
        </div>
        <div>
          <div className="createNewPayment_label">Enter Amount</div>
          <input
            type="number"
            className="createNewPayment_input"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </div>
        <button
          className="createNewPayment_button"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Payment Link'}
        </button>
      </div>
      {paymentLink && (
        <div className="createPaymentLink__result">
          Payment Link: <a href={paymentLink} target="_blank" rel="noopener noreferrer">{paymentLink}</a>
        </div>
      )}
      {error && <div className="createPaymentLink__error">{error}</div>}
    </div>
  );
};

export default CreatePaymentLink;
