
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { status, apiUrl, headers, message } from '../../../config'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,

} from '@material-ui/core';


import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BusinessServiceTypeList from './BusinessServiceTypeList';
import { sendHttpCall } from 'shareService/httpService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = {
  business_service_type_id: {},
  business_service_type: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 20
    }
  },
  status: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 20
    }
  },
};

const useStyles = makeStyles(theme => ({

  formControl: {
    margin: theme.spacing(1),
    minWidth: "95%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentList: {
    marginTop: '2%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const BusinessServiceType = props => {

  const { history } = props;
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [businessServiceTypes, setBusinessServiceTypes] = useState([]);

  async function fetchMyAPI() {
    sendHttpCall('GET', 'business_services_types', null, props).then((data) => {
      if (data) {
        setBusinessServiceTypes(data);
      }
    });
  }

  async function createNew(event) {

    let response = await fetch(`${apiUrl}/business_services_types`, {
      method: 'POST',
      body: JSON.stringify(formState.values),
      headers: headers
    })
    response = await response.json();
    toast.success('Business Service Type' + message.ADD);

    await setFormState(formState => ({
      ...formState,
      isValid: false,
      values: {
        business_service_type: null,
        businessTypeStatus: null,
      },
    }));
  }

  async function DeleteRecord(id) {
    let response = await fetch(`${apiUrl}/business_services_types/delete/${id}`, {
      method: 'delete',
      headers: headers
    })
    response = await response.json()
    toast.success('Business Service Type' + message.DELETE);
    fetchMyAPI()
  }

  useEffect(() => {
    toast.configure({
      autoClose: 2000,
      draggable: false,
      //etc you get the idea
    });
    fetchMyAPI();
    const errors = validate(formState.values, schema);


    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      isValid: true,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSignUp = event => {
    event.preventDefault();
    createNew(event);
  };

  const getDataFromChild = (childData) => {
    setFormState(formState => ({
      ...formState,
      isValid: true,
      values: {
        business_service_type_id: childData.business_service_type_id,
        business_service_type: childData.business_service_type,
        status: childData.status
      },
    }));

  }

  const getDataforDelete = (childData) => {
    DeleteRecord(childData.business_service_type_id);
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
    
  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={6}
          xs={6}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignUp}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Business service Types
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Business Service Type"
                  name="business_service_type"
                  onChange={handleChange}
                  autoComplete='off'
                  type="text"
                  value={formState.values.business_service_type || ''}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  label="Select Status"
                  margin="dense"
                  name="status"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formState.values.status || ''}
                  variant="outlined"
                >
                  <option
                    key={""}
                    value={""}
                  >
                    {""}
                  </option>
                  {status.map(option => (
                    <option
                      key={option.id}
                      value={option.name}
                    >
                      {option.name}
                    </option>
                  ))}
                </TextField>

                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  SAVE
                </Button>
              </form>
            </div>
          </div>
        </Grid>

        <Grid
          className={classes.contentList}
          item
          lg={6}
          xs={6}
        >
          <BusinessServiceTypeList businessServiceTypes={businessServiceTypes} editRow={getDataFromChild} deleteRow={getDataforDelete}></BusinessServiceTypeList>
        </Grid>
      </Grid>


    </div>
  );
};

BusinessServiceType.propTypes = {
  history: PropTypes.object
};

export default withRouter(BusinessServiceType);

