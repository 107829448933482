import React, { useState, useEffect } from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import './style.scss';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  score: {
    // margin: theme.spacing(3),
    border: '2px solid #3f51b5',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 10
  }
}));

export default function UserCompensationDetails(props) {
  const classes = useStyles();
  const { className, userid, user, ...rest } = props;
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography gutterBottom variant="h4">
            User Expected Compensation
          </Typography>
          <Divider gutterBottom />
          <div className="com_container">
            {user?.jobData && (
              <>
                <div className="com_title">Job</div>
                <div className="com_data">
                  <div className="com_data-grp">
                    <div className="com_data-key">Annual Compensation :</div>
                    <div className="com_data-value">
                      {user?.currency === 'USD'
                        ? user?.jobData?.monthlyCompensation?.value
                        : getCompensationFromIdNew(
                            user?.jobData?.monthlyCompensation?.id
                          )}
                    </div>
                  </div>
                  <div className="com_data-grp">
                    <div className="com_data-key">Type of Work :</div>
                    <div className="com_data-value">
                      {user?.jobData?.userPurpose
                        .map((item) => item.purpose)
                        .join(',')}
                    </div>
                  </div>
                </div>
              </>
            )}
            {user?.freelanceData && (
              <>
                <div className="com_title">Freelance</div>
                <div className="com_data">
                  <div className="com_data-grp">
                    <div className="com_data-key">Hourly Rate :</div>
                    <div className="com_data-value">
                      {user?.freelanceData?.hourlyCompensation?.value}
                    </div>
                  </div>
                  <div className="com_data-grp">
                    <div className="com_data-key">Service Provided :</div>
                    <div className="com_data-value">
                      {user?.freelanceData?.typeOfWork.value}
                    </div>
                  </div>
                </div>
              </>
            )}
            {user?.entrepreneurData && (
              <>
                <div className="com_title">Entrepreneurship</div>
                <div className="com_data">
                  <div className="com_data-grp">
                    <div className="com_data-key">Earning Potential :</div>
                    <div className="com_data-value">
                      {user?.entrepreneurData?.earningPotential?.value}
                    </div>
                  </div>
                  <div className="com_data-grp">
                    <div className="com_data-key">Investment Potential :</div>
                    <div className="com_data-value">
                      {user?.entrepreneurData?.investmentPotential?.value}
                    </div>
                  </div>
                  <div className="com_data-grp">
                    <div className="com_data-key">Work Type :</div>
                    <div className="com_data-value">
                      {user?.entrepreneurData?.workType?.value}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Paper>
      </div>
    </>
  );
}
const getCompensationFromIdNew = (id) => {
  switch (id) {
    case 1:
      return '1.2-3 LPA';
    case 2:
      return '3-6 LPA';
    case 3:
      return '6-9 LPA';
    case 4:
      return '9-12 LPA';
    case 5:
      return '12-18 LPA';
    case 6:
      return '18-24 LPA';
    case 7:
      return '24-36 LPA';
    case 8:
      return '36-60 LPA';
    case 9:
      return '>60 LPA';
    case 10:
      return '₹ 100 - 200';
    case 11:
      return '₹ 200 - 350';
    case 12:
      return '₹ 350 - 500';
    case 13:
      return '₹ 500 - 700';
    case 14:
      return '₹ 700 - 1000';
    case 15:
      return '₹ 1k - 1.5k';
    case 16:
      return '₹ 1.5k - 2k';
    case 17:
      return '₹ 2k - 3k';
    case 18:
      return '₹ 3k - 5k';
    case 19:
      return '> ₹ 5k';
    default:
      return '';
  }
};
