import React, { useState, useEffect, useRef } from 'react';
import styles from './CustomFormComponents.module.scss';

export const CustomInputText = ({ title, subTitle, placeholder, name, value, onChange, errorMessage, required, style }) => (
  <div className={`${styles.row} ${styles.column}`}>
    {title && (
      <div className={styles.title}>
        {title}
        {required && <span className={styles.requiredAsterisk}>*</span>}
      </div>
    )}
    {subTitle && (
      <div className={styles.subTitle}>
        {subTitle}
      </div>
    )}
    <input
      type="text"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={`${styles.textField} ${errorMessage ? styles.errorField : ''}`}
      style={{ ...style }}
    />
    {errorMessage && <p className={styles.errorMessage}>&nbsp;{errorMessage}</p>}
  </div>
);