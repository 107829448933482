/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';
// import AudioPlayer from './AudioPlayer';
import MaterialTable, { MTablePagination } from 'material-table';
import moment from 'moment';
import styles from './candidateFeedback.module.scss';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import ReactModal from 'react-modal';
import JobCard from 'components/JobCard/JobCard';

const CandidateFeedback=(props)=> {
  const { match } = props;
  const { offerId, userId } = match.params;
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);
  const [finishedInterviewsCount, setFinishedInterviewsCount] = useState(0);
  const [bloodDonorRequestId, setBloodDonorRequestId] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);

  // const getEveryOtherTableData = async () => {
  //   return new Promise((resolve, reject) => {
  //     setIsLoading(true);
  //     let url = `interview/`;
  //     sendHttpCall('GET', url, null, props).then((response) => {
  //       setIsLoading(false);
  //       setData(response.data);
  //     });
  //   });
  // };

  const getEveryOtherTableData = async (userID, offerID) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      let url = `interview/candidateFeedback`; // Replace <your-api-base-url> with your actual API base URL
      let body = {
        userId: userID,
        offerId: offerID
      };
      
      console.log("Sending request to:", url, "with body:", body); // Debugging log
  
      sendHttpCall('POST', url, body, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
        resolve(response);
      }).catch((error) => {
        console.error("Error in getEveryOtherTableData:", error); // Debugging log
        setIsLoading(false);
        reject(error);
      });
    });
  };
  

  const getData = async () => {
    getEveryOtherTableData(userId, offerId);
  };
  useEffect(() => {
    getData();
  }, [page]);
  useEffect(() => {
    getData();
  }, [userId, offerId]);
  const toDoubleDecimalPlaces = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>Interview Details</div>
        {/* <div className={styles.subtitle}>
          <Link to={`/account/${data?.userDetails?.user_id}`}>
            {data?.userDetails?.name}
          </Link>{' '}
          interviewing for{' '}
          <span onClick={() => setShowJobDetails(true)}>
            {data?.jobDetails?.title}
          </span>
        </div> */}
        <div className={styles.CandidateStatus}>
          Candidate status :
          <span >
            {data?.interviewClearanceStatus}
          </span>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.chatboxContainer}>
            <div className={styles.chatBoxMessages}>
              {data.messages?.map((item) =>
                item.sentBy === 'HR' ? (
                  <MessageReceived
                    text={item.message}
                    date={new Date(item.createdAt)}
                    playAudio={setAudioSrc}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : item.sentBy === 'USER' ? (
                  <MessageSent
                    text={item.message}
                    playAudio={setAudioSrc}
                    date={new Date(item.createdAt)}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : null
              )}
            </div>
          </div>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryTitle}>Summary</div>
            <div className={styles.summaryText}>{data.interviewSummary}</div>
          </div>
        </div> */}
      </div>{' '}
      {data.swotAnalysis && (
  <div className={styles.container3}>
    <div className={styles.title2}>SWOT Analysis</div>
    <div className={styles.interviewScores2Container}>
      {Object.entries(data.swotAnalysis).map(([key, value], index) => (
        <div key={index} className={styles.interviewScores2}>
          <div className={styles.interviewScoreSno}>{index + 1}.</div>
          <div>
            <div className={styles.interviewScoreLabel}>
              {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}

{data.detailedReasoning && data.detailedReasoning.length > 0 && (
  <div className={styles.container3}>
    <div className={styles.title2}>Detailed Reasoning</div>
    <div className={styles.interviewScores2Container}>
      {data.detailedReasoning.map((item, index) => (
        <div key={index} className={styles.interviewScores2}>
          <div className={styles.interviewScoreSno}>{index + 1}.</div>
          <div>
            <div className={styles.interviewScoreLabel}>
              Reason: {item.reason}
            </div>
            <div className={styles.interviewScoreLabel}>
              Linked To: {item.linkedTo}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}


  <div className={styles.container3}>

    {/* Improvement Areas Section */}
    {data.improvementArea && (
      <div className={styles.improvementAreas}>
        <div className={styles.interviewScoreLabel}>Improvement Areas</div>
        <div className={styles.interviewScoreValue}>{data.improvementArea}</div>
      </div>
    )}

  </div>
  
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}

export default CandidateFeedback
// export default {CandidateFeedback};
