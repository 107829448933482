import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { sendHttpCall } from 'shareService/httpService';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({}));

const FullMember = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {}, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <MaterialTable
          page={page}
          onChangePage={setPage}
          onChangeRowsPerPage={setPageSize}
          // actions={[
          //   {
          //     icon: 'send',
          //     tooltip: 'Open User',
          //     onClick: (event, rowData) => {
          //       props.history.push(`/account/${rowData.user_id}`);
          //     }
          //   }
          // ]}timestamp
          columns={[
            {
              title: 'S No.',
              render: (rowData) => page * pageSize + rowData.tableData.id + 1
            },
            {
              title: 'Name',
              field: 'name',
              render: (rowData) => (
                <div
                  onClick={() => {
                    props.history.push(`/account/${rowData.user_id}`);
                  }}
                  style={{ cursor: 'pointer' }}>
                  {rowData.name}
                </div>
              )
            },
            {
              title: 'Count',
              field: 'count'
            }
          ]}
          data={(query) => {
            return new Promise((resolve, reject) => {
              let url = `users/getFeedbackRequestData?limit=${query.pageSize}&page=${query.page}&start_date=${props.match.params.start_date}&end_date=${props.match.params.end_date}`;
              sendHttpCall('GET', url).then((response) => {
                if (response.data) {
                  resolve({
                    data: response.data,
                    page: parseInt(response.page),
                    totalCount: response.count
                  });
                } else {
                  reject({ error: 'some error occured' });
                }
              });
            });
          }}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 50, 100]
          }}
          title="Feedback Requests Data"
        />
      </CardContent>
    </Card>
  );
};

FullMember.propTypes = {
  className: PropTypes.string
};

export default withRouter(FullMember);
