import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { sendHttpCall } from 'shareService/httpService';
import { makeStyles } from '@material-ui/styles';
import styles from './design.module.scss';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import { selectoptions } from '../../config';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Grid,
  Typography,
  TextField,
  Chip,
  Button
} from '@material-ui/core';
import { useLeadDrawer } from '../../contexts/LeadDrawerContext';
import { useSelector } from 'react-redux';

export default function SalesPipeline(props) {
  const userType = localStorage.getItem('user_type');
  const userName = localStorage.getItem('user_name');
  const adminId = localStorage.getItem('admin_id');

  const [selectedStartDate, setSelectedStartDate] = useState(
    localStorage.getItem('pipelineStartDate') || moment().format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    localStorage.getItem('clickEndDate') || moment().format('YYYY-MM-DD')
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('selectedPeriodUserSearch') || 'Today'
  );
  const { openDrawer , isOpen } = useLeadDrawer();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalFunnelValue, setTotalFunnelValue] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [salesAdmins, setSalesAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const staticConfig = useSelector((state) => state.staticConfig.data)

  const getSalesAdmins = (adminData) => {
    // let url = `admin/users/SALES`;
    // sendHttpCall('GET', url, null, props)
    //   .then((response) => {
    //     const formattedAdmins = response.data.map((admin) => ({
    //       label: `${admin.name} (${admin.email_id})`,
    //       value: admin.admin_id
    //     }));
    //     setSalesAdmins([{ label: 'All Admins', value: '' }, ...formattedAdmins]);
    //   })
    //   .catch((error) => {
    //     console.log('Error fetching sales admins:', error);
    //   });
  
    const formattedAdmins = adminData.map((admin) => ({
      label: `${admin.name} (${admin.email_id})`,
      value: admin.admin_id
    }));
    setSalesAdmins([{ label: 'All Admins', value: '' }, ...formattedAdmins]);
  
  };

  const handleDrawerOpen = (leadId) => {
    openDrawer(leadId);
  };

  const openUserPage = (userId) => {
    window.open(`/dashboard#/account/${userId}`, '_blank');
  };

  const getTableData = async () => {
    setIsLoading(true);
    let url =
      'sales/pipeline' +
      `?limit=50&page=0`;
    
    if (userType !== 'ADMIN') {
      url += `&adminId=${adminId}`;
    } else if (selectedAdmin && selectedAdmin !== 'self') {
      url += `&adminId=${selectedAdmin}`;
    }

    sendHttpCall('GET', url, null, props).then((response) => {
      setIsLoading(false);
      setData(response.data);
      setTotalFunnelValue(response.totalFunnelValue);
      setTotalCount(response.data.total_Count);
    });
  };

  useEffect(() => {
    getTableData();
  }, [selectedEndDate, selectedStartDate, selectedAdmin]);
  
  useEffect(() => {
    if (userType === 'ADMIN' && staticConfig.loaded) {
      getSalesAdmins(staticConfig['allAdmins']);
    }
  }, [staticConfig, userType]);


  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        setSelectedStartDate(moment(new Date(2019, 1, 1)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));
        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
    }
  };

  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    if (event < selectedEndDate) {
      setOkdate(true);
    }
  };
  
  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      setOkdate(false);
    } else {
      setSelectedEndDate(event);
      setOkdate(true);
    }
  };

  const filterByDateRange = () => {
    setSelectedStartDate(moment(new Date(selectedStartDate)).format('YYYY-MM-DD'));
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
  }));
  const classes = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {userType === 'ADMIN' ? (
          "Sales Pipelines"
        ) : (
          "My Pipeline"
        )}
      </div>
      
      <div className="flex">
      {userType === 'ADMIN' ? (
          <div>
            <Typography variant="body2" color="textSecondary" component="p">
              SELECT SALES ADMIN
            </Typography>
            <TextField
  fullWidth
  select
  margin="dense"
  variant="outlined"
  value={selectedAdmin}
  onChange={(event) => setSelectedAdmin(event.target.value)}
  InputLabelProps={{
    style: { color: '#5f6368', fontWeight: 'bold' }
  }}
  SelectProps={{
    native: true,
    style: { fontSize: '1rem', padding: '2px' } // Improve dropdown appearance
  }}
>
  {salesAdmins.map((admin) => (
    <option key={admin.value} value={admin.value}>
      {admin.label}
    </option>
  ))}
</TextField>


          </div>
        ) : (
            <Typography
  variant="h4" 
  color="textPrimary"
  component="p"
  style={{
    fontWeight: 'bold',
    padding: '10px 0',
    color: '#297bca', 
  }}
>
  Hi, {userName}!
</Typography>

        )
    
    }

{totalFunnelValue && (
  <Chip
    label={`Funnel Value: ${totalFunnelValue.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
    })}`}
    style={{
      backgroundColor: '#297bca',
      color: '#FFFFFF',
      fontWeight: 'bold',
      padding: '10px',
      fontSize: '1rem',
      margin: '10px 0'
    }}
  />
)}

       {/* <div>
          <Typography variant="body2" color="textSecondary" component="p">
            SELECT PERIOD
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            onChange={handleTimeChange}
            required
            select
            SelectProps={{ native: true }}
            value={seletedPeriod}
            variant="outlined"
          >
            {selectoptions.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </TextField>
        </div>
        
       
        
        {isCustomDate && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                label="SELECT START DATE"
                format="dd/MM/yyyy"
                value={selectedStartDate}
                onChange={handleChangeStartDate}
              />
              <KeyboardDatePicker
                margin="normal"
                label="SELECT END DATE"
                format="dd/MM/yyyy"
                value={selectedEndDate}
                onChange={handleChageEndDate}
              />
              {okdate && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={filterByDateRange}
                >
                  SEARCH
                </Button>
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        )}
          */}
      </div>
      
      
      <MaterialTable
        isLoading={isLoading}
        columns={[
          {
            title: 'S No.',
            render: (rowData) => rowData.tableData.id + 1
          },
          { title: 'Company', field: 'companyName' },
          {
  title: 'Data Type',
  field: 'dataType',
  render: (rowData) => (
    <Chip
      label={rowData.dataType}
      style={{
        backgroundColor: rowData.dataType === 'Lead' ? '#4CAF50' : '#2196F3', // Green for Lead, Blue for Opportunity
        color: '#FFFFFF',
        fontWeight: 'bold',
        width: '100px',
        borderRadius: '16px',
        // padding: '4px 8px',
      }}
    />
  )
},

{
  title: 'Client Name',
  field: 'name',
  render: (rowData) => (
    <div
      style={{
        color: '#297bca',
        fontWeight: '500',
        cursor: 'pointer',
      }}
      onClick={() => rowData.dataType === 'Lead' 
        ? handleDrawerOpen(rowData._id) 
        : openUserPage(rowData.id || rowData.userId)}
    >
      {rowData.name}
    </div>
  )
},

          { title: 'Client Email', field: 'email' },
          { title: 'Contact', field: 'mobileNumber' },
          // {
          //   title: 'Next Follow Up',
          //   field: 'remarks',
          //   render: (rowData) => {
          //     const lastRemark = rowData.remarks[rowData.remarks.length - 1];
          //     return lastRemark ? (
          //       <span style={{ color: 'red', fontWeight: 'bold' }}>
          //         {moment(new Date(lastRemark.nextFollowUpAt)).format('DD/MM/YYYY h:mm A')}
          //       </span>
          //     ) : '';
          //   }
          // },               
          {
            title: 'Expected Revenue',
            field: 'expectedRevenue',
            render: (rowData) =>
              rowData.expectedRevenue != null
                ? `${rowData.currency} ${rowData.expectedRevenue}`
                : 'N/A'
          },          
          {
  title: 'Created At',
  field: 'createdAt',
  render: (rowData) => moment(new Date(rowData.createdAt)).format('DD/MM/YYYY h:mm A')
},

          { title: 'Source', field: 'source' },
          { title: 'Created By', field: 'createdBy.adminName' },
        ]}
        data={data}
        totalCount={totalCount}
        title=""
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: 50,
          pageSizeOptions: [20, 50],
          sorting: true
        }}
      />
    </div>
  );
}
