/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CheckIcon from '@material-ui/icons/Check';
import MaterialTable, { MTablePagination } from 'material-table';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import ReactModal from 'react-modal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dropdown: {
    maxWidth: '92%'
  },
  containerpop: {
    padding: 20,
    width: '80%',
    borderColor: '#000',
    borderWidth: 2
  },
  srch_btn: { margin: 8 },
  container_inside_pop: {
    padding: 30
  },
  popbtn: {
    alignSelf: 'right'
  },
  space: {
    margin: 2
  }
}));
export default function EveryTable(props) {
  const classes = useStyles();
  const convertToYearly = (monthlyVal) => {
    switch (monthlyVal) {
      case '₹ 10k - 25k':
      case '₹10k - 25k':
        return '₹ 1.2L - 3L';
      case '₹ 25k - 50k':
      case '₹25k - 50k':
        return '₹ 3L - 6L';
      case '₹ 50k - 75k':
        return '₹ 6L - 9L';
      case '₹ 75k - 100k':
        return '₹ 9L - 12L';
      case '₹ 100k - 150k':
        return '₹ 12L - 18L';
      case '₹ 150k - 200k':
        return '₹ 18L - 24L';
      case '₹ 200k - 300k':
        return '₹ 24L - 36L';
      case '₹ 300k - 500k':
        return '₹ 36L - 60L';
      case '>  ₹ 500k':
        return '> ₹ 60L';
      default:
        return monthlyVal;
    }
  };
  const [filter, setFilter] = useState(
    sessionStorage.getItem('demointerview-dashboard') ? 'All' : 'Completed'
  );
  const titleCase = (str) => {
    if (!str) return '';
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  };
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const getTimeIn12Hours = (date) => {
    const hour = date.getHours();
    return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
      date.getMinutes()
    )} ${hour > 12 ? 'PM' : 'AM'}`;
  };
  const formatDate = (date, variation = 1) =>
    `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
      date.getMonth() + 1
    )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

  const formatTime = (time) => {
    const date = new Date(time);

    return getTimeIn12Hours(date) + ', ' + formatDate(date);
  };

  const [selectedJob, selectJob] = useState(null);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [finishedInterviewsCount, setFinishedInterviewsCount] = useState(0);
  const [bloodDonorRequestId, setBloodDonorRequestId] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const getLocaleTime = (time) => {
    if (time) {
      if (props.match.params.type === 'active_member')
        return moment(new Date(time + ' UTC')).format('DD/MM/YYYY h:mm:ss A');
      return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    }
    return '';
  };
  const startDate = useRef(
    props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
  );
  const endDate = useRef(
    props.match.params.end_date
      ? props.match.params.end_date
      : moment(new Date()).format('YYYY-MM-DD')
  );

  const openUserPage = (userId, social) => {
    sessionStorage.setItem('everyTablePage', page);
    sessionStorage.setItem('everyTableLimit', limit);
    props.history.push(`/account/${userId}${social ? '?type=SOCIAL' : ''}`);
  };
  const columns = [
    {
      title: 'S No.',
      cellStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      headerStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFF'
      },
      render: (rowData) => page * 20 + rowData.tableData.id + 1
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openUserPage(rowData.userDetails.user_id);
          }}>
          {rowData.userDetails.name}
        </div>
      )
    },
    {
      title: 'Email Id',
      field: 'email_id',
      render: (rowData) => <div>{rowData.userDetails.email_id}</div>
    },
    {
      title: 'Company',
      field: 'company_name',
      render: (rowData) => (
        <div>{rowData.userDetails.recruiter_company_name}</div>
      )
    },
    {
      title: 'Job Title',
      field: 'job_title',
      render: (rowData) => rowData.jobDetails?.title
    },

    {
      title: 'Message Count',
      field: 'messageCount',
      render: (rowData) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            props.history.push('/auto-interview/interview/' + rowData._id)
          }>
          {rowData.messageCount - 1}
        </div>
      )
    },

    // {
    //   title: 'Status',
    //   render: (rowData) => rowData.interviewStatus
    // },
    {
      title: 'Stop Reason',
      render: (rowData) =>
        rowData.adminRemarks
          ?.replace('INTERVIEW ENDED BY USER', 'Ended by user')
          .replace('ABANDONED INTERVIEW', 'Abandoned')
    },
    {
      title: 'Started At',
      field: 'startedAt',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.startedAt)
    },
    {
      title: 'Finished At',
      field: 'completedAt',
      type: 'datetime',
      headerStyle: {
        position: 'static',
        backgroundColor: '#FFF'
      },
      render: (rowData) => getLocaleTime(rowData.completedAt)
    },
    {
      title: 'Grade',
      field: 'grade'
    }
  ];
  const getTableLabel = () => {
    let { type } = props.match.params;
    const countryCode = window.location.href.split('?countryCode=')[1];

    if (type === 'social_connect_requests') {
      return 'Social Connect Requests Table';
    }
    if (type === 'social_connect_requests_accepted') {
      return 'Social Connect Requests Accepted Table';
    }
    if (type === 'social_connect_requests_rejected') {
      return 'Social Connect Requests Rejected Table';
    }
    if (type === 'active_member') {
      return 'New Users Table';
    }
    if (type === 'active_member-country') {
      return `New Users Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'active_hirers') {
      return 'Active Hirers Table';
    }
    if (type === 'active_hirers-country') {
      return `Active Hirers Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'verified_hirers') {
      return 'Verified Hirers Table';
    }
    if (type === 'verified_hirers-country') {
      return `Verified Hirers Table ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'reportedJobs') {
      return 'Reported Jobs';
    }
    if (type === 'feedback') {
      return 'Feedbacks Given';
    }
    if (type === 'status1') {
      return 'Status 1 Users';
    }
    if (type === 'status1-country') {
      return `Status 1 Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'status2') {
      return 'Status 2 Users';
    }
    if (type === 'status2-country') {
      return `Status 2 Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'feedback_conversions') {
      return 'Users Converted From Feedback Requests';
    }
    if (type === 'by_country') {
      return `Users From ${countries[props.match.params.period]}`;
    }
    if (type === 'device_type') {
      return `Users From ${props.match.params.period}`;
    }
    if (type === 'by_campaign') {
      return `Users From ${props.match.params.period} Campaign`;
    }
    if (type === 'survey_sent') {
      return 'Survey Messages Sent';
    }
    if (type === 'survey_link_clicks') {
      return 'Survey Link Clicks';
    }
    if (type === 'survey_received') {
      return 'Survey Received';
    }
    if (type === 'closedJobs') {
      return 'Closed Jobs';
    }
    if (type === 'deactivated') {
      return 'Deactivated Users';
    }
    if (type === 'deactivated-country') {
      return `Deactivated Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'inactive_hirers') {
      return 'Inactive Hirers';
    }
    if (type === 'active_hirers-2') {
      return 'Active Hirers';
    }
    if (type === 'blocked') {
      return 'Blocked Users';
    }
    if (type === 'blocked-country') {
      return `Blocked Users ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    }
    if (type === 'transactions') {
      return 'Transactions';
    }
    if (type === 'failed-transactions') {
      return 'Failed Transactions';
    }
    if (type === 'created-orders') {
      return 'Created Orders';
    }

    if (type === 'survey_conversions') {
      return 'Users Converted From Survey Messages';
    }
    if (type === 'referrals_campus_ambassadors')
      return 'Referrals (Campus Ambassadors)';
    if (type === 'referrals_others') return 'Referrals (Others)';
    if (type === 'status2Hirer') return 'Hirer Status 2';
    if (type === 'status2Hirer-country')
      return `Hirer Status 2 ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    if (type === 'status2Member') return 'Job Seeker Status 2';
    if (type === 'status2Member-country')
      return `Job Seeker Status 2 ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
    if (type === 'status4') return 'Status 4 Only Members';
    if (type === 'find-blood-conversions') return 'Find Blood Conversions';
    if (type === 'find-blood-only-users') return 'Find Blood Only Users';
    if (type === 'blood-donor-requests') return 'Blood Donor Requests';
    if (type === 'blood-donor-requests-accepted')
      return 'Blood Donor Requests Accepted';
    if (type === 'blood-donor-requests-rejected')
      return 'Blood Donor Requests Rejected';
    if (type === 'blood-donor-requests-admin')
      return 'Blood Donor Requests Admin';
    if (type === 'resume-downloaded') return 'Resume Generated';
    if (type === 'resume-audio-uploaded') return 'Resume Audio Uploaded';
    if (type === 'status4-country')
      return `Status 4 Only Members ${
        countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
      }`;
  };
  const getEveryOtherTableData = async () => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      let url =
        'interview/interview-data?limit=' +
        limit +
        '&page=' +
        page +
        `&startDate=${startDate.current.toString()}&endDate=${endDate.current.toString()}&type=${
          props.match.params.type
        }&filter=${filter}`;
      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
        setTotalCount(response.totalCount);
        setFinishedInterviewsCount(response.finishedInterviewsCount);
      });
    });
  };
  const getData = async () => {
    getEveryOtherTableData();
  };

  useEffect(() => {
    if (page === 0) getData();
    setPage(0);
  }, [filter]);
  useEffect(() => {
    getData();
    sessionStorage.removeItem('everyTablePage');
    sessionStorage.removeItem('everyTableLimit');
  }, [page]);
  useEffect(() => {
    if (!data.length) return;
    if (page === 0) getData();
    else setPage(0);
  }, [limit]);
  return (
    <div>
      <div className="everyTable__title">
        {titleCase(props.match.params.type)} Interviews
      </div>{' '}
      <div style={{ width: '15rem', marginLeft: '1rem' }}>
        <Grid container spacing={4}>
          <Grid item container justify="space-around">
            <Grid container>
              <Grid xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                  FILTER
                </Typography>
                <TextField
                  fullWidth
                  // label="Select Period"
                  margin="dense"
                  name="status"
                  onChange={(event) => setFilter(event.target.value)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={filter}
                  variant="outlined"
                  // placeholder="select period"
                >
                  {/* <option value={""} > All time </option> */}
                  <option value="Completed">Completed</option>
                  <option value="Incomplete">Incomplete</option>
                  <option value="Ongoing">Ongoing</option>
                  <option value="All">All</option>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="everyTable__table">
        <MaterialTable
          isLoading={isLoading}
          columns={columns}
          data={data}
          totalCount={totalCount}
          page={page}
          title=""
          onChangePage={(e) => {
            console.log('Changing page - ' + e);
            setPage(e);
          }}
          onChangeRowsPerPage={(e) => setLimit(e)}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: limit,
            pageSizeOptions: [10, 20, 30, 40, 50],
            sorting: false
          }}
        />
      </div>{' '}
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}
